import { get } from "lodash"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { isJsonString } from "../helpers/isJSONString"
import { REGEX } from "../../../constants/regexConstants"

const generateKey = (key, path, questionKey) => {
  return `${key}.${path}.${questionKey}`
}

const updateFieldWithAnswer = (field, answer, autoFillAnswer, handleFieldUpdate, handleSearchSuffixClick, path) => {
  // console.log('path', path, field)

  const { defaultValue, regoStateField } = autoFillAnswer
  if (regoStateField) {
    if (isJsonString(answer)) {
      answer = JSON.parse(answer)?.[regoStateField]
    }
  }

  if (answer !== undefined) {
    if (defaultValue) {
      handleFieldUpdate(defaultValue)
      return
    }
    // If field has options then check answer exists in options
    if ("options" in field) {
      let selectedOption = field?.options?.find(x => x === answer || x?.label === answer || x?.value === answer)
      if (field?.isMultipleSelect && Array.isArray(answer)) {
        selectedOption = answer.filter(
          ans => field?.options?.findIndex(x => x === ans || x?.label === ans || x?.value === ans) >= 0,
        )
      }
      if (selectedOption) {
        handleFieldUpdate(selectedOption?.value || selectedOption, selectedOption?.label)
        return
      }
    }

    // if field doesn't have request or options then we can directly add answer to field
    if ("request" in field && field?.type === "search") {
      handleFieldUpdate(answer)
      if (handleSearchSuffixClick) handleSearchSuffixClick(answer)
      return
    }

    // if field doesn't have request or options then we can directly add answer to field
    if (!("request" in field) && !("options" in field)) {
      handleFieldUpdate(answer)
    }
  }
}

export const useAutoFillAnswer = (
  field,
  pathOfField,
  handleFieldUpdate,
  handleSearchSuffixClick,
  autoFillFieldsRef,
) => {
  const { formData } = useSelector(state => state?.brokerReducerNew)

  // Removes stored field in ref if that field is removed from DOM
  useEffect(() => {
    return () => {
      if (field?.autoFillAnswer) {
        for (const autoFillAnswer of field?.autoFillAnswer) {
          const { key, path, questionKey } = autoFillAnswer

          const storedKey = generateKey(key, path, questionKey)
          if (storedKey in autoFillFieldsRef.current) delete autoFillFieldsRef.current[storedKey]
        }
      }
    }
  }, [])

  // find field mentioned in autoFillAnswer flag and fills answer to current field
  useEffect(() => {
    let answer

    if (field?.autoFillAnswer) {
      for (const autoFillAnswer of field?.autoFillAnswer) {
        const { key, path, questionKey } = autoFillAnswer

        // START   -------------------------------------------------------------- storing data for autoFill fields in ref so it can be used later to update answer
        const storedKey = generateKey(key, path, questionKey)

        autoFillFieldsRef.current[storedKey] = {
          field,
          autoFillAnswer,
          handleFieldUpdate,
          handleSearchSuffixClick,
        }
        // END     -------------------------------------------------------------- storing data for autoFill fields in ref so it can be used later to update answer

        if (!field?.answer) {
          answer = get(formData, path, []).find(x => x.key === questionKey)?.answer
          updateFieldWithAnswer(field, answer, autoFillAnswer, handleFieldUpdate, handleSearchSuffixClick, path)
        }
      }
    }
  }, [field, field?.options])

  // If answer is changed in field then update that answer where its being used as autoFillAnswer
  useEffect(() => {
    if (!field?.autoFillAnswer && field?.key && typeof pathOfField === "string") {
      const path = pathOfField.replace(REGEX.REMOVE_SQUARE_BRACKED_AND_CONTENT_INBETWEEN, "")
      const storedKeys = Object.keys(autoFillFieldsRef.current).filter(x => x.includes(`${path}.${field.key}`))

      storedKeys.forEach(storedKey => {
        if (storedKey in autoFillFieldsRef.current) {
          updateFieldWithAnswer(
            autoFillFieldsRef.current[storedKey].field,
            field.answer,
            autoFillFieldsRef.current[storedKey].autoFillAnswer,
            autoFillFieldsRef.current[storedKey].handleFieldUpdate,
            autoFillFieldsRef.current[storedKey].handleSearchSuffixClick,
          )
        }
      })
    }
  }, [field?.answer])
}
