import { ASSET_REDUX_CONSTANTS } from "./AssetReduxConstants"
import AssetApiServices from "../services/AssetServices"
import { BASE_URL } from "../../../constants/UrlConstants"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import QuoteApiServices from "../../quote/services/QuoteServices"
import _ from "lodash"
import { displayErrors } from "../../../helpers/ErrorNotifyHelper"

export const setAssetData = assets => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await AssetApiServices.setAssettoZOHO(assets)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: ASSET_REDUX_CONSTANTS.SET_ASSET_ZOHO_DATA,
          data: response?.data?.data,
        })
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const handleRegoSearch = (url, method, params) => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.getDataFromExternalSources(`${BASE_URL}${url}`, _.toUpper(method), params)
      if (response?.data?.status === "SUCCESS") {
        dispatch(
          updateAssetAnswersREGO(
            "assetDescriptionrego",
            response?.data?.data?.yearCreate + " " + response?.data?.data?.modelName,
            false,
          ),
        )
        dispatch(updateAssetAnswersREGO("vin", response?.data?.data?.vin, false))
        dispatch(updateAssetAnswersREGO("rego", response?.data?.data?.rego, false))
        dispatch(updateAssetAnswersREGO("state", response?.data?.data?.state, false))
        dispatch(updateAssetAnswersREGOData(response?.data?.data))
        const responseNew = await QuoteApiServices.getDataFromExternalSources(`${BASE_URL}glassGuide`, "GET", {
          identifier: "getManufacturerOptions",
          optionType: "O",
          modelTypeCode: "A",
          nvic: response?.data?.data?.nvic,
        })
        if (responseNew) {
          const lvrData = response?.data?.data
          // delete lvrData?.table
          dispatch({
            type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_LVR_DATA,
            data: JSON.stringify(lvrData),
          })
        }
      }
    } catch (e) {
      displayErrors(e)
      dispatch(updateAssetAnswersREGO("assetDescriptionrego", "", false))
      dispatch(updateAssetAnswersREGO("vin", "", false))
      dispatch(updateAssetAnswersREGO("rego", "", false))
      dispatch(updateAssetAnswersREGO("state", "", false))
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}

export const updateAssetAnswersREGOData = data => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_REGO_DATA,
  data,
})
export const updateAssetFlags = (fieldName, fieldValue) => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FLAG_DATA,
  fieldName,
  fieldValue,
})
export const updateAssetAnswers = (fieldName, fieldValue) => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_ANSWER_DATA,
  fieldName,
  fieldValue,
})

export const updateAssetAnswersREGO = (fieldName, fieldValue) => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FIELD_REGO_ANSWER_DATA,
  fieldName,
  fieldValue,
})

export const showFieldOnAssetAnswer = nextField => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FIELD_ANSWER_DATA,
  nextField,
})
export const updateAssetGlassGuideLabelData = (fieldName, fieldLabel) => ({
  type: ASSET_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_LABEL_DATA,
  fieldName,
  fieldLabel,
})
