import { Input } from "../../../common"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useEffect, useMemo, useRef, useState } from "react"
import _ from "lodash"
import {
  adverseCreditHistoryReferenceCalculation,
  employmentTimeInDaysCalculation,
  getAdjustedSurPlusFieldValue,
  getSurPlusFieldValue,
  incomeCalculation,
  monthlyRepaymentCalculation,
  movedInDateCalculation,
  movedInDateInDaysCalculation,
  netAssetAndLiabilitiesCalculation,
  netIncomeAndOutgoingCalculation,
  netIncomeCalculation,
  outGoingCalculation,
  prevAddressInDaysCalculation,
  prevEmployerDateInDaysCalculation,
  previousMoveInDateCalculation,
  previousMoveOutDateCalculation,
  toBeFinancedCalculation,
} from "../../../helpers/applicationFormulaHelper"
import { applicationSelector } from "../redux/ApplicationSelector"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { DATE_FORMAT } from "../../../constants/regexConstants"
import moment from "moment"
import dayjs from "dayjs"
import { dateCheck } from "../hooks/useGlassGuide"

const employementCalculationFields = [
  "SOLE_TRADER_DATE_IN_DAYS",
  "PERMANENT_PART_TIME_IN_DAYS",
  "PENSION_DATE_IN_DAYS",
  "UN_EMPLOYED_DATE_IN_DAYS",
  "DIRECTOR_DATES_IN_DAYS",
  "PERMANENT_FULL_TIME_IN_DAYS",
  "CASUAL_DATE_IN_DAYS",
]

const ApplicationFormula = ({
  formulaName,
  inputType,
  fieldName,
  answer,
  titleWithRequired,
  handleFieldUpdate,
  indexForRepeater,
  isHide,
  isAmount,
  errorMessage,
  errorMessageDOM,
  isShowGrayedOut,
  suggestion,
  isEditable,
  minDate,
  isShowSeparate,
  isFromBrokerPage,
  isFromBrokerPageNew,
  currentForm,
  isQuoteField,
}) => {
  const { fieldsToShow, savedFormData, formsKey, subFormsKey, errorCounter } = useSelector(applicationSelector)

  const brokerDataNew = useSelector(state => state?.brokerReducerNew || {})
  const brokerDataOld = useSelector(state => state?.brokerReducer || {})

  const formData = useMemo(() => (isFromBrokerPage ? brokerDataOld?.formData : brokerDataNew?.formData), [
    isFromBrokerPage,
    brokerDataNew,
    brokerDataOld,
  ])
  const [fieldForCalculation, setFieldsForCalculation] = useState(null)
  const ref = useRef(fieldForCalculation)

  const fieldsToUse = (index = 0) => {
    if (isFromBrokerPage && formData && Array.isArray(currentForm) && currentForm?.length > 0) {
      return _.get(formData, currentForm.join("."))
    } else if (isFromBrokerPageNew && formData && Array.isArray(currentForm) && currentForm?.length > 0) {
      return _.get(formData, currentForm.join("."))
    } else {
      return fieldsToShow?.[index]
    }
  }

  const formDataToUse = formKey => {
    if (isFromBrokerPage && formData) {
      return formKey ? formData[formKey] : formData
    } else if (isFromBrokerPageNew && formData) {
      return formKey ? formData[formKey] : formData
    } else {
      return formKey ? savedFormData[formKey] : savedFormData
    }
  }
  const formKeyToUse = useMemo(() => {
    if (isFromBrokerPage) return currentForm?.[0]
    else if (isFromBrokerPageNew) return currentForm?.[0]
    else return formsKey?.[0]
  }, [formsKey])

  useEffect(() => {
    if (formulaName === "MONTHLY_INCOME") {
      const fields = fieldsToUse()
        ?.find(e => e?.fieldName === "income")
        ?.fields?.[indexForRepeater]?.map(e => ({
          fieldName: e?.fieldName,
          answer: e?.answer,
        }))
      const incomeHowOften = fields?.find(e => e?.fieldName === "incomeHowOften")?.answer
      const incomeAmount = fields?.find(e => e?.fieldName === "incomeAmount")?.answer
      setFieldsForCalculation({ incomeAmount, incomeHowOften })
    }
    if (formulaName === "SPOUSAL_INCOME") {
      const fields = fieldsToUse()
        ?.find(e => e?.fieldName === "income")
        ?.fields?.map(e => e?.find(field => field?.fieldName === "incomeType")?.answer)
      setFieldsForCalculation(fields)
    }
    if (formulaName === "MONTHLY_OTU_GOING") {
      const fields = fieldsToUse()
        ?.find(e => e?.fieldName === "outgoings")
        ?.fields?.[indexForRepeater]?.map(e => ({
          fieldName: e?.fieldName,
          answer: e?.answer,
        }))
      const outgoingHowOften = fields?.find(e => e?.fieldName === "outgoingHowOften")?.answer
      const outgoingAmount = fields?.find(e => e?.fieldName === "outgoingAmount")?.answer
      setFieldsForCalculation({ outgoingAmount, outgoingHowOften })
    }

    if (["MONTHLY_REPAYMENT", "COMPANY_MONTHLY_REPAYMENT"]?.includes(formulaName)) {
      const fieldName = formulaName === "MONTHLY_REPAYMENT" ? "liabilities" : "companyCommitmentSchedule"
      const howOften = formulaName === "MONTHLY_REPAYMENT" ? "liabilitiesHowOften" : "howOften"
      const repayment = formulaName === "MONTHLY_REPAYMENT" ? "liabilitiesRepayment" : "repayment"
      const fields = fieldsToUse()
        ?.find(e => e?.fieldName === fieldName)
        ?.fields?.[indexForRepeater]?.map(e => ({
          fieldName: e?.fieldName,
          answer: e?.answer,
        }))
      const f1 = fields?.find(e => e?.fieldName === howOften)?.answer
      const f2 = fields?.find(e => e?.fieldName === repayment)?.answer

      setFieldsForCalculation({ f1, f2 })
    }
    // if (formulaName === "NET_INCOME_AND_OUTGOINGS") {
    //   const assets = fieldsToUse()
    //     ?.find(e => e?.fieldName === "income")
    //     ?.fields?.map(e => e?.find(field => field.fieldName === "assetValue")?.answer)
    //   const limits = fieldsToUse()
    //     ?.find(e => e?.fieldName === "outgoings")
    //     ?.fields?.map(e => e?.find(field => field.fieldName === "increasedAmounts")?.answer)
    //   const assetTotal = assets?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
    //   const limitTotal = limits?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
    //   setFieldsForCalculation({ assetTotal, limitTotal })
    // }
    if (formulaName === "NET_ASSET_AND_LIABILITIES") {
      const assets = fieldsToUse()
        ?.find(e => e?.fieldName === "assets")
        ?.fields?.map(e => e?.find(field => field.fieldName === "assetValue")?.answer)
      const limits = fieldsToUse()
        ?.find(e => e?.fieldName === "liabilities")
        ?.fields?.map(e => e?.find(field => field.fieldName === "liabilitiesLimit")?.answer)
      const assetTotal = assets?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
      const limitTotal = limits?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
      setFieldsForCalculation({ assetTotal, limitTotal })
    }
    if (formulaName === "NET_INCOME") {
      const form1 = formDataToUse()?.applicantDetails1?.assetsAndLiabilities
      const netIncomes = form1?.find?.(e => e?.fieldName === "NETIncomeAndOutgoings")?.answer
      const form2 = formDataToUse()?.applicantDetails2?.assetsAndLiabilities
      const netIncomes2 = form2?.find?.(e => e?.fieldName === "NETIncomeAndOutgoings")?.answer
      setFieldsForCalculation({ netIncomes, netIncomes2 })
    }
    if (formulaName === "LENDER_SURPLUS_CALCULATION") {
      const netIncome = fieldsToUse()?.find(e => e?.fieldName === "surplus_Deficit")?.answer
      const newCurrentRepayment = fieldsToUse()?.find(e => e?.fieldName === "lenderBuffer")?.answer

      setFieldsForCalculation({ netIncome, newCurrentRepayment })
    }
    if (formulaName === "ADJUSTED_SURPLUS_CALCULATION") {
      const netIncome = fieldsToUse()?.find(e => e?.fieldName === "surplus_Deficit")?.answer
      const increasedAmountsPerMonth = fieldsToUse()?.find(e => e?.fieldName === "increasedAmountsPerMonth")?.answer
      const ReducedAmountsPerMonth = fieldsToUse()?.find(e => e?.fieldName === "ReducedAmountsPerMonth")?.answer

      setFieldsForCalculation({ netIncome, increasedAmountsPerMonth, ReducedAmountsPerMonth })
    }

    if (formulaName === "SURPLUS_CALCULATION") {
      const netIncome = fieldsToUse()?.find(e => e?.fieldName === "netIncome")?.answer
      const newCurrentRepayment = fieldsToUse()?.find(e => e?.fieldName === "newCurrentRepayment")?.answer
      setFieldsForCalculation({ netIncome, newCurrentRepayment })
    }
    if (formulaName === "NET_INCOME_AND_OUTGOINGS") {
      const form1 = formDataToUse(formKeyToUse)?.incomeAndOutgoings
      const incomes = form1
        ?.find?.(e => e?.fieldName === "income")
        ?.fields?.map(e => e?.find(field => field.fieldName === "monthlyIncome")?.answer)
      const outGoings = form1
        ?.find?.(e => e?.fieldName === "outgoings")
        ?.fields?.map(e => e?.find(field => field.fieldName === "monthlyOutgoing")?.answer)
      const monthlyRepayments = fieldsToUse()
        ?.find(e => e?.fieldName === "liabilities")
        ?.fields?.map(e => e?.find(field => field.fieldName === "liabilitiesMonthlyRepayment")?.answer)
      const incomeTotal = incomes?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
      const outGoingTotal = outGoings?.reduce((partialSum, a) => partialSum + (a ? parseFloat(a) : 0), 0)
      const monthlyRepaymentsTotal = monthlyRepayments?.reduce(
        (partialSum, a) => partialSum + (a ? parseFloat(a) : 0),
        0,
      )
      setFieldsForCalculation({ monthlyRepaymentsTotal, outGoingTotal, incomeTotal })
    }
    if (formulaName === "MOVED_IN_DATE") {
      const formIndex = subFormsKey?.findIndex(e => e === "addressInformation")
      const formData = fieldsToUse(formIndex)
      const fields = formData?.filter(e => ["currentPropertyYears", "currentPropertyMonths"]?.includes(e?.fieldName))
      const currMonth = fields?.find(e => e?.fieldName === "currentPropertyMonths")?.answer
      const currYear = fields?.find(e => e?.fieldName === "currentPropertyYears")?.answer

      setFieldsForCalculation({ currMonth, currYear })
    }
    if (["MOVED_IN_DATE_IN_DAYS", "TRADING_ADD_MOVE_IN_DATE"].includes(formulaName)) {
      const formName = formulaName === "MOVED_IN_DATE_IN_DAYS" ? "addressInformation" : "companyAddressDetails"
      const fieldName = formulaName === "MOVED_IN_DATE_IN_DAYS" ? "clientMoveInDate" : "tradingAddressMoveInDate"
      const formIndex = subFormsKey?.findIndex(e => e === formName)
      const formData = fieldsToUse(formIndex)
      const field = formData?.find(e => [fieldName]?.includes(e?.fieldName))
      const moveInDate = field?.answer
      setFieldsForCalculation({ moveInDate })
    }
    if (employementCalculationFields?.includes(formulaName)) {
      const fieldNameToCheck = formulaName === "SOLE_TRADER_DATE_IN_DAYS" ? "soleTraderStartDate" : "employerStartDate"
      const formIndex = subFormsKey?.findIndex(e => e === "employmentDetails")
      const formData = fieldsToUse(formIndex)
      const field = formData?.find(e => e?.fieldName === fieldNameToCheck)
      const startDate = field?.answer
      setFieldsForCalculation({ startDate })
    }
    if (formulaName === "ADVERSE_REFERENCE_FORMULA") {
      const formIndex = subFormsKey?.findIndex(e => e === "adverseCreditHistory")
      const formData = fieldsToUse(formIndex)
      const declaredBankrupt = formData?.find(e => ["haveYouEverBeenDeclaredBankrupt"]?.includes(e?.fieldName))?.answer
      const adverseCreditHistory = formData?.find(e => ["adverseCreditHistory"]?.includes(e?.fieldName))?.answer
      setFieldsForCalculation({
        adverseCreditHistory,
        declaredBankrupt,
      })
    }
    if (formulaName === "TO_BE_FINANCED") {
      const formIndex = subFormsKey?.findIndex(e => e === "assetPricingDetails")
      const formData = fieldsToUse(formIndex)
      const fields = formData?.filter(e => ["purchasePrice", "deposit", "tradeIn", "payout"]?.includes(e?.fieldName))
      const purchasePrice = fields?.find(e => e?.fieldName === "purchasePrice")?.answer
      const deposit = fields?.find(e => e?.fieldName === "deposit")?.answer
      const tradeIn = fields?.find(e => e?.fieldName === "tradeIn")?.answer
      const payout = fields?.find(e => e?.fieldName === "payout")?.answer
      let toBeFinanced = 0
      if (formKeyToUse === "asset2") {
        const asset1ToBeFinanced = formDataToUse()?.asset1?.assetPricingDetails?.find(
          e => e?.fieldName === "toBeFinanced",
        )?.answer
        toBeFinanced = parseFloat(asset1ToBeFinanced)
      }
      if (formKeyToUse === "asset3") {
        const asset2ToBeFinanced = formDataToUse()?.asset2?.assetPricingDetails?.find(
          e => e?.fieldName === "toBeFinanced",
        )?.answer
        toBeFinanced = parseFloat(asset2ToBeFinanced)
      }
      if (formKeyToUse === "asset4") {
        const asset3ToBeFinanced = formDataToUse()?.asset3?.assetPricingDetails?.find(
          e => e?.fieldName === "toBeFinanced",
        )?.answer
        toBeFinanced = parseFloat(asset3ToBeFinanced)
      }
      setFieldsForCalculation({ purchasePrice, deposit, tradeIn, payout, toBeFinanced })
    }
    if (formulaName === "YEARS_WITH_PREVIOUS_EMPLOYER") {
      const formIndex = subFormsKey?.findIndex(e => e === "previousEmploymentDetails")
      const formData = fieldsToUse(formIndex)
      const startDate = formData?.find(e => e?.fieldName === "startDate")?.answer
      const endDate = formData?.find(e => e?.fieldName === "endDate")?.answer
      setFieldsForCalculation({ startDate, endDate })
    }
    if (formulaName === "PREVIOUS_MOVED_IN_DATE_IN_DAYS") {
      const formIndex = subFormsKey?.findIndex(e => e === "previousAddressDetails")
      const formData = fieldsToUse(formIndex)
      const startDate = formData?.find(e => e?.fieldName === "previousMoveInDate")?.answer
      const endDate = formData?.find(e => e?.fieldName === "previousMoveOutDate")?.answer
      setFieldsForCalculation({ startDate, endDate })
    }

    if (formulaName === "CALCULATE_DAYS") {
      const formIndex = subFormsKey?.findIndex(e => e === "companyRegistrationDetails")
      const formData = fieldsToUse(formIndex)
      const startDate = formData?.find(e => e?.fieldName === "registeredFrom")?.answer
      setFieldsForCalculation({ startDate })
    }

    if (formulaName === "PREVIOUS_MOVE_IN_DATE") {
      const formIndex = subFormsKey?.findIndex?.(e => e === "previousAddressDetails")
      const liveInPreviousPropertyMonth = fieldsToUse(formIndex)?.find?.(
        field => field?.fieldName === "previousPropertyMonths",
      )?.answer
      const liveInPreviousPropertyYear = fieldsToUse(formIndex)?.find?.(
        field => field?.fieldName === "previousPropertyYears",
      )?.answer

      const addressInformationFields = formDataToUse(formKeyToUse)?.addressInformation
      const previousMoveOutDate = addressInformationFields?.find?.(field => field?.fieldName === "clientMoveInDate")
        ?.answer

      setFieldsForCalculation({
        liveInPreviousPropertyYear,
        liveInPreviousPropertyMonth,
        previousMoveOutDate,
      })
    }

    if (formulaName === "PREVIOUS_MOVE_OUT_DATE") {
      const addressInformationFields = formDataToUse(formKeyToUse)?.addressInformation
      const currentMoveInDate = addressInformationFields?.find?.(field => field?.fieldName === "clientMoveInDate")
        ?.answer
      setFieldsForCalculation({ currentMoveInDate })
    }

    if (formulaName === "PREVIOUS2_MOVE_OUT_DATE") {
      const addressInformationFields = formDataToUse(formKeyToUse)?.previousAddressDetails
      const currentMoveInDate = addressInformationFields?.find?.(field => field?.fieldName === "previousMoveInDate")
        ?.answer
      setFieldsForCalculation({ currentMoveInDate })
    }
    if (formulaName === "PREVIOUS3_MOVE_OUT_DATE") {
      const addressInformationFields = formDataToUse(formKeyToUse)?.companyAddressDetails
      const currentMoveInDate = addressInformationFields?.find?.(
        field => field?.fieldName === "tradingAddressMoveInDate",
      )?.answer
      setFieldsForCalculation({ currentMoveInDate })
    }
  }, [fieldsToShow, formData, errorCounter])

  useEffect(() => {
    if (!_.isEqual(ref.current, fieldForCalculation)) {
      ref.current = fieldForCalculation
      if (formulaName === "MONTHLY_INCOME") {
        const income = incomeCalculation(fieldForCalculation)
        handleFieldUpdate(income)
      }
      if (formulaName === "MONTHLY_OTU_GOING") {
        const outGoing = outGoingCalculation(fieldForCalculation)
        handleFieldUpdate(outGoing)
      }
      if (formulaName === "CALCULATE_DAYS") {
        const prevEmployerTime = employmentTimeInDaysCalculation(fieldForCalculation)
        handleFieldUpdate(prevEmployerTime)
      }
      if (["MONTHLY_REPAYMENT", "COMPANY_MONTHLY_REPAYMENT"]?.includes(formulaName)) {
        const monthlyRepayment = monthlyRepaymentCalculation(fieldForCalculation)
        if (monthlyRepayment) {
          handleFieldUpdate(monthlyRepayment)
        }
      }
      if (formulaName === "NET_ASSET_AND_LIABILITIES") {
        const netAssetAndLiabilities = netAssetAndLiabilitiesCalculation(fieldForCalculation)
        if (netAssetAndLiabilities || netAssetAndLiabilities === 0) {
          handleFieldUpdate(netAssetAndLiabilities)
        }
      }
      if (formulaName === "NET_INCOME") {
        const netIncome = netIncomeCalculation(fieldForCalculation)
        handleFieldUpdate(netIncome)
      }
      if (formulaName === "SURPLUS_CALCULATION") {
        const surplusDeficit = getSurPlusFieldValue(fieldForCalculation)
        if (surplusDeficit || surplusDeficit === 0) {
          handleFieldUpdate(surplusDeficit)
        }
      }
      if (formulaName === "LENDER_SURPLUS_CALCULATION") {
        const surplusDeficit = getSurPlusFieldValue(fieldForCalculation)
        if (surplusDeficit || surplusDeficit === 0) {
          handleFieldUpdate(surplusDeficit)
        }
      }
      if (formulaName === "ADJUSTED_SURPLUS_CALCULATION") {
        const surplusDeficit = getAdjustedSurPlusFieldValue(fieldForCalculation)
        if (surplusDeficit || surplusDeficit === 0) {
          handleFieldUpdate(surplusDeficit)
        }
      }

      if (formulaName === "NET_INCOME_AND_OUTGOINGS") {
        const netIncomeAndOutgoing = netIncomeAndOutgoingCalculation(fieldForCalculation)
        if (netIncomeAndOutgoing || netIncomeAndOutgoing === 0) {
          handleFieldUpdate(netIncomeAndOutgoing)
        }
      }
      if (formulaName === "MOVED_IN_DATE") {
        const movedInDate = movedInDateCalculation(fieldForCalculation)
        if (movedInDate) handleFieldUpdate(movedInDate ?? "")
      }
      if (["MOVED_IN_DATE_IN_DAYS", "TRADING_ADD_MOVE_IN_DATE"].includes(formulaName)) {
        const movedInDays = movedInDateInDaysCalculation(fieldForCalculation)
        handleFieldUpdate(movedInDays ?? "")
      }
      if (formulaName === "PREVIOUS_MOVED_IN_DATE_IN_DAYS") {
        const movedInDays = prevAddressInDaysCalculation(fieldForCalculation)
        handleFieldUpdate(movedInDays ?? "")
      }

      if (employementCalculationFields?.includes(formulaName)) {
        const employmentTimeInDays = employmentTimeInDaysCalculation(fieldForCalculation)
        handleFieldUpdate(employmentTimeInDays ?? "")
      }
      if (formulaName === "ADVERSE_REFERENCE_FORMULA") {
        const reference = adverseCreditHistoryReferenceCalculation(fieldForCalculation)
        handleFieldUpdate(reference)
      }
      if (formulaName === "SPOUSAL_INCOME") {
        if (fieldForCalculation?.includes?.("Spousal Wages After Tax (Non-Borrowed)")) {
          handleFieldUpdate(1)
        } else {
          handleFieldUpdate(undefined)
        }
      }
      if (formulaName === "TO_BE_FINANCED") {
        const toBeFinanced = toBeFinancedCalculation(fieldForCalculation)
        handleFieldUpdate(toBeFinanced)
      }
      if (formulaName === "YEARS_WITH_PREVIOUS_EMPLOYER") {
        const prevEmployerTime = prevEmployerDateInDaysCalculation(fieldForCalculation)
        handleFieldUpdate(prevEmployerTime)
      }
      if (formulaName === "PREVIOUS_MOVE_IN_DATE") {
        const previousMoveInDate = previousMoveInDateCalculation(fieldForCalculation)
        handleFieldUpdate(previousMoveInDate)
      }
      if (formulaName === "PREVIOUS_MOVE_OUT_DATE") {
        const previousMoveOutDate = previousMoveOutDateCalculation(fieldForCalculation)
        handleFieldUpdate(previousMoveOutDate)
      }
      if (formulaName === "PREVIOUS2_MOVE_OUT_DATE") {
        const previousMoveOutDate = previousMoveOutDateCalculation(fieldForCalculation)
        handleFieldUpdate(previousMoveOutDate)
      }
      if (formulaName === "PREVIOUS3_MOVE_OUT_DATE") {
        const previousMoveOutDate = previousMoveOutDateCalculation(fieldForCalculation)
        handleFieldUpdate(previousMoveOutDate)
      }
    }
  }, [ref.current, fieldForCalculation, errorCounter])

  if (isHide) {
    return <></>
  }

  switch (inputType?.toLowerCase()) {
    case "date": {
      return (
        <div>
          <DateRangePicker
            key={answer !== undefined ? answer : fieldsToUse()?.find(e => e?.fieldName === fieldName)?.answer}
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                format: DATE_FORMAT.DDMMYYYY,
                cancelLabel: "Clear",
              },
              showDropdowns: true,
              singleDatePicker: true,
              autoApply: true,
              maxDate: answer,
              startDate: answer,
              cancelButtonClasses: "button outlined-primary-button",
              applyButtonClasses: "button outlined-primary-button",
              drops: "auto",
            }}
            onApply={(event, picker) => {
              if (isEditable) {
                handleFieldUpdate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
              }
            }}
          >
            <Input
              className={`${!answer && isQuoteField ? "redField" : ""} ${
                errorMessage ? "input-has-value-or-focused border-error" : ""
              } ${!isEditable ? "disabledfield" : ""}`}
              placeholder={titleWithRequired}
              name={fieldName}
              value={answer}
              onChange={e => {
                //  if (!isFromBrokerPage) {
                const value = e.target.value
                handleFieldUpdate(value)
                //  }
              }}
              onBlur={e => {
                //  if (!isFromBrokerPage) {
                const value = e.target.value
                const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                if (isValid) {
                  const maxD = dayjs(new Date()).format(DATE_FORMAT.YMD)
                  const dateValue = value
                    .split("/")
                    .reverse()
                    .join("-")
                  const dateValid = dateCheck(dayjs(minDate).format(DATE_FORMAT.YMD), maxD, dateValue)
                  if (dateValid) {
                    handleFieldUpdate(value)
                  } else {
                    handleFieldUpdate("")
                  }
                } else {
                  handleFieldUpdate("")
                }
                //   }
              }}
            />
          </DateRangePicker>
          {suggestion && <div className="suggestion">{suggestion}</div>}
        </div>
      )
    }
    default: {
      const _answer =
        answer === "undefined"
          ? ""
          : ["number", "Number"].includes(inputType)
          ? parseFloat(answer)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : answer?.toString()

      return (
        <div className={`${isShowSeparate ? "adjusted_surplus_deficit" : ""}`}>
          <Input
            type={"text"}
            className={`${!answer && isQuoteField ? "redField" : ""} ${
              isAmount ||
              (["number", "Number"].includes(inputType) && !["yearsWithPreviousEmployer"].includes(fieldName))
                ? "moveLeft"
                : ""
            } ${isShowGrayedOut ? "grayout" : ""}  answer-input `}
            name={fieldName}
            value={_answer}
            /* value={  
              ["number", "Number"].includes(inputType)
                ? answer &&
                  (typeof answer === "number"
                    ? answer.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : parseFloat(answer)?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }))
                : answer?.toString()
            } */
            disabled={true}
            placeholder={titleWithRequired}
            prefix={
              isAmount ||
              (["number", "Number"].includes(inputType) && !["yearsWithPreviousEmployer"].includes(fieldName))
                ? "$"
                : ""
            }
            prefixClass={
              isAmount ||
              (["number", "Number"].includes(inputType) && !["yearsWithPreviousEmployer"].includes(fieldName))
                ? "symbols-outlined"
                : ""
            }
            prefixType={
              isAmount ||
              (["number", "Number"].includes(inputType) && !["yearsWithPreviousEmployer"].includes(fieldName))
                ? "icon"
                : ""
            }
            error={errorMessage ? errorMessageDOM : ""}
          />
          {suggestion && <div className="suggestion">{suggestion}</div>}
        </div>
      )
    }
  }
}

export default ApplicationFormula

ApplicationFormula.propTypes = {
  isShowSeparate: PropTypes.bool,
  fieldName: PropTypes.string,
  inputType: PropTypes.string,
  formulaName: PropTypes.string,
  answer: PropTypes.string,
  titleWithRequired: PropTypes.string,
  handleFieldUpdate: PropTypes.func,
  indexForRepeater: PropTypes.string,
  isHide: PropTypes.bool,
  isAmount: PropTypes.bool,
  isShowGrayedOut: PropTypes.bool,
  isEditable: PropTypes.bool,
  errorMessage: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  suggestion: PropTypes.string,
  errorMessageDOM: PropTypes.string,
  isFromBrokerPage: PropTypes.bool,
  isQuoteField: PropTypes.bool,
  isFromBrokerPageNew: PropTypes.bool,
  currentForm: PropTypes.array,
}
