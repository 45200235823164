import { isBoolean, isEmpty, isNumber, last } from "lodash"
import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { successNotification } from "../../../common/NotifyToaster"
import { findKeyInsideFields, getCloneDeep } from "../../../helpers/applicationHelper"
import { useQuery } from "../../../hooks/useQuery"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { APPLICATION_ID_QUERY_PARAM, DEFAULT_MERGE_FORMS } from "../ApplicationPage/ApplicationPageConstants"
import { removeNavigationHistory } from "../helpers/navigationHistoryHelper"
import {
  activeSaveApplicationData,
  formPathDetailsAPI,
  getAllApplicationData,
  getApplicationToolJSON,
  getInitialApplicationData,
  getQuickAccessMenuList,
  sendZohoUploadData,
} from "../redux/ApplicaitonActions"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { applicationSelector } from "../redux/ApplicationSelector"
import { useGlassGuide } from "./useGlassGuide"
import { useUpdateFields } from "./useUpdateFields"

export const useApplicationCommon = () => {
  const {
    formPaths,
    formsKey,
    subFormsKey,
    applicationJsonData,
    fieldsToShow,
    isLastForm,
    initialDataLoadedTrigger,
    savedDataLoadedTrigger,
  } = useSelector(applicationSelector)
  const { subformfilterByFormVisibility } = useUpdateFields()
  useGlassGuide(true)
  const [formTitles, setFormTitles] = useState([])
  const [formTitlesMain, setFormTitlesMain] = useState("")
  const dispatch = useDispatch()
  const { id } = useParams()
  const query = useQuery()
  const history = useHistory()
  const { repayments = "", rateCardID = "" } = history?.location?.state ?? {}

  // !!!!!!!!!!DONT MODIFY IT
  // START   -------------------------------------------------------------- To know current form is last form and send "isAppCompleted" key in activeSave api
  useEffect(() => {
    const data = findKeyInsideFields(fieldsToShow, "isLastForm")
    if (isBoolean(data) && data !== isLastForm) {
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.UPDATE_IS_LAST_FORM,
        payload: data,
      })
    }
  }, [fieldsToShow])
  // END     -------------------------------------------------------------- To know current form is last form and send "isAppCompleted" key in activeSave api
  // !!!!!!!!!!DONT MODIFY IT
  // START   -------------------------------------------------------------- get initial flow and data. ON RETURN: Reset application data on leaving page
  useEffect(() => {
    ;(async () => {
      try {
        removeNavigationHistory()
        await dispatch(getApplicationToolJSON())
        await dispatch(getInitialApplicationData(id, rateCardID, repayments))
        await dispatch(
          getQuickAccessMenuList({
            quoteId: id,
            applicationId: query.get(APPLICATION_ID_QUERY_PARAM) ?? undefined,
          }),
        )
        // COMMENTED BECAUSE IT WAS CAUSING ISSUE
        // when page is refreshed it was setting form index to previous one so when
        // next button clicked it was redirecting to some other page
        // dispatch(updateApplicationHistory(NAVIGATION.prev))
      } catch (e) {
        /**/
      }
    })()
    return () => {
      removeNavigationHistory()
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.RESET_APPLICATION_DATA,
      })
    }
  }, [])
  // END     -------------------------------------------------------------- get initial flow and data. ON RETURN: Reset application data on leaving page

  // START   -------------------------------------------------------------- Get application data when flow JSON is loaded
  useEffect(() => {
    const getAllApplicationDataCall = async applicationId => {
      if (applicationId) dispatch(getAllApplicationData(applicationId))
      else {
        dispatch({ type: APPLICATION_REDUX_CONSTANTS.ADD_EDIT_WARNINGS })
      }
    }
    if (!isEmpty(applicationJsonData) && initialDataLoadedTrigger) {
      getAllApplicationDataCall(query.get(APPLICATION_ID_QUERY_PARAM))
    }
  }, [applicationJsonData, initialDataLoadedTrigger])
  // END     -------------------------------------------------------------- Get application data when flow JSON is loaded

  // START   -------------------------------------------------------------- Add edit warning in SavedFormData
  useEffect(() => {
    if (initialDataLoadedTrigger && savedDataLoadedTrigger) {
      dispatch({ type: APPLICATION_REDUX_CONSTANTS.ADD_EDIT_WARNINGS })
    }
  }, [initialDataLoadedTrigger, savedDataLoadedTrigger])
  // END     -------------------------------------------------------------- Add edit warning in SavedFormData

  // START   -------------------------------------------------------------- Set form titles

  useEffect(() => {
    const mainNewTitles = formsKey.map(key => getCloneDeep(applicationJsonData, `${key}`)?.label)
    setFormTitlesMain(mainNewTitles)

    if (subFormsKey?.length) {
      const mainNewTitles = formsKey.map(key => getCloneDeep(applicationJsonData, `${formsKey}`)?.label)
      setFormTitlesMain(mainNewTitles)
    }
  }, [applicationJsonData, subFormsKey, formsKey])

  useEffect(() => {
    let newTitles = []

    if (subFormsKey?.length) {
      newTitles = subFormsKey.map(
        key => getCloneDeep(applicationJsonData, `${formsKey?.[formsKey?.length - 1]}.${key}`)?.label,
      )
    } else {
      newTitles = formsKey.map(key => getCloneDeep(applicationJsonData, `${key}`)?.label)
    }
    setFormTitles(newTitles)
  }, [applicationJsonData, subFormsKey, formsKey])
  // END     -------------------------------------------------------------- Set form titles
  // START   -------------------------------------------------------------- Initial data on rateCardId change
  useEffect(() => {
    ;(async () => {
      try {
        if (rateCardID) {
          await dispatch(getInitialApplicationData(id, rateCardID, repayments))
        }
      } catch (e) {
        /**/
      }
    })()
  }, [rateCardID])
  // END     -------------------------------------------------------------- Initial data on rateCardId change

  // START   -------------------------------------------------------------- Call formpath api depending on apiCallKey and activeSave api
  const formPathAPICall = async () => {
    if (id && isApiCall()) {
      await dispatch(formPathDetailsAPI())
    }
  }
  const activeSaveApiCall = async (isDisableLastForm, saveAsDraft) => {
    if (id) {
      const dataToSave = {
        quoteId: id,
        _id: query.get(APPLICATION_ID_QUERY_PARAM) ?? undefined,
      }
      if (isLastForm && !isDisableLastForm && !saveAsDraft) {
        dataToSave.isAppCompleted = isLastForm
      }

      const { isApplicationCompleted, applicationId, shouldShowRateCard, isZohoUpdated } =
        (await dispatch(activeSaveApplicationData(dataToSave, history))) ?? {}
      // Make api call to get quick access menu list
      if (applicationId) {
        await dispatch(getQuickAccessMenuList({ quoteId: id, applicationId }))
        if (isApplicationCompleted && !saveAsDraft) {
          successNotification("Application submitted successfully")

          if (shouldShowRateCard) {
            history.push(`${ROUTE_CONSTANTS_VARIABLE.RATE_CARD}`, {
              quoteId: id,
              applicationId: query.get(APPLICATION_ID_QUERY_PARAM),
            })
          } else {
            history.push(`${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}`)
          }
        } else if (saveAsDraft) {
          successNotification("Application saved as draft")
          history.push(`${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}`)
        } else if (query.get(APPLICATION_ID_QUERY_PARAM) !== applicationId) {
          // call pdf work drive call from here
          const dataforZOHO = {
            applicationId: applicationId,
            type: "equifaxReportOfUser",
          }
          await dispatch(sendZohoUploadData(dataforZOHO))
          // call pdf work drive call from here

          history.push(`${history.location.pathname}?${APPLICATION_ID_QUERY_PARAM}=${applicationId}`)
        }
      }
      return { isZohoUpdated }
    }
  }
  // END     -------------------------------------------------------------- Call formpath api depending on apiCallKey and activeSave api

  // START   -------------------------------------------------------------- Auto merge form
  useEffect(() => {
    const mergeFormsToPush = []
    if (subFormsKey?.length > 0) {
      subFormsKey.forEach(subform => {
        const getMergeForm = subform => {
          const data = getCloneDeep(applicationJsonData, [last(formsKey), subform])
          if (data?.mergeForm && DEFAULT_MERGE_FORMS.includes(data?.mergeFormName || "")) {
            mergeFormsToPush.push(data.mergeFormName)
            getMergeForm(data.mergeFormName)
          }
        }
        getMergeForm(subform)
      })
    }
    if (mergeFormsToPush?.length > 0) {
      const updatedSubforms = subformfilterByFormVisibility([...subFormsKey, ...mergeFormsToPush])
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.UPDATE_SUB_FORMS_KEY,
        payload: updatedSubforms,
      })
    }
  }, [formsKey])
  // END     -------------------------------------------------------------- Auto merge form

  const isApiCall = () => {
    if (formPaths?.length) {
      if (subFormsKey?.length) {
        const subForms = subFormsKey.map(key =>
          getCloneDeep(applicationJsonData, `${formsKey?.[formsKey?.length - 1]}.${key}`),
        )
        return subForms.some(e => e?.apiCall)
      } else {
        return formsKey.map(key => getCloneDeep(applicationJsonData, `${key}`))?.[formsKey?.length - 1]?.apiCall
      }
    } else {
      return true
    }
  }

  const getCurrentFormData = subFormKeyIndex => {
    let path = `${last(formsKey)}`
    if (subFormsKey?.length > 0) {
      if (isNumber(subFormKeyIndex)) path = path.concat(`.${subFormsKey[subFormKeyIndex]}`)
      else path = path.concat(`.${last(subFormsKey)}`)
    }
    return getCloneDeep(applicationJsonData, path)
  }

  return { formTitles, getCurrentFormData, formTitlesMain, formPathAPICall, activeSaveApiCall }
}
