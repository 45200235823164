import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { checkApplicationFormCount } from "../../helpers/ApplicationProgressBarHelper"
import { applicationSelector } from "../../screens/application/redux/ApplicationSelector"

const ApplicationProgressBar = () => {
  const { dataForActiveSave, formPaths, formsKey, subFormsKey } = useSelector(applicationSelector)

  const [style, setStyle] = useState({})
  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${checkApplicationFormCount(dataForActiveSave, formPaths, formsKey, subFormsKey)}%`,
      }
      setStyle(newStyle)
    }, 200)
  }, [dataForActiveSave, formPaths, formsKey, subFormsKey])
  return (
    <div className="progress">
      <div className="progress-done" style={style}></div>
    </div>
  )
}
export default ApplicationProgressBar
