import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { TableMobile } from "../TableMobile/TableMobile"
import { processTableDataByType } from "../../helpers/TableDataProcessHelper"

const ABNModalMobileTable = Props => {
  const { tableData, onClose, onRecordClick } = Props
  const onRecordClickMain = record => {
    onRecordClick(record)
    onClose(false)
  }

  const headers = [
    { label: "Name", name: "label" },
    { label: "ABN", name: "abn" },
    { label: "Type", name: "Type" },
    { label: "Location", name: "Location" },
    { label: "Status", name: "status" },
    { label: "Actions", name: "actions", type: "abnAcn" },
  ]

  const tableDataModified = useMemo(() => {
    const actions = {
      handleDrawerState: onRecordClickMain,
    }

    return tableData.map((e, index) => {
      const finalObj = {
        id: e._id ?? e.id ?? index,
      }
      headers.forEach(f => {
        finalObj[`${f.name}`] = processTableDataByType({ header: f, row: e, actions })
      })
      return finalObj
    })
  }, [tableData, onRecordClickMain])

  return (
    <div style={{ padding: "10px", width: "100%", justifyContent: "center", display: "flex" }}>
      {tableDataModified?.length > 0 ? (
        <TableMobile headers={headers} data={tableDataModified} headersToShow={[{ name: "label" }]} />
      ) : (
        <span style={{ textAlign: "center" }}>No record found</span>
      )}
    </div>
  )
}

ABNModalMobileTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onRecordClick: PropTypes.func,
}

export default React.memo(ABNModalMobileTable)
