/* eslint-disable no-unused-vars */
import _, { isEmpty, set } from "lodash"
import moment from "moment"
import PropTypes, { element } from "prop-types"
import { Fragment, useEffect, useRef, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import ABNModalTable from "../../../common/ABNModalTable/ABNModalTable"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { errorNotification } from "../../../common/NotifyToaster"
import { DEFAULT_DATE_FORMAT } from "../../../constants/Constant"
import { DATE_FORMAT } from "../../../constants/regexConstants"
import { BASE_URL } from "../../../constants/UrlConstants"
import { checkFieldVisibilityCondition, handleApplicationValidations } from "../../../helpers/applicationHelper"
import QuoteApiServices from "../../quote/services/QuoteServices"
import { useUpdateFields } from "../hooks/useUpdateFields"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { applicationSelector } from "../redux/ApplicationSelector"
import { RenderField } from "./RenderField"
import RepeaterForm from "./RepeaterForm"
import { isEditedContext } from "../../../helpers/contexts"

export const FormWrapper = props => {
  const { index, title, formFields, formUpdateListener, validateFields, getFormFields, formPath, isNextOrPrev } = props
  const [fields, setFields] = useState(formFields)
  const [errors, setErrors] = useState({})
  const [abnData, SetAbnData] = useState([])
  const { keysOfFieldsToKeep, isFieldUpdated } = useUpdateFields()

  const [isAbnDataReceived, setIsAbnDataReceived] = useState(true)
  const [isAbnModalOpen, setIsAbnModalOpen] = useState(false)
  const [requestSave, SetRequestSave] = useState("")
  const [fieldSave, SetFieldSave] = useState("")

  const isEdited = useContext(isEditedContext)
  const dispatch = useDispatch()
  const fieldUpdateRef = useRef(null)
  const handleFieldUpdateOfChild = cb => {
    fieldUpdateRef.current = cb
  }

  const parentValidationRef = useRef(null)

  const handleValidationOfChild = cb => {
    parentValidationRef.current =
      parentValidationRef.current?.length > 1
        ? [...parentValidationRef.current?.slice(-1), cb]
        : [...(parentValidationRef.current || []), cb]
  }

  const getParentFormFieldRef = useRef(null)
  const getFieldsOfChild = cb => {
    getParentFormFieldRef.current = cb
  }
  useEffect(() => {
    setFields(formFields)
  }, [formFields])

  const { applicationJsonData, formsKey, subFormsKey, dataForActiveSave, savedFormData, fieldsToShow } = useSelector(
    applicationSelector,
  )

  const updateAnswer = (data, i, value, path = "", isRepeater, repeaterIndex, repeaterKey, keyOfField) => {
    if (isRepeater) {
      data[i].fields[repeaterIndex] = data[i].fields[repeaterIndex].map(
        e =>
          keyOfField !== "" && e?.key === keyOfField
            ? {
                ...e,
                answer: value,
              }
            : e.fieldName === repeaterKey
            ? {
                ...e,
                answer: value,
              }
            : e,

        // keyOfField !== "" && e?.key === keyOfField
        //   ? {
        //       ...e,
        //       answer: value,
        //     }
        //   : e,
      )
      return [...data]
    } else {
      if (path) {
        set(data[i], `answer.${path}`, value)
      } else {
        data[i].answer = value
      }
      if (!data[i]?.options && fieldsToShow?.[index]?.[i]?.options) {
        data[i].options = fieldsToShow?.[index]?.[i]?.options
      }
      return [...data]
    }
  }

  const handleFieldUpdate = (key, value, path, isRepeater, repeaterIndex, repeaterKey, keyOfField = "") => {
    isEdited.current = true
    let i = fields.findIndex(x => x?.fieldName?.toLowerCase() === key?.toLowerCase())
    if (isRepeater) {
      if (formUpdateListener && typeof formUpdateListener === "function") {
        formUpdateListener(
          updateAnswer(fields, i, value, path, isRepeater, repeaterIndex, repeaterKey, keyOfField),
          index,
          fields[i],
          true,
        )
      }
      setFields(updateAnswer(fields, i, value, path, isRepeater, repeaterIndex, repeaterKey))
    } else {
      const keyArray = key?.split(".")
      if (keyArray?.length) {
        i = fields.findIndex(x => x?.fieldName?.toLowerCase() === keyArray?.[0]?.toLowerCase())
      }

      if (formUpdateListener && typeof formUpdateListener === "function") {
        formUpdateListener(updateAnswer(fields, i, value, path), index, fields[i])
      }
      if (fieldUpdateRef.current && typeof fieldUpdateRef.current === "function") {
        fieldUpdateRef.current(updateAnswer(fields, i, value, path), index, fields[i])
      }

      setFields(prev => updateAnswer(prev, i, value, path))
    }
  }

  const handleRepeaterAddOrRemove = (key, updatedFields) => {
    setErrors({})
    setFields(fields?.map(e => (e?.key === key ? { ...e, fields: updatedFields } : e)))
  }

  useEffect(() => {
    if (validateFields && typeof validateFields === "function") {
      validateFields(() => {
        const errorAccumulator = handleApplicationValidations(fields, savedFormData, formsKey)

        let isValidData = isEmpty(errorAccumulator)
        if (parentValidationRef.current && parentValidationRef.current.length > 0) {
          parentValidationRef.current.forEach(element => {
            const childValidated = element()
            isValidData = isValidData && childValidated
          })
        }

        setErrors(errorAccumulator)
        return isValidData
      }, index)
    } else {
      if (!isNextOrPrev) {
        setErrors({})
        return true
      }
    }

    if (getFormFields && typeof getFormFields === "function") {
      getFormFields(() => {
        let fieldsToSave = fields?.map(e => ({ ...e }))

        if (
          getParentFormFieldRef.current &&
          typeof getParentFormFieldRef.current === "function" &&
          formFields?.formFields
        ) {
          const childFieldsToSave = getParentFormFieldRef.current()
          fieldsToSave = [
            ...fieldsToSave,
            {
              [formFields?.formFields]: childFieldsToSave,
            },
          ]
        }
        return fieldsToSave
      }, index)
    }
    return () => {
      parentValidationRef.current = null
    }
  }, [validateFields, getFormFields, fields, index])

  const handleSearch = async (fieldName, value, request) => {
    if (
      fieldName === "name" &&
      value &&
      (subFormsKey.includes("trustDetails") || subFormsKey.includes("companyRegistrationDetails"))
    ) {
      if (isAbnDataReceived) {
        const formFields = applicationJsonData?.[formsKey]?.[subFormsKey]?.fields

        const updatedFields = formFields?.map(field => {
          return { ...field }
        })
        // const updatedFields = formFields
        //   ?.filter((rf, i, self) => {
        //     return checkFieldVisibilityCondition(rf, savedFormData, self)
        //   })
        //   ?.map(field => {
        //     return { ...field }
        //   })

        SetRequestSave(request)
        SetFieldSave(updatedFields)
        handleABNOrACNSearch(value, "ABRNameLookUp", request, updatedFields)
      }
    }
    if (
      fieldName === "abn" &&
      value &&
      (subFormsKey.includes("trustDetails") || subFormsKey.includes("companyRegistrationDetails"))
    ) {
      if (isAbnDataReceived) {
        const formFields = applicationJsonData?.[formsKey]?.[subFormsKey]?.fields

        const updatedFields = formFields?.map(field => {
          return { ...field }
        })

        SetRequestSave(request)
        SetFieldSave(updatedFields)
        handleABNOrACNSearch(value, "ABNLookUp", request, updatedFields)
      }
    }
    if (fieldName === "acn" && value) {
      if (isAbnDataReceived) {
        const formFields = applicationJsonData?.[formsKey]?.[subFormsKey]?.fields

        const updatedFields = formFields?.map(field => {
          return { ...field }
        })

        SetRequestSave(request)
        SetFieldSave(updatedFields)
        handleABNOrACNSearch(value, "ASICLookup", request, updatedFields)
      }
    }
  }
  const handleABNOrACNSearch = async (value, identifier, request, updatedFields) => {
    const isTrustSearch = subFormsKey?.includes("trustDetails")
    if (request === undefined) {
      request = requestSave
    }
    if (updatedFields === undefined) {
      updatedFields = fieldSave
    }

    if (request?.url) {
      const { url, method, params } = request

      setIsAbnDataReceived(false)
      let paramData = {
        ...params,
        identifier: identifier ?? params?.identifier,
        searchString: value.toString().trim(),
        searchType: isTrustSearch ? "Trust" : "Company",
      }
      if (paramData?.identifier !== "ABNLookUp" && paramData?.identifier !== "ASICLookup") {
        setIsAbnModalOpen(true)
      } else {
        paramData = {
          ...params,
          identifier: identifier ?? params?.identifier,
          searchString: value.toString().replace(/ /g, ""),
          searchType: isTrustSearch ? "Trust" : "Company",
        }
      }

      try {
        await dispatch(handleABNSearch(url, method, paramData, updatedFields))
        setIsAbnDataReceived(true)
      } catch (e) {
        /**/
      }
    }
  }
  const handleABNSearch = (url, method, params, updatedFields) => {
    return async dispatch => {
      try {
        const response = await QuoteApiServices.getDataFromExternalSources(
          `${BASE_URL}${url}`,
          _.toUpper(method),
          params,
        )

        if (response?.data?.status === "SUCCESS") {
          if (params?.identifier === "ABRNameLookUp") {
            // set state
            SetAbnData(response?.data?.data)
          } else {
            const dataOfAbn = []
            const obj = {}
            const companyStructure = response?.data?.data?.companyStructure
            if (subFormsKey.includes("trustDetails")) {
              if (
                response?.data?.data?.companyStructure === "Discretionary / Family Trust" ||
                response?.data?.data?.companyStructure === "Unit Trust" ||
                response?.data?.data?.companyStructure === "Hybrid Trust" ||
                response?.data?.data?.companyStructure === "Trust"
              ) {
                Object.entries(response?.data?.data).forEach(([key, value]) => {
                  if (key === "companyStructure") {
                    key = "type"
                  }
                  if (key === "entityName") {
                    key = "name"
                  }
                  if (
                    key !== "acn" &&
                    key !== "stateCode" &&
                    key !== "postCode" &&
                    key !== "isActive" &&
                    key !== "length"
                  ) {
                    dataOfAbn[[key]] = value
                  }
                })
                if (response?.data?.data?.companyStructure === "Sole Trader") {
                  dataOfAbn.numberOfDirectors = 1
                }

                if (dataOfAbn.gstStatus !== undefined) {
                  dataOfAbn.isGstStatus = "Yes"
                } else {
                  dataOfAbn.isGstStatus = "No"
                }

                let updatedFieldsNew = updatedFields
                  .map(field => {
                    if (field.fieldName === "GSTRegisteredFrom" && dataOfAbn.isGstStatus === "No") {
                      return ""
                    } else if (dataOfAbn[field.fieldName]) {
                      if ("fieldVisibility" in field) {
                        if (checkFieldVisibilityCondition(field, savedFormData, updatedFields)) {
                          const modifiedField = {
                            ...field,
                            answer: dataOfAbn[field.fieldName],
                          }
                          return modifiedField
                        } else {
                          return ""
                        }
                      } else {
                        const modifiedField = {
                          ...field,
                          answer: dataOfAbn[field.fieldName],
                        }
                        return modifiedField
                      }
                    }
                    return field
                  })
                  .filter(x => x)
                const keysToKeep = keysOfFieldsToKeep(updatedFieldsNew)
                if (keysToKeep?.length > 0) {
                  updatedFieldsNew = updatedFieldsNew.filter(field => {
                    return keysToKeep.includes(field?.key)
                  })
                }
                dispatch({
                  type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
                  payload: [updatedFieldsNew],
                })
              } else {
                if (subFormsKey.includes("trustDetails")) throw new Error("Please select trust entity")
              }
            }
            if (subFormsKey.includes("companyRegistrationDetails")) {
              if (
                dataForActiveSave?.companyDetails?.trustDetails?.trusteeType === "Individual" ||
                dataForActiveSave?.companyGurantor?.trustDetails?.trusteeType === "Individual"
              ) {
                if (!["Sole Trader"].includes(companyStructure)) {
                  throw new Error(`This is a ${companyStructure} please go back and change the trustee type.`)
                }
              }

              Object.entries(response?.data?.data).forEach(([key, value]) => {
                if (key === "entityName") {
                  key = "name"
                }
                if (key === "registeredDate") {
                  key = "registeredFrom"
                }
                if (key === "gstStatus") {
                  key = "GSTRegisteredFrom"
                }

                if (key === "registeredFrom" && value) {
                  //
                  const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                  if (isValid) {
                    dataOfAbn[[key]] = value
                  } else {
                    dataOfAbn[[key]] = moment(value).format(DEFAULT_DATE_FORMAT)
                  }
                } else if (key !== "stateCode" && key !== "postCode" && key !== "isActive" && key !== "length") {
                  dataOfAbn[[key]] = value
                }
              })

              if (dataOfAbn.GSTRegisteredFrom !== undefined) {
                dataOfAbn.registeredForGST = "Yes"
              } else {
                dataOfAbn.registeredForGST = "No"
              }
              if (["Sole Trader"].includes(companyStructure)) {
                dataOfAbn.numberOfDirectors = 1
              }
              let updatedFieldsNew = updatedFields
                .map(field => {
                  if (field.fieldName === "GSTRegisteredFrom" && dataOfAbn.registeredForGST === "No") {
                    return ""
                  } else if (dataOfAbn[field.fieldName]) {
                    if ("fieldVisibility" in field) {
                      if (checkFieldVisibilityCondition(field, savedFormData, updatedFields)) {
                        const modifiedField = {
                          ...field,
                          answer: dataOfAbn[field.fieldName],
                        }
                        return modifiedField
                      } else {
                        return ""
                      }
                    } else {
                      const modifiedField = {
                        ...field,
                        answer: dataOfAbn[field.fieldName],
                      }
                      return modifiedField
                    }
                  }
                  return field
                })
                .filter(x => x)
              const keysToKeep = keysOfFieldsToKeep(updatedFieldsNew)
              if (keysToKeep?.length > 0) {
                updatedFieldsNew = updatedFieldsNew.filter(field => {
                  return keysToKeep.includes(field?.key)
                })
              }

              dispatch({
                type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
                payload: [updatedFieldsNew],
              })
            } else {
              if (subFormsKey.includes("companyRegistrationDetails")) throw new Error("Select entity is not company")
            }
            // set field value
          }
        }
      } catch (e) {
        errorNotification(e?.response?.data?.message || e.message)
      }
    }
  }
  const onCloseAbnModal = () => {
    setIsAbnModalOpen(false)
  }
  return (
    <>
      {title && <div className="application-form-heading">{title || ""}</div>}
      <div
        className={`application-tool-form-inner ${formsKey["0"] === "signatureDetails" ? "noGrid" : ""} ${
          subFormsKey["0"] === "documentUpload" || subFormsKey["0"] === "supportingCompanyDocumentation"
            ? "gapBetweenDiv"
            : ""
        }`}
      >
        {fields &&
          fields?.length > 0 &&
          fields.map(field => {
            return (
              <Fragment key={field?.key}>
                {field.type === "repeater" ? (
                  <RepeaterForm
                    field={field}
                    onFieldUpdate={handleFieldUpdate}
                    isFromAssetPage={false}
                    isFromBrokerPage={false}
                    isFromBrokerPageNew={false}
                    errorMessage={errors?.[field?.fieldName] || ""}
                    onAdd={updatedFields => handleRepeaterAddOrRemove(field?.key, updatedFields)}
                    onRemove={updatedFields => handleRepeaterAddOrRemove(field?.key, updatedFields)}
                    handleSearch={handleSearch}
                  />
                ) : (
                  <RenderField
                    field={field}
                    formPath={formPath}
                    isFromAssetPage={false}
                    isFromBrokerPage={false}
                    isFromBrokerPageNew={false}
                    onFieldUpdate={handleFieldUpdate}
                    errorMessage={errors?.[field?.fieldName] || ""}
                    handleSearch={handleSearch}
                    formWrapperProps={{
                      ...props,
                      formUpdateListener: handleFieldUpdateOfChild,
                      validateFields: handleValidationOfChild,
                      getFormFields: getFieldsOfChild,
                    }}
                  />
                )}
              </Fragment>
            )
          })}
        {isAbnModalOpen && (
          <ABNModalTable
            tableData={abnData}
            isModalOpen={isAbnModalOpen}
            isLoading={isAbnDataReceived}
            onClose={onCloseAbnModal}
            onRecordClick={e => handleABNOrACNSearch(e?.abn, "ABNLookUp")}
          />
        )}
      </div>
    </>
  )
}

FormWrapper.propTypes = {
  isNextOrPrev: PropTypes.bool,
  index: PropTypes.number,
  // Title of form
  title: PropTypes.string,
  type: PropTypes.string,
  // fields to show
  formFields: PropTypes.array,
  // path of from in applicationJSONData
  formPath: PropTypes.array,
  // when field changes it'll call this function
  formUpdateListener: PropTypes.func,
  // to validate fields and return boolean value
  validateFields: PropTypes.func,
  // get form fields
  getFormFields: PropTypes.func,
}
