import PropTypes from "prop-types"
import { createRef, useEffect, useRef, useState } from "react"
import Input from "../../common/Input/Input"

const ReactGoogleAutocomplete = props => {
  const autoComplete = useRef(null)
  const { placeholder, setFinalAddress, value, error, name = "" } = props
  const [query, setQuery] = useState("")
  const searchInput = createRef(`${name}_${Math.random()}`)

  const handleScriptLoad = (updateQuery, setFinalAddress, autoCompleteRef) => {
    try {
      autoComplete.current = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        componentRestrictions: { country: "AUS" },
      })
      autoComplete.current.setFields(["address_components", "formatted_address"])
      autoComplete.current.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, setFinalAddress)
      })
    } catch (error) {
      setTimeout(() => {
        handleScriptLoad(updateQuery, setFinalAddress, autoCompleteRef)
      }, 200)
    }
  }

  const handlePlaceSelect = (updateQuery, setFinalAddress) => {
    const addressObject = autoComplete.current.getPlace()
    const formattedQuery = addressObject.formatted_address
    const address = {
      street_number: "",
      street_name: "",
      streetAddress: "",
      city: "",
      state: "",
      referenceStreetAddress: "",
      addressLine2: "",
      referenceAddressLine2: "",
      vendorAddressLine2: "",
      referenceState: "",
      referencePostCode: "",
      referenceCity: "",
      referenceCountry: "",
      vendorStreetAddress: "",
      vendorState: "",
      vendorPostCode: "",
      vendorCity: "",
      vendorCountry: "",

      zip: "",
      postCode: "",
      country: "",
      location: "",
    }

    address.location = addressObject.formatted_address
    address.addressLine2 = addressObject.formatted_address
    address.referenceAddressLine2 = addressObject.formatted_address
    address.vendorAddressLine2 = addressObject.formatted_address

    address.googleAddress = addressObject.formatted_address

    addressObject.address_components.forEach(component => {
      const types = component.types
      const value = component.long_name
      const valueshort = component.short_name
      if (types.includes("locality")) {
        address.city = value
        address.referenceCity = value
        address.vendorCity = value
        address.city = value
      }

      if (types.includes("street_number")) {
        address.street_number = value
        address.streetAddress = value
        address.referenceStreetAddress = value
        address.vendorStreetAddress = value
        address.streetAddress = value
      }

      if (types.includes("route")) {
        address.street_name = value
        address.streetAddress = `${address.streetAddress} ${value}`
        address.referenceStreetAddress = `${address.referenceStreetAddress} ${value}`
        address.vendorStreetAddress = `${address.vendorStreetAddress} ${value}`
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value
        address.referenceState = value
        address.vendorState = value
        address.stateshort = valueshort
      }

      if (types.includes("postal_code")) {
        address.zip = value
        address.postCode = value
        address.referencePostCode = value
        address.vendorPostCode = value
      }

      if (types.includes("country")) {
        address.referenceCountry = value
        address.vendorCountry = value
        address.country = "AUS"
      }
    })

    setFinalAddress(address)
    updateQuery(formattedQuery)
  }

  useEffect(() => {
    setQuery(value)
  }, [value])

  useEffect(() => {
    handleScriptLoad(setQuery, setFinalAddress, searchInput)
  }, [])

  return (
    <Input
      ref={searchInput}
      id={`${name}_${Math.random()}`}
      name={name}
      type="text"
      placeholder={placeholder ?? "Search location"}
      onChange={event => {
        setQuery(event.target.value)
      }}
      value={query}
      suffixClass="material-icons-round font-primary"
      suffix="search"
      error={error}
    />
  )
}

ReactGoogleAutocomplete.propTypes = {
  placeholder: PropTypes.string.isRequired,
  setFinalAddress: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  addressDefault: PropTypes.any,
}

ReactGoogleAutocomplete.defaultProps = {
  setFinalAddress: () => {},
  value: "",
  error: "",
}

export default ReactGoogleAutocomplete
