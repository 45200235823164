import { ASSET_REDUX_CONSTANTS } from "./AssetReduxConstants"
const initialState = {
  brokerGlassGuide: {
    fields: [
      {
        key: "Q1",
        question: "Asset Type",
        type: "dropdown",
        required: true,
        errorField: "an Asset Type",
        fieldName: "assetType",
        inputType: "text",
        options: [
          "Car, Van, Ute etc",
          "Big Truck",
          "Caravan, Campervan",
          "Motorbike, Quad etc",
          "Boat, Jetski etc",
          "Farm Equipment",
          "Earth Moving Equipment",
          "Industrial Equipment",
          "IT, Cameras etc",
          "Cafe/White Goods",
          "Fit Out",
          "Other",
        ],

        editable: true,
        nextQuestions: {
          CarVanUteetc: ["Q3"],
          BigTruck: ["Q3"],
          CaravanCampervan: ["Q3"],
          MotorbikeQuadetc: ["Q3"],
          BoatJetskietc: ["Q2", "Q3"],
          FarmEquipment: ["Q4"],
          EarthMovingEquipment: ["Q4"],
          IndustrialEquipment: ["Q4"],
          ITCamerasetc: ["Q4"],
          CafeWhiteGoods: ["Q4"],
          FitOut: ["Q4"],
          Other: ["Q4"],
        },
        defaultQuestion: true,
      },
      {
        key: "Q2",
        question: "Marine Type",
        type: "dropdown",
        fieldToCheckRecalculation: true,
        required: true,
        errorField: "an Asset Condition",
        fieldName: "marineType",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        options: ["Marine engine", "Jetski", "Trailer"],
      },
      {
        key: "Q3",
        required: true,
        question: "Condition",
        type: "dropdown",
        errorField: "an Asset Condition",
        fieldName: "assetCondition",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        options: ["New", "Demo", "Used", "Undecided"],
        nextQuestions: {
          New: ["Q5"],
          Demo: ["Q5"],
          Used: ["Q5"],
          Undecided: ["Q6"],
        },
      },
      {
        key: "Q4",
        question: "Condition",
        type: "dropdown",
        fieldToCheckRecalculation: true,
        required: true,
        errorField: "an Asset Condition",
        fieldName: "assetCondition",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        options: ["New", "Demo", "Used", "Undecided"],
        nextQuestions: {
          New: ["Q6"],
          Demo: ["Q6"],
          Used: ["Q6"],
          Undecided: ["Q6"],
        },
      },
      {
        key: "Q5",
        question: "Year",
        required: true,
        fieldToCheckRecalculation: true,
        type: "dropdown",
        errorField: "a Asset Year",
        fieldName: "assetAge",
        inputType: "string",
        editable: true,
        defaultQuestion: false,
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            assetType: "",
            identifier: "getListOfYears",
            marineType: "",
          },
          optionalParams: ["marineType"],
          response: ["modelTypeCode", "yearCreate"],
        },
        nextQuestions: {
          Other: ["Q7"],
          default: ["Q10"],
        },
      },
      {
        key: "Q6",
        question: "Year",
        required: true,
        type: "dropdown",
        extraOptions: {
          showOther: true,
        },
        errorField: "a Asset Year",
        fieldName: "nonGlassGuideAssetAge",
        inputType: "string",
        editable: true,
        defaultQuestion: false,
        nextQuestions: {
          Other: ["Q7", "Q8", "Q9"],
          default: ["Q8", "Q9"],
        },
        isDefaultYearOptions: true,
        previousYears: 20,
      },
      {
        key: "Q7",
        question: "Enter Other Asset Year",
        errorField: "a Asset Year",
        description: "",
        placeholder: "Enter Asset Year",
        type: "text",
        fieldName: "otherAssetYear",
        inputType: "string",
        editable: true,
        required: true,
        autoFieldWithFormula: false,
        onlyForInformation: false,
      },
      {
        key: "Q8",
        question: "Other Asset Make",
        type: "text",
        fieldName: "otherAssetMake",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
      },
      {
        key: "Q9",
        question: "Other Asset Model",
        type: "text",
        fieldName: "otherAssetModel",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
      },
      {
        key: "Q10",
        question: "Asset Make",
        type: "dropdown",
        required: true,
        errorField: "an Asset Make",
        fieldName: "assetMake",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        nextQuestions: {
          Other: ["Q8"],
          default: ["Q12"],
        },
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            modelTypeCode: "",
            assetAge: "",
            identifier: "getListOfMakes",
          },
          response: ["modelTypeCode", "yearCreate", "manufacturerCode"],
        },
      },
      {
        key: "Q12",
        question: "Asset Model",
        errorField: "an Asset Model",
        type: "dropdown",
        required: true,
        fieldName: "assetModel",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        nextQuestions: ["Q13"],
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            modelTypeCode: "",
            assetAge: "",
            assetMake: "",
            identifier: "getListOfModels",
          },
          response: ["modelTypeCode", "yearCreate", "manufacturerCode", "familyCode"],
        },
      },
      {
        key: "Q13",
        question: "Asset Badge",
        errorField: "an Asset Badge",
        type: "dropdown",
        required: true,
        fieldName: "assetBadge",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        nextQuestions: ["Q14"],
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            modelTypeCode: "",
            assetAge: "",
            assetMake: "",
            assetModel: "",
            identifier: "getListOfVariants",
          },
          response: ["modelTypeCode", "yearCreate", "manufacturerCode", "familyCode", "variantName"],
        },
      },
      {
        key: "Q14",
        question: "Asset Series",
        type: "dropdown",
        required: true,
        errorField: "an Asset Series",
        fieldName: "assetSeries",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        autoFieldWithFormula: false,
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            modelTypeCode: "",
            assetAge: "",
            assetMake: "",
            assetModel: "",
            assetBadge: "",
            identifier: "getListOfSeries",
          },
          response: ["modelTypeCode", "yearCreate", "manufacturerCode", "familyCode", "seriesCode"],
        },
        nextQuestions: ["Q15", "Q16", "Q17"],
      },
      {
        key: "Q15",
        question: "Select the Asset Model",
        type: "dropdown",
        errorField: "an Asset Model",
        required: true,
        fieldName: "model",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            modelTypeCode: "",
            assetAge: "",
            assetMake: "",
            assetModel: "",
            assetBadge: "",
            assetSeries: "",
            identifier: "getFinalModel",
          },
          response: ["modelTypeCode", "yearCreate", "manufacturerCode", "familyCode", "seriesCode"],
        },
      },
      {
        key: "Q16",
        question: "NVIC",
        type: "text",
        required: true,
        fieldName: "nvic",
        inputType: "text",
        editable: false,
        defaultQuestion: false,
        autoFieldWithFormula: false,
      },
      {
        key: "Q17",
        question: "Description",
        type: "text",
        errorField: "an Asset Description",
        required: true,
        fieldName: "assetDescription",
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        nextQuestions: ["Q18", "Q19", "Q20"],
      },
      {
        key: "Q18",
        question: "Asset Manufacturer Options ",
        type: "dropdown",
        fieldName: "assetManufacturerOptions",
        isMultipleSelect: true,
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            nvic: "",
            modelTypeCode: "",
            optionType: "O",
            identifier: "getManufacturerOptions",
          },
        },
      },
      {
        key: "Q19",
        question: "Asset Generic Options",
        type: "dropdown",
        options: [
          "Alarm",
          "Alloy Tray",
          "ARB  Classic Smooth Canopy with Lift Up Side Windows",
          "ARB Ascent Canopy with Lift up Side Windows",
          "ARB Bullbar",
          "ARB Classic Canopy Low Roof with Sliding Windows Both Sides",
          "ARB Commercial Bull Bar (Black)",
          "ARB Deluxe Bullbar with side rails",
          "ARB Summit Bull Bar-Black",
          "ARB Tradesmans Roof Rack",
          "ARB Under Vehicle Protection",
          "Black and White Plates",
          "Black and White Slimline Plates",
          "Black Badge Pack",
          "Black Sports Bars",
          "Black Wheel Pack",
          "Bonnet Protector",
          "Boot Liner",
          "Canopy ",
          "Cargo Barrier",
          "Cargo Liner",
          "Cargo Tray",
          "Cross Bars",
          "Dash Cam",
          "Drawers",
          "Electric Roller Cover",
          "First Aid Kit",
          "Floor Mats",
          "Floor Mats Front and Rear",
          "Full Tank of Fuel",
          "Hamer bar with driving lights",
          "Hamer King Series Bullbar",
          "Hardlid with Remote",
          "Heated Front Seats",
          "Ironman Deluxe Bulbar",
          "Ladder Racks",
          "LED Lightbar",
          "Luggage Compartment Tray",
          "Luxury Pack",
          "Metallic Paint",
          "Nudge Bar",
          "Panoramic Sunroof",
          "Premium Plates",
          "Protection Pack",
          "Redarc Electric Brakes",
          "Reverse Camera",
          "Rhino Flat Rack",
          "Roller Tub Cover",
          "Roof Racks",
          "Rubber Floor Mats",
          "Seat Covers (front)",
          " Slim Weather Shields",
          "Snorkel",
          "Soft Tonneau Cover",
          "Sports Bar",
          "Standard Plates",
          "Tinted bonnet protector",
          "Tow Ball",
          "Tow Bar Heavy Duty",
          "Tow Hitch Receiver",
          "Towbar",
          "Towbar & 7 Pin Round Plug",
          "Towbar with 12 Pin Flat Plug",
          "Tub Liner",
          " UHF CB Radio and Antenna",
          " Window Tint",
          "Window Tint (Darkest Legal)",
          "Wooden Wall Lining",
        ],
        fieldName: "assetGenericOptions",
        isMultipleSelect: true,
        inputType: "text",
        editable: true,
        defaultQuestion: false,
        autoFieldWithFormula: false,
      },
      {
        key: "Q20",
        question: "Kilometres / Hours",
        type: "amount",
        fieldName: "assetKilometres",
        inputType: "number",
        editable: true,
        defaultQuestion: false,
        autoFieldWithFormula: false,
      },
    ],
  },
  regoGlassGuide: {
    fields: [
      {
        question: "",
        placeholder: "Rego Number",
        errorField: "a rego number",
        description: "",
        type: "text",
        fieldName: "rego",
        inputType: "string",
        minLimit: 0,
        maxLimit: 255,
        editable: true,
        required: true,
        autoFieldWithFormula: false,
        onlyForInformation: false,
      },
      {
        question: "",
        placeholder: "Select State",
        errorField: "a state",
        description: "",
        type: "regoState",
        fieldName: "state",
        inputType: "string",
        editable: true,
        required: true,
        autoFieldWithFormula: false,
        onlyForInformation: false,
        isDependent: true,
        dependentFieldName: "rego",
        options: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            identifier: "getByRegoState",
            rego: "",
            state: "",
            assetType: "",
          },
          response: [
            "engineNumber",
            "yearOfManufacture",
            "compliancePlate",
            "vin",
            "nvic",
            "plate",
            "plateState",
            "RRP",
            "Retail",
          ],
        },
      },
      {
        type: "divider",
        dividerText: "or",
      },
      {
        question: "",
        placeholder: "VIN",
        description: "",
        errorField: "a VIN",
        type: "search",
        fieldName: "vin",
        editable: true,
        inputType: "string",
        minLimit: 17,
        maxLimit: 17,
        required: true,
        autoFieldWithFormula: false,
        onlyForInformation: false,
        request: {
          url: "glassGuide",
          method: "GET",
          params: {
            identifier: "getByVIN",
            vin: "",
            assetType: "",
          },
          response: [
            "engineNumber",
            "yearOfManufacture",
            "compliancePlate",
            "vin",
            "nvic",
            "plate",
            "plateState",
            "RRP",
            "Retail",
          ],
        },
      },
      {
        question: "Is this your vehicle?",
        description: "",
        type: "inlineText",
        fieldName: "assetDescriptionrego",
        fieldYear: "yearCreate",
        fieldModel: "modelName",
        inputType: "regoApiData",
        editable: false,
        required: true,
        autoFieldWithFormula: false,
        onlyForInformation: false,
        questionToCheckFlow: true,
      },
    ],
  },
  FieldsUpdates: [],
  FieldsUpdatesRego: [],

  glassGuideDataAsset: {},
  regoDataAsset: {},
  glassGuideLabel: {},
  lvrData: "",
  assetzohoID: "",
  isDisabledSubmit: true,
  isRegoSearch: false,
}
export const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_ANSWER_DATA: {
      const { brokerGlassGuide } = { ...state }
      brokerGlassGuide?.fields?.forEach((field, formIndex) => {
        if (field?.fieldName === action?.fieldName) {
          field.answer = action?.fieldValue
        }
      })

      return { ...state }
    }

    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FIELD_ANSWER_DATA: {
      const nextQuestions = action?.nextField
      return { ...state, FieldsUpdates: nextQuestions }
    }
    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FIELD_REGO_ANSWER_DATA: {
      const { regoGlassGuide } = { ...state }
      regoGlassGuide?.fields?.forEach((field, formIndex) => {
        if (field?.fieldName === action?.fieldName) {
          field.answer = action?.fieldValue
        }
      })

      return { ...state }
    }
    case ASSET_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_LABEL_DATA:
      return {
        ...state,
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          [action?.fieldName]: action?.fieldLabel,
        },
      }
    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_FLAG_DATA:
      return {
        ...state,
        [action?.fieldName]: action?.fieldValue,
      }

    case ASSET_REDUX_CONSTANTS.UPDATE_APPLICATION_GLASS_GUIDE_DATA: {
      const { FieldsUpdates, glassGuideDataAsset } = { ...state }
      FieldsUpdates?.forEach((field, formIndex) => {
        if (field?.fieldName === action?.field?.fieldName) {
          field.options = action?.data?.table?.map(i => ({ value: i?.value, label: i?.label }))
        }
      })
      if (action?.field) {
        FieldsUpdates?.forEach((field, fieldIndex) => {
          if (field?.fieldName === action?.field?.fieldName) {
            if (field?.fieldName === "model" && field?.request?.url === "glassGuide") {
              field.options = action?.data?.table?.map(i => ({
                value: i?.label,
                label: i?.label,
                nvic: i?.value,
              }))
            } else {
              field.options = action?.data?.table?.map(i => ({ value: i?.value, label: i?.label }))
              if (field?.fieldName === "assetManufacturerOptions") {
                field.options.push({ value: "None", label: "None" })
              }
            }
          }
        })
      }
      return { ...state, glassGuideDataAsset: { ...glassGuideDataAsset, ...action.data } }
    }
    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_LVR_DATA: {
      return { ...state, lvrData: action?.data }
    }
    case ASSET_REDUX_CONSTANTS.UPDATE_ASSET_REGO_DATA: {
      return { ...state, regoDataAsset: action?.data }
    }
    case ASSET_REDUX_CONSTANTS.SET_ASSET_ZOHO_DATA: {
      return { ...state, assetzohoID: action?.data }
    }

    default:
      return state
  }
}
