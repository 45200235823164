import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import IconButton from "../../../common/IconButton/IconButton"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { handleBackClick, handleNextClick, isActiveSavingActive } from "../../../helpers/QuoteValidationHelper"
import { handleEquifaxFieldChange } from "../../auth/EquifaxPage/redux/EquifaxActions"

import { useEffect } from "react"
import { EQUIFAX_DEFAULT_SCORE } from "../../../constants/regexConstants"
import { skipQuoteScreen } from "../redux/QuoteActions"

const QuoteBottomNavigation = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const { _id: userId } = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})

  const {
    quoteId,
    currentQuoteData,
    quoteAnswersData,
    glassGuideData,
    glassGuideLabel,
    previousQuestions,
    dropDownOptions,
    currentForm,
    currentQuestion,
    quoteJsonData,
  } = useSelector(({ quoteReducer }) => quoteReducer ?? {})

  useEffect(() => {
    if (window.location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.EQUIFAX)) {
      dispatch(handleEquifaxFieldChange("isonEquifaxPage", true))
    }
  }, [window.location.pathname])

  const { isonEquifaxPage, isFormShow, isEquifaxFailed } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})

  const {
    isLastQuestion,
    fieldName,
    nextQuestions,
    defaultQuestions,
    otherField,
    type,
    checkDefaultForNext,
    fields,
    lastQuestionFor,
    canSkip,
  } = currentQuoteData ?? {}

  const { firstName, lastName, equifaxData } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})
  let equifaxDataFinal = { data: { ...equifaxData?.data, isDefaultScore: false } }
  if (equifaxData?.data?.creditScore === undefined) {
    equifaxDataFinal = {
      data: {
        firstName: firstName,
        lastName: lastName,
        creditScore: EQUIFAX_DEFAULT_SCORE,
        isDefaultScore: true,
      },
    }
  }

  useEffect(() => {
    if (canSkip) {
      dispatch(skipQuoteScreen(false))
      handleNextClick(
        isonEquifaxPage ? true : isLastQuestion,
        isonEquifaxPage,
        currentQuoteData,
        quoteAnswersData,
        dropDownOptions,
        currentForm,
        equifaxDataFinal,
        equifaxData,
        currentQuestion,
        authStatus,
        userId,
        quoteId,
        glassGuideData,
        glassGuideLabel,
        previousQuestions,
        isLastQuestion,
        isonEquifaxPage,
        nextQuestions,
        defaultQuestions,
        otherField,
        type,
        checkDefaultForNext,
        fields,
        fieldName,
        history,
        false,
        quoteJsonData,
        isEquifaxFailed,
        isFormShow,
        dispatch,
        true,
        false,
      )
    }
  }, [
    isonEquifaxPage,
    isLastQuestion,
    currentQuoteData,
    quoteAnswersData,
    dropDownOptions,
    currentForm,
    equifaxDataFinal,
    equifaxData,
    currentQuestion,
    authStatus,
    userId,
    quoteId,
    glassGuideData,
    glassGuideLabel,
    previousQuestions,
    nextQuestions,
    defaultQuestions,
    otherField,
    type,
    checkDefaultForNext,
    fields,
    fieldName,
    history,
    quoteJsonData,
    isEquifaxFailed,
    isFormShow,
    dispatch,
    canSkip,
  ])

  return (
    <div className={`${!isFormShow ? "hidebtns" : "showbtns"}`}>
      {!userId && !authStatus ? (
        <Button
          isDisabled={Object.keys(quoteAnswersData).length > 0 ? 0 : 1}
          buttonType="outlined-primary"
          onClick={() =>
            Object.keys(quoteAnswersData).length > 0 &&
            handleNextClick(
              isonEquifaxPage ? true : isLastQuestion,
              true,
              currentQuoteData,
              quoteAnswersData,
              dropDownOptions,
              currentForm,
              equifaxDataFinal,
              equifaxData,
              currentQuestion,
              authStatus,
              userId,
              quoteId,
              glassGuideData,
              glassGuideLabel,
              previousQuestions,
              isLastQuestion,
              isonEquifaxPage,
              nextQuestions,
              defaultQuestions,
              otherField,
              type,
              checkDefaultForNext,
              fields,
              fieldName,
              history,
              true,
              quoteJsonData,
              isEquifaxFailed,
              isFormShow,
              dispatch,
              false,
              false,
            )
          }
        >
          {isonEquifaxPage ? "Skip" : "Save As Draft"}
        </Button>
      ) : !isonEquifaxPage ? (
        <Button buttonType="outlined-primary" onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)}>
          Cancel
        </Button>
      ) : (
        <div></div>
      )}
      <div className={`${authStatus && isonEquifaxPage ? "hidebtns" : ""} prev-next-button-container`}>
        <IconButton
          buttonType="outlined-primary"
          title="arrow_back_ios"
          onClick={() => handleBackClick(isonEquifaxPage, authStatus, dispatch, history)}
        />
        {!isonEquifaxPage && (
          <>
            {!isActiveSavingActive() && isLastQuestion && !lastQuestionFor ? (
              <Button
                buttonType="outlined-primary"
                className={!isonEquifaxPage ? "icon-button" : ""}
                onClick={() =>
                  handleNextClick(
                    isonEquifaxPage ? true : isLastQuestion,
                    isonEquifaxPage,
                    currentQuoteData,
                    quoteAnswersData,
                    dropDownOptions,
                    currentForm,
                    equifaxDataFinal,
                    equifaxData,
                    currentQuestion,
                    authStatus,
                    userId,
                    quoteId,
                    glassGuideData,
                    glassGuideLabel,
                    previousQuestions,
                    isLastQuestion,
                    isonEquifaxPage,
                    nextQuestions,
                    defaultQuestions,
                    otherField,
                    type,
                    checkDefaultForNext,
                    fields,
                    fieldName,
                    history,
                    false,
                    quoteJsonData,
                    isEquifaxFailed,
                    isFormShow,
                    dispatch,
                    false,
                    false,
                  )
                }
              >
                <span className="material-icons-outlined">arrow_forward_ios</span>
              </Button>
            ) : !isActiveSavingActive() &&
              isLastQuestion &&
              lastQuestionFor &&
              quoteAnswersData?.[currentForm]?.[fieldName] === lastQuestionFor ? (
              <Button
                className={!isonEquifaxPage ? "icon-button" : ""}
                buttonType="outlined-primary"
                onClick={() =>
                  handleNextClick(
                    isonEquifaxPage ? true : isLastQuestion,
                    isonEquifaxPage,
                    currentQuoteData,
                    quoteAnswersData,
                    dropDownOptions,
                    currentForm,
                    equifaxDataFinal,
                    equifaxData,
                    currentQuestion,
                    authStatus,
                    userId,
                    quoteId,
                    glassGuideData,
                    glassGuideLabel,
                    previousQuestions,
                    isLastQuestion,
                    isonEquifaxPage,
                    nextQuestions,
                    defaultQuestions,
                    otherField,
                    type,
                    checkDefaultForNext,
                    fields,
                    fieldName,
                    history,
                    false,
                    quoteJsonData,
                    isEquifaxFailed,
                    isFormShow,
                    dispatch,
                    false,
                    false,
                  )
                }
              >
                {isonEquifaxPage ? "Complete" : <span className="material-icons-outlined">arrow_forward_ios</span>}
              </Button>
            ) : isLastQuestion &&
              lastQuestionFor &&
              quoteAnswersData?.[currentForm]?.[fieldName] !== lastQuestionFor ? (
              <IconButton
                buttonType="outlined-primary"
                title="arrow_forward_ios"
                onClick={() =>
                  handleNextClick(
                    isonEquifaxPage ? true : isLastQuestion,
                    isonEquifaxPage,
                    currentQuoteData,
                    quoteAnswersData,
                    dropDownOptions,
                    currentForm,
                    equifaxDataFinal,
                    equifaxData,
                    currentQuestion,
                    authStatus,
                    userId,
                    quoteId,
                    glassGuideData,
                    glassGuideLabel,
                    previousQuestions,
                    isLastQuestion,
                    isonEquifaxPage,
                    nextQuestions,
                    defaultQuestions,
                    otherField,
                    type,
                    checkDefaultForNext,
                    fields,
                    fieldName,
                    history,
                    false,
                    quoteJsonData,
                    isEquifaxFailed,
                    isFormShow,
                    dispatch,
                    false,
                    false,
                  )
                }
              />
            ) : (
              <IconButton
                buttonType="outlined-primary"
                title={`arrow_forward_ios`}
                onClick={() =>
                  handleNextClick(
                    isonEquifaxPage ? true : isLastQuestion,
                    isonEquifaxPage,
                    currentQuoteData,
                    quoteAnswersData,
                    dropDownOptions,
                    currentForm,
                    equifaxDataFinal,
                    equifaxData,
                    currentQuestion,
                    authStatus,
                    userId,
                    quoteId,
                    glassGuideData,
                    glassGuideLabel,
                    previousQuestions,
                    isLastQuestion,
                    isonEquifaxPage,
                    nextQuestions,
                    defaultQuestions,
                    otherField,
                    type,
                    checkDefaultForNext,
                    fields,
                    fieldName,
                    history,
                    false,
                    quoteJsonData,
                    isEquifaxFailed,
                    isFormShow,
                    dispatch,
                    false,
                    false,
                  )
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default QuoteBottomNavigation
