import { REGEX } from "../constants/regexConstants"

export const validateEmail = emailAddress => REGEX.EMAIL.test(emailAddress)
export const validatePhone = (phoneNumber, question = "Mobile") => {
  let errorMessage = ""
  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "")
  const firstDigit = String(cleanedPhoneNumber)?.substring(0, 1)

  if (cleanedPhoneNumber === "") {
    cleanedPhoneNumber = phoneNumber
    errorMessage = ""
  } else if (cleanedPhoneNumber.length === 8) {
    cleanedPhoneNumber = phoneNumber
    errorMessage = "Please enter an area code"
  } else if (
    question === "Mobile" ||
    question === "Vendor Phone" ||
    question === "Home Phone" ||
    question === "Employer Phone" ||
    question === "Phone" ||
    question === "Fax"
  ) {
    if (REGEX.PHONE.test(cleanedPhoneNumber)) {
      if (firstDigit !== "0") {
        cleanedPhoneNumber = `0${cleanedPhoneNumber}`
      }
      const part1 = String(cleanedPhoneNumber)?.substring(0, 2)
      const part2 = String(cleanedPhoneNumber)?.substring(2, 6)
      const part3 = String(cleanedPhoneNumber)?.substring(6, 10)
      cleanedPhoneNumber = `${part1} ${part2}-${part3}`
    } else {
      cleanedPhoneNumber = phoneNumber
      errorMessage = "Please enter valid mobile number"
    }
  } else {
    if (REGEX.HOMEPHONE.test(cleanedPhoneNumber)) {
      if (firstDigit !== "0") {
        cleanedPhoneNumber = `0${cleanedPhoneNumber}`
      }
      const part1 = String(cleanedPhoneNumber)?.substring(0, 2)
      const part2 = String(cleanedPhoneNumber)?.substring(2, 6)
      const part3 = String(cleanedPhoneNumber)?.substring(6, 10)
      cleanedPhoneNumber = `${part1} ${part2}-${part3}`
    } else {
      cleanedPhoneNumber = phoneNumber
      errorMessage = "Please enter valid home number"
    }
  }
  return {
    phoneNumber: cleanedPhoneNumber,
    errorMessage: errorMessage,
  }
}
export const onlyAlphabets = string => REGEX.ONLY_ALPHABETIC.test(string)
