/* eslint-disable no-console */
import _, { isEmpty, isNull } from "lodash"
// import moment from "moment"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import logo from "../../assets/images/logo.svg"
import ABNModalTable from "../../common/ABNModalTable/ABNModalTable"
import Button from "../../common/Button/Button"
import { errorNotification } from "../../common/NotifyToaster"
import Loader from "../../common/loader/Loader"
import { stopLoader } from "../../common/loader/redux/LoaderActions"
import { BASE_URL } from "../../constants/UrlConstants"
import { useQuery } from "../../hooks/useQuery"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"
import { DEAL_ID_QUERY_PARAM } from "../application/ApplicationPage/ApplicationPageConstants"
import { RenderField } from "../application/components/RenderField"
import ApplicationApiServices from "../application/services/ApplicationServices"
import BrokerEmailVerification from "./components/BrokerEmailVerification"
import BrokerOTPVerification from "./components/BrokerOTPVerification"
import RepeaterForm from "./components/RepeaterForm"
import { getQueryParams } from "./helpers/queryParams"
import {
  // checkEmailStatusAction,
  createUserActionNew,
  equifaxSaveDatForBrokerNew,
  getApplicationFormPathNew,
  getBrokerDataNew,
  getBrokerJSONNew,
  getQuoteFormPathNew,
  getVanishFormDataNew,
  handleRegoSearchBrokerNew,
  quickSaveBrokerDataNew,
  saveBrokerDataNew,
  validateFieldsActionNew,
} from "./redux/BrokerAction"
import {
  copyAssetNew,
  copyFieldsNew,
  performMergeFormNew,
  removeFormDataNew,
  // removeFormData,
  repeaterFormAddAndRemoveNew,
  autoPopulateOutgoing,
  setFormDataNew,
  setGlassGuideLabelDataNew,
  setInitialFormDataNew,
  syncFormDataWithFieldsNew,
  // updateBrokerFieldsNew,
  validateFieldsNew,
  setQuoteDetails,
  setAssetDetails,
  setPayoutField,
  setDirectors,
} from "./redux/brokerSliceNew"

const BrokerFormScreenNew = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const brokerData = useSelector(state => state?.brokerReducerNew)
  const {
    brokerApplicationJsonData,
    formData,
    brokerFormPaths,
    brokerFormPathIndex,
    isLastQuestion,
    mergeFormsData,
    isApplicationLastQuestion,
    errorCounter,
    waitForSecond,
    isBrokerquotecomplete,
  } = useMemo(() => brokerData, [brokerData])

  // console.log('jsonData', brokerApplicationJsonData);
  // console.log('formData', formData);
  // console.log('brokerFormPaths', brokerFormPaths)

  const outGoingType = [
    "Rent or Board",
    "Groceries",
    "Utilities",
    "Telecommunications",
    "Clothing",
    "Insurances",
    "Transport",
    "Education",
    "Dining Out/Take Away Food",
    "Alcohol,Tobacco and Gambling",
    "Hobbies/Membership",
    "Subscriptions",
    "Leisure/Entertainment",
    "Other",
  ]

  const { brokerLoader, brokerLoaderNew } = useSelector(({ loaderReducer }) => loaderReducer ?? {})
  const [isAbnModalOpen, setIsAbnModalOpen] = useState(false)
  // const [isMinimumFilled, setIsMinimumFilled] = useState(true)
  const [hideButtonAfterSave, setHideButtonAfterSave] = useState(false)
  const [isAbnDataReceived, setIsAbnDataReceived] = useState(true)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [abnDataBroker, SetAbnData] = useState([])
  const [requestSave, SetRequestSave] = useState("")
  const [cform, Setcform] = useState("")
  const [saveButtonClicked, setSaveButtonClicked] = useState(false)
  const [quoteButtonClick, setQuoteButtonClicked] = useState(false)

  const [outGoingData, setOutGoingData] = useState(0)

  const [searchData, setSearchData] = useState("")

  const query = useQuery()
  const autoFillFieldsRef = useRef({})

  const fieldRefs = useRef([])

  const lastQuestion = useMemo(() => {
    const keyArray = Object.keys(formData)
    return keyArray.includes("serviceability")
  }, [formData])

  useEffect(() => {
    ;(async () => {
      try {
        if (Object.values(brokerApplicationJsonData).length === 0) {
          await dispatch(getBrokerJSONNew())

          // START   -------------------------------------------------------------- Get broker data from dealId
          if (query.get(DEAL_ID_QUERY_PARAM)) {
            // console.log("get deail id", query.get(DEAL_ID_QUERY_PARAM))
            await dispatch(getBrokerDataNew(query.get(DEAL_ID_QUERY_PARAM)))
          }
          // END     -------------------------------------------------------------- Get broker data from dealId
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])

  useEffect(async () => {
    let isMounted = true
    if ("companyDetails" in formData) {
      Object.entries(searchData).forEach(async ([key, value]) => {
        const path = formData.companyDetails.companyRegistrationDetails?.findIndex(e => e.fieldName === key)
        if (path >= 0 && isMounted && formData.companyDetails.companyRegistrationDetails[path].answer !== value) {
          await dispatch(
            setFormDataNew({
              path: `companyDetails[companyRegistrationDetails][${path}].answer`,
              value: value,
            }),
          )
        }
      })
    }

    // console.log('searchData', searchData, isMounted);

    return () => {
      isMounted = false
    }
  }, [formData.employmentDefault, formData.companyDetails?.companyRegistrationDetails])

  useEffect(async () => {
    if (formData?.companyDetails?.companyRegistrationDetails?.findIndex(e => e.fieldName === "companyStructure")) {
      await dispatch(
        syncFormDataWithFieldsNew({
          index: formData.companyDetails.companyRegistrationDetails?.findIndex(e => e.fieldName === "companyStructure"),
          path: "formData[companyDetails][companyRegistrationDetails]",
        }),
      )
    }
  }, [formData.companyDetails?.companyRegistrationDetails])

  useEffect(async () => {
    let isMounted = true

    if ("companyGurantor" in formData) {
      formData.companyGurantor?.companyRegistrationDetails.map((element, index) => {
        if (isMounted && formData.companyGurantor.companyRegistrationDetails[index].editable !== true) {
          dispatch(
            setFormDataNew({
              path: `companyGurantor[companyRegistrationDetails][${index}].editable`,
              value: true,
            }),
          )
        }
        return ""
      })
    }
    if (
      formData?.companyGurantor?.companyRegistrationDetails?.findIndex(e => e.fieldName === "companyStructure") &&
      isMounted
    ) {
      await dispatch(
        syncFormDataWithFieldsNew({
          index: formData.companyGurantor.companyRegistrationDetails?.findIndex(
            e => e.fieldName === "companyStructure",
          ),
          path: "formData[companyGurantor][companyRegistrationDetails]",
        }),
      )
    }
    return () => {
      isMounted = false
    }
  }, [formData.companyGurantor?.companyRegistrationDetails])

  useEffect(() => {
    if (Object.keys(brokerApplicationJsonData).length > 0) {
      dispatch(setInitialFormDataNew())
      // setIsInitialCalled( true )
    }
  }, [brokerFormPaths, brokerFormPathIndex, brokerApplicationJsonData])

  useEffect(() => {
    ;(async () => {
      if (lastQuestion) {
        let pendingField = 0
        Object.keys(formData).forEach(key => {
          const fields = formData[key]

          if (Array.isArray(fields)) {
            fields.forEach(field => {
              if (field?.isQuoteField && !field?.answer && !field?.hide) {
                pendingField = pendingField + 1
              }
            })
          } else {
            Object.keys(fields).forEach(keyInner => {
              const fieldsInner = fields[keyInner]
              fieldsInner.forEach(fieldIf => {
                if (fieldIf?.isQuoteField && !fieldIf?.answer && !fieldIf?.hide) {
                  if (fieldIf?.fields) {
                    const findPending = fieldIf?.fields.filter(fieldIfS => fieldIfS?.isQuoteField && !fieldIfS?.answer)
                    if (findPending.length) {
                      pendingField = pendingField + 1
                    }
                  } else {
                    pendingField = pendingField + 1
                  }
                }
              })
            })
          }
        })

        if (pendingField === 0) {
          setIsBtnDisabled(false)
        } else {
          setIsBtnDisabled(true)
        }
      }
    })()
  }, [formData])

  useEffect(() => {
    ;(async () => {
      if (isLastQuestion) {
        setTimeout(async () => {
          await dispatch(getQuoteFormPathNew())
        }, [1450])
      }
      if (isApplicationLastQuestion) {
        if (waitForSecond) {
          setTimeout(async () => {
            await dispatch(getApplicationFormPathNew())
          }, [1000])
        } else {
          await dispatch(getApplicationFormPathNew())
        }
      }
      if (!isEmpty(mergeFormsData) && !isNull(mergeFormsData)) {
        await dispatch(performMergeFormNew(mergeFormsData))
      }
    })()
  }, [mergeFormsData, isLastQuestion, isApplicationLastQuestion, waitForSecond])

  let unSecuredBusiness = false
  let newLoanType = false

  const getLabel = formToShow => {
    let label = _.get(brokerApplicationJsonData, `${formToShow.join(".")}.label`, "")

    const loanType = formData.loanType

    if (loanType && loanType[0]?.answer === "Unsecured Business Loan") {
      unSecuredBusiness = true
    }

    if (loanType && (loanType[2]?.answer === "New" || loanType[2]?.answer === "Demo")) {
      newLoanType = true
    }

    if (unSecuredBusiness && formToShow.indexOf("employmentDefault") >= 0) {
      label = "Finance Use"
    }

    if (newLoanType && formToShow.indexOf("vehicleSelect") >= 0) {
      label = "Asset Details"
    }

    return label
  }

  const getParamValue = paramName => {
    const url = window.location.search.substring(1)
    const qArray = url.split("&")
    for (let i = 0; i < qArray.length; i++) {
      const pArr = qArray[i].split("=")
      if (pArr[0] === paramName) return pArr[1]
    }
  }

  const formsToShow = useMemo(() => brokerFormPaths.slice(0, brokerFormPathIndex + 1), [
    brokerFormPaths,
    brokerFormPathIndex,
  ])

  // add logic on cancel button
  const handleCancelClick = () => {
    const confirmBox = window.confirm("Are you sure you want to quit this process? action can't be undone")
    if (query.get(DEAL_ID_QUERY_PARAM) === null && confirmBox === true) {
      window.location.replace(`https://crm.zoho.com/crm/simply/tab/Potentials/custom-view/4901466000000087545/list`)
    } else if (query.get(DEAL_ID_QUERY_PARAM) !== null && confirmBox === true) {
      window.location.replace(`https://crm.zoho.com/crm/simply/tab/Potentials/${query.get(DEAL_ID_QUERY_PARAM)}`)
    }
  }

  const errorElement = document.getElementsByClassName("application-error-message")

  // console.log('save button clicked', saveButtonClicked, errorCounter, errorElement)

  // console.log('save button clicked', saveButtonClicked)

  if (saveButtonClicked && errorElement.length > 0) {
    // console.log('errorElement', errorElement);

    const element = errorElement[0]?.closest(".input-label")
    element?.scrollIntoView({ behavior: "smooth" })
    setSaveButtonClicked(false)
  }

  useEffect(() => {
    console.log("errorCounter", errorCounter)

    if (quoteButtonClick && errorCounter === 0) {
      prepareQuoteData()
      setQuoteButtonClicked(false)
    } else if (saveButtonClicked && errorCounter === 0) {
      sendDataOfForm()
      setSaveButtonClicked(false)
    } else {
      setQuoteButtonClicked(false)
    }
  }, [errorCounter, quoteButtonClick, saveButtonClicked])

  const sendDataOfForm = async () => {
    // eslint-disable-next-line no-unused-vars
    let equifaxScore = 700

    const fieldsToSend = {}
    const fieldsToFind = [
      "firstName",
      "lastName",
      "middleName",
      "mobile",
      "email",
      "dob",
      "city",
      "postCode",
      "country",
      "stateshort",
      "street_number",
      "street_name",
      "toBeFinanced",
      "howMuchDoYouWantToBorrow",
    ]

    const isTrustFlow = brokerFormPaths?.findIndex(e => e?.[0] === "gurantor1" && e?.[1] === "customerDetails")
    const path =
      isTrustFlow > -1
        ? ["gurantor1.customerDetails", "gurantor1.addressInformation", "gurantor1.personalDetails", "financeNumbers"]
        : [
            "applicantDetails1.customerDetails",
            "applicantDetails1.addressInformation",
            "applicantDetails1.personalDetails",
            "financeNumbers",
          ]
    let jsonFields = []
    path.forEach(e => {
      const fields = _.get(formData, e) || []
      jsonFields = [...jsonFields, ...fields]
    })

    function collect(array) {
      array.forEach(function(el) {
        if (el?.fields && el?.fields?.length > 0) {
          collect(el?.fields)
        } else {
          if (fieldsToFind.includes(el?.fieldName) && el?.answer) {
            fieldsToSend[el.fieldName] = el?.answer
          }
        }
      })
    }

    collect(jsonFields)

    const {
      firstName,
      lastName,
      middleName,
      dob,
      city: suburb,
      postCode,
      country,
      stateshort: state,
      street_number: streetNumber,
      street_name: streetName,
      toBeFinanced,
      howMuchDoYouWantToBorrow,
    } = fieldsToSend ?? {}

    const financedAmount = toBeFinanced || howMuchDoYouWantToBorrow || 10000

    const dateOfBirth = dob
      ?.split("/")
      ?.reverse()
      ?.join("-")

    if (dateOfBirth && lastName && firstName && state && streetName && suburb && streetNumber && postCode && country) {
      const data = {
        lastName: lastName?.trim(),
        firstName: firstName?.trim(),
        middleName: middleName?.trim(),
        dateOfBirth: dateOfBirth,
        gender: "U",
        streetNumber: streetNumber?.trim(),
        streetName: streetName?.trim(),
        suburb: suburb?.trim(),
        state: state?.trim(),
        postCode: postCode?.trim(),
        country: country?.trim(),
        toBeFinanced: Math.round(financedAmount),
      }
      await dispatch(
        equifaxSaveDatForBrokerNew(data, score => {
          if (score) {
            equifaxScore = score
          }
        }),
      )
    }
    dispatch(
      quickSaveBrokerDataNew(response => {
        stopLoader("brokerLoader")
        setHideButtonAfterSave(true)
        if (response?.[0]?.applicationId) {
          let url = `${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM_NEW}/${response[0].applicationId}`
          if (getQueryParams(DEAL_ID_QUERY_PARAM))
            url = url.concat(`?${DEAL_ID_QUERY_PARAM}=${getQueryParams(DEAL_ID_QUERY_PARAM)}`)
          history.push(url)
        }
      }),
    )
  }
  const handleSearchBroker = async (currentform, fieldName, value, request) => {
    if (fieldName === "vin" && value) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        vin: params?.identifier === "getByVIN" ? value : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearchBrokerNew(url, method, paramData, currentform?.[0])).then(() => {})
    } else if (fieldName === "abn" && value) {
      //
      SetRequestSave(request)
      Setcform(currentform)
      handleABNOrACNSearchBroker(value, "ABNLookUp", request, currentform)
    } else if ((fieldName === "entityName" || fieldName === "name") && value) {
      SetRequestSave(request)
      Setcform(currentform)
      handleABNOrACNSearchBroker(value, "ABRNameLookUp", request, currentform)
    } else {
      if (fieldName === "acn" && value) {
        SetRequestSave(request)
        Setcform(currentform)
        handleABNOrACNSearchBroker(value, "ASICLookup", request, currentform)
      }
    }
  }

  const onCloseAbnModal = () => {
    setIsAbnModalOpen(false)
  }
  const handleABNOrACNSearchBroker = async (value, identifier, request, currentform) => {
    // console.log('value', value);
    // console.log('identifier', identifier);
    // console.log('request', request);
    // console.log('currentform', currentform);

    if (request === undefined) {
      request = requestSave
    }
    if (currentform === undefined) {
      currentform = cform
    }
    if (request?.url) {
      const { url, method, params } = request

      setIsAbnDataReceived(false)
      let paramData = {
        ...params,
        identifier: identifier ?? params?.identifier,
        searchString: value.toString().trim(),
      }

      if (
        ["companyDetails", "companyGurantor"].includes(currentform?.[0]) &&
        currentform?.[1] === "companyRegistrationDetails"
      ) {
        paramData.searchType = "Company"
      }
      if (["companyDetails", "companyGurantor"].includes(currentform?.[0]) && currentform?.[1] === "trustDetails") {
        paramData.searchType = "Trust"
      }
      if (paramData?.identifier !== "ABNLookUp" && paramData?.identifier !== "ASICLookup") {
        setIsAbnModalOpen(true)
      } else {
        paramData = {
          ...params,
          identifier: identifier ?? params?.identifier,
          searchString: value.toString().replace(/ /g, ""),
        }
        if (
          ["companyDetails", "companyGurantor"].includes(currentform?.[0]) &&
          currentform?.[1] === "companyRegistrationDetails"
        ) {
          paramData.searchType = "Company"
        }
        if (["companyDetails", "companyGurantor"].includes(currentform?.[0]) && currentform?.[1] === "trustDetails") {
          paramData.searchType = "Trust"
        }
      }

      try {
        await dispatch(handleABNSearch(url, method, paramData, currentform))
        setIsAbnDataReceived(true)
      } catch (e) {
        /**/
      }
    }
  }
  const scrollToElementD = divID => {
    const element = document.getElementById(divID)
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  // const setCompanyDetails = (data, currentform, params = []) => {
  //   if (currentform?.[1] === "companyRegistrationDetails") {
  //     const trusteeType = formData?.companyDetails?.trustDetails?.find(e => e.fieldName === "trusteeType")?.answer
  //     const trusteeTypeG = formData?.companyGurantor?.trustDetails?.find(e => e.fieldName === "trusteeType")?.answer
  //     const companyStructure = data?.companyStructure

  //     if (trusteeType === "Individual" || trusteeTypeG === "Individual") {
  //       if (!["Sole Trader"].includes(companyStructure)) {
  //         errorNotification(`This is a ${companyStructure} please go back and change the trustee type.`)
  //         return false
  //       }
  //     }
  //     // Object.entries(formData[`${currentform?.[0]}`]?.companyRegistrationDetails).forEach(([key, value]) => {
  //     // })
  //     const formIndexentityName = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "name",
  //     )
  //     const formIndexAbn = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "abn",
  //     )
  //     const formIndexAcn = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "acn",
  //     )
  //     const formIndexBNV = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "businessANewVenture",
  //     )
  //     // const formIndexNOD = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //     //   e => e.fieldName === "numberOfDirectors",
  //     // )
  //     const formIndexCompanyStructure = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "companyStructure",
  //     )

  //     const indexofForm = brokerFormPaths.findIndex(e => e.join(".") === currentform.join("."))
  //     if (indexofForm >= 0) {
  //       dispatch(
  //         syncFormDataWithFieldsNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexBNV}]`,
  //           index: indexofForm,
  //         }),
  //       )
  //     }

  //     const formIndexRegisteredDate = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "registeredFrom",
  //     )
  //     const formIndexgstStatus = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
  //       e => e.fieldName === "gstStatus",
  //     )

  //     if (formIndexentityName >= 0) {
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexentityName}].answer`,
  //           value: data?.entityName,
  //         }),
  //       )
  //     }
  //     formIndexAbn &&
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAbn}].answer`,
  //           value: data?.abn,
  //         }),
  //       )
  //     formIndexAcn &&
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAcn}].answer`,
  //           value: data?.acn,
  //         }),
  //       )
  //     // if ( companyStructure === "Sole Trader" )
  //     // {
  //     //   formIndexNOD &&
  //     //     dispatch(
  //     //       setFormDataNew({
  //     //         path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexNOD}].answer`,
  //     //         value: 1,
  //     //       }),
  //     //     )
  //     // }
  //     // if ( companyStructure !== "Sole Trader" )
  //     // {
  //     //   formIndexNOD &&
  //     //     dispatch(
  //     //       setFormDataNew( {
  //     //         path: `${ currentform?.[ 0 ] }.companyRegistrationDetails[${ formIndexNOD }].answer`,
  //     //         value: "",
  //     //       } ),
  //     //     )
  //     // }
  //     if (data?.registeredDate) {
  //       let diff = 0
  //       if (params?.identifier === "ASICLookup") {
  //         const startDate = moment(data?.registeredDate, "YYYY-MM-DD")
  //         const endDate = moment(new Date(), "YYYY-MM-DD")
  //         diff = endDate.diff(startDate, "days")
  //       } else {
  //         const startDate = moment(data?.registeredDate, "DD/MM/YYYY")
  //         const endDate = moment(new Date(), "DD/MM/YYYY")
  //         diff = endDate.diff(startDate, "days")
  //       }
  //       formIndexBNV &&
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexBNV}].answer`,
  //             value: diff > 90 ? "No" : "Yes",
  //           }),
  //         )
  //     }
  //     formIndexCompanyStructure &&
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexCompanyStructure}].answer`,
  //           value: data?.companyStructure,
  //         }),
  //       )

  //     formIndexRegisteredDate &&
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexRegisteredDate}].answer`,
  //           value: data?.registeredDate,
  //         }),
  //       )
  //     formIndexgstStatus &&
  //       dispatch(
  //         setFormDataNew({
  //           path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexgstStatus}].answer`,
  //           value: data?.gstStatus || "",
  //         }),
  //       )
  //   } else if (currentform?.[1] === "trustDetails") {
  //     if (
  //       data?.companyStructure === "Discretionary / Family Trust" ||
  //       data?.companyStructure === "Unit Trust" ||
  //       data?.companyStructure === "Hybrid Trust" ||
  //       data?.companyStructure === "Trust"
  //     ) {
  //       const formIndexentityName = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
  //         e => e.fieldName === "name",
  //       )
  //       const formIndexAbn = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(e => e.fieldName === "abn")

  //       const formIndexRegisteredDate = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
  //         e => e.fieldName === "registeredDate",
  //       )
  //       const indexofForm = brokerFormPaths.findIndex(e => e.join(".") === currentform.join("."))
  //       if (indexofForm >= 0) {
  //         dispatch(
  //           syncFormDataWithFieldsNew({
  //             path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAbn}]`,
  //             index: indexofForm,
  //           }),
  //         )
  //       }

  //       const formIndexgsttype = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(e => e.fieldName === "type")
  //       const formIndexgstStatus = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
  //         e => e.fieldName === "gstStatus",
  //       )

  //       formIndexAbn &&
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.trustDetails[${formIndexAbn}].answer`,
  //             value: data?.abn,
  //           }),
  //         )
  //       if (formIndexentityName >= 0) {
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.trustDetails[${formIndexentityName}].answer`,
  //             value: data?.entityName,
  //           }),
  //         )
  //       }
  //       formIndexRegisteredDate &&
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.trustDetails[${formIndexRegisteredDate}].answer`,
  //             value: data?.registeredDate,
  //           }),
  //         )
  //       formIndexgsttype &&
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.trustDetails[${formIndexgsttype}].answer`,
  //             value: data?.companyStructure,
  //           }),
  //         )
  //       formIndexgstStatus &&
  //         dispatch(
  //           setFormDataNew({
  //             path: `${currentform?.[0]}.trustDetails[${formIndexgstStatus}].answer`,
  //             value: data?.gstStatus || "",
  //           }),
  //         )
  //     } else {
  //       errorNotification("Please select trust entity")
  //     }
  //   }
  // }

  const handleABNSearch = (url, method, params, currentform) => {
    return async dispatch => {
      try {
        const response = await ApplicationApiServices.getDataFromExternalSources(
          `${BASE_URL}${url}`,
          _.toUpper(method),
          params,
        )
        if (response?.data?.status === "SUCCESS") {
          if (params?.identifier === "ABRNameLookUp") {
            // set state
            SetAbnData(response?.data?.data)
          } else {
            if (currentform?.[0] === "employment" || currentform?.[0] === "employmentDefault") {
              // console.log('response', response.data.data);

              setSearchData(response.data.data)
              Object.entries(response.data.data).forEach(([key, value]) => {
                const path = formData[`${currentform?.[0]}`]?.findIndex(e => e.fieldName === key)

                // console.log('path', path, key, value)

                if (path >= 0)
                  dispatch(
                    setFormDataNew({
                      path: `${currentform?.[0]}[${path}].answer`,
                      value: value,
                    }),
                  )
                return true
              })
            } else if (currentform?.[0] === "companyDetails") {
              setSearchData(response.data.data)

              Object.entries(response.data.data).forEach(([key, value]) => {
                const path = formData.employmentDefault?.findIndex(e => e.fieldName === key)

                // console.log('path', path, key, value)

                if (path >= 0)
                  dispatch(
                    setFormDataNew({
                      path: `employmentDefault[${path}].answer`,
                      value: value,
                    }),
                  )
                return true
              })
            } else if (currentform?.[0] === "companyGurantor") {
              setSearchData(response.data.data)
              Object.entries(response.data.data).forEach(([key, value]) => {
                const path = formData.companyGurantor?.companyRegistrationDetails?.findIndex(e => e.fieldName === key)

                // console.log('path', path, key, value)

                if (path >= 0)
                  dispatch(
                    setFormDataNew({
                      path: `companyGurantor[companyRegistrationDetails][${path}].answer`,
                      value: value,
                    }),
                  )
                return true
              })
              //   }
              // }
            }
          }
        }
      } catch (e) {
        errorNotification(e?.response?.data?.message || e.message)
      }
    }
  }

  // const handleQuoteNowThings = (fieldname, val) => {
  //   if (fieldname === "residencyDetails" && !isEmpty(val)) {
  //     setIsMinimumFilled(false)
  //   }
  //   if (fieldname === "consumerOrCommercial") {
  //     if (!isEmpty(val)) {
  //       setIsMinimumFilled(true)
  //     } else {
  //       setIsMinimumFilled(false)
  //     }
  //   }
  // }

  const prepareQuoteData = async () => {
    let equifaxScore = 700

    if (errorCounter <= 0) {
      const fieldsToSend = {}
      const fieldsToFind = [
        "firstName",
        "lastName",
        "middleName",
        "mobile",
        "email",
        "dob",
        "city",
        "postCode",
        "country",
        "stateshort",
        "street_number",
        "street_name",
        "toBeFinanced",
        "howMuchDoYouWantToBorrow",
      ]

      const isTrustFlow = brokerFormPaths?.findIndex(e => e?.[0] === "gurantor1" && e?.[1] === "customerDetails")
      const path =
        isTrustFlow > -1
          ? ["gurantor1.customerDetails", "gurantor1.addressInformation", "gurantor1.personalDetails", "financeNumbers"]
          : [
              "applicantDetails1.customerDetails",
              "applicantDetails1.addressInformation",
              "applicantDetails1.personalDetails",
              "financeNumbers",
            ]
      let jsonFields = []
      path.forEach(e => {
        const fields = _.get(formData, e) || []
        jsonFields = [...jsonFields, ...fields]
      })

      function collect(array) {
        array.forEach(function(el) {
          if (el?.fields && el?.fields?.length > 0) {
            collect(el?.fields)
          } else {
            if (fieldsToFind.includes(el?.fieldName) && el?.answer) {
              fieldsToSend[el.fieldName] = el?.answer
            }
          }
        })
      }

      collect(jsonFields)

      const {
        firstName,
        lastName,
        middleName,
        mobile,
        email,
        dob,
        city: suburb,
        postCode,
        country,
        stateshort: state,
        street_number: streetNumber,
        street_name: streetName,
        toBeFinanced,
        howMuchDoYouWantToBorrow,
      } = fieldsToSend ?? {}

      const financedAmount = toBeFinanced || howMuchDoYouWantToBorrow || 10000

      const dateOfBirth = dob
        ?.split("/")
        ?.reverse()
        ?.join("-")

      if (
        dateOfBirth &&
        lastName &&
        firstName &&
        state &&
        streetName &&
        suburb &&
        streetNumber &&
        postCode &&
        country
      ) {
        const data = {
          lastName: lastName?.trim(),
          firstName: firstName?.trim(),
          middleName: middleName?.trim(),
          dateOfBirth: dateOfBirth,
          gender: "U",
          streetNumber: streetNumber?.trim(),
          streetName: streetName?.trim(),
          suburb: suburb?.trim(),
          state: state?.trim(),
          postCode: postCode?.trim(),
          country: country?.trim(),
          toBeFinanced: Math.round(financedAmount),
        }
        await dispatch(
          equifaxSaveDatForBrokerNew(data, score => {
            if (score) {
              equifaxScore = score
            }
          }),
        )
      }
      if (firstName && lastName && email && mobile && equifaxScore) {
        const dataToSend = { firstName, lastName, email, mobile, equifaxScore }
        await dispatch(
          createUserActionNew(dataToSend, data => {
            // dispatch(
            // checkEmailStatusAction(data.id, cbData => {
            // if (cbData?.isEmailVerified) {
            dispatch(
              saveBrokerDataNew(data.id, query.get(DEAL_ID_QUERY_PARAM), response => {
                stopLoader("brokerLoader")
                setHideButtonAfterSave(true)

                let url = `${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM_NEW}/${response[0].applicationId}`
                if (getQueryParams(DEAL_ID_QUERY_PARAM))
                  url = url.concat(`?${DEAL_ID_QUERY_PARAM}=${getQueryParams(DEAL_ID_QUERY_PARAM)}`)
                history.push(url)
              }),
            )
            // }
            // }),
            // )
          }),
        )
      }
    }
  }

  const renderField = (formToShow, field, self, index, mainIndex, innerIndex = "") => {
    // if ( field?.fieldName === "newCurrentRepayment" )
    // {
    //   const formDataFields = _.get( formData, "financeNumbers" )
    //   const value = formDataFields?.find( x => x?.fieldName === "payoutRepayment" )?.answer || 0
    //   field = { ...field, answer: value }
    // }

    // dispatch( repeaterFormAddAndRemoveNew( { isAdd: false, index, mainIndex, removeIndex } ) )

    return (
      <RenderField
        fieldRefs={fieldRefs}
        key={`${formToShow.join(".")}-${field.fieldName}-${field.key}`}
        autoFillFieldsRef={autoFillFieldsRef}
        field={field}
        formPath={[]}
        isFromAssetPage={false}
        showSeparate={""}
        isFromBrokerPageNew={true}
        isFromBrokerPage={false}
        currentForm={formToShow}
        pathOfField={
          innerIndex || innerIndex === 0
            ? `${formToShow.join(".")}[${mainIndex}].fields[${innerIndex}]`
            : `${formToShow.join(".")}[${mainIndex}]`
        }
        onFieldUpdate={async (fieldName, value, label) => {
          // START   -------------------------------------------------------------- Copy asset data
          if (field?.copyForms) {
            const copyForms = { ...field?.copyForms }

            if (typeof copyForms.formName !== "string") {
              copyForms.formName = copyForms.formName[value]
            }
            dispatch(copyAssetNew({ copyForms, mainForm: formToShow[0], isAdd: !!value }))
          }
          // END     -------------------------------------------------------------- Copy asset data
          // START   -------------------------------------------------------------- Copy fields
          if (field?.copyFields) dispatch(copyFieldsNew({ field: { ...field, answer: value }, formPathIndex: index }))
          // END     -------------------------------------------------------------- Copy fields

          if (field.fieldName === "personalOrBusinessUse" && value === "Business Use") {
            dispatch(setQuoteDetails({ quote: "Commercial" }))
          }
          if (field.fieldName === "personalOrBusinessUse" && value === "Personal Use") {
            dispatch(setQuoteDetails({ quote: "Consumer" }))
          }

          if (field.fieldName === "assetCondition") {
            dispatch(setAssetDetails({ value }))
          }

          // console.log('field name', field.fieldName)

          if (field.fieldName === "tradeIn" && value === "No") {
            dispatch(setPayoutField())
          }

          if (field.fieldName === "numberOfDirectors") {
            dispatch(setDirectors(value))
          }

          let path = ""
          let pathAD = ""
          let pathADnv = ""
          // SUB FIELDS
          if (innerIndex || innerIndex === 0) {
            path = `${formToShow.join(".")}[${mainIndex}].fields[${innerIndex}]`
            // NO SUB FIELDS
          } else {
            path = `${formToShow.join(".")}[${mainIndex}]`
          }

          // Will store key if glassguide field already has answer and its changed
          const glassGuideFieldAnswerChangedKey = field?.request?.url && !!field?.answer && value ? field?.key : ""
          if (fieldName === "nvic" && formToShow[0] === "vehicleSelect") {
            /** for NVIC */
            label && field?.request && dispatch(setGlassGuideLabelDataNew({ formToShow, fieldName, label, value }))
            dispatch(
              setFormDataNew({
                path,
                value: { ...field, answer: value },
              }),
            )
            /** for NVIC */

            /** for asset description */
            const formIndex = formData[`${formToShow[0]}`]?.findIndex(e => e.fieldName === "assetDescription")

            const assetYear = formData[`${formToShow[0]}`]?.find(e => e.fieldName === "assetYear")?.answer
            if (innerIndex || innerIndex === 0) {
              pathAD = `${formToShow.join(".")}[${mainIndex}].fields[${formIndex}]`
              // NO SUB FIELDS
            } else {
              pathAD = `${formToShow.join(".")}[${formIndex}]`
            }
            dispatch(
              setFormDataNew({
                path: `${pathAD}.answer`,
                value: `${assetYear} ${label}`,
              }),
            )
            /** for asset description */
          } else {
            if (formToShow[1] === "assetGlassGuide") {
              if (fieldName === "model") {
                /** for asset nvic */
                const formIndexnvic = _.get(formData, formToShow)?.findIndex(e => e.fieldName === "nvic")
                pathADnv = `${formToShow.join(".")}[${formIndexnvic}].answer`

                dispatch(
                  setFormDataNew({
                    path: `${pathADnv}`,
                    value: `${value}`,
                  }),
                )
                /** for asset description */

                /** for asset description */
                const formIndex = _.get(formData, formToShow)?.findIndex(e => e.fieldName === "assetDescription")
                const assetYear = _.get(formData, `${formToShow["0"]}.assetDetails`)?.find(
                  e => e.fieldName === "assetAge",
                )?.answer

                pathAD = `${formToShow.join(".")}[${formIndex}].answer`
                dispatch(
                  setFormDataNew({
                    path: `${pathAD}`,
                    value: `${assetYear} ${label}`,
                  }),
                )
                /** for asset description */
              }
            }
            label &&
              field?.request &&
              dispatch(setGlassGuideLabelDataNew({ formToShow: formToShow?.[0], fieldName, label }))

            dispatch(
              setFormDataNew({
                path,
                value: { ...field, answer: value },
              }),
            )
          }
          field?.fieldsToWipeOut && dispatch(removeFormDataNew({ index, mainIndex, innerIndex }))
          if (field?.vanishNextForms) {
            dispatch(syncFormDataWithFieldsNew({ path, index, mainIndex, glassGuideFieldAnswerChangedKey }))
          }

          if (field?.type !== "formula") {
            // field?.vanishNextForms && dispatch( removeFormData( { index, mainIndex, innerIndex } ) )
            setTimeout(async () => {
              field?.vanishNextForms &&
                (await dispatch(
                  getVanishFormDataNew({
                    formToShow,
                    fieldName,
                    value,
                    answer: field?.answer,
                    index,
                    mainIndex,
                    innerIndex,
                  }),
                ))
            }, 450)
          }
          setTimeout(async () => {
            await dispatch(syncFormDataWithFieldsNew({ path, index, mainIndex, glassGuideFieldAnswerChangedKey }))
            if (errorCounter > 0 || field?.errorMessage) dispatch(validateFieldsActionNew())
          }, 600)

          // handleQuoteNowThings(fieldName, value)
        }}
        errorMessage={field?.errorMessage || ""}
        handleSearch={handleSearchBroker}
      />
    )
  }

  const dealId = (getParamValue("dealId") && `Deal ID: ${getParamValue("dealId")}`) || ""

  const vehicleDetails = document.getElementById("VehicleDetails")

  const assetDetails = document.getElementById("AssetDetails")

  let scrollToAssetTarget = ""

  if (vehicleDetails) {
    scrollToAssetTarget = "VehicleDetails"
  } else if (assetDetails) {
    scrollToAssetTarget = "AssetDetails"
  }

  return (
    <>
      <Loader fromPathLoader={false} isLoading={brokerLoader} />
      <>
        <div className="header-container logo-login-button ">
          <div>
            <img src={logo} alt="Simply Finance" title="Simply Finance" />
          </div>
          <div></div>
          <div>{dealId}</div>
        </div>
      </>
      {!isBrokerquotecomplete ? (
        <div className="application-tool-form broker-app-form movetoSidebar">
          {formsToShow.map((formToShow, index) => {
            const fields = _.get(formData, formToShow.join(".")) || []

            // console.log('fields', formData, fields, formToShow.join('.'));

            let formLabel = ""
            const subformLabel = getLabel(formToShow)
            const mainLabelIndex = formsToShow.findIndex(x => formToShow[0] === x[0])

            if (mainLabelIndex === index) {
              formLabel = getLabel([formToShow[0]])
            }

            // console.log('formLabel', formLabel, fields, formData);

            return (
              <div key={`${formToShow.join(".")}`} className="application-tool-form-content">
                <div className="application-tool-form-inner">
                  <>
                    {formLabel && (
                      <h1 id={formLabel.replace(/ /g, "")} className="application-form-heading gridcolchange">
                        {formLabel}
                      </h1>
                    )}
                    {formLabel === "Company Details" && (
                      <a
                        className="form-googlelookup-question"
                        style={{ marginTop: "-20px", marginBottom: "10px" }}
                        onClick={() => scrollToElementD("VehicleUse")}
                        href="javascript:void(0);"
                      >
                        Edit Company Details
                      </a>
                    )}
                    {formLabel === "Asset 1 Details" && (
                      <a
                        className="form-googlelookup-questionNew"
                        style={{ marginTop: "-20px", marginBottom: "10px" }}
                        onClick={() => scrollToElementD(scrollToAssetTarget)}
                        href="javascript:void(0);"
                      >
                        {" "}
                        Edit Asset Details - provide the mandatory fields (Make and Model) to update asset details
                      </a>
                    )}
                    {subformLabel && formLabel !== subformLabel && (
                      <h3 className="application-form-subheading">{subformLabel}</h3>
                    )}
                  </>

                  {fields?.length > 0 &&
                    fields.map((field, mainIndex, self) => {
                      return (
                        <Fragment key={`${formToShow.join(".")}-${field?.key}`}>
                          {field.type === "repeater" ? (
                            <RepeaterForm
                              fieldRefs={fieldRefs}
                              field={field}
                              autoFillFieldsRef={autoFillFieldsRef}
                              isFromAssetPage={false}
                              isFromBrokerPageNew={true}
                              isFromBrokerPage={false}
                              errorMessage={""}
                              isOutGoing={outGoingType.length > outGoingData}
                              handleSearch={handleSearchBroker}
                              currentForm={formToShow}
                              onFieldUpdate={(index, innerIndex, field, value) => {
                                if (errorCounter > 0 || field?.errorMessage) dispatch(validateFieldsActionNew())
                                const path = `${formToShow.join(".")}[${mainIndex}].fields[${index}][${innerIndex}]`
                                dispatch(
                                  setFormDataNew({
                                    path,
                                    value: { ...field, answer: value },
                                  }),
                                )
                              }}
                              onAdd={() => {
                                dispatch(repeaterFormAddAndRemoveNew({ isAdd: true, index, mainIndex, field }))

                                if (field.fieldName === "income" && field.fields.length === 0) {
                                  outGoingType.map((o, i) => {
                                    dispatch(autoPopulateOutgoing({ outGoingType: o, index, mainIndex }))

                                    setOutGoingData(i + 1)

                                    return o
                                  })
                                } else if (field.fieldName === "outgoings") {
                                  setOutGoingData(outGoingData + 1)
                                }
                              }}
                              onRemove={removeIndex => {
                                dispatch(repeaterFormAddAndRemoveNew({ isAdd: false, index, mainIndex, removeIndex }))

                                if (field.fieldName === "outgoings") {
                                  setOutGoingData(outGoingData - 1)
                                }
                              }}
                            />
                          ) : field?.fields?.length > 0 ? (
                            field?.fields?.map((innerField, indexInner, self) =>
                              renderField(formToShow, innerField, self, index, mainIndex, indexInner),
                            )
                          ) : (
                            renderField(formToShow, field, self, index, mainIndex)
                          )}
                        </Fragment>
                      )
                    })}
                </div>
              </div>
            )
          })}
          {isAbnModalOpen && (
            <ABNModalTable
              tableData={abnDataBroker}
              isModalOpen={isAbnModalOpen}
              isLoading={isAbnDataReceived}
              onClose={onCloseAbnModal}
              onRecordClick={e => handleABNOrACNSearchBroker(e?.abn, "ABNLookUp")}
            />
          )}
        </div>
      ) : (
        <>
          {/* // update text */}
          <section className="quote-tool-container">
            <div className="quote-tool-form">
              <div className="quote-tool-form-content">
                <div className="question">
                  <p className="question_text">
                    {/* Application submitted successfully. To edit visit ZOHO CRM and search{" "}
                    {getQueryParams(DEAL_ID_QUERY_PARAM)} deal and click edit application button */}
                    Application submitted successfully. Click on EDIT button to be routed to Sigle Page Broker Form.
                  </p>
                  {/* redirect to broker-form */}
                  <Button
                    isDisabled={false}
                    buttonType="outlined-primary"
                    onClick={() =>
                      window.location.replace(
                        `${ROUTE_CONSTANTS_VARIABLE.BROKER_ONEPAGE_FORM_NEW}/?dealId=${getQueryParams(
                          DEAL_ID_QUERY_PARAM,
                        )}`,
                      )
                    }
                  >
                    Edit Application
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <div className="footer-container">
        <div className="showbtns">
          <Button
            isDisabled={false}
            buttonType="outlined-primary"
            className="error-button white-text"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          {brokerLoaderNew && <div className="w-100 d-flex align-center just-center p-20 font-primary">Loading...</div>}
          <div className={`${hideButtonAfterSave ? "hidebtns" : ""} prev-next-button-container`}>
            {/** isMinimumFilled */}
            <Button
              className={`success-button ${!lastQuestion || isBtnDisabled ? "disable" : ""}`}
              isDisabled={!lastQuestion || isBtnDisabled}
              onClick={() => {
                dispatch(validateFieldsNew())
                setQuoteButtonClicked(true)
              }}
            >
              Quote Now
            </Button>
            <Button
              // isDisabled={!lastQuestion || errorCounter > 0}
              buttonType="outlined-primary"
              onClick={() => {
                dispatch(validateFieldsNew())
                setSaveButtonClicked(true)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <BrokerOTPVerification />
      <BrokerEmailVerification />
    </>
  )
}
export default BrokerFormScreenNew
