import QuoteBottomNavigation from "../../screens/quote/components/QuoteBottomNavigation"

const Footer = () => {
  return (
    <div className="footer-container">
      <QuoteBottomNavigation />
    </div>
  )
}

export default Footer
