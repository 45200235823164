import { REGISTER_REDUX_CONSTANTS } from "./RegisterReduxConstants"

const initialRegistrationState = {
  firstName: "",
  lastName: "",
  email: "",
  countryName: {
    label: "🇦🇺 +61",
    value: {
      name: "Australia",
      cca2: "AU",
      flag: "🇦🇺",
      code: "+61",
    },
  },
  mobileNumber: "",
  isAllowMessages: false,
  isAllowTermsCondition: false,
  errors: {},
}

export const registerReducer = (state = initialRegistrationState, action) => {
  switch (action.type) {
    case REGISTER_REDUX_CONSTANTS.CREDENTIAL_CHANGE:
      return {
        ...state,
        [action.name]: action.value,
      }
    case REGISTER_REDUX_CONSTANTS.CREDENTIAL_RESET:
      return initialRegistrationState
    default:
      return state
  }
}
