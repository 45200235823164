import Accordion from "../../../common/Accordion/Accordion"
import AccordionItem from "../../../common/Accordion/AccordionItem"
import pepper from "../../../assets/images/pepper.png"
import { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import RateCardDetails from "./RateCardDetails"
import { NumberCommaSeparator } from "../../../helpers/NumberCommaSeparator"

const RateMobileHeader = ({ rateCard, isOpen = true }) => {
  return (
    <>
      <img src={rateCard?.lenderImage || pepper} alt="pepper" />
      {isOpen ? (
        <>
          <div className="rate-sub-head">
            <div>$ {NumberCommaSeparator(rateCard?.repayments)}</div>
            <div>Repayments</div>
          </div>
          <div className="rate-sub-head">
            <div>$ {NumberCommaSeparator(rateCard?.totalPayments)}</div>
            <div>Total Payments</div>
          </div>
        </>
      ) : (
        <div className="rate-see-all-label">
          See All <span className="material-icons-outlined">expand_more</span>
        </div>
      )}
    </>
  )
}
RateMobileHeader.propTypes = {
  rateCard: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
}

const RateCardMobile = ({ rateCards, onCardSelect, isOpenView }) => {
  const [selectedRate, setSelectedRate] = useState(0)
  const [openIndex, setOpenIndex] = useState(true)
  const [isOpen2, setIsOpen2] = useState(false)

  const selectedRateCard = useMemo(() => rateCards?.find(e => e?.index === selectedRate), [selectedRate])
  const unSelectedRateCards = useMemo(() => rateCards?.filter(e => e?.index !== selectedRate), [selectedRate])

  useEffect(() => {
    if (!openIndex) {
      setTimeout(() => {
        setOpenIndex(true)
      }, [200])
    }
  }, [openIndex])

  return (
    <div className="rate-card-mobile">
      <div className="rate-accordion">
        <Accordion>
          <AccordionItem
            header={
              <div className="rate-mobile-header-card">
                <RateMobileHeader isOpen={isOpen2} rateCard={selectedRateCard} />
              </div>
            }
            index={1}
            setIndex={openIndex ? 1 : 0}
            openCallBack={e => {
              if (e === 1) {
                setIsOpen2(true)
                setOpenIndex(true)
              } else {
                setOpenIndex(false)
                setIsOpen2(false)
              }
            }}
          >
            {unSelectedRateCards?.length &&
              unSelectedRateCards?.map(e => (
                <div
                  key={e?.index}
                  className="rate-mobile-cards cursor-pointer"
                  onClick={() => {
                    setOpenIndex(false)
                    setIsOpen2(false)
                    setSelectedRate(e?.index)
                  }}
                >
                  <RateMobileHeader rateCard={e} />
                </div>
              ))}
          </AccordionItem>
        </Accordion>
      </div>
      <div className="mobile-rate-card-info">
        <RateCardDetails
          data={rateCards?.[selectedRate]}
          onCardSelect={onCardSelect}
          isOpenView={isOpenView}
          isMobileView
        />
      </div>
    </div>
  )
}
export default RateCardMobile

RateCardMobile.propTypes = {
  rateCards: PropTypes.array.isRequired,
  onCardSelect: PropTypes.func,
  isOpenView: PropTypes.bool,
}
