import Button from "../../../common/Button/Button"
import QuickAccessPopup from "../../../common/QuickAccessPopup"
import PropTypes from "prop-types"

const RateCardFilterHeader = ({ rateFilters, selectedFilter, handleFilterSelect }) => {
  return (
    <>
      <section className="rate-filters">
        <div>Filter By</div>
        {rateFilters?.map(e => (
          <Button
            key={e?.name}
            buttonType={selectedFilter === e?.name ? "outlined-secondary" : "secondary"}
            title={e?.label}
            onClick={() => handleFilterSelect(e)}
          />
        ))}
      </section>
      <QuickAccessPopup
        className="rate-filter-quick-access"
        title="Filter By"
        iconActive="close"
        iconInActive="filter_list"
      >
        <section className="rate-mobile-filters">
          {rateFilters?.map(e => (
            <Button
              key={e?.name}
              buttonType={selectedFilter === e?.name ? "outlined-secondary" : "secondary"}
              title={e?.label}
              onClick={() => handleFilterSelect(e)}
            />
          ))}
        </section>
      </QuickAccessPopup>
    </>
  )
}

export default RateCardFilterHeader

RateCardFilterHeader.propTypes = {
  rateFilters: PropTypes.array.isRequired,
  handleFilterSelect: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string,
}
