import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Pagination from "../../../common/Pagination/Pagination"
import Table from "../../../common/Table/Table"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { getDashboardQuoteList } from "../Redux/dashboardActions"

const QuoteTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dashboardQuoteList = useSelector(({ dashboardReducer }) => dashboardReducer?.quoteList ?? {})
  const { docs, headers, limit, page, pages, total, mobileHeaders } = dashboardQuoteList ?? {}
  useEffect(async () => {
    dispatch(getDashboardQuoteList({ page: 1, limit: 5 }))
  }, [])
  const pageActionClick = newPage => {
    dispatch(getDashboardQuoteList({ page: newPage, limit }))
  }
  const handleQuoteRedirect = id => {
    history.push(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE + `/${id}`)
  }
  return (
    <>
      <Table
        tableType="quote"
        data={docs}
        headers={headers}
        mobileHeaders={mobileHeaders}
        handleRedirectClick={id => handleQuoteRedirect(id)}
      />
      <Pagination
        total={total}
        page={page}
        pages={pages}
        limit={limit}
        pageActionClick={pageActionClick}
        isAllowedChooseLimit={false}
      />
    </>
  )
}

export default QuoteTable
