import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { UPPER_MENU_CONSTANTS } from "../../constants/UpperMenuConstants"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import logo from "../../assets/images/logo.svg"
import smallLogo from "../../assets/images/smallLogo.png"

const UpperMenu = () => {
  const { userProfile } = useSelector(({ headerReducer }) => headerReducer ?? {})
  const isMobile = useMediaQuery("(max-width:640px)")
  const isAdmin = userProfile?.role?.toString()?.toLowerCase() === "admin"

  return (
    <div className="upper-menu-container">
      {!isMobile && <img src={logo} alt="logo" className="dashboard-desktop-logo" />}
      {isMobile && <img src={smallLogo} alt="logo" className="dashboard-mobile-logo" />}
      {UPPER_MENU_CONSTANTS.map(menu =>
        menu.name === "userModule" && !isAdmin ? (
          <div key={menu.path} />
        ) : (
          <NavLink className="menu-item" key={menu.path} to={menu.path} replace>
            <span className="material-icons-outlined header-icons">{menu.icon}</span>
            {!isMobile && menu.label}
          </NavLink>
        ),
      )}
    </div>
  )
}

export default UpperMenu
