import dayjs from "dayjs"

export const defaultAssetYears = previousYears => {
  const years = [...new Array(previousYears ?? 20)].map((_, i) => {
    const year = dayjs()
      .startOf("year")
      .subtract(i, "years")
      .format("YYYY")
    return { label: year, value: year }
  })
  return [...years, { label: "Other", value: "Other" }]
}

export const defaultManufactureOption = option => {
  if (option === undefined) {
    return [{ label: "None", value: "None" }]
  } else {
    return [{ label: "None", value: "None" }, ...option]
  }
}

export const defaultAssetYearsWithoutOther = previousYears => {
  return [...new Array(previousYears ?? 20)].map((_, i) => {
    return dayjs()
      .startOf("year")
      .subtract(i, "years")
      .format("YYYY")
  })
}
