import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getApplicationDocData, sendApplicationDocData } from "../redux/ApplicaitonActions"
import Button from "../../../common/Button/Button"
import FileUpload from "../components/fileUpload/FileUpload"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { useHistory } from "react-router-dom"

const EditAplicationPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  useEffect(() => {
    ;(async () => {
      try {
        if (authStatus && id) {
          await dispatch(getApplicationDocData(id, {}))
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])
  const editApplicationData = useSelector(({ applicationReducer }) => applicationReducer.editApplicationData)
  const handleFileFieldUpdate = filePath => {
    const fieldName = filePath.split("_test_")[0]
    let fieldValue = filePath.split("_test_")[1]
    if (fieldValue === "undefined") {
      fieldValue = ""
    }
    const finalData = editApplicationData?.map(e => {
      if (e?.id === fieldName) {
        return { ...e, url: fieldValue, Status: "Supplied" }
      }
      return { ...e }
    })

    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.SET_EDIT_APPLICATION_DATA,
      data: finalData,
    })
  }
  const onFormSubmit = async () => {
    const finalData = editApplicationData?.filter(data => data?.url)
    if (typeof finalData === "object" && Object.keys(finalData).length > 0) {
      await dispatch(sendApplicationDocData(id, { sendDocuments: editApplicationData }))
      history.push(`${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}`)
    } else {
      const finalData = editApplicationData?.map(e => {
        if (!e?.url) {
          return { ...e, error: "Please Provide requested document" }
        }
        return { ...e, error: "" }
      })

      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.SET_EDIT_APPLICATION_DATA,
        data: finalData,
      })
    }
  }

  return (
    <React.Fragment>
      <section className="application-container">
        <div className="application-tool-form">
          <div className="application-tool-form-content">
            <div className="application-form-heading">Requested Document</div>
            <div className="application-tool-form-inner  gapBetweenDiv">
              {editApplicationData &&
                editApplicationData?.length > 0 &&
                editApplicationData.map(field => {
                  return (
                    <div className="mb-15" key={field?.id}>
                      <FileUpload
                        key={field?.id}
                        multiple={false}
                        maxFiles={1}
                        name={field?.id}
                        label={field?.Document_Type + " *"}
                        fromEdit={true}
                        onUpload={handleFileFieldUpdate}
                        id={id}
                        value={field?.url}
                        errorMessage={field?.error}
                      />
                      <span className="application-error-message">{field?.error}</span>
                    </div>
                  )
                })}
            </div>
          </div>
          <Button buttonType="secondary" onClick={() => onFormSubmit()} className="mb-15">
            Submit
          </Button>
        </div>
      </section>
    </React.Fragment>
  )
}
export default EditAplicationPage
