import { get } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "../../../../hooks/useMediaQuery"
import { APPLICATION_REDUX_CONSTANTS } from "../../redux/ApplicaitonReduxConstants"
import { applicationSelector } from "../../redux/ApplicationSelector"
import "./_quickAccessMenuStyle.scss"

const QuickAccessMenuDrawer = ({ runValidations, handleNavigation }) => {
  const dispatch = useDispatch()
  const isMobileScreen = useMediaQuery("(max-width: 680px)")
  const { applicationJsonData, formsKey, subFormsKey, quickAccessMenuList } = useSelector(applicationSelector)

  // state for handling drawer toggle
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // disable hover functionality
  const disableHover = useRef(false)

  useEffect(() => {
    if (isMobileScreen) {
      disableHover.current = true
    }
  }, [isMobileScreen])

  const handleDrawerOpen = value => {
    if (disableHover.current && !value) {
      // disable hover functionality and reenable it after animation is complete
      setTimeout(() => {
        if (!isMobileScreen) {
          disableHover.current = false
        }
      }, 1000)
    }
    setIsDrawerOpen(value)
  }

  // handle mouse hover in/out
  const handleMouseHover = isHover => {
    // if disable hover is false then call drawer toggle function
    if (!disableHover.current) {
      if (isHover) {
        !isDrawerOpen && handleDrawerOpen(true)
      } else {
        isDrawerOpen && handleDrawerOpen(false)
      }
    }
  }

  // handle when section is clicked
  const handleMainSectionClick = async section => {
    // if there is no error in current form
    handleDrawerOpen(false)
    if (runValidations()) {
      // close drawer
      // active save call and disable auto next form
      await handleNavigation(true, false, true)
      // redux action to change redux states. (More detail on reducer)
      dispatch({ type: APPLICATION_REDUX_CONSTANTS.QUICK_ACCESS_MENU_NAVIGATION, payload: section })
    }
  }

  // If there is no data then don't show QAM
  if (
    !Array.isArray(quickAccessMenuList) ||
    (Array.isArray(quickAccessMenuList) && quickAccessMenuList?.length === 0)
  ) {
    return <></>
  }

  const getLabel = form => {
    if (form.includes("applicantDetails")) return "Applicant(s)"
    else if (form.includes("asset")) return "Asset(s)"
    else if (form.includes("gurantor")) return "Gurantor(s)"
    else if (form.includes("serviceability")) return "Servicing"
    return get(applicationJsonData, `${form}.label`)
  }

  return (
    <>
      {isMobileScreen && <div className={`backdrop ${isDrawerOpen ? "active" : "inactive"}`} />}
      <div className={`quickAccesscontainer ${isDrawerOpen ? "active" : "inactive"}`}>
        <div
          className="arrow"
          onMouseOver={() => handleMouseHover(true)}
          onMouseOut={() => handleMouseHover(false)}
          onClick={() => {
            disableHover.current = true
            handleDrawerOpen(!isDrawerOpen)
          }}
        >
          <span className="material-icons">{isDrawerOpen ? "arrow_forward_ios" : "arrow_back_ios_new"}</span>
        </div>
        <div
          className="container"
          onMouseOver={() => handleMouseHover(true)}
          onMouseOut={() => handleMouseHover(false)}
        >
          <div className="content">
            {quickAccessMenuList.map(({ isDisabled, mainForm, subForm }, index, self) => {
              const classNames = ["section"]
              if (formsKey.includes(mainForm)) classNames.push("active")
              if (!isDisabled) {
                isDisabled = subForm
                  ? subFormsKey.includes(subForm) && formsKey.includes(mainForm)
                  : formsKey.includes(mainForm)
              }
              if (isDisabled) classNames.push("disabled")

              const label = getLabel(mainForm)

              return (
                <div key={index} className={classNames.join(" ")}>
                  <span
                    className="label"
                    onClick={() => {
                      if (isDisabled) return
                      disableHover.current = true
                      const section = self[index]
                      handleMainSectionClick(section)
                    }}
                  >
                    {label || ""}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

QuickAccessMenuDrawer.propTypes = {
  runValidations: PropTypes.func,
  handleNavigation: PropTypes.func,
}

export { QuickAccessMenuDrawer }
