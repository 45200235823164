import AccordionItem from "../../../common/Accordion/AccordionItem"
import { CommaSeperator, NumberCommaSeparator } from "../../../helpers/NumberCommaSeparator"

const ViewQuoteSingle = props => {
  const { data, index } = props || {}
  return (
    <>
      <AccordionItem
        index={index}
        accordionBodyClass="application-active-accordion-scroll"
        header={<label>{data?.formName}</label>}
        prefixInActive="add"
        prefixActive="remove"
      >
        <div className="quote-main-container">
          {data.fields.length > 0
            ? data.fields.map((questions, index) => (
                <div className="quote-inner" key={index}>
                  <div className="quote-question">{questions?.question === "Vin" ? "VIN" : questions?.question}</div>
                  <div className="quote-answer">
                    {questions?.type === "number" ||
                    (questions?.question === "Would you like a balloon at the end of term?" && questions?.answer > 40)
                      ? `$ ${NumberCommaSeparator(questions?.answer)}`
                      : questions?.answer && questions?.type === "number"
                      ? `${CommaSeperator(questions?.answer)}`
                      : questions?.answer}
                  </div>
                </div>
              ))
            : ""}
        </div>
      </AccordionItem>
    </>
  )
}
export default ViewQuoteSingle
