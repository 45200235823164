import { HEADER_URLS } from "../../../constants/UrlConstants"
import ApiService from "../../../services/ApiServices"

const HeaderApiService = {
  logoutUser: () => ApiService.deleteData(HEADER_URLS.LOGOUT_URL),
  getUserProfile: () => ApiService.getData(HEADER_URLS.GET_USER_PROFILE),
  changePassword: data => ApiService.putData(HEADER_URLS.CHANGE_PASSWORD, data),
  profileSaveData: data => ApiService.postData(HEADER_URLS.UPDATE_USER_PROFILE, data),
}

export default HeaderApiService
