import { BrowserRouter as Router, Switch } from "react-router-dom"
import Layout from "../common/Layout/Layout"
import { ROUTE_CONSTANTS } from "./RouteConstants"
import { AuthenticatedRoute } from "./AuthenticatedRoute"
import { NonAuthenticatedRoute } from "./NonAuthenticatedRoute"
import { AUTH_REDUX_CONSTANTS } from "../screens/auth/redux/AuthReduxConstants"
import { getAuthTokenFromLocalStorage } from "../helpers/LocalStorageHelper"
import { useDispatch } from "react-redux"
import { useEffect, useRef } from "react"
import { isEditedContext } from "../helpers/contexts"

const Routes = () => {
  const isEdited = useRef(false)
  const dispatch = useDispatch()
  useEffect(() => {
    window.addEventListener("storage", () => {
      const isToken = getAuthTokenFromLocalStorage()
      if (!isToken) {
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: false,
        })
      }
    })

    return () => {
      window.removeEventListener("storage", () => {})
    }
  }, [])

  return (
    <Router>
      <isEditedContext.Provider value={isEdited}>
        <Layout>
          <Switch>
            {ROUTE_CONSTANTS.map(({ path, component, authenticated, isEscapeRedirect }) => {
              const Component = authenticated ? AuthenticatedRoute : NonAuthenticatedRoute
              return (
                <Component key={path} exact path={path} component={component} isEscapeRedirect={isEscapeRedirect} />
              )
            })}
          </Switch>
        </Layout>
      </isEditedContext.Provider>
    </Router>
  )
}
export default Routes
