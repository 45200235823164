import { QUOTE_URLS } from "../../../constants/UrlConstants"
import ApiService from "../../../services/ApiServices"

const QuoteApiServices = {
  getQuoteList: params => ApiService.getData(QUOTE_URLS.GET_QUOTE_LIST, { params }),
  getQuoteToolJSON: () => ApiService.getData(QUOTE_URLS.GET_QUOTE_JSON),
  getEmailVerificationStatus: id => ApiService.getData(`${QUOTE_URLS.GET_EMAIL_VERI_STATUS}/${id}`),
  getNextQuoteForm: data => ApiService.putData(QUOTE_URLS.GET_NEXT_QUOTE_FORM, data),
  getDataFromExternalSources: (url, method, params) =>
    ApiService.request({
      url,
      method,
      params,
      timeout: 60000,
    }),
  submitAndSaveAsDraftQuoteData: data => ApiService.postData(QUOTE_URLS.SUBMIT_SAVE_AS_DRAFT, data),
  validateQuoteData: data => ApiService.postData(QUOTE_URLS.VALIDATE_QUOTE_DATA, data),
  validateEmailData: data => ApiService.postData(QUOTE_URLS.UPDATE_EMAIL_VERI_STATUS, data),
  getQuoteData: id => ApiService.getData(`${QUOTE_URLS.GET_QUOTE_DATA}/${id}`),
  getQuoteFilledData: id => ApiService.getData(`${QUOTE_URLS.GET_QUOTE_FILLED_DATA}/${id}`),
  activeSaveQuote: data => ApiService.postData(QUOTE_URLS.ACTIVE_SAVE_QUOTE, data),
  getEquifaxStatus: () => ApiService.getData(QUOTE_URLS.SHOW_EQUIFAX_SCREEN),
  checkQuoteLimit: () => ApiService.getData(QUOTE_URLS.CHECK_QUOTE_LIMIT),
  getRateCard: () => ApiService.getData(QUOTE_URLS.RATE_CARD),
}
export default QuoteApiServices
