import React, { useEffect, useState } from "react"
import Carousel from "react-elastic-carousel"
import { useDispatch, useSelector } from "react-redux"
import { getRateCardData } from "./redux/rateCardAction"
import RateCardDetails from "./components/RateCardDetails"
import { useHistory } from "react-router-dom"
import QuoteApiServices from "../quote/services/QuoteServices"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"
import VerificationEmail from "../../common/Header/components/VerificationEmail"
import Loader from "../../common/loader/Loader"
import { getStoreRateCardLenderId } from "../application/redux/ApplicaitonActions"
import RateCardMobile from "./components/RateCardMobile"
import RateCardSummary from "./components/RateCardSummary"
import RateCardFilterHeader from "./components/RateCardFilterHeader"
import { applicationSelector } from "../application/redux/ApplicationSelector"

const rateFilters = [
  {
    name: "CHEAPEST_PAYMENTS",
    label: "$ Cheapest Payments",
  },
  { name: "LOWEST_INTEREST_RATES", label: "% Lowest Interest Rates" },
  {
    name: "CHEAPEST_TOTAL_COST",
    label: "$ Cheapest Total Cost",
  },
]

const rateFiltersFromQuote = {
  "Cheapest Repayments": "CHEAPEST_PAYMENTS",
  "Lowest Interest Rates": "LOWEST_INTEREST_RATES",
  "Cheapest Total Cost": "CHEAPEST_TOTAL_COST",
}

const RateCard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isViewMore, setIsViewMore] = useState(false)
  const [stateToContinue, setStateToContinue] = useState(null)
  const { quoteFilter } = useSelector(applicationSelector)

  const { summary, rateCards, status } = useSelector(({ rateCardReducer }) => rateCardReducer?.rateCardData ?? {})

  const { rateCardLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const { quoteId, applicationId, rateFilter = "CHEAPEST_PAYMENTS" } = history?.location?.state ?? {}

  const [selectedFilter, setSelectedFilter] = useState(rateFiltersFromQuote[rateFilter])

  useEffect(() => {
    if (applicationId) {
      setSelectedFilter(quoteFilter?.filter)
    }
  }, [])

  if (quoteId === undefined) {
    history.push(ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST)
  }
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 475, itemsToShow: 2 },
    { width: 775, itemsToShow: 3 },
    { width: 1075, itemsToShow: 4 },
    { width: 1375, itemsToShow: 5 },
    { width: 1675, itemsToShow: 6 },
  ]

  const handleRateSelect = async (id, repayments) => {
    if (quoteId && !applicationId) {
      const response = await QuoteApiServices.getEmailVerificationStatus(quoteId)
      if (response?.data?.status === "SUCCESS") {
        setStateToContinue({
          isPasswordScreen: response?.data?.data?.passwordScreen,
          isEmailAuthScreen: response?.data?.data?.emailAuthScreen,
          id: quoteId,
          lenderId: id,
          repayments: repayments,
        })
      }
      if (!response?.data?.data?.passwordScreen && !response?.data?.data?.emailAuthScreen) {
        history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${quoteId}`, {
          rateCardID: id,
          repayments: repayments,
        })
      }
    } else if (quoteId && applicationId) {
      await dispatch(getStoreRateCardLenderId({ quoteId, applicationId, rateCardId: id, repayments: repayments }))
      history.push(`${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}`)
    }
  }

  useEffect(() => {
    dispatch(getRateCardData(quoteId, applicationId, selectedFilter))
  }, [quoteId, applicationId, selectedFilter])

  // console.log("rateCards", rateCards)

  return (
    <div className="rate-card-screen">
      <div className="empty-wrapper"></div>
      {applicationId && (
        <div className="applicationEdited">
          <b>Since the application data has been edited, we need you to confirm your selected lender and offer</b>
        </div>
      )}
      {!rateCardLoader ? (
        rateCards?.length && status === "SUCCESS" ? (
          <div className="rate-card-wrapper">
            <RateCardFilterHeader
              selectedFilter={selectedFilter}
              rateFilters={rateFilters}
              handleFilterSelect={e => setSelectedFilter(e?.name)}
            />
            <RateCardSummary summary={summary} />
            <section className="rate-card-container">
              <Carousel itemsToShow={4} pagination={false} itemPadding={[10, 10]} breakPoints={breakPoints}>
                {rateCards?.length &&
                  rateCards?.map((e, index) => (
                    <React.Fragment key={index}>
                      <RateCardDetails
                        data={e}
                        onCardSelect={handleRateSelect}
                        applicationId={applicationId}
                        isOpenView={isViewMore}
                        isMobileView={false}
                      />
                    </React.Fragment>
                  ))}
              </Carousel>
              <div className="p-20 text-center">
                <span
                  className="font-primary font-underline cursor-pointer"
                  onClick={() => setIsViewMore(prev => !prev)}
                >
                  {!isViewMore ? "Click Here For More Info" : "View less"}
                </span>
              </div>
            </section>
            <RateCardMobile
              rateCards={rateCards?.map((e, index) => ({ ...e, index: index }))}
              onCardSelect={handleRateSelect}
              isOpenView
            />
            {(stateToContinue?.isEmailAuthScreen || stateToContinue?.isPasswordScreen) && (
              <VerificationEmail
                isOpen={stateToContinue?.isEmailAuthScreen}
                id={stateToContinue?.id}
                isPwd={stateToContinue?.isPasswordScreen}
                rateCardId={stateToContinue?.lenderId}
                repayments={stateToContinue?.repayments}
              />
            )}
          </div>
        ) : (
          <div className="font-primary mt-20 text-center h-100">No Rate Cards available</div>
        )
      ) : (
        <Loader isLoading={rateCardLoader} />
      )}
    </div>
  )
}

export default RateCard
