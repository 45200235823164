import { last } from "lodash"

export const applicationSelector = state => {
  return state?.applicationReducer || {}
}

export const currentFormKey = state => {
  return { lastFormKey: last(state?.applicationReducer?.formsKey || []) }
}

export const modelTypeCodeSelector = state => {
  return { modelTypeCode: state?.applicationReducer?.glassGuideData?.modelTypeCode }
}
