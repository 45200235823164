import dayjs from "dayjs"
import Button from "../common/Button/Button"
import { DATE_FORMAT } from "../constants/regexConstants"

export const processTableDataByType = ({ header, row, actions }) => {
  const { type } = header
  const currentData = row[`${header.name}`]
  const { handleDrawerState, handleViewDocument } = actions ?? {}
  switch (type) {
    case "booleanString":
      return currentData ? "Yes" : "No"

    case "endorsedString":
      return currentData && "yes"

    case "link":
      return currentData && currentData.toString().trim().length > 0 ? (
        <div className="link" onClick={() => handleViewDocument(header, row)}>
          {currentData}
        </div>
      ) : (
        "-"
      )

    case "Amount": {
      const numberToUSD = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      })

      return currentData?.toString()?.trim()?.length > 0 ? numberToUSD.format(currentData) : "-"
    }

    case "redirectLink":
      return currentData && currentData?.value?.toString().trim().length > 0 ? (
        <div
          className="link"
          onClick={e => {
            e.stopPropagation()
            handleDrawerState(header, currentData, row)
          }}
        >
          {currentData?.id}
        </div>
      ) : (
        "-"
      )

    case "date":
      return currentData?.toString().trim().length > 0 ? dayjs(currentData).format(DATE_FORMAT.DDMMYYYY) : "-"

    case "info":
      return currentData ? (
        <div className="d-flex align-center">
          {currentData}{" "}
          <span
            className="material-icons-outlined info-button"
            onClick={e => {
              e.stopPropagation()
              handleDrawerState(header, currentData, row)
            }}
          >
            info
          </span>
        </div>
      ) : (
        "-"
      )
    case "abnAcn":
      return (
        <div>
          <Button buttonType={"secondary"} onClick={() => handleDrawerState(row)}>
            Apply
          </Button>
        </div>
      )
    case "Mix":
      return (
        <div className="table-buttons">
          {currentData}
          {header.request.map((element, index) =>
            element.icon === "dynamic_form" ? (
              ["Completed", "Manual Application"]?.includes(row?.status) && row?.isApplicationStart ? (
                <Button
                  key={index}
                  title={row?.rateCardScreen === true ? "View Rate" : "Start Application"}
                  buttonType={"secondary"}
                  onClick={e => {
                    e.stopPropagation()
                    const header1 = { ...header, request: element }
                    handleDrawerState(header1, currentData, row)
                  }}
                />
              ) : (
                ""
              )
            ) : element.icon === "mode_edit" ? (
              ["Partially Completed", "Completed", "Manual Application"]?.includes(row?.status) ? (
                <span
                  key={index}
                  title={element?.title}
                  className="material-icons-outlined info-button"
                  onClick={e => {
                    e.stopPropagation()

                    const header1 = { ...header, request: element }
                    handleDrawerState(header1, currentData, row)
                  }}
                >
                  {element.icon}
                </span>
              ) : (
                ""
              )
            ) : element.icon === "file_upload" ? (
              row?.documentScreen ? (
                <span
                  key={index}
                  title={element?.title}
                  className="material-icons-outlined info-button"
                  onClick={e => {
                    e.stopPropagation()

                    const header1 = { ...header, request: element }
                    handleDrawerState(header1, currentData, row)
                  }}
                >
                  {element.icon}
                </span>
              ) : (
                ""
              )
            ) : (
              <span
                key={index}
                title={element?.title}
                className="material-icons-outlined info-button"
                onClick={e => {
                  e.stopPropagation()

                  const header1 = { ...header, request: element }
                  handleDrawerState(header1, currentData, row)
                }}
              >
                {element.icon}
              </span>
            ),
          )}
        </div>
      )
    default:
      return currentData && currentData.toString().trim().length > 0 ? currentData : "-"
  }
}
