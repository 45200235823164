import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import rateCardApiServices from "../services/rateCardServices"
import { RATE_CARD_REDUX_CONSTANTS } from "./rateCardReduxConstants"

export const getRateCardData = (quoteId, applicationId, rateFilter) => {
  return async dispatch => {
    try {
      startLoader("rateCardLoader")
      let response = {}
      if (applicationId) {
        response = await rateCardApiServices.getRateCardApplication(applicationId, rateFilter)
      } else {
        response = await rateCardApiServices.getRateCard(quoteId, rateFilter)
      }

      if (response?.data?.data?.status === "SUCCESS") {
        dispatch({
          type: RATE_CARD_REDUX_CONSTANTS.SET_RATE_CARD_DATA,
          data: response?.data?.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("rateCardLoader")
    }
  }
}
