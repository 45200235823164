import ApplicationBottomNavigation from "../ApplicationBottomNavigation"
import PropTypes from "prop-types"

const Footer = ({ handleNextClick, handlePreviousClick }) => {
  return (
    <div className="footer-container">
      <ApplicationBottomNavigation handleNextClick={handleNextClick} handlePreviousClick={handlePreviousClick} />
    </div>
  )
}

Footer.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
}

export default Footer
