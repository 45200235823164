import { EQUIFAX_CONSTANTS } from "./EquifaxReduxConstants"
import EquifaxServices from "../services/EquifaxServices"
import { successNotification } from "../../../../common/NotifyToaster"
import { displayErrors } from "../../../../helpers/ErrorNotifyHelper"
import { startLoader, stopLoader } from "../../../../common/loader/redux/LoaderActions"
export const handleEquifaxFieldChange = (fieldName, fieldValue) => ({
  type: EQUIFAX_CONSTANTS.EQUIFAX_FIELD_CHANGE,
  fieldName,
  fieldValue,
})

export const setAddressData = data => ({
  type: EQUIFAX_CONSTANTS.EQUIFAX_ADDRESS_CHANGE,
  data,
})
export const equifaxSaveData = data => {
  return async dispatch => {
    try {
      startLoader("isEquifaxDataSave")
      const response = await EquifaxServices.equifaxSaveData(data)
      if (response?.data?.status === "SUCCESS") {
        const dataOfPDf = data
        await EquifaxServices.submitPdfData(dataOfPDf)
        dispatch(handleEquifaxFieldChange("equifaxScore", response?.data?.data?.creditScore))
        dispatch(handleEquifaxFieldChange("equifaxData", response?.data))
        localStorage.removeItem("otpCount")
        successNotification(response?.data?.message)
        return "datafound_test_" + JSON.stringify(response?.data)
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isEquifaxDataSave")
    }
  }
}
export const handleSubmitEquifax = data => {
  return async dispatch => {
    try {
      const response = await EquifaxServices.submitEquifaxData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    }
  }
}

export const resetEquifaxReducer = () => ({
  type: EQUIFAX_CONSTANTS.RESET_EQUIFAX_REDUCER,
})
