import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import { quoteReducer } from "../screens/quote/redux/QuoteReducer"
import { applicationReducer } from "../screens/application/redux/ApplicaitonReducer"
import { registerReducer } from "../screens/auth/RegistrationPage/redux/RegisterReducer"
import { dashboardReducer } from "../screens/dashboard/Redux/dashboardReducer"
import { authReducer } from "../screens/auth/redux/AuthReducer"
import { userModuleReducer } from "../screens/UserModule/redux/userModuleReducer"
import { equifaxReducer } from "../screens/auth/EquifaxPage/redux/EquifaxReducer"
import { headerReducer } from "../common/Header/Redux/headerReducer"
import { loaderReducer } from "../common/loader/redux/LoaderReducer"
import { rateCardReducer } from "../screens/RateCard/redux/rateCardReducer"
import { assetReducer } from "../screens/asset/redux/AssetReducer"
import brokerReducer from "../screens/broker/redux/brokerSlice"
import brokerReducerNew from "../screens/brokernew/redux/brokerSliceNew"
export const appReducer = combineReducers({
  quoteReducer,
  applicationReducer,
  registerReducer,
  dashboardReducer,
  equifaxReducer,
  authReducer,
  headerReducer,
  userModuleReducer,
  loaderReducer,
  rateCardReducer,
  assetReducer,
  brokerReducer,
  brokerReducerNew,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authReducer"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
