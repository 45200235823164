export const ASSET_REDUX_CONSTANTS = {
  SET_ASSET_JSON_DATA: "SET_ASSET_JSON_DATA",
  SET_ASSET_ZOHO_DATA: "SET_ASSET_ZOHO_DATA",
  UPDATE_ASSET_ANSWER_DATA: "UPDATE_ASSET_ANSWER_DATA",
  UPDATE_ASSET_FIELD_ANSWER_DATA: "UPDATE_ASSET_FIELD_ANSWER_DATA",
  UPDATE_ASSET_FIELD_REGO_ANSWER_DATA: "UPDATE_ASSET_FIELD_REGO_ANSWER_DATA",
  UPDATE_APPLICATION_GLASS_GUIDE_DATA: "UPDATE_APPLICATION_GLASS_GUIDE_DATA",
  UPDATE_GLASS_GUIDE_LABEL_DATA: "UPDATE_GLASS_GUIDE_LABEL_DATA",
  UPDATE_ASSET_LVR_DATA: "UPDATE_ASSET_LVR_DATA",
  UPDATE_ASSET_REGO_DATA: "UPDATE_ASSET_REGO_DATA",
  UPDATE_ASSET_FLAG_DATA: "UPDATE_ASSET_FLAG_DATA",
}
