import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { useDropzone } from "react-dropzone"
import { useDispatch } from "react-redux"
import { errorNotification } from "../../../../common/NotifyToaster"
import { removeApplicationFiles, uploadApplicationFiles } from "../../redux/ApplicaitonActions"

const FileUpload = props => {
  const dispatch = useDispatch()
  const {
    formsKey,
    subFormsKey,
    label,
    name,
    multiple,
    id,
    onUpload,
    value,
    errorMessage,
    maxFiles,
    maxSize,
    fromEdit = false,
  } = props
  const fileInputRef = useRef()
  const onDrop = async acceptedFiles => {
    let size = 0
    acceptedFiles?.forEach(file => {
      size = parseInt(size) + parseInt(file.size)
    })
    const totalFile =
      value && typeof value === "string"
        ? parseInt(value?.split(",").length) + parseInt(acceptedFiles.length)
        : parseInt(acceptedFiles.length)
    if (totalFile > maxFiles) {
      errorNotification("Only " + maxFiles + " file is allowed to be uploaded for " + label)
      return false
    } else if (size > maxSize) {
      errorNotification("Maximum upload size is " + maxSize / (1024 * 1024) + "MB for the " + label)
      return false
    } else {
      const formData = new FormData()
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        timeout: 0,
      }

      acceptedFiles?.forEach(file => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        formData.append(name, file)
      })
      const fileList = await dispatch(uploadApplicationFiles(formData, config, id))
      if (value !== undefined && value !== "") {
        const valueArr = value?.split(",")
        let fileListNew = fileList?.split(",")
        fileListNew = fileListNew?.concat(valueArr)
        fileListNew = fileListNew?.join(",")
        if (fromEdit) {
          onUpload(name + "_test_" + fileListNew)
        } else {
          onUpload(fileListNew)
        }
      } else {
        if (fromEdit) {
          onUpload(name + "_test_" + fileList)
        } else {
          onUpload(fileList)
        }
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "file/pdf": [".pdf"],
      "file/doc": [".doc"],
      "file/docx": [".docx"],
      "file/odt": [".odt"],
    },
  })

  useEffect(() => {
    if (fileRejections?.length) {
      // const rejectedFiles = fileRejections
      //   ?.map(e => ({
      //     ...e?.file,
      //   }))
      //   ?.map(e => e?.path)
      //   ?.join(", ")
      // errorNotification(`Invalid file found ${rejectedFiles}`)
      errorNotification("Only document, pdf and image file type is allowed to upload")
    }
  }, [fileRejections])

  const shotFileName = () => {
    const exported = value !== undefined && value !== "" ? value?.split(",") : ""
    if (exported !== "") {
      return exported?.map((element, index) => (
        <div key={index}>
          {element?.split("/").pop()}{" "}
          <button className="btn-remove" onClick={() => fileInputRefClick(element, exported)}>
            x
          </button>
        </div>
      ))
    } else {
      return ""
    }
  }
  const fileInputRefClick = async (element, exported) => {
    const DeleteData = {
      path: [element],
      applicationId: id,
      fieldName: name,
      FormName: formsKey,
      SubFormName: subFormsKey,
    }
    const fileList = await dispatch(removeApplicationFiles(DeleteData, exported, element))
    if (fromEdit) {
      onUpload(name + "_test_" + fileList)
    } else {
      onUpload(fileList)
    }

    fileInputRef.current.value = ""
  }

  return (
    <div>
      <div>
        <div>
          <div className="file-upload-label">{label}</div>
          <div className={`file-drag ${errorMessage ? "input-has-value-or-focused border-error" : ""}`}>
            <div className="input-wrapper" {...getRootProps()}>
              <input multiple={multiple} ref={fileInputRef} name={name} {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag n drop some files here, or click to select files</p>
              )}
            </div>
          </div>
        </div>
        <div className="previews">{value !== undefined && value !== "" ? shotFileName() : ""}</div>
      </div>
    </div>
  )
}

FileUpload.propTypes = {
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  multiple: PropTypes.boolean,
  onUpload: PropTypes.func,
  fromEdit: PropTypes.boolean,
  formsKey: PropTypes.string,
  subFormsKey: PropTypes.string,
}

FileUpload.defaultProps = {
  label: "",
  multiple: false,
}
export default FileUpload
