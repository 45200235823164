import moment from "moment"
import { isValidAnswer } from "./applicationHelper"
import { DEFAULT_DATE_FORMAT } from "../constants/Constant"

export const incomeCalculation = fields => {
  const { incomeAmount, incomeHowOften } = fields ?? {}

  if (incomeHowOften && incomeAmount) {
    let monthlyIncome = 0
    switch (incomeHowOften) {
      case "Annually": {
        monthlyIncome = parseFloat(incomeAmount) / 12
        break
      }
      case "Quarterly": {
        monthlyIncome = parseFloat(incomeAmount) / 3
        break
      }
      case "Weekly": {
        monthlyIncome = (parseFloat(incomeAmount) * 52) / 12
        break
      }
      case "Fortnightly": {
        monthlyIncome = (parseFloat(incomeAmount) * 26) / 12
        break
      }
      default: {
        monthlyIncome = parseFloat(incomeAmount)
        break
      }
    }
    return parseFloat(monthlyIncome).toFixed(2)
  }
  return 0
}

export const outGoingCalculation = fields => {
  const { outgoingAmount, outgoingHowOften } = fields ?? {}
  if (outgoingHowOften && outgoingAmount) {
    let monthlyOutGoing = 0
    switch (outgoingHowOften) {
      case "Annually": {
        monthlyOutGoing = parseFloat(outgoingAmount) / 12
        break
      }
      case "Quarterly": {
        monthlyOutGoing = parseFloat(outgoingAmount) / 3
        break
      }
      case "Weekly": {
        monthlyOutGoing = (parseFloat(outgoingAmount) * 52) / 12
        break
      }
      case "Fortnightly": {
        monthlyOutGoing = (parseFloat(outgoingAmount) * 26) / 12
        break
      }
      default: {
        monthlyOutGoing = parseFloat(outgoingAmount)
        break
      }
    }
    return parseFloat(monthlyOutGoing).toFixed(2)
  }
  return 0
}

export const monthlyRepaymentCalculation = fields => {
  const { f1, f2 } = fields ?? {}
  if (f1 && f2) {
    let monthlyRepayment = 0
    switch (f1) {
      case "Annually": {
        monthlyRepayment = parseFloat(f2) / 12
        break
      }
      case "Quarterly": {
        monthlyRepayment = parseFloat(f2) / 3
        break
      }
      case "Weekly": {
        monthlyRepayment = (parseFloat(f2) * 52) / 12
        break
      }
      case "Fortnightly": {
        monthlyRepayment = (parseFloat(f2) * 26) / 12
        break
      }
      default: {
        monthlyRepayment = parseFloat(f2)
        break
      }
    }
    return parseFloat(monthlyRepayment).toFixed(2)
  }
  return 0
}

export const netAssetAndLiabilitiesCalculation = fields => {
  const { limitTotal, assetTotal } = fields ?? {}
  if ((assetTotal && limitTotal) || limitTotal === 0 || assetTotal === 0) {
    return assetTotal - limitTotal
  }
  return 0
}
export const netIncomeCalculation = fields => {
  let { netIncomes, netIncomes2 } = fields ?? {}
  if (!netIncomes) {
    netIncomes = 0
  }
  if (!netIncomes2) {
    netIncomes2 = 0
  }

  return parseFloat(netIncomes) + parseFloat(netIncomes2)
}
export const getSurPlusFieldValue = fields => {
  let { netIncome, newCurrentRepayment } = fields ?? {}
  if (!netIncome) {
    netIncome = 0
  }
  if (!newCurrentRepayment) {
    newCurrentRepayment = 0
  }

  return parseFloat(netIncome) - parseFloat(newCurrentRepayment)
}
export const getAdjustedSurPlusFieldValue = fields => {
  let { netIncome, increasedAmountsPerMonth, ReducedAmountsPerMonth } = fields ?? {}
  if (!netIncome) {
    netIncome = 0
  }
  if (!increasedAmountsPerMonth) {
    increasedAmountsPerMonth = 0
  }
  if (!ReducedAmountsPerMonth) {
    ReducedAmountsPerMonth = 0
  }

  return parseFloat(netIncome) + parseFloat(increasedAmountsPerMonth) - parseFloat(ReducedAmountsPerMonth)
}

export const netIncomeAndOutgoingCalculation = fields => {
  const { monthlyRepaymentsTotal, outGoingTotal, incomeTotal } = fields ?? {}
  if (
    (monthlyRepaymentsTotal && outGoingTotal && incomeTotal) ||
    monthlyRepaymentsTotal === 0 ||
    outGoingTotal === 0 ||
    incomeTotal === 0
  ) {
    return incomeTotal - outGoingTotal - monthlyRepaymentsTotal
  }
  return 0
}

export const movedInDateCalculation = fields => {
  const { currMonth = 0, currYear } = fields ?? {}
  if ((currMonth && currYear && isValidAnswer(currMonth)) || isValidAnswer(currYear)) {
    const totalMonths = parseInt(currYear) * 12 + parseInt(currMonth)

    return moment()
      .subtract(totalMonths, "months")
      .format(DEFAULT_DATE_FORMAT)
  }

  return ""
}

export const movedInDateInDaysCalculation = fields => {
  const { moveInDate } = fields ?? {}
  if (moveInDate) {
    return moment().diff(moment(moveInDate, DEFAULT_DATE_FORMAT), "days")
  }
  return ""
}

export const employmentTimeInDaysCalculation = fields => {
  const { startDate } = fields ?? {}
  if (startDate) {
    const value = moment().diff(moment(startDate, DEFAULT_DATE_FORMAT), "days")
    return isNaN(value) ? "" : value
  }
  return ""
}

export const adverseCreditHistoryReferenceCalculation = fields => {
  const { adverseCreditHistory, declaredBankrupt } = fields ?? {}
  let reference = null
  if (adverseCreditHistory === "Yes" || declaredBankrupt === "Yes") {
    reference = 1
  }
  if (adverseCreditHistory === "Yes" && declaredBankrupt === "Yes") {
    reference = 2
  }
  return reference
}

export const toBeFinancedCalculation = fields => {
  let { payout, tradeIn, deposit, purchasePrice, toBeFinanced } = fields ?? {}

  payout = payout ? Number(payout) : 0
  tradeIn = tradeIn ? Number(tradeIn) : 0
  deposit = deposit ? Number(deposit) : 0
  purchasePrice = purchasePrice ? Number(purchasePrice) : 0
  toBeFinanced = toBeFinanced ? Number(toBeFinanced) : 0

  const calculations = purchasePrice + toBeFinanced - deposit - tradeIn + payout
  return parseFloat(calculations).toFixed(2)
}
export const prevEmployerDateInDaysCalculation = fields => {
  const { endDate, startDate } = fields ?? {}
  if (endDate && startDate) {
    const d1 = moment(startDate, DEFAULT_DATE_FORMAT)
    const d2 = moment(endDate, DEFAULT_DATE_FORMAT)
    return moment(d2).diff(moment(d1), "years")
  }
  return ""
}

export const prevAddressInDaysCalculation = fields => {
  const { endDate, startDate } = fields ?? {}
  if (endDate && startDate) {
    const d1 = moment(startDate, DEFAULT_DATE_FORMAT)
    const d2 = moment(endDate, DEFAULT_DATE_FORMAT)
    return moment(d2).diff(moment(d1), "days")
  }
  return ""
}

export const previousMoveInDateCalculation = fields => {
  const { liveInPreviousPropertyYear = 0, liveInPreviousPropertyMonth = 0, previousMoveOutDate } = fields ?? {}

  if (
    isValidAnswer(liveInPreviousPropertyMonth) &&
    isValidAnswer(liveInPreviousPropertyYear) &&
    isValidAnswer(previousMoveOutDate)
  ) {
    return moment(previousMoveOutDate, DEFAULT_DATE_FORMAT)
      .subtract(parseInt(liveInPreviousPropertyYear), "years")
      .subtract(parseInt(liveInPreviousPropertyMonth), "months")
      .format(DEFAULT_DATE_FORMAT)
  }
  return ""
}

export const previousMoveOutDateCalculation = fields => {
  const { currentMoveInDate } = fields ?? {}
  if (isValidAnswer(currentMoveInDate)) {
    return moment(currentMoveInDate, DEFAULT_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
  }
  return ""
}
