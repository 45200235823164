import PropTypes from "prop-types"
import { NumberCommaSeparator } from "../../helpers/NumberCommaSeparator"
import Accordion from "../Accordion/Accordion"
import AccordionItem from "../Accordion/AccordionItem"

const TableMobile = ({ headers, data, headersToShow }) => {
  return (
    <div className="table-mobile-container">
      <div className="header">
        {headersToShow?.map((e, i) => (
          <span key={i}>{e?.label}</span>
        ))}
      </div>
      <div className="content">
        <Accordion>
          {Array.isArray(data) &&
            data?.length > 0 &&
            data.map((e, i) => {
              const headerLeft = e?.[headersToShow?.[0]?.name]
              const headerRight = e?.[headersToShow?.[1]?.name]

              return (
                <AccordionItem
                  header={
                    <div className="table-mobile-header">
                      <div>{headerLeft}</div>
                      <div>{headerRight}</div>
                    </div>
                  }
                  index={i}
                  key={i}
                  accordionBodyClass="accordion-item-wrapper"
                >
                  {headers?.map((items, i2) => {
                    const itemLeft = items?.label
                    const itemName = items?.name
                    let itemRight = ""
                    switch (itemName) {
                      case "id":
                        itemRight = ""
                        break
                      case "loanAmount":
                      case "balloonCalculation":
                        itemRight = e?.[items?.name] !== "-" ? `$ ${NumberCommaSeparator(e?.[items?.name])}` : "-"
                        break
                      case "financeTerms":
                        itemRight = e?.[items?.name] !== "-" ? `${NumberCommaSeparator(e?.[items?.name])} year(s)` : "-"
                        break
                      default:
                        itemRight = e?.[items?.name] || "-"
                        break
                    }

                    return (
                      <div className="mobile-accordion-item" key={i2}>
                        <span className="row-label">{itemLeft}</span>
                        {}
                        {itemRight && <span className="row-value">{itemRight}</span>}
                      </div>
                    )
                  })}
                </AccordionItem>
              )
            })}
        </Accordion>
      </div>
    </div>
  )
}
TableMobile.propTypes = {
  headers: PropTypes.object,
  data: PropTypes.array,
  headersToShow: PropTypes.array,
}

export { TableMobile }
