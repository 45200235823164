import { DASHBOARD } from "./dashboardReduxConstants"

const initialDashboard = {
  quoteList: {},
  applicationList: {},
  dashboardCounts: {},
}

export const dashboardReducer = (state = initialDashboard, action) => {
  switch (action.type) {
    case DASHBOARD.DASHBOARD_GET_QUOTE_LIST:
      return {
        ...state,
        quoteList: {
          ...action?.data,
        },
      }
    case DASHBOARD.DASHBOARD_GET_APPLICATION_LIST:
      return {
        ...state,
        applicationList: {
          ...action?.data,
        },
      }

    case DASHBOARD.DASHBOARD_GET_COUNTS:
      return {
        ...state,
        dashboardCounts: action?.data,
      }

    default:
      return state
  }
}
