import Modal from "../../../common/Modal/Modal"
import { useEffect, useState } from "react"
import { validatePhone } from "../../../helpers/ValidationHelper"
import { useDispatch, useSelector } from "react-redux"
import { getOTPAction, verifyOTPAction } from "../redux/AuthActions"
import PropTypes from "prop-types"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { defaultCountry } from "../../../constants/quoteDefaultConstants"
import OtpInput from "../../../common/OtpInput/OtpInput"
import { useMediaQuery } from "../../../hooks/useMediaQuery"
import { onResendOtp } from "../OTPPage/redux/otpAction"
import Button from "../../../common/Button/Button"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"

const otpLength = 6

const ChangePhoneModal = ({ isOpen, onClose }) => {
  dayjs.extend(duration)
  const [phone, setPhone] = useState(null)
  const [error, setError] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry)
  const [isVerificationSent, setIsVerificationSent] = useState(false)
  const [counter, setCounter] = useState(300)
  const isMobileScreen = useMediaQuery("(max-width: 480px)")

  const { isVerifyPhoneLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const dispatch = useDispatch()

  const resetFields = () => {
    setPhone(null)
    setError(null)
    setIsVerificationSent(false)
  }

  const handleClose = (isOTPVerify = false) => {
    setCounter(300)
    if (isOTPVerify === true) {
      onClose(selectedCountry?.value?.code, phone)
    } else onClose()
    resetFields()
  }

  const handleErrorMessage = error => {
    setError(error)
  }

  const onPhoneChange = async () => {
    let errors = null
    if (!selectedCountry || !selectedCountry?.label?.toString()?.trim()?.length) errors = "Please select country code"
    if (!phone || !phone?.length) errors = "Please enter mobile number"
    if (phone?.length && (phone?.length < 10 || phone?.length > 15)) errors = "Please enter valid mobile number"
    if (phone && !validatePhone(phone)) errors = "Please enter valid mobile number"
    if (!errors) {
      setError(null)
      const isOTPSent = await dispatch(
        getOTPAction({
          mobileNumber: phone,
          countryCode: selectedCountry?.value?.code,
        }),
      )
      setIsVerificationSent(isOTPSent)
    } else setError(errors)
  }

  const onSubmitOtp = async otpData => {
    const countryCode = selectedCountry?.value?.code || +61
    if (otpData && otpData.length === otpLength) {
      const data = {
        otp: Number(otpData.join("")),
        mobileNumber: phone,
        countryCode,
      }
      const isOTPVerify = await dispatch(verifyOTPAction(data))
      if (isOTPVerify) {
        handleClose(true)
      } else handleClose()
    }
  }

  const onResetOtp = async () => {
    const countryCode = selectedCountry?.value?.code || +61
    const data = {
      mobileNumber: phone,
      countryCode,
    }
    const isOtpResend = await dispatch(onResendOtp(data))
    if (isOtpResend) {
      setCounter(180)
    }
  }

  const changePhoneModalButtons = [
    {
      title: "Close",
      buttonType: "outlined-primary",
      onClick: handleClose,
    },
    {
      title: "Save",
      buttonType: "primary",
      isLoading: isVerifyPhoneLoader,
      onClick: onPhoneChange,
    },
  ]

  useEffect(() => {
    let timer
    if (isVerificationSent) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    }
    return () => clearInterval(timer)
  }, [counter, isVerificationSent])

  return (
    <>
      {isOpen && (
        <Modal
          header="Update Mobile Number"
          buttons={!isVerificationSent ? changePhoneModalButtons : changePhoneModalButtons?.slice(0, 1)}
          hideModal={handleClose}
          isOutSideClickAllowed={false}
        >
          {!isVerificationSent ? (
            <CountrySelect
              countryCode=""
              countryName={selectedCountry}
              phoneNumber={phone}
              placeholder="Mobile number"
              className="w-100"
              updatePhoneNumber={e => {
                setPhone(e?.toString()?.trim())
              }}
              updateErrorMessage={handleErrorMessage}
              updatePhoneCountry={e => {
                setSelectedCountry(e)
              }}
              error={error}
            />
          ) : (
            <>
              <div className="otp-info-text">{`Code will be sent to ${phone}`}</div>
              <OtpInput
                otpInputs={otpLength}
                otpBoxHeight={isMobileScreen ? 45 : 55}
                otpBoxWidth={isMobileScreen ? 45 : 55}
                onOtpFill={onSubmitOtp}
                otpBoxWithValueClass="border-primary"
                onFocusOtpBoxClass="border-secondary"
              />
              <div className="otp-resend-button">
                <Button
                  title="Resend"
                  className={counter > 0 ? "otp-resend-button-disabled" : ""}
                  buttonType="secondary"
                  onClick={onResetOtp}
                  isDisabled={counter > 0 || isVerifyPhoneLoader}
                />
              </div>
              {counter > 0 && (
                <div className="otp-reset-text">
                  Resend code in <span>{dayjs.duration(counter, "seconds").format("mm:ss")}</span>
                </div>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  )
}

ChangePhoneModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChangePhoneModal
