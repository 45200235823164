import { AUTH_REDUX_CONSTANTS } from "./AuthReduxConstants"

export const authReducer = (state = {}, action) => {
  switch (action?.type) {
    case AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS:
      return {
        ...state,
        authStatus: action?.status,
      }
    default:
      return state
  }
}
