import _ from "lodash"
import { formatAnswerForKey } from "../../../helpers/applicationHelper"
import { APPLICATION_FIRST_FORM } from "../../../constants/Constant"

const assetSubForms = [
  "commonQuestion",
  "assetDetails",
  "assetGlassGuide",
  "assetOptions",
  "assetPricingDetails",
  "assetIdentifiersDetails",
  "assetVendorDetails",
  "assetOtherDetails",
]
const fixSubFormsKeySequence = subforms => {
  subforms.sort((x, y) => {
    const x2 = assetSubForms.findIndex(a => a === x)
    const y2 = assetSubForms.findIndex(a => a === y)
    if (x2 < y2) {
      return -1
    } else if (x2 > y2) {
      return 1
    } else return 0
  })
}

export const getFormIndex = (formPath, currentForm) =>
  formPath?.findIndex(e => e?.[0] === currentForm[0] && e?.[1] === currentForm[1])

export const getCorrectMergeForm = (mergeFormName, formData, mainForm) => {
  return mergeFormName.reduce((acc, mergeFormSet) => {
    const fields = _.get(formData, `${mainForm}.${mergeFormSet.formName}`)
    if (fields?.length) {
      const field = fields.find(field => field?.fieldName === mergeFormSet.fieldName)
      const newSubForms =
        Array.isArray(mergeFormSet.value) && field?.answer
          ? [mergeFormSet.value[0]]
          : mergeFormSet.value?.[formatAnswerForKey(field?.answer)]
      if (newSubForms?.length > 0) acc.push(...newSubForms)
    }
    return acc
  }, [])
}
export const getCorrectMergeFormNew = mergeFormName => {
  return mergeFormName.reduce((acc, mergeFormSet) => {
    const newSubForms = [mergeFormSet]
    if (newSubForms?.length > 0) acc.push(...newSubForms)
    return acc
  }, [])
}
export const mergeFormFilterByFormVisibility = (mergeFormsNames, mainForm, json, formData) => {
  fixSubFormsKeySequence(mergeFormsNames)
  return mergeFormsNames.filter(mergeForm => {
    const subformData = _.get(json, [mainForm, mergeForm])
    if (!subformData?.formVisibility?.length) {
      return true
    }
    return subformData?.formVisibility?.reduce((acc, curr) => {
      const andResult = curr?.reduce((acc2, curr2) => {
        const { formName, fieldName, value } = curr2
        const answer2 = _.get(formData, [mainForm, formName])?.find(e => e.fieldName === fieldName)?.answer
        return acc2 && answer2 === value
      }, true)

      return acc || andResult
    }, true)
  })
}

export const filterGlassGuideLabelDataForSave = labelData => {
  const acc = {}
  const recursion = obj => {
    for (const key in obj) {
      if (["number", "string"].includes(typeof obj[key])) {
        acc[key] = obj[key]
      } else {
        recursion(obj[key])
      }
    }
  }
  recursion(labelData)
  return acc
}

export const getQuoteAndApplicationFormPaths = brokerFormPaths => {
  const quoteDetailsIndex = brokerFormPaths.findIndex(x => x[0] === APPLICATION_FIRST_FORM)

  const indexToSeparateDataFrom = quoteDetailsIndex > -1 ? quoteDetailsIndex : brokerFormPaths.length

  const quoteFormPaths = brokerFormPaths.slice(0, indexToSeparateDataFrom).map(x => x.join("."))
  const applicationFormPaths = brokerFormPaths.slice(indexToSeparateDataFrom).map(x => x.join("."))

  return { quoteFormPaths, applicationFormPaths }
}
