import { useRef } from "react"
import PropTypes from "prop-types"
import { useOnClickOutside } from "../../hooks/UserClickOutsideHook"

const Drawer = props => {
  const { isDrawerOpened, closeDrawer, header, className, children, ...restProps } = props
  const drawerMenuRef = useRef()
  useOnClickOutside(drawerMenuRef, () => {
    if (isDrawerOpened) closeDrawer()
  })
  const drawerClasses = `drawer-container ${isDrawerOpened ? "drawer-opened" : ""} ${className}`

  return (
    <>
      <div className={isDrawerOpened ? "drawer-overlay" : ""} />
      <div className={drawerClasses} {...restProps} ref={drawerMenuRef}>
        <div className="drawer-header-container">
          {header}
          <span className="material-icons-round close-drawer" title="Close drawer" onClick={closeDrawer}>
            close
          </span>
        </div>
        <div className="drawer-content">{children}</div>
      </div>
    </>
  )
}

Drawer.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.element,
  isDrawerOpened: PropTypes.bool,
  closeDrawer: PropTypes.func,
}

Drawer.defaultProps = {
  header: null,
  className: "",
  isDrawerOpened: false,
  children: null,
  closeDrawer: () => {},
}

export default Drawer
