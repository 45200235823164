import { useCallback, useMemo, useReducer } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { processTableDataByType } from "../../helpers/TableDataProcessHelper"
import TableApiService from "./TableApiService"
import Drawer from "../../common/Drawer/Drawer"
import { NumberCommaSeparator } from "../../helpers/NumberCommaSeparator"
import Button from "../Button/Button"
import dayjs from "dayjs"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"
import { resetQuoteReducer } from "../../screens/quote/redux/QuoteActions"
import { useHistory } from "react-router-dom"
import { QUOTE_REDUX_CONSTANTS } from "../../screens/quote/redux/QuoteReduxConstants"
import VerificationEmail from "../Header/components/VerificationEmail"
import { DATE_FORMAT } from "../../constants/regexConstants"
import QuoteApiServices from "../../screens/quote/services/QuoteServices"
import { TableMobile } from "../TableMobile/TableMobile"
import { displayErrors } from "../../helpers/ErrorNotifyHelper"

const EditableDrawer = () => {}

export const DRAWER_ACTIONS = {
  SHOW_DRAWER: "SHOW_DRAWER",
  HIDE_DRAWER: "HIDE_DRAWER",
  UPDATE_DRAWER: "UPDATE_DRAWER",
}

const drawerInitialState = {
  visible: false,
  data: [],
  drawerHeader: "",
  isEditableDrawer: false,
  isEmailAuthScreen: false,
  isPasswordScreen: false,
  id: "",
}

const drawerReducer = (state, action) => {
  switch (action.type) {
    case DRAWER_ACTIONS.SHOW_DRAWER:
      return {
        visible: true,
        data: action.data,
        drawerHeader: action.drawerHeader,
        isEditableDrawer: action?.isEditableDrawer,
        id: action?.id,
        idOfQuote: action?.idOfQuote,
      }
    case DRAWER_ACTIONS.UPDATE_DRAWER:
      return {
        ...state,
        isEmailAuthScreen: action?.isEmailAuthScreen,
        isPasswordScreen: action?.isPasswordScreen,
        id: action?.id,
        idOfQuote: action?.idOfQuote,
      }
    case DRAWER_ACTIONS.HIDE_DRAWER:
      return {
        ...drawerInitialState,
        isEmailAuthScreen: state.isEmailAuthScreen,
        isPasswordScreen: state.isPasswordScreen,
        id: state.id,
        idOfQuote: action?.idOfQuote,
      }

    default:
      return state
  }
}

const Table = props => {
  const {
    tableClass,
    headers,
    headerClass,
    data,
    rowClass,
    isEditableDrawer,
    isEmailAuthScreen,
    isPasswordScreen,
    tableType,
    mobileHeaders,
  } = props
  const tableClassName = `table-class ${tableClass}`
  const history = useHistory()
  const dispatch = useDispatch()
  const [drawerState, dispatchDrawerState] = useReducer(drawerReducer, drawerInitialState)

  const handleDrawerState = useCallback(
    async (header, currentData, row) => {
      const params = {}
      if (isEditableDrawer) {
        params.isEditableDrawer = isEditableDrawer
      }

      try {
        let id = ""
        if (tableType === "quote") {
          id = row.quoteId.value
        } else {
          id = row.applicationId.value
          row._id = id
        }
        let response = {}
        if (
          tableType === "quote" &&
          row?.status === "Partially Completed" &&
          header.request.icon !== "info" &&
          header.type !== "redirectLink"
        ) {
          //
        } else {
          response = await TableApiService.tableActions({
            url: header.request.url ?? header.request[currentData.type],
            method: header.request.method,
            id: id,
            params,
          })
        }

        if (tableType === "quote") {
          if (header.request.icon === "info") {
            dispatchDrawerState({
              type: DRAWER_ACTIONS.SHOW_DRAWER,
              data: response?.data?.data?.response,
              drawerHeader: response?.data?.data?.header,
              isEditableDrawer,
              id: row._id,
            })
          } else if (header.request.icon === "mode_edit") {
            await dispatch(resetQuoteReducer())
            if (tableType === "quote" && row?.status === "Partially Completed") {
              history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/${id}`)
            } else {
              await dispatch({
                type: QUOTE_REDUX_CONSTANTS.SET_EDIT_CALLED,
                data: true,
              })
              history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/${response?.data?.data?._id}`)
            }
          } else if (header.request.icon === "dynamic_form") {
            const response1 = await QuoteApiServices.getEmailVerificationStatus(row._id)
            if (response1?.data?.status === "SUCCESS") {
              dispatchDrawerState({
                type: DRAWER_ACTIONS.UPDATE_DRAWER,
                isPasswordScreen: response1?.data?.data?.passwordScreen,
                isEmailAuthScreen: response1?.data?.data?.emailAuthScreen,
                id: row._id,
              })
            }
            if (response1?.data?.data?.rateCardScreen) {
              history.push(ROUTE_CONSTANTS_VARIABLE.RATE_CARD, {
                quoteId: row._id,
              })
            } else {
              if (!response1?.data?.data?.passwordScreen && !response1?.data?.data?.emailAuthScreen) {
                history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${row._id}`)
              }
            }
          } else {
            dispatchDrawerState({
              type: DRAWER_ACTIONS.SHOW_DRAWER,
              data: response?.data?.data?.response,
              drawerHeader: response?.data?.data?.header,
              isEditableDrawer,
              id: row._id,
            })
          }
        } else {
          if (header.request.icon === "info") {
            dispatchDrawerState({
              type: DRAWER_ACTIONS.SHOW_DRAWER,
              data: response?.data?.data?.response,
              drawerHeader: response?.data?.data?.header,
              isEditableDrawer,
              id: row._id,
              idOfQuote: row?.idOfQuote,
            })
          } else if (header.request.icon === "file_upload") {
            history.push(`${ROUTE_CONSTANTS_VARIABLE.EDIT_APPLICATION}/${row._id}`)
          } else {
            dispatchDrawerState({
              type: DRAWER_ACTIONS.SHOW_DRAWER,
              data: response?.data?.data?.response,
              drawerHeader: response?.data?.data?.header,
              isEditableDrawer,
              id: row._id,
              idOfQuote: row?.idOfQuote,
            })
          }
        }
      } catch (e) {
        displayErrors(e)
        /**/
      }
    },
    [isEditableDrawer, isEmailAuthScreen, isPasswordScreen],
  )
  const closeDrawer = useCallback(() => {
    dispatchDrawerState({
      type: DRAWER_ACTIONS.HIDE_DRAWER,
    })
  }, [])

  const tableData = useMemo(() => {
    const actions = {
      handleDrawerState,
    }

    return data.map((e, index) => {
      const finalObj = {
        id: e._id ?? e.id ?? index,
      }
      headers.forEach(f => {
        finalObj[`${f.name}`] = processTableDataByType({ header: f, row: e, actions })
      })
      return finalObj
    })
  }, [data, handleDrawerState])
  return (
    <>
      {tableData?.length > 0 ? (
        <>
          <TableLinkDrawer tableType={tableType} drawerState={drawerState} closeDrawer={closeDrawer} />
          <div className="table-wrapper">
            <table className={tableClassName}>
              <thead>
                <tr>
                  {headers.length > 0 &&
                    headers.map(heading => (
                      <th
                        key={heading.label}
                        className={`${headerClass} ${
                          heading.type === "Mix" ? "table-big-header" : "table-small-header"
                        }  `}
                      >
                        {heading.label}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((e, index) => (
                  <Row key={index.toString()} data={e} dataIndex={index} rowClass={rowClass} />
                ))}
              </tbody>
            </table>
          </div>
          <TableMobile
            headers={headers?.filter(e => !mobileHeaders?.includes(e?.name))}
            data={tableData}
            headersToShow={headers?.filter(e => mobileHeaders?.includes(e?.name))}
          />
        </>
      ) : (
        (() => {
          switch (tableType) {
            case "quote":
              return <div className="no-record-found">No Quotes Found</div>
            case "application":
              return <div className="no-record-found">No Applications Found</div>
            default:
              return null
          }
        })()
      )}
      {(drawerState?.isEmailAuthScreen || drawerState?.isPasswordScreen) && (
        <VerificationEmail
          isOpen={drawerState?.isEmailAuthScreen}
          id={drawerState?.id}
          isPwd={drawerState?.isPasswordScreen}
        />
      )}
    </>
  )
}

Table.propTypes = {
  tableClass: PropTypes.string,
  headers: PropTypes.array,
  headerClass: PropTypes.string,
  data: PropTypes.array,
  rowClass: PropTypes.string,
  isEmailAuthScreen: PropTypes.bool,
  id: PropTypes.string,
  isPasswordScreen: PropTypes.bool,
  isEditableDrawer: PropTypes.bool,
  type: PropTypes.string,
  tableType: PropTypes.string,
  mobileHeaders: PropTypes.array,
}

Table.defaultProps = {
  tableClass: "",
  headers: [],
  headerClass: "",
  data: [],
  type: "",
  rowClass: "",
  id: "",
  isEmailAuthScreen: false,
  isPasswordScreen: false,
  isEditableDrawer: false,
  tableType: "",
  mobileHeaders: [],
}

export default Table

function Row(props) {
  const { data, dataIndex } = props

  return (
    <tr
      className={`
          main-table-row
              ${dataIndex % 2 === 0 ? "bg-white" : "bg-secondary-background-color"}
        `}
    >
      {Object.entries(data).map(([key, value], index) => {
        switch (key) {
          case "id":
            return null
          case "loanAmount":
          case "balloonCalculation":
            return <td key={index.toString()}>{value !== "-" ? `$ ${NumberCommaSeparator(value)}` : "-"}</td>
          case "financeTerms":
            return <td key={index.toString()}>{value !== "-" ? `${NumberCommaSeparator(value)} year(s)` : "-"}</td>
          default:
            return <td key={index.toString()}>{value || "-"}</td>
        }
      })}
    </tr>
  )
}

Row.propTypes = {
  data: PropTypes.object,
  dataIndex: PropTypes.number,
}

Row.defaultProps = {
  data: {},
  dataIndex: "",
}

function TableLinkDrawer(props) {
  const { drawerState, closeDrawer, tableType } = props

  const checkValue = row => {
    switch (row.type) {
      case "dollar":
        return row?.value && row?.value !== "N/A" ? `$ ${NumberCommaSeparator(row?.value)}` : "-"
      case "amount":
        return row?.value && row?.value !== "N/A" ? `$ ${NumberCommaSeparator(row?.value)}` : "-"
      case "percent":
        return row?.value && row?.value !== "N/A" ? `${row?.value} %` : "-"
      case "date":
        return row?.value && row?.value !== "N/A" ? dayjs(row?.value).format(DATE_FORMAT.DDMMYYYY) : "-"
      case "editableString":
        if (drawerState?.isEditableDrawer) {
          return <EditableDrawer row={row} id={drawerState?.id} drawerData={drawerState?.data} />
        }
        return row?.value ? row?.value : "-"

      case "editableDate":
        if (drawerState?.isEditableDrawer) {
          return (
            <EditableDrawer row={row} editableField="EXPIRY_DATE" id={drawerState?.id} drawerData={drawerState?.data} />
          )
        }
        return row?.value ? row?.value : "-"

      default:
        return row?.value || "-"
    }
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const onDrawerEditButton = (status, drawerState) => {
    if (tableType === "quote") {
      if (status === "Completed" || status === "Manual Application") {
        history.push(`${ROUTE_CONSTANTS_VARIABLE.VIEW_QUOTE}/${drawerState.id}`)
      } else {
        dispatch(resetQuoteReducer())
        history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/${drawerState.id}`)
      }
    }
    if (tableType === "application") {
      if (status === "Completed") {
        history.push(`${ROUTE_CONSTANTS_VARIABLE.VIEW_APPLICATION}/${drawerState.id}`)
      } else {
        const quoteId = drawerState?.idOfQuote
        if (quoteId && drawerState?.id)
          history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${quoteId}?applicationId=${drawerState.id}`)
      }
    }
  }
  const drawerBtn = drawerState?.data?.filter(function(item) {
    return item?.label === "Status"
  })
  return (
    <Drawer header={drawerState?.drawerHeader} isDrawerOpened={drawerState?.visible} closeDrawer={closeDrawer}>
      <div className="drawer-grid">
        {drawerState?.data?.map(row => (
          <>
            <div className="title">{row?.label}</div>
            <div>{checkValue(row)}</div>
          </>
        ))}
      </div>
      {drawerState?.drawerHeader &&
        (drawerBtn[0]?.value === "Completed" || drawerBtn[0]?.value === "Manual Application" ? (
          <Button
            buttonType="secondary"
            className="drawer-edit-button"
            title={`View ${drawerState?.drawerHeader}`}
            onClick={() => onDrawerEditButton(drawerBtn[0]?.value, drawerState)}
          />
        ) : drawerBtn[0]?.value === "Expired" || drawerBtn[0]?.value === "Transformed" ? (
          <></>
        ) : (
          <Button
            buttonType="secondary"
            className="drawer-edit-button"
            title={`Edit ${drawerState?.drawerHeader}`}
            onClick={() => onDrawerEditButton(drawerBtn[0]?.value, drawerState)}
          />
        ))}
    </Drawer>
  )
}

TableLinkDrawer.propTypes = {
  drawerState: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    drawerHeader: PropTypes.string.isRequired,
    isEmailAuthScreen: PropTypes.bool,
    isPasswordScreen: PropTypes.bool,
    isEditableDrawer: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    idOfQuote: PropTypes.string,
  }).isRequired,
  closeDrawer: PropTypes.func.isRequired,
  tableType: PropTypes.string,
}
