import { BROKER_URLS_NEW } from "../../../constants/UrlConstants"
import ApiService from "../../../services/ApiServices"
export const BASE_URL = process.env.REACT_APP_BASE_URL

const BrokerApiServices = {
  getBrokerJson: () => ApiService.getData(BROKER_URLS_NEW.GET_BROKER_JSON),
  getQuoteFormPathNew: data => ApiService.putData(BROKER_URLS_NEW.GET_QUOTE_FORMPATH, data),
  getApplicationFormPathNew: data => ApiService.putData(BROKER_URLS_NEW.GET_APPLICATION_FORMPATH, data),
  createUser: data => ApiService.postData(BROKER_URLS_NEW.CREATE_USER, data),
  saveData: data => ApiService.postData(BROKER_URLS_NEW.SAVE_DATA, data),
  quickSaveData: data => ApiService.postData(BROKER_URLS_NEW.QUICK_SAVE, data),
  saveQuoteData: data => ApiService.postData(BROKER_URLS_NEW.SAVE_QUOTE_DATA, data),
  getData: id => ApiService.getData(BROKER_URLS_NEW.GET_DATA(id)),
  getEmailVerificationStatus: id => ApiService.getData(`${BROKER_URLS_NEW.GET_EMAIL_VERI_STATUS}/${id}`),
  onBrokerSubmitOtp: data => ApiService.postData(BROKER_URLS_NEW.OTP_VERIFY_STATUS, data),
  getQuoteData: id => ApiService.getData(BROKER_URLS_NEW.GET_QUOTE_DATA(id)),
  getRateCardData: id => ApiService.getData(BROKER_URLS_NEW.GET_RATE_DATA(id)),
  getBrokerPathData: id => ApiService.getData(BROKER_URLS_NEW.getBrokerPathData(id)),
  getApplicationData: id => ApiService.getData(BROKER_URLS_NEW.GET_APPLICATION_DATA(id)),
  saveZOHOData: (id, data) =>
    ApiService.postData(`${BROKER_URLS_NEW.SEND_APPLICATION_ZOHO_DATA}/${id}`, data, { timeout: 0 }),
  saveZOHODataRecursion: (id, data, Path) => ApiService.postData(`${BASE_URL}${Path}/${id}`, data, { timeout: 0 }),
  saveQuoteDataRecursion: (id, data, Path) => ApiService.postData(`${BASE_URL}${Path}/${id}`, data, { timeout: 0 }),
  quoteVanishNextForms: data => ApiService.postData(BROKER_URLS_NEW.VANISH_QUOTE_FORM, data),
  applicaitonVanishNextForms: data => ApiService.postData(BROKER_URLS_NEW.VANISH_APPLICATION_FORM, data),
}
export default BrokerApiServices
