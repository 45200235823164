import PropTypes from "prop-types"
import { DATE_FORMAT, REGEX } from "../../../constants/regexConstants"
import { CheckBox, CustomSelect, Input, RadioButton } from "../../../common"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import React, { createRef, useEffect, useMemo, useState } from "react"
import { FormWrapper } from "./FormWrapper"
import FileUpload from "./fileUpload/FileUpload"
import { useHistory, useParams } from "react-router-dom"
// import SignatureCanvas from "react-signature-canvas"
import ApplicationFormula from "./ApplicationFormula"
import { useDispatch, useSelector } from "react-redux"
import { activeSaveApplicationData } from "../redux/ApplicaitonActions"
import { APPLICATION_ID_QUERY_PARAM } from "../ApplicationPage/ApplicationPageConstants"
import { useQuery } from "../../../hooks/useQuery"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { countries, DEFAULT_DATE_FORMAT } from "../../../constants/Constant"
// import Button from "../../../common/Button/Button"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import ReactGoogleAutocomplete from "../../../common/ReactGoogleAutocomplete/ReactGoogleAutocomplete"
import { applicationSelector, modelTypeCodeSelector } from "../redux/ApplicationSelector"
import NumberFormat from "react-number-format"
import { compare, isObject, isValidAnswer } from "../../../helpers/applicationHelper"
import { defaultAssetYearsWithoutOther } from "../../../constants/DefaultDropDownOptions"
import { dateCheck, useGlassGuide } from "../hooks/useGlassGuide"
// import { useMediaQuery } from "../../../hooks/useMediaQuery"
import moment from "moment"
import dayjs from "dayjs"
import { handleRegoSearch, updateAssetFlags } from "../../asset/redux/AssetAction"
import { handleRegoSearchBroker, validateFieldsAction } from "../../broker/redux/BrokerAction"
import { handleRegoSearchBrokerNew, validateFieldsActionNew } from "../../brokernew/redux/BrokerAction"
import { useAutoFillAnswer } from "../../brokernew/hooks/useAutoFillAnswer"

import _ from "lodash"
import { setFormData, syncFormDataWithFields } from "../../broker/redux/brokerSlice"

import { setFormDataNew, syncFormDataWithFieldsNew } from "../../brokernew/redux/brokerSliceNew"

import { calculateBalloon, calculateToBeFinanced } from "../../../helpers/QuoteFormulaCalculations"

const countryAustralia = countries.find(i => i.cca2 === "AU")

const RenderFieldMemo = ({
  field,
  formPath,
  onFieldUpdate,
  errorMessage,
  handleSearch,
  formWrapperProps,
  indexForRepeater,
  isFromAssetPage,
  isFromBrokerPage,
  isFromBrokerPageNew,
  pathOfField,
  AssetFields,
  currentForm = [],
  autoFillFieldsRef,
}) => {
  let { editable = true, answer } = field
  const {
    type,
    inputType,
    fieldName,
    getDateFrom,
    minLimit,
    maxLimit,
    question,
    placeholder,
    suggestion,
    options,
    fields,
    required,
    numberOfFiles,
    maxFileSize,
    request,
    formulaName,
    showEditWarning,
    isFutureDate,
    defaultQuestion,
    extraOptions,
    hide,
    isAmount,
    isDefaultYearOptions,
    previousYears,
    isRate,
    isMultipleSelect,
    showGrayedOut,
    dividerText,
    dependentFieldName,
    isDependent,
    calculationFormula,
    formulaFields,
    isQuoteField,
  } = field
  const { subFormsKey, formsKey, dataForActiveSave, fieldsToShow } = useSelector(applicationSelector)
  const { modelTypeCode } = useSelector(modelTypeCodeSelector)
  // const sigPad = createRef({})
  const titleWithRequired = `${question !== "" ? question : placeholder} ${required ? "*" : ""}`

  const searchRef = createRef()
  const dispatch = useDispatch()
  const {
    getGlassGuideOptions,
    getGlassGuideOptionsFromAsset,
    getGlassGuideOptionsBroker,
    getGlassGuideOptionsBrokerNew,
  } = useGlassGuide()
  const [isFetchingGlassGuideData, setIsFetchingGlassGuideData] = useState(false)
  const [errors, setErrors] = useState({})

  const brokerDataNew = useSelector(state => state?.brokerReducerNew || {})
  const brokerDataOld = useSelector(state => state?.brokerReducer || {})

  const brokerData = useMemo(() => (isFromBrokerPage ? brokerDataOld : brokerDataNew), [
    isFromBrokerPage,
    isFromBrokerPageNew,
    brokerDataNew,
    brokerDataOld,
  ])

  const { formData, brokerFormPaths, brokerFormPathIndex } = useMemo(() => brokerData, [brokerData])

  const formatPhoneNumber = phone => {
    const cleaned = phone.replace(/\D/g, "")

    const firstDigit = phone.slice(0, 1).toString()
    const secondDigit = phone.slice(1, 1).toString()

    const errors = {}

    if (cleaned.length === 10) {
      if (firstDigit === "0" && secondDigit !== 0) {
        handleFieldUpdate(`${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)
      } else if (firstDigit !== "0") {
        handleFieldUpdate(`0${cleaned.slice(0, 1)} ${cleaned.slice(1, 5)}-${cleaned.slice(5)}`)
      }
    } else if (cleaned.length === 9) {
      if (firstDigit === "4") {
        handleFieldUpdate(`0${cleaned.slice(0, 1)} ${cleaned.slice(1, 5)}-${cleaned.slice(5)}`)
      } else {
        handleFieldUpdate(`${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)
        errorMessage = `Please enter valid ${fieldName} number`
      }
    } else if (cleaned.length === 8) {
      handleFieldUpdate(`${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)

      if (
        firstDigit === "2" ||
        firstDigit === "3" ||
        firstDigit === "5" ||
        firstDigit === "6" ||
        firstDigit === "7" ||
        firstDigit === "8" ||
        firstDigit === "9"
      ) {
        errors.phone = `Please enter an area code - e.g, 03 02`
      } else {
        errors.phone = `Please enter valid ${fieldName} number`
      }
    } else {
      const prefix = cleaned.slice(0, 2)

      if (prefix === "04" || firstDigit === "0") {
        handleFieldUpdate(`${prefix} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)
        errors.phone = `Please enter 10 digits`
      } else {
        errors.phone = `Please enter valid ${fieldName} number`
      }
    }

    // setIsInputFocused(false)
  }

  const fieldsToUse = index => {
    if (isFromBrokerPage && formData !== undefined) {
      return _.get(formData, currentForm)
    } else if (isFromBrokerPageNew && formData !== undefined) {
      return _.get(formData, currentForm)
    } else {
      return index || index === 0 ? fieldsToShow[index] : fieldsToShow
    }
  }

  const formsKeyToUse = index => {
    if (isFromBrokerPage) {
      return index || index === 0 ? currentForm[0] : currentForm
    } else if (isFromBrokerPageNew) {
      return index || index === 0 ? currentForm[0] : currentForm
    } else {
      return index || index === 0 ? formsKey[0] : formsKey
    }
  }

  const subFormKeysToUse = index => {
    if (isFromBrokerPageNew) {
      return index || index === 0 ? currentForm[1] : currentForm
    } else if (isFromBrokerPage) {
      return index || index === 0 ? currentForm[1] : currentForm
    } else {
      return index || index === 0 ? subFormsKey[0] : subFormsKey
    }
  }

  // Calculate to be financed value of quote
  const toBeFinancedValue = useMemo(() => {
    if (calculationFormula && isFromBrokerPage && formData !== undefined) {
      let fields
      formulaFields?.forEach(e => {
        const formDataFields = _.get(formData, currentForm)
        const fieldIndex = formDataFields?.length > 0 && formDataFields.findIndex(field => field?.fieldName === e)
        fields = {
          ...fields,
          [e]: fieldIndex >= 0 ? Number(formDataFields[fieldIndex]?.answer) : undefined,
        }
      })
      if (calculationFormula === "toBeFinancedCalculation") {
        return calculateToBeFinanced(fields, dispatch, true)
      }
    }
    if (calculationFormula && isFromBrokerPageNew && formData !== undefined) {
      let fields
      formulaFields?.forEach(e => {
        const formDataFields = _.get(formData, currentForm)
        const fieldIndex = formDataFields?.length > 0 && formDataFields.findIndex(field => field?.fieldName === e)
        fields = {
          ...fields,
          [e]: fieldIndex >= 0 ? Number(formDataFields[fieldIndex]?.answer) : undefined,
        }
      })
      if (calculationFormula === "toBeFinancedCalculation") {
        return calculateToBeFinanced(fields, dispatch, true)
      }
    }
  }, [formData, calculationFormula, isFromBrokerPage, isFromBrokerPageNew, currentForm])

  // Calculate balloon calculation value of quote
  const balloonCalculationValue = useMemo(() => {
    if (calculationFormula && isFromBrokerPageNew) {
      let fields

      formulaFields?.forEach(e => {
        const formDataFields = _.get(formData, currentForm)
        const fieldIndex = formDataFields?.length > 0 && formDataFields.findIndex(field => field?.fieldName === e)
        fields = {
          ...fields,
          [e]: fieldIndex >= 0 ? Number(formDataFields[fieldIndex]?.answer) : 0,
        }
      })
      if (calculationFormula === "balloonCalculation") return calculateBalloon(fields, dispatch, true)
    }
    if (calculationFormula && isFromBrokerPage) {
      let fields

      formulaFields?.forEach(e => {
        const formDataFields = _.get(formData, currentForm)
        const fieldIndex = formDataFields?.length > 0 && formDataFields.findIndex(field => field?.fieldName === e)
        fields = {
          ...fields,
          [e]: fieldIndex >= 0 ? Number(formDataFields[fieldIndex]?.answer) : 0,
        }
      })
      if (calculationFormula === "balloonCalculation") return calculateBalloon(fields, dispatch, true)
    }
  }, [formData, calculationFormula, isFromBrokerPage, isFromBrokerPageNew, currentForm])

  const consumerOrCommercialValue = useMemo(() => {
    if (calculationFormula === "setConsumerOrCommercial" && isFromBrokerPageNew) {
      const fieldIndex = formData?.employmentUnsecuredBusinessLoan?.findIndex(
        field => field.fieldName === "personalOrBusinessUse",
      )
      if (
        formData?.employmentUnsecuredBusinessLoan &&
        formData?.employmentUnsecuredBusinessLoan[fieldIndex] &&
        formData?.employmentUnsecuredBusinessLoan[fieldIndex]?.answer
      ) {
        if (formData?.employmentUnsecuredBusinessLoan[fieldIndex]?.answer === "Personal Use") return "Consumer"
        if (formData?.employmentUnsecuredBusinessLoan[fieldIndex]?.answer === "Business Use") return "Commercial"
      }
    }
  }, [formData, calculationFormula, isFromBrokerPage, isFromBrokerPageNew, currentForm])

  // Update tobeFinanced value in formData of broker
  useEffect(() => {
    if (calculationFormula === "toBeFinancedCalculation" && isFromBrokerPage) {
      dispatch(setFormData({ path: pathOfField, value: { ...field, answer: toBeFinancedValue } }))
    }
    if (calculationFormula === "toBeFinancedCalculation" && isFromBrokerPageNew) {
      dispatch(setFormDataNew({ path: pathOfField, value: { ...field, answer: toBeFinancedValue } }))
    }
  }, [toBeFinancedValue])

  useEffect(() => {
    if (
      calculationFormula === "setConsumerOrCommercial" &&
      (isFromBrokerPage || isFromBrokerPageNew) &&
      !consumerOrCommercialValue
    ) {
      dispatch(setFormData({ path: pathOfField, value: { ...field, editable: true } }))
    } else {
      if (calculationFormula === "setConsumerOrCommercial" && isFromBrokerPage) {
        dispatch(
          setFormData({ path: pathOfField, value: { ...field, answer: consumerOrCommercialValue, editable: false } }),
        )
      }
      if (calculationFormula === "setConsumerOrCommercial" && isFromBrokerPageNew) {
        dispatch(
          setFormDataNew({
            path: pathOfField,
            value: { ...field, answer: consumerOrCommercialValue, editable: false },
          }),
        )
      }
    }
  }, [consumerOrCommercialValue])

  // Update balloonCalculation value in formData of broker
  useEffect(() => {
    if (calculationFormula === "balloonCalculation" && isFromBrokerPage) {
      dispatch(setFormData({ path: pathOfField, value: { ...field, answer: balloonCalculationValue } }))
      dispatch(
        syncFormDataWithFields({
          index: brokerFormPaths.findIndex(x => x[0] === currentForm[0] && x?.[1] === currentForm?.[1]),
          path: pathOfField,
        }),
      )
    }
    if (calculationFormula === "balloonCalculation" && isFromBrokerPageNew) {
      dispatch(setFormDataNew({ path: pathOfField, value: { ...field, answer: balloonCalculationValue } }))
      dispatch(
        syncFormDataWithFieldsNew({
          index: brokerFormPaths.findIndex(x => x[0] === currentForm[0] && x?.[1] === currentForm?.[1]),
          path: pathOfField,
        }),
      )
    }
  }, [balloonCalculationValue])

  // const isMobileScreen = useMediaQuery("(max-width: 480px)")
  // const [endDate, setEndDate] = useState()
  const query = useQuery()
  const history = useHistory()
  let fileSize = 2097152
  if (maxFileSize !== undefined) {
    fileSize = maxFileSize.replace("MB", "")
    fileSize = fileSize * 1024 * 1024
  }

  // As it is getting used at multiple places so extracted this code
  // const signaturePadHeightAndWidth = useMemo(() => {
  //   return { width: !isMobileScreen ? 500 : 330, height: !isMobileScreen ? 200 : 130 }
  // }, [isMobileScreen])

  const selectBoxDefault = [
    "state",
    "assetManufacturerOptions",
    "assetGenericOptions",
    "placingDeposit",
    "isThereATradeIn",
    "vendorType",
    "assetBadge",
    "assetSeries",
    "assetType",
  ]
  const getAddressDefault = () => {
    if (subFormKeysToUse().includes("reference1") || subFormKeysToUse().includes("reference2")) {
      return [
        "referenceAddressLine2",
        "referenceStreetAddress",
        "referenceCity",
        "referenceState",
        "referencePostCode",
        "referenceCountry",
      ]
    }
    if (subFormKeysToUse().includes("assetVendorDetails")) {
      return [
        "vendorAddressLine2",
        "vendorStreetAddress",
        "vendorCity",
        "vendorState",
        "vendorPostCode",
        "vendorCountry",
      ]
    }
    return ["addressLine2", "streetAddress", "city", "state", "postCode", "country"]
  }
  const handleErrorMessage = error => {
    setErrors({ ...errors, mobileNumber: error })
  }
  const handleFieldUpdate = (value, label = "", path = "") => {
    // console.log('isFromBrokerPage', isFromBrokerPage);
    // console.log('isFromBrokerPageNew', isFromBrokerPageNew);

    if (onFieldUpdate) {
      if (fieldName === "numberOfAsset" && value === "") {
        value = 0
      }
      if (isFromAssetPage) {
        onFieldUpdate(fieldName, value, label, path)

        const rego = AssetFields.filter(x => x.fieldName === "rego")

        switch (type) {
          case "regoState":
            if (isDependent && dependentFieldName && rego?.length > 0) {
              const regoVal = rego?.[0]?.answer
              handleRegoStateSearch(value, regoVal)
            }
        }
      } else if (isFromBrokerPage) {
        onFieldUpdate(fieldName, value, label)

        const vehicle = currentForm["0"]
        const rego =
          formData?.[`${vehicle}`]?.length > 0 && formData?.[`${vehicle}`].filter(x => x.fieldName === "rego")
        switch (type) {
          case "regoState":
            if (isDependent && dependentFieldName && rego?.length > 0) {
              const regoVal = rego?.[0]?.answer
              handleRegoStateSearchBroker(value, regoVal, vehicle)
            }
        }
      } else if (isFromBrokerPageNew) {
        onFieldUpdate(fieldName, value, label)

        const vehicle = currentForm["0"]
        const rego =
          formData?.[`${vehicle}`]?.length > 0 && formData?.[`${vehicle}`].filter(x => x.fieldName === "rego")
        switch (type) {
          case "regoState":
            if (isDependent && dependentFieldName && rego?.length > 0) {
              const regoVal = rego?.[0]?.answer
              handleRegoStateSearchBrokerNew(value, regoVal, vehicle)
            }
        }
      } else {
        onFieldUpdate(fieldName, value, path)
      }
    }
  }
  const handleRegoStateSearch = (value, rego) => {
    if (request?.url) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        rego: params?.identifier === "getByRegoState" ? rego.toUpperCase()?.trim() : undefined,
        state: params?.identifier === "getByRegoState" ? value?.trim() : undefined,
        vin: params?.identifier === "getByVIN" ? value?.trim() : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearch(url, method, paramData)).then(() => {
        dispatch(updateAssetFlags("isDisabledSubmit", false))
        dispatch(updateAssetFlags("isRegoSearch", true))
      })
    }
  }
  const handleRegoStateSearchBroker = (value, rego, vehicle) => {
    if (request?.url) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        rego: params?.identifier === "getByRegoState" ? rego.toUpperCase()?.trim() : undefined,
        state: params?.identifier === "getByRegoState" ? value?.trim() : undefined,
        vin: params?.identifier === "getByVIN" ? value?.trim() : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearchBroker(url, method, paramData, vehicle)).then(() => {})
    }
  }
  const handleRegoStateSearchBrokerNew = (value, rego, vehicle) => {
    if (request?.url) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        rego: params?.identifier === "getByRegoState" ? rego.toUpperCase()?.trim() : undefined,
        state: params?.identifier === "getByRegoState" ? value?.trim() : undefined,
        vin: params?.identifier === "getByVIN" ? value?.trim() : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearchBrokerNew(url, method, paramData, vehicle)).then(() => {})
    }
  }

  /* auto complete code */
  const handleFieldGoogleLookup = e => {
    let path = pathOfField
      ?.split(".")
      ?.filter(x => !x?.includes("fields"))
      ?.join(".")
    path = `${path}.fields`
    const fields = _.get(formData, path || "")
    getAddressDefault().forEach(element => {
      if (isFromBrokerPage && fields?.length > 0) {
        const elementIndex = fields.findIndex(field => field?.fieldName === element)
        if (elementIndex >= 0) {
          dispatch(
            setFormData({
              path: `${path}[${elementIndex}]`,
              value: { ...fields[elementIndex], answer: e?.[element] || "" },
            }),
          )
          dispatch(validateFieldsAction())
        }
      } else if (isFromBrokerPageNew && fields?.length > 0) {
        const elementIndex = fields.findIndex(field => field?.fieldName === element)
        if (elementIndex >= 0) {
          dispatch(
            setFormDataNew({
              path: `${path}[${elementIndex}]`,
              value: { ...fields[elementIndex], answer: e?.[element] || "" },
            }),
          )
          dispatch(validateFieldsActionNew())
        }
      } else if (onFieldUpdate) {
        onFieldUpdate(element, e?.[element], "")
      }
    })
  }

  const handleSearchSuffixClick = autoFillAnswer => {
    const ans = typeof autoFillAnswer === "string" ? autoFillAnswer : answer
    isFromBrokerPage || isFromBrokerPageNew
      ? handleSearch(currentForm, fieldName, ans, request)
      : handleSearch(fieldName, ans, request)
  }

  // TO AUTOFILL ANSWER IN BROKER PAGE
  // it'll use autoFillAnswer property to get answer from formdata and update answer as if user selected it
  useAutoFillAnswer(field, pathOfField, handleFieldUpdate, handleSearchSuffixClick, autoFillFieldsRef)

  /* auto complete code */

  let d = new Date()
  let maxYear = d.getFullYear() + 20
  const minYear = d.getFullYear() - 100
  if (isFutureDate === false) {
    maxYear = d.getFullYear()
  }
  if (fieldName === "dob" || fieldName === "dateOfBirth") {
    d = new Date().setFullYear(new Date().getFullYear() - 18)
    d = new Date(d)
  }
  let minDate = new Date().setFullYear(minYear)
  minDate = new Date(minDate)

  const [maxDate, setMaxDate] = useState(d)
  useEffect(() => {
    if (subFormKeysToUse()?.includes("previousAddressDetails2") && fieldName === "previousMoveInDate") {
      const previousMoveOutDateAnswer = fieldsToUse()
        ?.flat()
        ?.find?.(field => field?.fieldName === "previousMoveOutDate")?.answer
      if (previousMoveOutDateAnswer) {
        setMaxDate(new Date(moment(previousMoveOutDateAnswer, DEFAULT_DATE_FORMAT).toString()))
      }
    }
    if (
      subFormKeysToUse()?.includes("companyPreviousAddressDetails") &&
      fieldName === "previousTradingAddressMoveInDate"
    ) {
      const previousMoveOutDateAnswer = fieldsToUse()
        ?.flat()
        ?.find?.(field => field?.fieldName === "previousTradingAddressMoveOutDate")?.answer
      if (previousMoveOutDateAnswer) {
        setMaxDate(new Date(moment(previousMoveOutDateAnswer, DEFAULT_DATE_FORMAT).toString()))
      }
    }
  }, [fieldsToShow, fieldName, formData])

  const handleFieldChange = async () => {
    if (showEditWarning) {
      const confirmBox = window.confirm("If you are editing than you need to complete whole process again")
      if (confirmBox === true) {
        const dataToSave = {
          quoteId: id,
          _id: query.get(APPLICATION_ID_QUERY_PARAM) ?? undefined,
          applicationAnswersData: isFromBrokerPage || isFromBrokerPageNew ? formData : dataForActiveSave,
          isTransform: true,
        }
        const { applicationId } = await dispatch(activeSaveApplicationData(dataToSave, history))
        if (query.get(APPLICATION_ID_QUERY_PARAM) === applicationId || query.get(APPLICATION_ID_QUERY_PARAM) === null) {
          window.location.replace(`${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}`)
        }
      }
    }
    window.dispatchEvent(new Event("resize"))
  }
  const handleFileFieldUpdate = filePath => {
    onFieldUpdate(fieldName, filePath, "")
  }
  const { id } = useParams()

  // const errorMessageDOM = errorMessage ? <span className="application-error-message">{errorMessage}</span> : <></>

  const errorMessageDOM = errorMessage

  const isGlassGuideFieldChanged = useMemo(() => {
    return Boolean(field?.request)
  }, [field, formData])

  useEffect(() => {
    if (isFromAssetPage) {
      if (field?.request?.url && field?.answer === undefined) {
        setIsFetchingGlassGuideData(true)
        getGlassGuideOptionsFromAsset(request, field, AssetFields)
          .then(() => {
            setIsFetchingGlassGuideData(false)
          })
          .catch(error => {
            setIsFetchingGlassGuideData(false)
            // eslint-disable-next-line no-console
            console.error("Glass guide error:", error)
          })
      }
    } else {
      const fieldToShowField = fieldsToUse()
        ?.flat()
        ?.find(x => x?.key === field?.key && x?.fieldName === field?.fieldName)
      if (
        fieldToShowField &&
        !isFetchingGlassGuideData &&
        fieldToShowField?.request?.url &&
        (fieldToShowField?.answer === undefined ||
          fieldToShowField?.options === undefined ||
          fieldToShowField?.options?.length === 0)
      ) {
        setIsFetchingGlassGuideData(true)
        getGlassGuideOptions(request, field, formPath)
          .then(() => {
            setIsFetchingGlassGuideData(false)
          })
          .catch(error => {
            setIsFetchingGlassGuideData(false)
            // eslint-disable-next-line no-console
            console.error("Glass guide error:", error)
          })
      }
    }
  }, [type, fieldsToShow, modelTypeCode, AssetFields])

  useEffect(async () => {
    if (isFromBrokerPage) {
      if (
        !isFetchingGlassGuideData &&
        field?.type !== "search" &&
        field?.request?.url &&
        (field?.answer === undefined || field?.options === undefined || field?.options?.length === 0)
      ) {
        setIsFetchingGlassGuideData(true)
        await getGlassGuideOptionsBroker(
          request,
          field,
          currentForm?.length > 1 ? _.get(formData, currentForm[0]) : formData,
          pathOfField,
        )
          .then(() => {
            setIsFetchingGlassGuideData(false)
          })
          .catch(error => {
            setIsFetchingGlassGuideData(false)
            // eslint-disable-next-line no-console
            console.error("Glass guide error:", error)
          })
      }
    }
    if (isFromBrokerPageNew) {
      if (
        !isFetchingGlassGuideData &&
        field?.type !== "search" &&
        field?.request?.url &&
        (field?.answer === undefined || field?.options === undefined || field?.options?.length === 0)
      ) {
        setIsFetchingGlassGuideData(true)

        await getGlassGuideOptionsBrokerNew(
          request,
          field,
          currentForm?.length > 1 ? _.get(formData, currentForm[0]) : formData,
          pathOfField,
        )
          .then(() => {
            setIsFetchingGlassGuideData(false)
          })
          .catch(error => {
            setIsFetchingGlassGuideData(false)
            // eslint-disable-next-line no-console
            console.error("Glass guide error:", error)
          })
      }
    }
  }, [field, isGlassGuideFieldChanged])

  // START   -------------------------------------------------------------- No need to show skip checkbox if next form is already visible
  const doesFormPathAlreadyHaveNextFrom = useMemo(() => {
    const currentFormPathIndex = brokerFormPaths.findIndex(x =>
      x?.length > 1 ? x[0] === currentForm[0] && x?.[1] === currentForm?.[1] : x[0] === currentForm[0],
    )
    return brokerFormPathIndex > currentFormPathIndex
  }, [brokerFormPaths, currentForm, brokerFormPathIndex])

  if (isFromBrokerPage && fieldName && fieldName.includes("skip") && doesFormPathAlreadyHaveNextFrom) {
    return <></>
  }
  if (isFromBrokerPageNew && fieldName && fieldName.includes("skip") && doesFormPathAlreadyHaveNextFrom) {
    return <></>
  }
  // END     -------------------------------------------------------------- No need to show skip checkbox if next form is already visible

  if (hide && type !== "formula") {
    return <></>
  }

  let assetYear = ""

  // console.log('currentForm', currentForm.join('.'), fieldName)

  if (currentForm.join(".") === "assetInfo" && fieldName === "assetYear") {
    assetYear = answer
  }

  if (fieldName === "assetAge" && answer === undefined) {
    answer = assetYear
  }

  if (fieldName === "assetAge" && answer && typeof answer !== "string") {
    answer = answer.toString()
  }

  // console.log('fieldName', fieldName, type);

  switch (type?.toLowerCase()) {
    case "googlelookup": {
      return (
        <div className="form-googlelookup-question">
          <ReactGoogleAutocomplete
            name={fieldName}
            value={answer}
            placeholder={titleWithRequired}
            addressDefault={getAddressDefault()}
            setFinalAddress={handleFieldGoogleLookup}
          />
        </div>
      )
    }

    case "text": {
      return (
        <div
          className={`input-label ${
            fieldName === "streetAddress" ||
            fieldName === "vendorStreetAddress" ||
            fieldName === "referenceStreetAddress"
              ? "form-streetaddress-question"
              : ""
          } ${
            subFormKeysToUse(0) === "addressInformation" && fieldName === "landlordRealEstate"
              ? "land-lord-real-estate"
              : ""
          }`}
        >
          <>
            <Input
              type={inputType}
              className={`answer-input ${!answer && isQuoteField ? "redField" : ""}  ${
                fieldName === "balloon" && answer !== undefined && answer > 40 ? "ballon-dollar-sign" : ""
              } ${errorMessage ? "input-has-value-or-focused border-error" : ""}`}
              name={fieldName}
              value={answer}
              minLength={minLimit}
              maxLength={maxLimit}
              disabled={!editable}
              suffix={isRate || (fieldName === "balloon" && answer !== undefined && answer < 41) ? "%" : ""}
              suffixClass={isRate ? "no-cursor" : ""}
              prefix={fieldName === "balloon" && answer !== undefined && answer > 40 ? "$" : ""}
              prefixType="icon"
              isShowEditWarning={showEditWarning}
              onFieldChange={handleFieldChange}
              placeholder={titleWithRequired}
              onChange={e => {
                const value = e.target.value

                if (e.target.type === "number") {
                  if (e.target.name === "balloon") {
                    if (REGEX.DECIMAL_REGEX.test(value) || value === "") {
                      if (String(value).length <= maxLimit) {
                        value.replace(REGEX.DECIMAL_REGEX, "")
                        handleFieldUpdate(value)
                      }
                    }
                  } else {
                    if (REGEX.NUMBER_REGEX.test(value) || value === "") {
                      value.replace(REGEX.NUMBER_REGEX, "")
                      handleFieldUpdate(value)
                    }
                  }
                } else {
                  if (fieldName === "rego") {
                    const formIndexState = formData?.carSearch?.findIndex(e => e.fieldName === "state")
                    const formIndexVin = formData?.carSearch?.findIndex(e => e.fieldName === "vin")
                    const formIndexAllOther = formData?.carSearch?.findIndex(e => e.fieldName === "carSearch")
                    const formIndexassetD = formData?.carSearch?.findIndex(e => e.fieldName === "assetDescription")
                    if (isFromBrokerPage) {
                      dispatch(setFormData({ path: `carSearch[${formIndexState}].answer`, value: "" }))
                      dispatch(setFormData({ path: `carSearch[${formIndexVin}].answer`, value: "" }))
                      dispatch(setFormData({ path: `carSearch[${formIndexAllOther}].answer`, value: "" }))
                      dispatch(
                        setFormData({
                          path: `carSearch[${formIndexassetD}].answer`,
                          value: ``,
                        }),
                      )
                    }
                    if (isFromBrokerPageNew) {
                      dispatch(setFormDataNew({ path: `carSearch[${formIndexState}].answer`, value: "" }))
                      dispatch(setFormDataNew({ path: `carSearch[${formIndexVin}].answer`, value: "" }))
                      dispatch(setFormDataNew({ path: `carSearch[${formIndexAllOther}].answer`, value: "" }))
                      dispatch(
                        setFormDataNew({
                          path: `carSearch[${formIndexassetD}].answer`,
                          value: ``,
                        }),
                      )
                    }
                  }
                  handleFieldUpdate(value)
                }
              }}
            />
          </>
          {suggestion && <div className="suggestion">{suggestion}</div>}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "decimal": {
      return (
        <div>
          <Input
            type={inputType}
            className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
              errorMessage ? "input-has-value-or-focused border-error" : ""
            } ${!editable ? "disabledfield" : ""}`}
            name={fieldName}
            value={answer}
            minLength={minLimit}
            maxLength={maxLimit}
            disabled={!editable}
            isShowEditWarning={showEditWarning}
            onFieldChange={handleFieldChange}
            placeholder={titleWithRequired}
            onChange={e => {
              const value = e.target.value
              if (e.target.type === "number") {
                if (REGEX.DECIMAL_REGEX.test(value) || value === "") {
                  value.replace(REGEX.DECIMAL_REGEX, "")
                  handleFieldUpdate(value)
                }
              }
            }}
          />
          {suggestion && <div className="suggestion">{suggestion}</div>}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "amount": {
      return (
        <div className="input-label">
          <>
            <NumberFormat
              value={answer ?? ""}
              className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
                errorMessage ? "input-has-value-or-focused border-error" : ""
              }`}
              name={fieldName}
              placeholder={titleWithRequired}
              minLength={minLimit}
              maxLength={maxLimit}
              decimalScale={2}
              disabled={!editable}
              // isAllowed={({ floatValue }) => {
              //   return floatValue !== undefined ? floatValue < MAX_VALUE : true
              // }}
              onChange={e => {
                const value = e.target.value
                let value1 = value.replace(/[&#,+()$~%'":*?<>{}]/g, "")
                value1 = value1.replace("- ", "").trim()
                value1 = value1.replace("-", "").trim()
                value1 = value1.replace(/^0+/, "")
                handleFieldUpdate(value1)
              }}
              customInput={Input}
              thousandSeparator={!isRate}
              suffix={isRate ? "%" : ""}
              suffixClass={isRate ? "no-cursor" : ""}
              prefix={isRate || fieldName === "assetKilometres" ? "" : "$ "}
              // onValueChange={({ floatValue }) => handleInputChange(floatValue)}
            />
          </>
          {suggestion && <div className="suggestion">{suggestion}</div>}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "date": {
      return (
        <div
          className={
            subFormKeysToUse(0) === "previousEmploymentDetails" && fieldName === "startDate"
              ? "previous-employer-start-date"
              : ""
          }
        >
          <>
            {isFutureDate ? (
              <DateRangePicker
                initialSettings={{
                  autoUpdateInput: false,
                  locale: {
                    format: DATE_FORMAT.DDMMYYYY,
                    cancelLabel: "Clear",
                  },
                  showDropdowns: true,
                  singleDatePicker: true,
                  autoApply: true,
                  minDate: d,
                  maxYear: maxYear,
                  maxDate: new Date("01/01/" + maxYear),
                  cancelButtonClasses: "button outlined-primary-button",
                  applyButtonClasses: "button outlined-primary-button",
                  drops: "auto",
                }}
                disabled={!editable}
                onApply={(event, picker) => {
                  if (editable) {
                    handleFieldUpdate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
                  }
                }}
              >
                <Input
                  className={`${!answer && isQuoteField ? "redField" : ""} ${
                    errorMessage ? "input-has-value-or-focused border-error" : ""
                  }`}
                  placeholder={titleWithRequired}
                  name={fieldName}
                  value={answer}
                  disabled={!editable}
                  isShowEditWarning={showEditWarning}
                  onFieldChange={handleFieldChange}
                  onChange={e => {
                    // if (!isFromBrokerPage) {
                    const value = e.target.value
                    handleFieldUpdate(value)
                    //  }
                  }}
                  onBlur={e => {
                    //  if (!isFromBrokerPage) {
                    const value = e.target.value
                    const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                    if (isValid) {
                      const minD = dayjs(d).format(DATE_FORMAT.YMD)
                      const dateValue = value
                        .split("/")
                        .reverse()
                        .join("-")
                      const dateValid = dateCheck(minD, maxYear + "-01-01", dateValue)
                      if (dateValid) {
                        handleFieldUpdate(value)
                      } else {
                        handleFieldUpdate("")
                      }
                    } else {
                      handleFieldUpdate("")
                    }
                    //   }
                  }}
                />
              </DateRangePicker>
            ) : isFutureDate === false ? (
              getDateFrom !== undefined ? (
                <DateRangePicker
                  key={fieldsToUse(0)?.find(e => e?.fieldName === getDateFrom)?.answer}
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                      format: DATE_FORMAT.DDMMYYYY,
                      cancelLabel: "Clear",
                    },
                    showDropdowns: true,
                    singleDatePicker: true,
                    autoApply: true,
                    minDate: fieldsToUse(0)?.find(e => e?.fieldName === getDateFrom)?.answer,
                    maxDate: d,
                    cancelButtonClasses: "button outlined-primary-button",
                    applyButtonClasses: "button outlined-primary-button",
                    drops: "auto",
                  }}
                  disabled={!editable}
                  onApply={(event, picker) => {
                    if (editable) {
                      handleFieldUpdate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
                    }
                  }}
                >
                  <Input
                    className={`${!answer && isQuoteField ? "redField" : ""}${
                      errorMessage ? "input-has-value-or-focused border-error" : ""
                    }`}
                    placeholder={titleWithRequired}
                    name={fieldName}
                    value={answer}
                    disabled={!editable || !fieldsToUse(0)?.find(e => e?.fieldName === getDateFrom)?.answer}
                    isShowEditWarning={showEditWarning}
                    onFieldChange={handleFieldChange}
                    onChange={e => {
                      // if (!isFromBrokerPage) {
                      const value = e.target.value
                      handleFieldUpdate(value)
                      //  }
                    }}
                    onBlur={e => {
                      //    if (!isFromBrokerPage) {
                      const value = e.target.value
                      const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                      if (isValid) {
                        const maxD = dayjs(d).format(DATE_FORMAT.YMD)
                        let minD = fieldsToUse(0)?.find(e => e?.fieldName === getDateFrom)?.answer
                        minD = minD
                          ?.split("/")
                          .reverse()
                          .join("-")
                        const dateValue = value
                          .split("/")
                          .reverse()
                          .join("-")
                        const dateValid = dateCheck(minD, maxD, dateValue)
                        if (dateValid) {
                          handleFieldUpdate(value)
                        } else {
                          handleFieldUpdate("")
                        }
                      } else {
                        handleFieldUpdate("")
                      }
                      //    }
                    }}
                  />
                </DateRangePicker>
              ) : (
                <DateRangePicker
                  key={maxDate}
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                      format: DATE_FORMAT.DDMMYYYY,
                      cancelLabel: "Clear",
                    },
                    showDropdowns: true,
                    singleDatePicker: true,
                    autoApply: true,
                    maxDate,
                    startDate: maxDate,
                    minDate: minDate,
                    cancelButtonClasses: "button outlined-primary-button",
                    applyButtonClasses: "button outlined-primary-button",
                    drops: "auto",
                  }}
                  disabled={!editable}
                  onApply={(event, picker) => {
                    if (editable) {
                      handleFieldUpdate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
                    }
                  }}
                >
                  <Input
                    className={`${!answer && isQuoteField ? "redField" : ""}${
                      errorMessage ? "input-has-value-or-focused border-error" : ""
                    }`}
                    placeholder={titleWithRequired}
                    name={fieldName}
                    value={answer}
                    disabled={!editable}
                    isShowEditWarning={showEditWarning}
                    onFieldChange={handleFieldChange}
                    onChange={e => {
                      //  if (!isFromBrokerPage) {
                      const value = e.target.value
                      handleFieldUpdate(value)
                      // }
                    }}
                    onBlur={e => {
                      //    if (!isFromBrokerPage) {
                      const value = e.target.value
                      const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                      if (isValid) {
                        const maxD = dayjs(d).format(DATE_FORMAT.YMD)
                        const dateValue = value
                          .split("/")
                          .reverse()
                          .join("-")
                        const dateValid = dateCheck(dayjs(minDate).format(DATE_FORMAT.YMD), maxD, dateValue)
                        if (dateValid) {
                          handleFieldUpdate(value)
                        } else {
                          handleFieldUpdate("")
                        }
                      } else {
                        handleFieldUpdate("")
                      }
                      //   }
                    }}
                  />
                </DateRangePicker>
              )
            ) : (
              <DateRangePicker
                initialSettings={{
                  autoUpdateInput: false,
                  locale: {
                    format: DATE_FORMAT.DDMMYYYY,
                    cancelLabel: "Clear",
                  },
                  minDate: minDate,
                  showDropdowns: true,
                  singleDatePicker: true,
                  autoApply: true,
                  cancelButtonClasses: "button outlined-primary-button",
                  applyButtonClasses: "button outlined-primary-button",
                  drops: "auto",
                }}
                disabled={!editable}
                onApply={(event, picker) => {
                  if (editable) {
                    handleFieldUpdate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
                  }
                }}
              >
                <Input
                  className={`${!answer && isQuoteField ? "redField" : ""}${
                    errorMessage ? "input-has-value-or-focused border-error" : ""
                  }`}
                  placeholder={titleWithRequired}
                  name={fieldName}
                  value={answer}
                  disabled={!editable}
                  isShowEditWarning={showEditWarning}
                  onFieldChange={handleFieldChange}
                  onChange={e => {
                    //  if (!isFromBrokerPage) {
                    const value = e.target.value
                    handleFieldUpdate(value)
                    //  }
                  }}
                  onBlur={e => {
                    //   if (!isFromBrokerPage) {
                    const value = e.target.value
                    const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()
                    if (isValid) {
                      const maxD = d.getFullYear() + 100
                      const dateValue = value
                        .split("/")
                        .reverse()
                        .join("-")
                      const dateValid = dateCheck(dayjs(minDate).format(DATE_FORMAT.YMD), maxD + "-01-01", dateValue)
                      if (dateValid) {
                        handleFieldUpdate(value)
                      } else {
                        handleFieldUpdate("")
                      }
                    } else {
                      handleFieldUpdate("")
                    }
                    //   }
                  }}
                />
              </DateRangePicker>
            )}
          </>
          <div className="suggestion">{DATE_FORMAT.DDMMYYYY}</div>
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "dropdownforglassguide":
    case "dropdown":
    case "regostate": {
      const isAssetModel = fieldName === "model" && request?.url === "glassGuide"
      let removeAns = false
      let allOptions = options
      if (options?.length === 0) {
        options?.push({
          label: "None",
          value: "None",
        })
      }
      if (isDefaultYearOptions && previousYears) {
        allOptions = defaultAssetYearsWithoutOther(previousYears)
      }
      const optionData =
        allOptions?.map(e => {
          return {
            ...(isObject(e) ? e : {}),
            label: ["string", "number", "boolean"].includes(typeof e?.label)
              ? e.label?.toString?.()
              : isAssetModel
              ? e?.value?.toString?.()
              : e?.toString(),
            value: ["string", "number", "boolean"].includes(typeof e?.value) ? e.value : e ?? "",
          }
        }) || []
      if (extraOptions?.showOther && !optionData?.find(x => x?.value?.toLowerCase?.() === "other")) {
        optionData?.push({
          label: "Other",
          value: "Other",
        })
      }

      if (typeof answer === "string" && answer) {
        const haveAnswer = optionData?.findIndex(x => compare(x?.value, answer)) > -1
        if (!haveAnswer || optionData?.length === 0) {
          optionData.push({ label: answer, value: answer })
        }
      }
      if (fieldName === "sameVendorDetailsAs") {
        const sameAssetDetailsAsAnswer = fieldsToUse()
          ?.flat()
          ?.find?.(field => field?.fieldName === "sameAssetDetailsAs")?.answer
        const assetYouAfterAnswer = fieldsToUse()
          ?.flat()
          ?.find?.(field => field?.fieldName === "assetYouAfter")?.answer
        if (sameAssetDetailsAsAnswer === true || ["Yes", "Undecided"].includes(assetYouAfterAnswer)) {
          editable = true
        }
        if (sameAssetDetailsAsAnswer === false) {
          answer = ""
          removeAns = true
        }
      }

      const checkFormAndSubFOrm =
        (subFormKeysToUse().includes("adverseCreditHistory") && defaultQuestion) ||
        (formsKeyToUse().includes("serviceability") && ["increasedAmounts", "reducedAmounts"].includes(fieldName))

      let value = optionData?.find(e => compare(e?.value, answer))
      if (isMultipleSelect) {
        if (Array.isArray(answer)) {
          value = answer.reduce((acc, x) => {
            const foundIndex = optionData.findIndex(y => y?.value === x)
            if (foundIndex > -1) {
              acc.push(optionData[foundIndex])
            }
            return acc
          }, [])
        }
      }

      return (
        <div
          className={`input-label ${checkFormAndSubFOrm && "display-in-grid"} ${type === "regoState" &&
            isFromAssetPage &&
            "regostate"}`}
        >
          <CustomSelect
            name={fieldName}
            options={optionData}
            value={value}
            isRemoveAns={removeAns}
            isLoading={isFetchingGlassGuideData}
            isDisabled={!editable}
            isCheckPosition={!selectBoxDefault.includes(fieldName)}
            placeholder={titleWithRequired}
            isShowEditWarning={showEditWarning}
            onFieldChange={handleFieldChange}
            isMulti={isMultipleSelect}
            className={`answer-custom-select ${!answer && isQuoteField ? "redField" : ""} ${
              errorMessage ? "input-has-value-or-focused border-error" : ""
            } ${!editable ? "disabledfield" : ""}`}
            onChangeCustomSelect={e => {
              let assetAgeAnswer = ""

              if (isAssetModel) {
                if (isFromAssetPage) {
                  assetAgeAnswer = AssetFields?.find?.(x =>
                    ["nonGlassGuideAssetAge", "assetAge", "otherAssetYear"].includes(x?.fieldName),
                  )?.answer
                  onFieldUpdate(
                    `${fieldName}@test@nvic@test@assetDescription`,
                    `${e?.value}@test@${e?.nvic}@test@${assetAgeAnswer || ""} ${e?.value || ""}`,
                  )
                } else if (isFromBrokerPage) {
                  handleFieldUpdate(e?.value, e?.label)
                } else if (isFromBrokerPageNew) {
                  handleFieldUpdate(e?.value, e?.label)
                } else {
                  assetAgeAnswer = fieldsToUse()
                    ?.flat()
                    ?.find?.(x => ["nonGlassGuideAssetAge", "assetAge", "otherAssetYear"].includes(x?.fieldName))
                    ?.answer
                  onFieldUpdate(fieldName, e?.value || "", "")
                  onFieldUpdate("nvic", e?.nvic || "", "")
                  if (assetAgeAnswer || e?.value) {
                    onFieldUpdate("assetDescription", `${assetAgeAnswer || ""} ${e?.value || ""}`, "")
                  }
                }
              } else {
                let multiValue = e?.value ?? ""
                let multiLabel = e?.label ?? ""
                if (isMultipleSelect) {
                  const noneAnswer = e?.find?.(x => ["None"].includes(x?.value))
                  if (noneAnswer !== undefined) {
                    multiValue = ["None"]
                  } else {
                    multiValue = e?.map?.(x => isValidAnswer(x?.value))?.filter?.(x => isValidAnswer(x)) || []
                    multiLabel = e?.map?.(x => isValidAnswer(x?.label))?.filter?.(x => isValidAnswer(x)) || []
                  }
                }
                if (fieldName === "assetManufacturerOptions") {
                  handleFieldUpdate(multiValue, multiLabel)
                } else {
                  handleFieldUpdate(multiValue, e?.label)
                }
              }
            }}
          />
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "inlinetext": {
      return <>{answer && <div className="text-info small_width centerAlign">{`${answer}`}</div>}</>
    }
    case "search": {
      return (
        <div>
          <>
            <Input
              ref={searchRef}
              type={inputType}
              className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
                errorMessage ? "input-has-value-or-focused border-error" : ""
              }`}
              name={fieldName}
              value={answer}
              placeholder={titleWithRequired}
              onChange={e => handleFieldUpdate(e.target.value)}
              disabled={!editable}
              suffixClass="material-icons-round cursor-pointer quote-input-suffix"
              suffix="search"
              suffixClick={handleSearchSuffixClick}
              isShowEditWarning={showEditWarning}
              onFieldChange={handleFieldChange}
              minLength={minLimit}
              maxLength={maxLimit}
              onKeyDown={e => {
                if (e?.key === "Enter") {
                  isFromBrokerPage || isFromBrokerPageNew
                    ? handleSearch(currentForm, fieldName, answer, request)
                    : handleSearch(fieldName, answer, request)
                }
              }}
            />
          </>
          {suggestion ? <div className="suggestion">{suggestion}</div> : ""}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "radio": {
      return (
        <div className="form-checkbox-question">
          {titleWithRequired}
          <div className="d-flex">
            {options?.map((option, index) => (
              <React.Fragment key={Math.random()}>
                <RadioButton
                  name={fieldName}
                  disabled={!editable}
                  id={`${Math.random() + index}`}
                  label={option}
                  value={option}
                  checked={answer === option}
                  isChecked={answer === option}
                  onChange={e => {
                    handleFieldUpdate(e.target.value)
                  }}
                />
              </React.Fragment>
            ))}
            {/* {showEditWarning && (
              <span className="material-icons-outlined" onClick={handleFieldChange}>
                edit
              </span>
            )} */}
          </div>

          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "file": {
      return (
        <div>
          <FileUpload
            key={fieldName}
            multiple={numberOfFiles !== 1}
            maxFiles={numberOfFiles}
            name={fieldName}
            subFormsKey={subFormKeysToUse(0)}
            formsKey={formsKeyToUse(0)}
            maxSize={fileSize}
            fromEdit={false}
            onUpload={handleFileFieldUpdate}
            label={titleWithRequired}
            id={query.get(APPLICATION_ID_QUERY_PARAM)}
            value={answer}
            errorMessage={errorMessage}
          />
          {/* {showEditWarning && (
            <span className="material-icons-outlined" onClick={handleFieldChange}>
              edit
            </span>
          )} */}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "phone": {
      return (
        <div>
          <CountrySelect
            className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
              errorMessage ? "input-has-value-or-focused border-error" : ""
            }`}
            countryCode={countryAustralia.code}
            disabled={!editable}
            phoneNumber={answer || ""}
            placeholder={titleWithRequired}
            updatePhoneNumber={handleFieldUpdate}
            updateErrorMessage={handleErrorMessage}
            inputType={inputType}
            fieldName={fieldName}
            error={errors.mobileNumber}
            question={question}
            handleBlur={phone => {
              formatPhoneNumber(phone)
            }}
          />
          {/* {showEditWarning && (
            <span className="material-icons-outlined" onClick={handleFieldChange}>
              edit
            </span>
          )} */}
        </div>
      )
    }
    case "email": {
      return (
        <div>
          <Input
            type="email"
            className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
              errorMessage ? "input-has-value-or-focused border-error" : ""
            }`}
            name={fieldName}
            value={answer || ""}
            disabled={!editable}
            placeholder={titleWithRequired}
            isShowEditWarning={showEditWarning}
            onFieldChange={handleFieldChange}
            onChange={e => {
              const value = e.target.value
              handleFieldUpdate(value)
            }}
          />
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "website": {
      return (
        <div>
          <Input
            type="url"
            className={`answer-input ${!answer && isQuoteField ? "redField" : ""} ${
              errorMessage ? "input-has-value-or-focused border-error" : ""
            }`}
            name={fieldName}
            value={answer || ""}
            disabled={!editable}
            placeholder={titleWithRequired}
            isShowEditWarning={showEditWarning}
            onFieldChange={handleFieldChange}
            onChange={e => {
              const value = e.target.value
              handleFieldUpdate(value)
            }}
          />
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "checkbox": {
      if (isFromBrokerPageNew && fieldName.includes("skip")) {
        const formDataFields = _.get(formData, currentForm)
        const fieldForDisable = formDataFields?.length > 0 && formDataFields.find((field, index) => index === 0)
        editable = !fieldForDisable?.answer
      }
      if (isFromBrokerPage && fieldName.includes("skip")) {
        const formDataFields = _.get(formData, currentForm)
        const fieldForDisable = formDataFields?.length > 0 && formDataFields.find((field, index) => index === 0)
        editable = !fieldForDisable?.answer
      }
      return (
        <div className="form-checkbox-question">
          <CheckBox
            title={titleWithRequired}
            name={fieldName}
            checked={answer}
            disabled={!editable}
            onChange={e => handleFieldUpdate(e.target.checked)}
            className={suggestion ? "" : "d-flex align-center"}
          />
          {/* {showEditWarning && (
            <span className="material-icons-outlined" onClick={handleFieldChange}>
              edit
            </span>
          )} */}
          {suggestion && <div className="suggestion">{suggestion}</div>}
          {errorMessage ? <div className="application-error-message">{errorMessageDOM}</div> : ""}
        </div>
      )
    }
    case "address": {
      if (!formWrapperProps) return <></>
      return (
        <div className="multiple-questions">
          <div className="title"> {titleWithRequired}</div>
          <div>
            <FormWrapper type={type?.toLowerCase()} {...formWrapperProps} title={null} formFields={fields} />
          </div>
        </div>
      )
    }
    case "divider":
      return <div className="divider-text centerAlign">{dividerText ?? ""}</div>

    case "multiplequestion": {
      if (!formWrapperProps) return <></>
      return (
        <div className="multiple-questions">
          <div className="title"> {titleWithRequired}</div>
          <div>
            <FormWrapper {...formWrapperProps} title={null} formFields={fields} />
          </div>
        </div>
      )
    }
    // case "signature": {
    //   return (
    //     <div className="signature-wrapper">
    //       <div className="title align-center">{question}</div>
    //       <SignatureCanvas
    //         ref={sigPad}
    //         name={fieldName}
    //         penColor="black"
    //         canvasProps={{
    //           ...signaturePadHeightAndWidth,
    //           className: "sigCanvas",
    //         }}
    //         onEnd={_.debounce(saveSignature, 500)}
    //       />
    //       {errorMessageDOM && <div className="signature-error-message">{errorMessage}</div>}
    //       <div className="signature-buttons">
    //         <Button
    //           key={"clear"}
    //           buttonType={"secondary"}
    //           className="button secondary-button"
    //           onClick={removeSignature}
    //           title={"Clear Signature"}
    //         />
    //       </div>

    //       {suggestion && <div className="suggestion">{suggestion}</div>}
    //     </div>
    //   )
    // }
    case "formula": {
      return (
        <>
          <ApplicationFormula
            formulaName={formulaName}
            fieldName={fieldName}
            answer={`${answer}`}
            inputType={inputType}
            titleWithRequired={titleWithRequired}
            handleFieldUpdate={handleFieldUpdate}
            indexForRepeater={indexForRepeater}
            isHide={hide}
            isEditable={editable}
            isAmount={isAmount}
            isShowGrayedOut={showGrayedOut}
            errorMessage={errorMessage}
            errorMessageDOM={errorMessageDOM}
            minDate={minDate}
            suggestion={suggestion}
            isFromBrokerPage={isFromBrokerPage}
            isFromBrokerPageNew={isFromBrokerPageNew}
            currentForm={currentForm}
            isQuoteField={isQuoteField}
          />
        </>
      )
    }
    default:
      return <b>Missing case for type {type}</b>
  }
}

export const RenderField = React.memo(RenderFieldMemo, (prevProps, nextProps) => _.isEqual(prevProps, nextProps))

RenderFieldMemo.propTypes = {
  // field to show
  field: PropTypes.object,
  // path of from in applicationJSONData
  formPath: PropTypes.array,
  AssetFields: PropTypes.object,
  formWrapperProps: PropTypes.object,
  // call when field changes
  onFieldUpdate: PropTypes.func,
  errorMessage: PropTypes.string,
  pathOfField: PropTypes.string,
  handleSearch: PropTypes.func,
  isFromAssetPage: PropTypes.bool,
  isFromBrokerPage: PropTypes.bool,
  isFromBrokerPageNew: PropTypes.bool,
  indexForRepeater: PropTypes.number,
  isShowSeparate: PropTypes.bool,
  currentForm: PropTypes.array,
  autoFillFieldsRef: PropTypes.object,
  minDate: PropTypes.string,
}
