import _ from "lodash"
import {
  checkFieldVisibilityCondition,
  checkNextQuestionCondition,
  getDefaultQuestions,
  getNextQuestionsFromAnswer,
  isValidAnswer,
} from "../../../helpers/applicationHelper"

export default class FieldsToKeep {
  constructor(formDataFields, jsonFields, formData, currentForm, isSyncing = false) {
    this.formDataFields = formDataFields
    this.jsonFields = jsonFields
    this.formData = formData
    this.currentForm = currentForm
    this.isSyncing = isSyncing
    //  logLodashData(currentForm)
    this.keysOfFieldsToKeep = this.fieldsKeyToKeep()

    // console.log('keyoff', this.keysOfFieldsToKeep);

    this.fieldsToKeep = []

    if (this.formDataFields?.length > 0 && this.keysOfFieldsToKeep?.length > 0)
      this.fieldsToKeep = this.jsonFields.reduce((acc, jsonField) => {
        // console.log('jsonField', jsonField.key);

        if (this.keysOfFieldsToKeep.includes(jsonField?.key)) {
          const formDataField = this.formDataFields.find(x => x?.key === jsonField?.key)
          const field = this.filterFieldsWithFieldVisibility(_.cloneDeep(formDataField || jsonField))
          if (field) acc.push(field)
          // acc.push(field);
        }

        return acc
      }, [])

    // console.log('fieldsToKeep', this.fieldsToKeep);
  }

  filterFieldsWithFieldVisibility(field) {
    if (Array.isArray(field?.fieldVisibility) && field?.fieldVisibility?.length > 0) {
      if (this.isFieldAllowed(field)) return field
    } else if (Array.isArray(field?.fields) && field?.fields?.length > 0) {
      field.fields.forEach((x, i) => {
        if (Array.isArray(x) && x?.length > 0) {
          x.forEach((y, j) => {
            if (y?.fieldVisibility && y?.fieldVisibility?.length > 0 && !this.isFieldAllowed(y)) {
              field.fields[i].splice(j, 1)
            }
          })
        }
      })
      return field
    } else {
      return field
    }
  }

  fieldsKeyToKeep() {
    if (Array.isArray(this.formDataFields) && this.formDataFields?.length > 0) {
      // logLodashData( this.formDataFields )

      const _reduce = this.formDataFields.reduce((acc, x, i, self) => {
        if (x?.answer) {
          acc.push(x?.key)
        } else if (x?.defaultQuestion && this.isFieldAllowed(x)) {
          acc.push(x?.key)
        }

        if (acc.includes(x?.key) || isValidAnswer(x?.answer)) {
          const nextQuestions = getNextQuestionsFromAnswer(x?.nextQuestions, x?.answer)

          /* console.log('nextQuestion', nextQuestions, x?.question) */

          if (nextQuestions && checkNextQuestionCondition(x)) {
            acc.push(...nextQuestions)
          }
        }

        if (this.isSyncing) {
          const defaultQuestions = getDefaultQuestions(x?.defaultQuestions)
          if (defaultQuestions) {
            acc.push(...defaultQuestions)
          }
        }

        // console.log('acc', acc);

        return acc
      }, [])

      return _.uniq(_reduce)
    } else {
      return []
    }
  }

  isFieldAllowed(field) {
    if (Array.isArray(field?.fieldVisibility) && field?.fieldVisibility?.length > 0) {
      const savedFormDataNew = _.cloneDeep(this.formData)
      if (["asset1"].includes(this.currentForm[0])) {
        _.update(savedFormDataNew, `${this.currentForm[0]}.assetDetails[1].answer`, () => {
          // TODO Aakash: DONT KNOW
          // if (fieldsToShow?.[0]?.[1]?.fieldName === "assetType") {
          //   return fieldsToShow?.[0]?.[1]?.answer
          // } else {
          return _.get(this.formData, `${this.currentForm[0]}.assetDetails[1].answer`)
          // }
        })
      }
      if (["asset2", "asset3", "asset4"].includes(this.currentForm[0])) {
        _.update(savedFormDataNew, `${this.currentForm[0]}.assetDetails[1]`, () => {
          // TODO Aakash: DONT KNOW
          // if (fieldsToShow?.[1]?.[1]?.fieldName === "assetType") {
          //   return fieldsToShow?.[1]?.[1]
          // } else {
          return _.get(this.formData, `${this.currentForm[0]}.assetDetails[1]`)
          // }
        })
      }
      return checkFieldVisibilityCondition(field, savedFormDataNew, this.formDataFields)
    }

    return isValidAnswer(field?.answer) || field?.defaultQuestion
  }
}
