export const addQueryParameter = (name, value) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set(name, value)
  const newUrl = window.location.pathname + "?" + urlSearchParams.toString()
  window.history.pushState({ path: newUrl }, "", newUrl)
}

export const getQueryParams = queryParamKey => {
  let queryString = window.location.search

  if (queryString[0] === "?") {
    queryString = queryString.substr(1, queryString.length)
  }

  const urlSearchParams = queryString.split("&")

  for (const query of urlSearchParams) {
    const [key, value] = query.split("=")

    if (key === queryParamKey && value.length > 0) return value
  }

  // return returnValue;

  // const urlSearchParams = new URLSearchParams(window.location.search);

  /* 
  for (const [key, value] of urlSearchParams) {

    if (queryParamKey === key) return value
  } */
}
