import PropTypes from "prop-types"

const CheckBox = props => {
  const { title, className, ...restProps } = props
  const checkboxClasses = `${className}`
  return (
    <div className={checkboxClasses} onClick={e => e.stopPropagation()}>
      <label className="checkbox-container align-left" style={{ paddingLeft: title ? "25px" : "24px" }}>
        {title}
        <input type="checkbox" {...restProps} />
        <span className="checkmark" />
      </label>
    </div>
  )
}

CheckBox.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

CheckBox.defaultProps = {
  title: "",
  className: "",
}
export default CheckBox
