import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Input } from "../../../common"
import Button from "../../../common/Button/Button"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { getUserProfile, handleProfileFieldChange, ProfileSaveData } from "../../../common/Header/Redux/headerAction"
import { onlyAlphabets } from "../../../helpers/ValidationHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import ChangeEmailModal from "./EditProfileChangeEmailModal"
import { useMemo, useState } from "react"
import { successNotification } from "../../../common/NotifyToaster"
import ChangePhoneModal from "./EditProfileChangePhoneModal"

const EditProfile = () => {
  const { firstName, firstNameCopy, lastNameCopy, lastName, userProfile, errors } = useSelector(
    ({ headerReducer }) => headerReducer ?? {},
  )
  const [isEmailModal, setIsEmailModal] = useState(false)
  const [isPhoneModal, setIsPhoneModal] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const isButtonDisabled = useMemo(() => firstName === firstNameCopy && lastName === lastNameCopy, [
    firstName,
    firstNameCopy,
    lastName,
    lastNameCopy,
  ])

  const handleFieldChange = e => {
    const { name, value } = e?.target
    dispatch(handleProfileFieldChange(name, value.trim()))
  }
  const handleProfileButtonClick = async () => {
    const profileErrors = {}

    const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/

    if (!firstNameCopy || !firstNameCopy.length) profileErrors.firstName = "Please enter first name"
    if (firstNameCopy && !onlyAlphabets(firstNameCopy)) profileErrors.firstName = "Please enter valid first name"
    if (firstNameCopy && format.test(firstNameCopy)) profileErrors.firstName = "Please enter valid first name"
    if (!lastNameCopy || !lastNameCopy.length) profileErrors.lastName = "Please enter last name"
    if (lastNameCopy && !onlyAlphabets(lastNameCopy)) profileErrors.lastName = "Please enter valid last name"
    if (lastNameCopy && format.test(lastNameCopy)) profileErrors.lastName = "Please enter valid last name"
    if (_.isEmpty(profileErrors)) {
      dispatch(handleProfileFieldChange("errors", {}))
      const data = {
        lastName: lastNameCopy.trim(),
        firstName: firstNameCopy.trim(),
      }
      await dispatch(ProfileSaveData(data))
      dispatch(getUserProfile())
      history.push(`${ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE}`)
    } else {
      dispatch(handleProfileFieldChange("errors", profileErrors))
    }
  }

  const handleEmailClose = email => {
    if (email) {
      dispatch(handleProfileFieldChange("userProfile", { ...userProfile, email: email?.trim() }))
      successNotification("Email address updated successfully")
    }
    setIsEmailModal(false)
  }

  const handlePhoneClose = (selectedCountry, phone) => {
    if (selectedCountry && phone) {
      dispatch(
        handleProfileFieldChange("userProfile", {
          ...userProfile,
          countryCode: selectedCountry,
          mobileNumber: phone,
        }),
      )
      successNotification("Mobile number updated successfully")
    }
    setIsPhoneModal(false)
  }

  return (
    <>
      <div className="page-header-row">
        <div className="content-header">Edit Profile</div>
      </div>
      <div>
        <div className="profile-wrapper">
          <div className="input-field-wrapper">
            <Input
              type="text"
              placeholder="First Name*"
              name="firstNameCopy"
              value={firstNameCopy}
              onChange={handleFieldChange}
              error={errors.firstName}
            />
          </div>
          <div className="input-field-wrapper">
            <Input
              type="text"
              placeholder="Last Name*"
              name="lastNameCopy"
              value={lastNameCopy}
              onChange={handleFieldChange}
              error={errors.lastName}
            />
          </div>
          <div className="input-field-wrapper">
            <Input
              type="email"
              placeholder="Email address *"
              name="emailAddress"
              value={userProfile?.email}
              disabled={true}
              suffix="edit"
              suffixClass="material-icons-outlined font-primary"
              suffixClick={() => setIsEmailModal(true)}
            />
          </div>
          <div className="input-field-wrapper">
            <CountrySelect
              countryCode={userProfile?.countryCode ? userProfile?.countryCode : ""}
              countryName={userProfile?.countryCode}
              phoneNumber={userProfile?.mobileNumber}
              placeholder="Mobile number*"
              className="w-100"
              isDisabled
              suffix="edit"
              suffixClass="material-icons-outlined font-primary"
              suffixClick={() => setIsPhoneModal(true)}
            />
          </div>
        </div>
        <div className="back-save-buttons">
          <Button
            buttonType="secondary"
            title="Back"
            className="form-button"
            onClick={() => history.push(`${ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE}`)}
          />
          <Button
            buttonType="secondary"
            title="Save"
            className="form-button"
            onClick={() => handleProfileButtonClick()}
            isDisabled={isButtonDisabled}
          />
        </div>
      </div>
      <ChangeEmailModal onClose={handleEmailClose} isOpen={isEmailModal} />
      <ChangePhoneModal onClose={handlePhoneClose} isOpen={isPhoneModal} />
    </>
  )
}
export default EditProfile
