import { set } from "lodash"
import { checkFieldVisibilityCondition, getCloneDeep, getNextQuestionsFromAnswer, isObject } from "./applicationHelper"

export class BeneficiaryChange {
  changeSubformKey(subFormKeys) {
    if (Array.isArray(subFormKeys) && subFormKeys?.length > 0) {
      return subFormKeys?.map(subForm => {
        if (subForm?.includes?.("beneficiary") && !subForm?.includes?.("beneficiaryData")) {
          return `${subForm}.beneficiaryData`
        }
        return subForm
      })
    }
    return subFormKeys
  }

  changeFormName(dataForActiveSave, originalData, path) {
    Object.entries(dataForActiveSave).forEach(([key, value]) => {
      if (key === "beneficiaryData") {
        const insideValue = value.formName
        value.formName = getCloneDeep(originalData, `${path}.formName`)
        set(originalData, `${path}.formName`, insideValue)
      } else {
        if (isObject(value)) this.changeFormName(value, originalData, path ? `${path}.${key}` : key)
      }
    })
  }

  structureFormsKey(forms) {
    return forms.map(form => {
      if (
        form?.innerForm &&
        ["beneficiary1", "beneficiary2", "beneficiary3", "beneficiary4"]?.includes(form?.innerForm)
      ) {
        return { nextForm: form?.nextForm, innerForm: `${form?.innerForm}.beneficiaryData` }
      }
      return form
    })
  }

  structureChange(path, value, allData, callback) {
    let pathToWorkOn = path.split(".").reduce((acc, x) => {
      if (x.includes("[") && x.includes("]")) {
        // if its array join path by . to generate path to formName
        // return data inside it
        const dataPath = [...acc, x].join(".")
        const lastData = getCloneDeep(allData, `${dataPath}.formName`, x)
        return [...acc, lastData]
      }
      // if its not array return default data
      return [...acc, x]
    }, [])
    pathToWorkOn = pathToWorkOn.join(".")
    const newPath = pathToWorkOn.replace(/beneficiaryDetails.beneficiaryData.*/, `${value}.beneficiaryData`)
    const deletePath = pathToWorkOn.replace(/\.beneficiaryData.*/, "")
    callback(newPath, deletePath)
  }
}

export class AssetChange {
  changePath(path) {
    if (Array.isArray(path) && path.length > 0) {
      const includesAsset1To4 = path.reduce((acc, x) => {
        if (!acc) {
          acc = ["asset1", "asset2", "asset3", "asset4"].includes(x)
        }
        return acc
      }, false)
      if (path.includes("assets") && includesAsset1To4) {
        const index = path.findIndex(x => x === "assets")
        if (index > -1) {
          path.splice(index, 1)
        }
      }
    }
    return path
  }

  typeCastAssetAge(k, v) {
    if (k === "assetAge" && typeof v === "number") {
      return v.toString()
    }
    return v
  }
}

export class ReferencesChange {
  structureChange(path, allData, value, callback) {
    let newPath = path.split(".").reduce((acc, x) => {
      if (x?.includes("applicantDetails") && x.includes("[") && x.includes("]")) {
        // if its array join path by . to generate path to formName
        // return data inside it
        const dataPath = [...acc, x].join(".")
        const lastData = getCloneDeep(allData, `${dataPath}.formName`, x)
        return [...acc, lastData]
      }
      // if its not array return default data
      return [...acc, x]
    }, [])
    newPath = newPath.join(".").replace(/references\.referencesData\[.\]/, value)
    callback(newPath)
  }
}

export class MultiFieldWithSameName {
  duplicateFieldNameCommon(finalData, path, currentField, dependentFieldName) {
    const finalDataWithpath = getCloneDeep(finalData, path)
    const applicantField =
      Array.isArray(finalDataWithpath) &&
      finalDataWithpath?.length > 0 &&
      finalDataWithpath.find(x => x?.fieldName === dependentFieldName)
    if (applicantField?.answer && applicantField?.nextQuestions) {
      const nextQ = getNextQuestionsFromAnswer(applicantField?.nextQuestions, applicantField?.answer)
      if (nextQ?.length > 0) {
        return nextQ.includes(currentField?.key)
      }
    }
  }

  companyStructureBasedOnFieldVisibility(finalData, path, currentField) {
    const finalDataWithPath = getCloneDeep(finalData, path)
    const isFieldVisible = checkFieldVisibilityCondition(currentField, finalData, finalDataWithPath)
    return isFieldVisible
  }

  employerStartDate(apiData, applicationJSON, path, currentField) {
    const answer = getCloneDeep(apiData, "employmentType")
    const finalDataWithpath = getCloneDeep(applicationJSON, `${path}.fields`)
    const employmentTypeField =
      Array.isArray(finalDataWithpath) &&
      finalDataWithpath?.length > 0 &&
      finalDataWithpath.find(x => x?.fieldName === "employmentType")
    if (answer && employmentTypeField?.nextQuestions) {
      const nextQ = getNextQuestionsFromAnswer(employmentTypeField?.nextQuestions, answer)
      if (nextQ?.length > 0) {
        return nextQ.includes(currentField?.key)
      }
    }
  }
}

export class AssetCommonQuestionChange {
  structureChange(path, key, allData) {
    let newPath = path.split(".").reduce((acc, x) => {
      if (x.includes("[") && x.includes("]")) {
        // if its array join path by . to generate path to formName
        // return data inside it
        const dataPath = [...acc, x].join(".")
        const lastData = getCloneDeep(allData, `${dataPath}.formName`, x)
        return [...acc, lastData]
      }
      // if its not array return default data
      return [...acc, x]
    }, [])
    newPath = newPath.slice(1, -1)
    newPath.push("commonQuestion")
    newPath.push(key)
    return newPath.join(".")
  }
}
