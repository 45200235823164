import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { handleOtherQuestionOnSamePage } from "../../redux/QuoteActions"
import GetQuoteComponent from "../GetQuoteComponent"
import MultiFieldForm from "../MultiFieldForm"

const Answer = props => {
  const dispatch = useDispatch()
  const { suggestion, error } = props ?? {}
  const { currentQuoteData, quoteAnswersData, currentForm } = useSelector(({ quoteReducer }) => quoteReducer ?? {})

  const answer = quoteAnswersData?.[currentForm]

  useEffect(() => {
    if (
      ["Other", "Visa", "Yes"]?.includes(answer?.[currentQuoteData?.fieldName]) &&
      currentQuoteData?.otherOnSamePage &&
      !currentQuoteData?.otherField
    ) {
      dispatch(handleOtherQuestionOnSamePage())
    }
  }, [currentQuoteData?.otherOnSamePage, answer, currentQuoteData?.otherField])

  return (
    <React.Fragment>
      {currentQuoteData?.type === "multipleQuestions" ? (
        <MultiFieldForm error={error} />
      ) : (
        <>
          <GetQuoteComponent data={currentQuoteData} isOtherField={false} error={error} />
          {currentQuoteData?.otherField && (
            <GetQuoteComponent data={currentQuoteData?.otherField} isOtherField={true} error={error} />
          )}
        </>
      )}
      {suggestion && <p className="suggestion">{suggestion}</p>}
    </React.Fragment>
  )
}

export default React.memo(Answer)
