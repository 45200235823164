import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const Input = forwardRef((props, ref) => {
  const {
    prefix,
    prefixType,
    prefixClass,
    suffix,
    suffixClick,
    suffixClass,
    placeholder,
    type,
    inputClassName,
    className,
    onFocus,
    onBlur,
    isCountryInput,
    error,
    isShowEditWarning,
    onFieldChange,
    ...restProps
  } = props

  const [focused, setFocused] = React.useState(false)

  const handleOnFocus = e => {
    setFocused(true)
    onFocus(e)
  }

  const handleOnBlur = e => {
    setFocused(false)
    onBlur(e)
  }

  const inputClass = `${inputClassName}`
  const prefixClassName = `prefix ${prefixClass}`
  const suffixClassName = `suffix ${suffixClass}`
  const inputBorderClass = `input-container ${focused &&
    !isCountryInput &&
    "input-has-value-or-focused border-secondary"} ${!focused &&
    restProps?.value?.toString()?.trim()?.length > 0 &&
    !isCountryInput &&
    "input-not-focused-has-text border-primary"} ${restProps?.value?.toString()?.trim()?.length > 0 &&
    !isCountryInput &&
    "input-has-value-or-focused"} ${className}`

  return (
    <>
      <div className={`${inputBorderClass} ${error && "input-has-value-or-focused border-error border-error"}`}>
        {prefix && prefixType === "icon" && <span className={prefixClassName}>{prefix}</span>}
        {!isCountryInput && <label className="input-label">{placeholder}</label>}
        <input
          ref={ref}
          placeholder={isCountryInput ? placeholder : ""}
          autoComplete="off"
          type={type}
          className={inputClass}
          onFocus={handleOnFocus}
          onBlur={e => handleOnBlur(e)}
          {...restProps}
        />
        {/* {isShowEditWarning && (
          <span className="material-icons-outlined" onClick={() => onFieldChange()}>
            edit
          </span>
        )} */}
        {suffix && (
          <span className={suffixClassName} onClick={() => suffixClick(ref?.current)}>
            {suffix}
          </span>
        )}
      </div>
      {error && <span className="error-text">{error}</span>}
    </>
  )
})

Input.propTypes = {
  value: PropTypes.string,
  prefix: PropTypes.string,
  prefixType: PropTypes.oneOf(["icon", ""]),
  prefixClass: PropTypes.string,
  suffix: PropTypes.string,
  suffixClick: PropTypes.func,
  suffixClass: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  isCountryInput: PropTypes.bool,
  isShowEditWarning: PropTypes.bool,
  onFieldChange: PropTypes.func,
  onFocus: () => {},
  onBlur: () => {},
  error: PropTypes.string,
}

Input.defaultProps = {
  value: "",
  prefix: "",
  prefixType: "",
  prefixClass: "",
  suffix: "",
  type: "text",
  suffixClick: () => {},
  suffixClass: "",
  className: "",
  inputClassName: "",
  isCountryInput: false,
  isShowEditWarning: false,
  onFocus: () => {},
  onBlur: () => {},
  error: "",
}
Input.displayName = "Input"

export default Input
