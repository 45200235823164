import { HEADER_REDUX_CONSTANTS } from "./headerConstants"

const initialState = {
  firstName: "",
  firstNameCopy: "",
  lastName: "",
  lastNameCopy: "",
  emailAddress: "",
  mobileNumber: "",
  countryCode: "",
  countryName: {
    label: "🇦🇺 +61",
    value: {
      name: "Australia",
      cca2: "AU",
      flag: "🇦🇺",
      code: "+61",
    },
  },
  errors: {},
}

export const headerReducer = (state = initialState, action) => {
  switch (action?.type) {
    case HEADER_REDUX_CONSTANTS.USER_PROFILE:
      return {
        ...state,
        userProfile: action?.data,
        firstName: action?.data?.firstName,
        lastName: action?.data?.lastName,
        firstNameCopy: action?.data?.firstName,
        lastNameCopy: action?.data?.lastName,
        emailAddress: action?.data?.emailAddress,
      }
    case HEADER_REDUX_CONSTANTS.PROFILE_FIELD_CHANGE:
      return {
        ...state,
        [action?.fieldName]: action?.fieldValue,
      }
    default:
      return state
  }
}
