export const checkApplicationFormCount = (dataForActiveSave, formPaths, formsKey, subFormsKey) => {
  let numOfFormsFilled = dataForActiveSave !== undefined ? Object.keys(dataForActiveSave).length : 1
  const totalFormSize = formPaths !== undefined ? formPaths.length : 0
  if (formPaths !== undefined) {
    const index = formPaths.findIndex(object => {
      return object.nextForm === formsKey["0"] && object.innerForm === subFormsKey["0"]
    })
    numOfFormsFilled = index + 1
  }
  let getPercentage = 0
  if (totalFormSize !== 0) {
    getPercentage = Math.round((numOfFormsFilled * 100) / totalFormSize)
    if (formsKey["0"] === "disclosure") {
      getPercentage = 98
    }
  }

  return getPercentage
}
