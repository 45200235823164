import RateCardFilters from "./RateCardFilters"
import QuickAccessPopup from "../../../common/QuickAccessPopup"
import PropTypes from "prop-types"
import { Fragment } from "react"

const RateCardSummary = ({ summary }) => {
  return (
    <>
      <section className="summary">
        <div>
          <div className="font-primary summary-title">Your Summary</div>
        </div>
        <div className="summary-main">
          {summary?.length &&
            summary?.map(e =>
              e?.value ? (
                <Fragment key={e.value}>
                  <RateCardFilters label={e?.value} icon={e?.icon} />
                </Fragment>
              ) : null,
            )}
        </div>
      </section>
      <QuickAccessPopup
        className="rate-summary-quick-access"
        title="Your Summary"
        iconActive="close"
        iconInActive="description"
      >
        <section className="summary-main">
          {summary?.length &&
            summary?.map(e =>
              e?.value ? (
                <Fragment key={e.value}>
                  <RateCardFilters label={e?.value} icon={e?.icon} />
                </Fragment>
              ) : null,
            )}
        </section>
      </QuickAccessPopup>
    </>
  )
}

export default RateCardSummary

RateCardSummary.propTypes = {
  summary: PropTypes.array.isRequired,
}
