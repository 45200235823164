import React from "react"
import PropTypes from "prop-types"

const Question = props => {
  const { question, toolTipText, description, isFromMulti } = props ?? {}

  return (
    <div className="question">
      {question && (
        <div
          // eslint-disable-next-line react/no-unknown-property
          toolTipTitle={toolTipText}
          className={`question_text ${toolTipText && "tooltip"} ${isFromMulti ? "question-text-multi" : ""}`}
        >
          {question}
        </div>
      )}
      {description && <div className="description">{description}</div>}
    </div>
  )
}

Question.propTypes = {
  question: PropTypes.string,
  description: PropTypes.string,
  isFromMulti: PropTypes.bool,
}
export default React.memo(Question)
