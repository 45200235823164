import dayjs from "dayjs"
import AccordionItem from "../../../common/Accordion/AccordionItem"
import { DATE_FORMAT } from "../../../constants/regexConstants"
import { isAnswer } from "../../../helpers/applicationHelper"
import { CommaSeperator, fileNameSeparator, NumberCommaSeparator } from "../../../helpers/NumberCommaSeparator"

const ViewApplicationSingle = props => {
  const { data, index, formkey, formName, applicationJsonData, isFromBroker, isFromBrokerNew } = props || {}

  const showFieldValue = (answer, type) => {
    if (type !== "" && type === "Amount") {
      return `$ ${NumberCommaSeparator(answer)}`
    } else if (type !== "" && type === "Comma") {
      return `${CommaSeperator(answer)}`
    } else if (type !== "" && type === "File") {
      return `${fileNameSeparator(answer)}`
    } else if (type !== "" && type === "Percentage") {
      return answer + "%"
    } else {
      if (typeof answer === "string") {
        const includeWord = answer?.includes("T00")
        if (includeWord) {
          const answerFirstVal = answer?.split("T00")
          return dayjs(answerFirstVal[0]).format(DATE_FORMAT.DDMMYYYY)
        }
      }
      return answer.toString()
    }
  }
  const getSplittedData = data => {
    const firstPath = data?.split("__test__")
    return firstPath[0]
  }
  const getDatafromObject = (object, form) => {
    if (form === "fullName") {
      return Object.keys(object).map((formDataInner, indexInner) => {
        return (
          <div key={indexInner} className="quote-answer">
            <div className="quote-question">{object[formDataInner]?.title}</div>
            <div className="quote-answer">{object[formDataInner]?.Answer.toString()}</div>
          </div>
        )
      })
    } else {
      return Object.keys(object).map((formDataInner, indexInner) => {
        if (formDataInner !== "title") {
          return (
            <div key={indexInner} className="quote-answer">
              <div className="quote-question">{object[formDataInner]?.title}</div>
              <div className="quote-answer">{object[formDataInner]?.Answer.toString()}</div>
            </div>
          )
        }

        return ""
      })
    }
  }
  return (
    <>
      <AccordionItem
        index={index}
        accordionBodyClass="application-active-accordion-scroll"
        header={<label>{formName}</label>}
        prefixInActive="add"
        prefixActive="remove"
      >
        <div className="quote-main-container">
          {Object.keys(data).map((formData, index) => (
            <div className="quote-inner" key={index}>
              {data[formData]?.mainTitle && <h1>{data[formData].mainTitle}</h1>}
              <div className="quote-question">
                {["quoteDetails", "serviceability", "signatureDetails", "disclosure"].includes(formkey) ? (
                  <div className="quote-question">{data[formData]?.Question}</div>
                ) : (
                  <>
                    <h2>
                      {formData.includes("beneficiaryDetails")
                        ? "Beneficiary Detail"
                        : formData.includes("incomeAndOutgoings")
                        ? "Incoming and Outgoing Detail"
                        : formData.includes("references")
                        ? "References"
                        : data[formData]?.title !== "Form Name" && data[formData]?.Question}
                    </h2>
                  </>
                )}

                <div className="quote-answer">
                  {data[formData]?.Answer === true ? (
                    "Yes"
                  ) : data[formData]?.Answer && data[formData]?.Question === "Signature" ? (
                    <img src={`${getSplittedData(data[formData]?.Answer)}`}></img>
                  ) : (
                    data[formData]?.title !== "Form Name" &&
                    (data[formData]?.Answer === false
                      ? "No"
                      : data[formData]?.Type === "Amount"
                      ? `$ ${NumberCommaSeparator(data[formData]?.Answer)}`
                      : data[formData]?.Answer)
                  )}
                </div>
              </div>
              {Object.keys(data[formData]).map((formDataInner, indexInner) => {
                if (data?.[formData]?.[formDataInner]?.Answer === false) {
                  return (
                    <>
                      <div className="quote-question">
                        <h2>{data?.[formData]?.title}</h2>
                      </div>
                      <div className="quote-question">{data[formData][formDataInner].Question}</div>
                      <div className="quote-answer">No</div>
                    </>
                  )
                } else if (data?.[formData]?.[formDataInner]?.Answer === true) {
                  return (
                    <>
                      <div className="quote-question">
                        <h2>{data?.[formData]?.title}</h2>
                      </div>
                      <div className="quote-question">{data[formData][formDataInner].Question}</div>
                      <div className="quote-answer">Yes</div>
                    </>
                  )
                }
                if (formDataInner.includes("address")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerAddress, indexInnerAddress) => {
                    if (formDataInnerAddress !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerAddress}>
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerAddress]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerAddress]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerAddress]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerAddress]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                }
                if (
                  isFromBroker &&
                  !isFromBrokerNew &&
                  !Object.keys(data[formData]).includes("assetVendorDetails") &&
                  formDataInner.includes("formName") &&
                  !formDataInner.includes("assetOptions") &&
                  !formDataInner.includes("assetIdentifiersDetails") &&
                  !formDataInner.includes("assetOtherDetails") &&
                  !formDataInner.includes("assetPricingDetails") &&
                  !formDataInner.includes("assetGlassGuide")
                ) {
                  return (
                    <div className="quote-question" key={indexInner}>
                      <h1>{applicationJsonData?.[data[formData]?.formName?.Answer]?.label}</h1>
                    </div>
                  )
                } else if (formDataInner.includes("assetDetails")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      const titleNew = isFromBrokerNew && (
                        <div className="quote-question" key={indexInner}>
                          <h1>{applicationJsonData?.[data[formData]?.formName?.Answer]?.label}</h1>
                        </div>
                      )
                      title = (
                        <>
                          {titleNew}
                          <div className="quote-question" key={indexInnerSecond}>
                            <h2>{data[formData][formDataInner]?.title}</h2>
                          </div>
                        </>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetVendorDetails")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h1>{applicationJsonData?.[data[formData]?.formName?.Answer]?.label}</h1>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          {["vendorAddressDetails"].includes(formDataInnerSecond)
                            ? getDatafromObject(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond],
                                formDataInnerSecond,
                              )
                            : ""}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetOptions")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetIdentifiersDetails")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Types,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetOtherDetails")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetPricingDetails")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (formDataInner.includes("assetGlassGuide")) {
                  return Object.keys(data[formData][formDataInner]).map((formDataInnerSecond, indexInnerSecond) => {
                    let title = ""
                    if (indexInnerSecond === 0) {
                      title = (
                        <div className="quote-question" key={indexInnerSecond}>
                          <h2>{data[formData][formDataInner]?.title}</h2>
                        </div>
                      )
                    }
                    if (formDataInnerSecond !== "title") {
                      return (
                        <div className="quote-answer" key={indexInnerSecond}>
                          {title}
                          <div className="quote-question">
                            {data[formData][formDataInner][formDataInnerSecond]?.Question}
                          </div>
                          <div className="quote-answer">
                            {data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer &&
                              showFieldValue(
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Answer,
                                data?.[formData]?.[formDataInner]?.[formDataInnerSecond]?.Type,
                              )}
                          </div>
                        </div>
                      )
                    }
                    return ""
                  })
                } else if (
                  formDataInner !== "title" &&
                  !formDataInner.includes("address") &&
                  !formDataInner.includes("formName")
                ) {
                  let title = ""
                  if (
                    indexInner === 0 &&
                    !["quoteDetails", "serviceability", "signatureDetails", "disclosure"].includes(formkey) &&
                    data[formData]?.title !== "Form Name"
                  ) {
                    title = (
                      <div className="quote-question">
                        <h2>{data[formData]?.title}</h2>
                      </div>
                    )
                  }
                  return (
                    <div className="quote-answer" key={indexInner}>
                      {title}
                      <div className="quote-question">{data[formData][formDataInner]?.Question}</div>
                      <div className="quote-answer">
                        {["fullName", "employmentAddressDetails", "previousAddressDetails"].includes(formDataInner)
                          ? getDatafromObject(data?.[formData]?.[formDataInner], formDataInner)
                          : ""}
                        {isAnswer(data?.[formData]?.[formDataInner]?.Answer) &&
                          showFieldValue(
                            data?.[formData]?.[formDataInner]?.Answer,
                            data?.[formData]?.[formDataInner]?.Type,
                          )}
                      </div>
                    </div>
                  )
                }
                return ""
              })}
            </div>
          ))}
        </div>
      </AccordionItem>
    </>
  )
}
export default ViewApplicationSingle
