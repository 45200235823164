import ApiService from "../../../services/ApiServices"
import { RATE_CARD_URLS } from "../../../constants/UrlConstants"

const rateCardApiServices = {
  // getRateCard: quoteId => ApiService.getData(`${RATE_CARD_URLS.GET_RATE_CARD}`),
  getRateCard: (quoteId, rateFilter) => {
    let url = `${RATE_CARD_URLS.GET_RATE_CARD}${quoteId}`
    if (rateFilter) {
      url = url + `?filter=${rateFilter}`
    }
    return ApiService.getData(`${url}`)
  },
  getRateCardApplication: (applicationId, rateFilter) => {
    let url = `${RATE_CARD_URLS.GET_RATE_CARD_APPLICATION}${applicationId}`
    if (rateFilter) {
      url = url + `?filter=${rateFilter}`
    }
    return ApiService.getData(`${url}`)
  },
}
export default rateCardApiServices
