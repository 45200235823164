// eslint-disable-next-line import/prefer-default-export
export const NumberCommaSeparator = number => {
  return number?.toString()?.trim()?.length > 0 ? number?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : ""
}
export const CommaSeperator = value => {
  if (typeof value === "string" || typeof value === "number") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return value
  }
}

export const fileNameSeparator = value => {
  return value?.split("/")?.pop()
}
