import _ from "lodash"
import { REGEX } from "../../../constants/regexConstants"
import { isValidAnswer, isObject } from "../../../helpers/applicationHelper"

export const prepareBrokerDataForSave = data => {
  let objectToSave = {}

  objectToSave = Object.entries(data).reduce((preparedObject, [key, value]) => {
    /**
     * Possibilities
     * array -> fieldName, answer, fields
     * object -> obejct -> fieldName, answer, fields
     * fields -> fieldName, answer, fields
     */

    const objectWithModification = {
      ...preparedObject,
    }
    const preaparedKeys = processBrokerDataForSave(value)

    if (key && !_.isEmpty(preaparedKeys)) {
      objectWithModification[key] = { ...preaparedKeys, isCompleted: true, isBlocked: false, formName: key }
    }

    return objectWithModification
  }, {})
  return objectToSave
}

export const processBrokerDataForSave = value => {
  let preparedObject = {}

  if (Array.isArray(value)) {
    preparedObject = value?.reduce((field, currentField) => {
      let finalObjectWithAllNestedFields = { ...field }
      const processedNestedFields = processBrokerDataForSave(currentField)

      if (processedNestedFields && !_.isEmpty(processedNestedFields)) {
        if (currentField?.fieldName && currentField?.fields) {
          finalObjectWithAllNestedFields = {
            ...finalObjectWithAllNestedFields,
            [currentField?.fieldName]: processedNestedFields,
          }
        } else {
          finalObjectWithAllNestedFields = { ...finalObjectWithAllNestedFields, ...processedNestedFields }
        }
      }
      return finalObjectWithAllNestedFields
    }, {})
  } else if (isObject(value)) {
    if (value?.fields) {
      if (value?.type !== "repeater") {
        preparedObject = processBrokerDataForSave(value?.fields)
      } else {
        preparedObject = value?.fields?.map(field => {
          return processBrokerDataForSave(field)
        })
      }
    } else if (value?.fieldName && isValidAnswer(value?.answer)) {
      let answer = value?.answer
      // To change date format
      if (REGEX.DATE_FORMAT_DD_MM_YYYY.test(answer)) {
        const [dd, mm, yyyy] = answer.split("/")
        answer = [yyyy, mm, dd].join("-")
      }
      preparedObject = {
        ...preparedObject,
        [value?.fieldName]: answer,
      }
    } else if (!value?.fieldName) {
      preparedObject = prepareBrokerDataForSave(value)
    }
  }

  return preparedObject
}
