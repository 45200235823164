import { cloneDeep, isNumber, keys, last, uniq, update } from "lodash"
import {
  addEditWarningForInitialData,
  getCloneDeep,
  isAnswer,
  prepareApplicationDataForActiveSave,
  prepareApplicationDataWithSubTypes,
  sortAndUniqFieldsToShow,
  stringCompare,
} from "../../../helpers/applicationHelper"
import {
  convertAPIDataToSavedFormData,
  fieldVisibilityWithoutFormname,
  fixSavedData,
  restructureAllGetAPIData,
} from "../../../helpers/dataParser"
import { BeneficiaryChange } from "../../../helpers/specialChanges"
import { getNavigationHistory, saveNavigationHistoryInStorage } from "../helpers/navigationHistoryHelper"
import { APPLICATION_REDUX_CONSTANTS } from "./ApplicaitonReduxConstants"

const initialState = {
  applicationJsonData: {},
  initialDataLoadedTrigger: false,
  editWarningPaths: [],
  savedDataLoadedTrigger: false,
  formsKey: ["quoteDetails"],
  subFormsKey: [],
  formPathIndex: 0,
  fieldsToShow: [],
  savedFormData: {},
  dataForActiveSave: {},
  formPaths: [],
  applicationListPage: {
    applicationList: {},
  },
  navigationHistory: [],
  viewApplicationData: {},
  editApplicationData: {},
  isLastForm: false,
  glassGuideData: {},
  quickAccessMenuList: [],
  isRecalculationFieldUpdated: false,
  isOnDisclosurePage: false,
  quoteFilter: {},
  isNextOrPrev: false,
}

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Set application json data of flow api response
    case APPLICATION_REDUX_CONSTANTS.SET_APPLICATION_JSON_DATA: {
      const preparedApplicationData = prepareApplicationDataWithSubTypes(action?.data)

      // START   -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname
      fieldVisibilityWithoutFormname(preparedApplicationData)
      // END     -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname

      return {
        ...initialState,
        applicationJsonData: {
          ...preparedApplicationData,
        },
      }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_FILTER_IN_RATE_CARD: {
      return {
        ...state,
        quoteFilter: {
          ...action?.payload?.quoteFilter,
        },
      }
    }
    case APPLICATION_REDUX_CONSTANTS.GET_APPLICATION_LIST:
      return {
        ...state,
        applicationListPage: {
          applicationList: {
            ...action?.data,
          },
        },
      }

    case APPLICATION_REDUX_CONSTANTS.SET_INITIAL_APPLICATION_DATA: {
      const { applicationJsonData, initialDataLoadedTrigger } = { ...state }
      const structuredData = restructureAllGetAPIData(action.payload)
      const newSavedFormData = convertAPIDataToSavedFormData(structuredData, cloneDeep(applicationJsonData))
      return {
        ...state,
        savedFormData: newSavedFormData,
        initialDataLoadedTrigger: !initialDataLoadedTrigger,
        editWarningPaths: addEditWarningForInitialData(newSavedFormData),
      }
    }
    // set fields to show state
    // sort fields by key and it should be unique
    case APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW: {
      return { ...state, fieldsToShow: sortAndUniqFieldsToShow(action.payload) }
    }

    case APPLICATION_REDUX_CONSTANTS.ADD_SAVED_FORM_DATA: {
      const { savedFormData } = { ...state }
      const newForms = keys(action.payload)
      const availableForms = keys(savedFormData)
      let dataToSave = cloneDeep(savedFormData)
      if (availableForms.includes(newForms?.[0])) {
        if (Array.isArray(dataToSave?.[newForms?.[0]]) && Array.isArray(action.payload?.[newForms?.[0]])) {
          const fieldsToKeep = [...action.payload?.[newForms?.[0]], ...dataToSave?.[newForms?.[0]]].reduce(
            (acc, x, i, self) => {
              const alreadyHave = acc.findIndex(w => w?.key === x?.key) > -1
              if (!alreadyHave) {
                const duplicateFields = self.filter(y => y?.key === x?.key)
                if (duplicateFields?.length > 0) {
                  acc.push(duplicateFields.find(z => isAnswer(z?.answer)))
                } else {
                  acc.push(x)
                }
              }
              return acc
            },
            [],
          )
          dataToSave = {
            ...dataToSave,
            [newForms?.[0]]: fieldsToKeep,
          }
        } else {
          dataToSave = {
            ...dataToSave,
            [newForms?.[0]]: { ...dataToSave?.[newForms?.[0]], ...action.payload?.[newForms?.[0]] },
          }
        }
      } else {
        dataToSave = { ...dataToSave, ...action.payload }
      }

      const dataForActiveSave = prepareApplicationDataForActiveSave(dataToSave)
      new BeneficiaryChange().changeFormName(dataForActiveSave, dataForActiveSave)
      return { ...state, savedFormData: dataToSave, dataForActiveSave }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_FORMS_PATH: {
      let newFormPath = [...state.formPaths]
      if (action?.data?.length) {
        newFormPath = [...action?.data]
        if (newFormPath.findIndex(x => stringCompare(x?.nextForm, initialState.formsKey[0])) === -1) {
          newFormPath.unshift({ nextForm: initialState.formsKey[0] })
        }
      }
      return { ...state, formPaths: newFormPath }
    }
    // State update when user clicks on next button
    case APPLICATION_REDUX_CONSTANTS.NAVIGATE_NEXT: {
      let { formPaths, formPathIndex, formsKey, subFormsKey } = { ...state }

      // Increate index from current index
      const nextIndex = formPathIndex + 1

      // Get innerForm and nextForm from formpaths array using index
      const nextFormData = formPaths?.[nextIndex]

      // If formpaths have data
      if (formPaths && formPaths?.length) {
        formsKey = [nextFormData?.nextForm]
        subFormsKey = nextFormData?.innerForm ? [nextFormData?.innerForm] : []
        // index is greater than 0 and have data of extracted values from formpaths
      } else if (nextIndex > 0 && nextFormData?.innerForm && nextFormData?.nextForm) {
        formsKey = [nextFormData?.nextForm]
        subFormsKey = nextFormData?.innerForm ? [nextFormData?.innerForm] : []
        // Set it to first item of formPaths
      } else if (action?.data && formPaths?.[0]?.nextForm && formPaths?.[0]?.innerForm) {
        formsKey = [formPaths?.[0]?.nextForm]
        subFormsKey = formPaths?.[0]?.innerForm ? [formPaths?.[0]?.innerForm] : []
      }

      return {
        ...state,
        formPathIndex: nextIndex,
        formsKey,
        subFormsKey: uniq(new BeneficiaryChange().changeSubformKey(subFormsKey)),
        fieldsToShow: [],
        navigationHistory: [
          ...state.navigationHistory,
          {
            formsKey: state.formsKey,
            subFormsKey: state.subFormsKey,
          },
        ],
      }
    }
    case APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_ACTIVE_SAVE_DATA: {
      return {
        ...state,
        dataForActiveSave: action?.payload,
      }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_VIEW_APPLICATION_DATA: {
      return {
        ...state,
        viewApplicationData: action?.data,
      }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_EDIT_APPLICATION_DATA: {
      return {
        ...state,
        editApplicationData: action?.data,
      }
    }

    // Called when user clicks on previous button
    case APPLICATION_REDUX_CONSTANTS.NAVIGATE_PREVIOUS: {
      let { formsKey, subFormsKey, navigationHistory } = { ...state }
      // copy navigation history
      const updatedHistory = [...navigationHistory]
      // take last forms of navigation history
      const lastItem = last(updatedHistory)
      // if there is no last item then exit
      if (!lastItem) return { ...state }

      // extract forms from last form and remove last item of navigation history
      formsKey = lastItem?.formsKey
      subFormsKey = lastItem?.subFormsKey
      updatedHistory.splice(-1)

      return {
        ...state,
        navigationHistory: updatedHistory,
        formsKey,
        subFormsKey: uniq(new BeneficiaryChange().changeSubformKey(subFormsKey)),
        formPathIndex: state.formPathIndex > 0 ? (state.formPathIndex -= 1) : 0,
      }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_DATA_AFTER_REFRESH: {
      const { applicationJsonData, savedDataLoadedTrigger } = { ...state }

      // extract previousQuestions from payload and delete it from payload
      const previousQuestions = cloneDeep(action.payload?.previousQuestions?.[0])
      if (action.payload?.previousQuestions) delete action.payload?.previousQuestions

      // Initial values
      let navigationHistory = []
      let formPaths = initialState.formPaths
      let formPathIndex = initialState.formPathIndex

      // if payload previousQuestions have navigation history then set it to navigation history
      if (previousQuestions?.navigationHistory?.length > 0) {
        navigationHistory = previousQuestions.navigationHistory
      }

      // If payload previousQuestions have formPaths then set it to formPaths
      if (previousQuestions?.formPaths) {
        formPaths = previousQuestions.formPaths
      }

      // If payload previousQuestions have formPathIndex then set it to formPathIndex
      // else take length of navigation history as formPathIndex or set formPathIndex to 0
      if (isNumber(previousQuestions?.formPathIndex)) {
        formPathIndex = previousQuestions.formPathIndex
      } else {
        formPathIndex = navigationHistory?.length - 1
        if (formPathIndex < 0) formPathIndex = 0
      }

      // Restructure data that is send by server
      // For ex, converting array into objects using formName inside it, making structure same as flow json except fields
      const structuredData = restructureAllGetAPIData(action.payload)
      // Convert key value pair of response data into flow json fields
      const newSavedFormData = fixSavedData(
        convertAPIDataToSavedFormData(structuredData, cloneDeep(applicationJsonData)),
      )

      return {
        ...state,
        savedDataLoadedTrigger: !savedDataLoadedTrigger,
        savedFormData: { ...state.savedFormData, ...newSavedFormData },
        navigationHistory: navigationHistory.splice(0, navigationHistory.length - 1),
        formPathIndex,
        formPaths,
        formsKey: last(navigationHistory)?.formsKey || initialState.formsKey,
        subFormsKey: uniq(
          new BeneficiaryChange().changeSubformKey(last(navigationHistory)?.subFormsKey || initialState.subFormsKey),
        ),
      }
    }
    case APPLICATION_REDUX_CONSTANTS.RESET_APPLICATION_DATA: {
      return {
        ...initialState,
      }
    }
    case APPLICATION_REDUX_CONSTANTS.UPDATE_IS_LAST_FORM: {
      return { ...state, isLastForm: action.payload }
    }
    case APPLICATION_REDUX_CONSTANTS.UPDATE_SUB_FORMS_KEY: {
      return { ...state, subFormsKey: uniq(new BeneficiaryChange().changeSubformKey(action.payload)) }
    }

    case APPLICATION_REDUX_CONSTANTS.UPDATE_LVR_DATA: {
      const { fieldsToShow } = { ...state }
      fieldsToShow?.forEach((form, formIndex) => {
        form?.forEach((field, fieldIndex) => {
          if (field?.fieldName === "assetGlassGuideData") {
            const field = fieldsToShow?.[formIndex]?.[fieldIndex]
            if (field) {
              field.answer = action?.data
            }
          }
        })
      })
      return { ...state }
    }

    case APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_GLASS_GUIDE_DATA: {
      const { fieldsToShow, applicationJsonData, glassGuideData } = { ...state }
      if (action?.formPath?.length) {
        const form = getFormFromApplicationJSONData(applicationJsonData, action?.formPath, action?.field?.fieldName)
        if (form) {
          form.options = action?.data?.table?.map(i => ({ value: i?.value, label: i?.label }))
        }
      }

      if (action?.field) {
        fieldsToShow?.forEach((form, formIndex) => {
          form?.forEach((field, fieldIndex) => {
            if (field?.fieldName === action?.field?.fieldName) {
              const field = fieldsToShow?.[formIndex]?.[fieldIndex]
              if (field) {
                if (field?.fieldName === "model" && field?.request?.url === "glassGuide") {
                  field.options = action?.data?.table?.map(i => ({
                    value: i?.label,
                    label: i?.label,
                    nvic: i?.value,
                  }))
                } else {
                  field.options = action?.data?.table?.map(i => ({ value: i?.value, label: i?.label }))
                  if (action?.field?.fieldName === "assetManufacturerOptions") {
                    field.options.push({ value: "None", label: "None" })
                  }
                }
              }
            }
          })
        })
      }

      return { ...state, applicationJsonData, glassGuideData: { ...glassGuideData, ...action.data } }
    }
    case APPLICATION_REDUX_CONSTANTS.ADD_EDIT_WARNINGS: {
      const { editWarningPaths, savedFormData } = { ...state }
      if (editWarningPaths?.length > 0) {
        editWarningPaths.forEach(({ path, fieldKey }) => {
          update(savedFormData, path, x => {
            if (x?.length > 0) {
              const index = x?.findIndex(y => y?.key === fieldKey)
              if (index > -1) {
                x[index].showEditWarning = true
              }
            }
            return x
          })
        })
      }
      return {
        ...state,
        savedFormData,
      }
    }

    // Set subForms and fieldsToShow from payload
    case APPLICATION_REDUX_CONSTANTS.SET_SUB_FORM_KEY_AND_FIELDS_TO_SHOW: {
      const { subFormsKey, fieldsToShow } = action.payload

      return {
        ...state,
        subFormsKey: uniq(new BeneficiaryChange().changeSubformKey([...subFormsKey])),
        fieldsToShow,
      }
    }

    // Set saved form data (data for all application forms)
    case APPLICATION_REDUX_CONSTANTS.SET_SAVED_FORM_DATA: {
      return { ...state, savedFormData: action.payload }
    }

    /*
                        It'll take main form and subForm from QAM list
                        It'll change below states
                          1. formPathIndex
                          2. formsKey
                          3. subFormsKey
                          4. NavigationHistory
                          5. isLastForm
                      */
    case APPLICATION_REDUX_CONSTANTS.QUICK_ACCESS_MENU_NAVIGATION: {
      const { mainForm, subForm } = action.payload
      let newIsLastForm = state.isLastForm
      // Modifying formPaths so that it includes quoteDetails form which does not come from formPathDetails api response
      // Modifying it so that it'll be same as navigationHistory and easy to manage
      const modifiedFormPaths = cloneDeep(state.formPaths)

      // finding mainForm & subForm(received in payload) in modifiedFormPaths so we get index of formPaths where those forms are
      const newFormPathIndex = modifiedFormPaths.findIndex(({ nextForm, innerForm }) => {
        if (subForm) return stringCompare(nextForm, mainForm) && stringCompare(innerForm, subForm)
        return stringCompare(nextForm, mainForm)
      })

      // deep cloning so that it don't modify original state
      const { nextForm, innerForm } = cloneDeep(modifiedFormPaths?.[newFormPathIndex]) || {}

      /*
                                1. new index is greater than -1
                                2. found nextForm or innerForm which has value
                              */
      if (newFormPathIndex > -1 && (nextForm || innerForm)) {
        //  Putting string value of form into array
        const newformsKey = nextForm ? [nextForm] : []
        const newSubformsKey = innerForm ? [innerForm] : []

        // deep cloning so that it don't modify original state
        let newNavigationHistory = cloneDeep(state.navigationHistory)

        /*
                                        if old index is greater than new index
                                            user is trying to navigate to previous forms
                                        else
                                            user is trying to navigate next forms
                                        */
        if (state.formPathIndex > newFormPathIndex) {
          // user is trying to navigate to oldForms so we need to store current navigationHistory in localstorage.
          // storing it because we can use it when user tries to navigate in next forms
          saveNavigationHistoryInStorage(modifiedFormPaths, state.navigationHistory)
          // setting lastForm to false as user is navigating back and if we didn't change it then on clicking next it'll complete the application
          newIsLastForm = false
          // new navigationHistory which will have history till index
          newNavigationHistory = state.navigationHistory.slice(0, newFormPathIndex)
        } else {
          // get navigation history from localstorage
          const navigationHistory = getNavigationHistory(modifiedFormPaths)
          if (navigationHistory) {
            // new navigationHistory which will have history till index
            newNavigationHistory = navigationHistory.slice(0, newFormPathIndex)
          }
        }

        return {
          ...state,
          formPathIndex: newFormPathIndex,
          formsKey: newformsKey,
          subFormsKey: newSubformsKey,
          navigationHistory: newNavigationHistory,
          isLastForm: newIsLastForm,
        }
      }
      return state
    }

    // Set quick access menu list items (which is shown on right side small drawer)
    case APPLICATION_REDUX_CONSTANTS.SET_QUICK_ACCESS_MENU_LIST: {
      return { ...state, quickAccessMenuList: action.payload }
    }

    case APPLICATION_REDUX_CONSTANTS.SET_RECALCULATION_FIELD_UPDATE: {
      return { ...state, isRecalculationFieldUpdated: action.payload }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_POPUP_FIELD_UPDATE: {
      return { ...state, isOnDisclosurePage: action.payload }
    }
    case APPLICATION_REDUX_CONSTANTS.SET_NEXT_PREV_UPDATE: {
      return { ...state, isNextOrPrev: action.payload }
    }

    default:
      return state
  }
}

const getFormFromApplicationJSONData = (applicationFlowData, keys, fieldName) => {
  let index = 0
  if (applicationFlowData && keys && applicationFlowData?.[keys?.[index]]) {
    let temp = getCloneDeep(applicationFlowData, keys[index], undefined)
    if (temp && temp?.[keys?.[++index]]) {
      while (index < keys?.length - 1) {
        temp = temp[keys[++index]]
      }

      if (temp?.fields) {
        return temp?.fields?.find(item => item?.fieldName === fieldName)
      }
    }
  }

  return null
}
