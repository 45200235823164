import { successNotification } from "../../../../common/NotifyToaster"
import { displayErrors } from "../../../../helpers/ErrorNotifyHelper"
import { resetPasswordApiService } from "../services/ResetPasswordApiService"
import { SHA256 } from "crypto-js"
import { startLoader, stopLoader } from "../../../../common/loader/redux/LoaderActions"

export const onResetPassword = (
  password,
  isRedirectedFromQuote,
  isQuoteSubmit,
  history,
  authToken,
  fromEquifaxPage,
) => {
  return async () => {
    try {
      startLoader("isResetPasswordLoader")
      const data = {
        password: SHA256(password).toString(),
        token: authToken === "" ? history.location.state.token : authToken,
      }
      const response = await resetPasswordApiService.onSubmitResetPassword(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response.data.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isResetPasswordLoader")
    }
  }
}
