import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { ROUTE_CONSTANTS_VARIABLE } from "./RouteConstants"

export const AuthenticatedRoute = ({ component, ...options }) => {
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)

  if (!authStatus) {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.LOGIN} />
      </Route>
    )
  }

  if (options.path !== ROUTE_CONSTANTS_VARIABLE.DASHBOARD && !component) {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.DASHBOARD} />
      </Route>
    )
  }

  return (
    <div>
      <Route {...options} component={component} />
    </div>
  )
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

AuthenticatedRoute.defaultProps = {
  component: null,
}
