/* eslint-disable node/no-callback-literal */
/* eslint-disable no-console */
import _ from "lodash"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { ERROR_MESSAGES } from "../../../constants/Constant"
import { errorNotification, successNotification } from "../../../common/NotifyToaster"
import { BASE_URL } from "../../../constants/UrlConstants"
import { BeneficiaryChange } from "../../../helpers/specialChanges"
import ApplicationApiServices from "../../application/services/ApplicationServices"
import BrokerApiServices from "../services/BrokerServices"
// import res from "../existingApplication.json"
import {
  changeJsonData,
  resetExtraFields,
  restoreFormData,
  setApplicationAnswerData,
  setBrokerExtraPathData,
  setFieldData,
  setFormDataNew,
  setFormPath,
  setJsonData,
  setLastQuestion,
  setQuoteAnswerData,
  setRateDataNew,
  validateFieldsNew,
  restoreGlassGuideDataAndLabel,
  getCurrentForm,
  changeFormData,
  setQuoteData,
  setBrokerQoteNowData,
  setBrokerQoteNowzohoData,
  setAppLastQuestionOnly,
  // changeSecondFlag,
} from "./brokerSliceNew"
// import { filterGlassGuideLabelDataForSave } from "../helpers"
import { prepareBrokerDataForSave } from "../helpers/prepareSaveFormData"
import EquifaxServices from "../../auth/EquifaxPage/services/EquifaxServices"
import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import { DEAL_ID_QUERY_PARAM, USER_ID_QUERY_PARAM } from "../../application/ApplicationPage/ApplicationPageConstants"
import { addQueryParameter, getQueryParams } from "../helpers/queryParams"
import { getQuoteAndApplicationFormPaths } from "../helpers"

export const getBrokerJSONNew = () => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await BrokerApiServices.getBrokerJson()
      if (response?.data?.status === "SUCCESS") {
        dispatch(setJsonData(response?.data?.data))
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const getQuoteFormPathNew = () => {
  return async (dispatch, getState) => {
    try {
      const { brokerLoaderNew } = getState()?.loaderReducer || {}
      if (brokerLoaderNew) return

      const { formData, brokerFormPaths, errorCounter, currentFormPathIndex, brokerApplicationJsonData } =
        getState()?.brokerReducerNew || {}

      // logLodashData( getState() )

      if (formData && (!errorCounter || errorCounter <= 0)) {
        const formToSend = {
          [`${brokerFormPaths[currentFormPathIndex][0]}`]: formData?.[
            `${brokerFormPaths?.[currentFormPathIndex]?.[0]}`
          ],
        }

        const data = prepareBrokerDataForSave(formToSend)

        new BeneficiaryChange().changeFormName(data, data)
        startLoader("brokerLoaderNew")

        // console.log("data", data)

        const response = await BrokerApiServices.getQuoteFormPathNew({ forBroker: true, data: data })

        if (response?.data?.status === "SUCCESS") {
          const nextForm = response?.data?.data

          let isAlreadyExists = false

          for (const nForms of nextForm) {
            isAlreadyExists = brokerFormPaths.findIndex(x => x?.[0] === nForms?.data) > 0
            if (isAlreadyExists) break
          }

          if (!isAlreadyExists) {
            const fieldValue = formData?.loanType?.[0]?.answer

            const isPersonalOrBusinessUse = formData?.employmentDefault?.[0]?.answer

            const quoteForm = getQuoteDetails(isPersonalOrBusinessUse)

            await dispatch(setQuoteData(quoteForm))

            const form = getUpdatedFinanceFormBroker(fieldValue)

            const employmentForm = getUpdatedEmploymentFormBroker(fieldValue)

            await dispatch(changeJsonData([form, employmentForm]))

            const isQuoteDetailIn = brokerFormPaths.findIndex(
              x => x?.[0] === "quoteDetails" || x?.[0] === "quoteDetails2",
            )

            const isPreviousAddress = brokerFormPaths.findIndex(x => x?.[1] === "previousAddressDetails")

            console.log("previous", isPreviousAddress)

            if (isPreviousAddress > 0) {
              const newArr = []

              const addressInformation = brokerFormPaths.findIndex(x => x?.[1] === "addressInformatioin")

              const array1 = brokerFormPaths.slice(0, addressInformation)

              const array2 = brokerFormPaths.slice(isPreviousAddress, addressInformation + brokerFormPaths.length)

              array1.forEach(element => {
                newArr.push(element)
              })
              let isAlreadyExistsfst = false
              for (const nForms of nextForm) {
                isAlreadyExistsfst = brokerFormPaths.findIndex(x => x?.[0] === nForms?.data) > 0
                if (!isAlreadyExistsfst) {
                  newArr.push([nForms?.data])
                }
              }

              array2.forEach(element => {
                newArr.push(element)
              })

              await dispatch(
                setFormPath({
                  newForms: newArr,
                }),
              )
            }

            if (isQuoteDetailIn > 0) {
              const newArr = []
              // append another in middle
              const array1 = brokerFormPaths.slice(0, isQuoteDetailIn)

              const array2 = brokerFormPaths.slice(isQuoteDetailIn, isQuoteDetailIn + brokerFormPaths.length)

              array1.forEach(element => {
                newArr.push(element)
              })
              let isAlreadyExistsfst = false
              for (const nForms of nextForm) {
                isAlreadyExistsfst = brokerFormPaths.findIndex(x => x?.[0] === nForms?.data) > 0
                if (!isAlreadyExistsfst) {
                  newArr.push([nForms?.data])
                }
              }

              array2.forEach(element => {
                newArr.push(element)
              })

              await dispatch(
                setFormPath({
                  newForms: newArr,
                }),
              )
            } else {
              const bFOrmPath = [...brokerFormPaths]
              for (const nForms of nextForm) {
                if (nForms?.data != null && nForms.data) {
                  bFOrmPath.push([nForms?.data])
                }
              }

              let data1 = { ...data }

              for (const nForms of nextForm) {
                // if call next form flag yes
                const callNextForm = brokerApplicationJsonData?.[nForms?.data]?.callNextForm
                if (callNextForm) {
                  data1 = { ...data1, [nForms?.data]: {} }
                  const response = await BrokerApiServices.getQuoteFormPathNew({
                    forBroker: true,
                    callNextForm: callNextForm,
                    data: data1,
                  })
                  const nextForm1 = response?.data?.data
                  for (const rnForms of nextForm1) {
                    const isAlreadyExistsLoop = brokerFormPaths.findIndex(x => x?.[0] === rnForms?.data) > 0
                    if (!isAlreadyExistsLoop) {
                      const fieldValue = formData?.loanType?.[0]?.answer

                      const form = getUpdatedFinanceFormBroker(fieldValue)
                      const employmentForm = getUpdatedEmploymentFormBroker(fieldValue)

                      await dispatch(changeJsonData([form, employmentForm]))

                      const isQuoteDetailIn = brokerFormPaths.findIndex(
                        x => x?.[0] === "quoteDetails" || x?.[0] === "quoteDetails2",
                      )

                      if (isQuoteDetailIn > 0) {
                        const newArr = []
                        // append another in middle
                        const array1 = brokerFormPaths.slice(0, isQuoteDetailIn)
                        const array2 = brokerFormPaths.slice(isQuoteDetailIn, isQuoteDetailIn + brokerFormPaths.length)
                        array1.forEach(element => {
                          newArr.push(element)
                        })

                        newArr.push([rnForms?.data])

                        array2.forEach(element => {
                          newArr.push(element)
                        })

                        await dispatch(
                          setFormPath({
                            newForms: newArr,
                          }),
                        )
                      } else {
                        if (rnForms?.data != null && rnForms.data) {
                          bFOrmPath.push([rnForms?.data])
                        }
                      }
                    }
                  }
                }
              }
              await dispatch(
                setFormPath({
                  newForms: bFOrmPath,
                }),
              )
            }
          }
        }
        await dispatch(setLastQuestion(false))
      }
    } catch (error) {
      dispatch(setLastQuestion(false))
      //
    } finally {
      stopLoader("brokerLoaderNew")
    }
  }
}

export const getQuoteDetails = fieldValue => {
  switch (fieldValue) {
    case "Personal Use":
      return "quoteDetails"
    case "Business Use":
      return "quoteDetails2"
    default:
      return "quoteDetails"
  }
}

export const getUpdatedFinanceFormBroker = fieldValue => {
  switch (fieldValue) {
    case "Personal Loan":
      return "financeNumbersPersonalLoan"
    case "Unsecured Business Loan":
    case "Cashflow Finance":
    case "Business Loan":
    case "Debtor Finance":
    case "Debt Consolidation":
    case "Insurance":
    case "Warranty":
    case "Other":
      return "financeNumbers"
    default:
      return "financeNumbers"
  }
}

export const getUpdatedEmploymentFormBroker = () => {
  /* switch (fieldValue) {
    case "Personal Loan":
    case "Unsecured Business Loan":
    case "Debt Consolidation":
    case "Business Loan":
    case "Cashflow Finance":
    case "Debtor Finance":
    default:
      return "employmentDefault"
  } */

  return "employmentDefault"
}

export const getApplicationFormPathNew = () => {
  return async (dispatch, getState) => {
    const { currentFormPathIndex } = getState()?.brokerReducerNew || {}

    try {
      // to define till which index of formpaths data need to be sent
      const index = currentFormPathIndex

      const { formData, errorCounter, brokerFormPaths, brokerFormPathIndex } = getState()?.brokerReducerNew || {}

      // let brokerFormPath = _.cloneDeep(brokerFormPaths)
      if (formData && (!errorCounter || errorCounter <= 0)) {
        // filter data based on index of formPath

        const slicedFormPaths = brokerFormPaths.slice(0, (index || index === 0 ? index : brokerFormPathIndex) + 1)

        let formDataToSend = slicedFormPaths.reduce((acc, formPath) => {
          const path = formPath.join(".")

          _.set(acc, path, _.get(formData, path))

          return acc
        }, {})

        // for commercial send all data on asset
        const isConsumerCommercial = formData?.quoteDetails?.findIndex(x => x?.fieldName === "consumerOrCommercial")

        const isnumberOfAsset = formData?.quoteDetails?.findIndex(x => x?.fieldName === "numberOfAsset")
        if (isConsumerCommercial > 0) {
          const ConsumerCommercial = formData?.quoteDetails?.[isConsumerCommercial]?.answer
          if (ConsumerCommercial === "Commercial" && isnumberOfAsset > 0) {
            formDataToSend = brokerFormPaths.reduce((acc, formPath) => {
              const path = formPath.join(".")
              _.set(acc, path, _.get(formData, path))
              return acc
            }, {})
          }
        }

        const data = prepareBrokerDataForSave(formDataToSend)

        new BeneficiaryChange().changeFormName(data, data)
        startLoader("brokerLoaderNew")

        const response = await BrokerApiServices.getApplicationFormPathNew({ forBroker: true, ...data })

        // console.log("response", response?.data?.data)

        // const applicantType = formData?.quoteDetails?.findIndex( x => x?.fieldName === 'applicantType' )

        if (response?.data?.status === "SUCCESS") {
          let nextForm = response?.data?.data

          nextForm = new BeneficiaryChange().structureFormsKey(nextForm)

          dispatch(
            setFormPath({
              newForms: nextForm,
              isForApplication: true,
            }),
          )

          dispatch(setLastQuestion(false))

          // Will be used to distinguish between quote data and application data
          if (nextForm?.[0]) {
            const payload = []
            if (nextForm[0]?.nextForm) payload.push(nextForm[0].nextForm)
            if (nextForm[0]?.innerForm) payload.push(nextForm[0].innerForm)
          }
        }
      }
      dispatch(setAppLastQuestionOnly(false))
    } catch (error) {
      dispatch(setLastQuestion(false))
    } finally {
      stopLoader("brokerLoaderNew")
    }
  }
}
export const getGlassGuideDataFromBrokerNew = (data, field, pathOfField, callBack) => {
  return async dispatch => {
    const { url, method, params } = data

    try {
      // startLoader("brokerLoader")
      const response = await ApplicationApiServices.getDataFromExternalSources(
        `${BASE_URL}${url}`,
        _.toUpper(method),
        params,
      )
      callBack && callBack()
      if (response?.data?.status === "SUCCESS") {
        dispatch(setFieldData({ data: response?.data?.data, pathOfField, field }))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in glass guide:", e)
      callBack && callBack()
      errorNotification("Failed to fetch glass guide data")
    } finally {
      // loader remove
      //  stopLoader("brokerLoader")
    }
  }
}

export const validateFieldsActionNew = () => {
  return async (dispatch, getState) => {
    const { currentFormPathIndex } = getState()?.brokerReducerNew || {}
    dispatch(validateFieldsNew(currentFormPathIndex))
    return ""
  }
}

export const handleRegoSearchBrokerNew = (url, method, params, vehicle) => {
  return async (dispatch, getState) => {
    try {
      startLoader("brokerLoader")
      const response = await ApplicationApiServices.getDataFromExternalSources(
        `${BASE_URL}${url}`,
        _.toUpper(method),
        params,
      )

      if (response?.data?.status === "SUCCESS") {
        const { formData } = getState()?.brokerReducerNew || {}

        const formIndexRego = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "rego")
        const formIndexState = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "state")
        const formIndexVin = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "vin")
        const formIndexAllOther = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "carSearch")
        const formIndexassetD = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "assetDescription")

        dispatch(setFormDataNew({ path: `${vehicle}[${formIndexRego}].answer`, value: response?.data?.data?.rego }))
        dispatch(setFormDataNew({ path: `${vehicle}[${formIndexState}].answer`, value: response?.data?.data?.state }))
        dispatch(setFormDataNew({ path: `${vehicle}[${formIndexVin}].answer`, value: response?.data?.data?.vin }))
        dispatch(
          setFormDataNew({
            path: `${vehicle}[${formIndexAllOther}].answer`,
            value: JSON.stringify(response?.data?.data),
          }),
        )
        dispatch(
          setFormDataNew({
            path: `${vehicle}[${formIndexassetD}].answer`,
            value: `${response?.data?.data?.yearCreate} ${response?.data?.data?.modelName}`,
          }),
        )
      }
    } catch (e) {
      errorNotification("Failed to fetch rego data")
      const { formData } = getState()?.brokerReducerNew || {}

      const formIndexRego = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "rego")
      const formIndexState = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "state")
      const formIndexVin = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "vin")
      const formIndexAllOther = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "carSearch")
      const formIndexassetD = formData[`${vehicle}`]?.findIndex(e => e.fieldName === "assetDescription")

      dispatch(setFormDataNew({ path: `${vehicle}[${formIndexRego}].answer`, value: "" }))
      dispatch(setFormDataNew({ path: `${vehicle}[${formIndexState}].answer`, value: "" }))
      dispatch(setFormDataNew({ path: `${vehicle}[${formIndexVin}].answer`, value: "" }))
      dispatch(setFormDataNew({ path: `${vehicle}[${formIndexAllOther}].answer`, value: "" }))
      dispatch(
        setFormDataNew({
          path: `${vehicle}[${formIndexassetD}].answer`,
          value: ``,
        }),
      )
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const createUserActionNew = (data, cb) => {
  return async () => {
    try {
      startLoader("brokerLoader")
      const res = await BrokerApiServices.createUser(data)
      if (res?.data?.status === "SUCCESS" && cb && typeof cb === "function") {
        // eslint-disable-next-line node/no-callback-literal
        cb(res?.data?.data)
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const submitQuoteData = (id, dealId, cb) => {
  return async (dispatch, getState) => {
    try {
      startLoader("brokerLoader")
      const { formData, brokerFormPaths, brokerExtraPathData, glassGuideDataBrokerNew, glassGuideLabelBrokerNew } =
        getState()?.brokerReducerNew || {}

      const { applicationFormPaths, quoteFormPaths } = getQuoteAndApplicationFormPaths(brokerFormPaths)

      let quoteAnswersData = _.pick(formData, quoteFormPaths)
      let applicationAnswerData = _.pick(formData, applicationFormPaths)

      quoteAnswersData = prepareBrokerDataForSave(quoteAnswersData)
      new BeneficiaryChange().changeFormName(quoteAnswersData, quoteAnswersData)

      applicationAnswerData = prepareBrokerDataForSave(applicationAnswerData)
      new BeneficiaryChange().changeFormName(applicationAnswerData, applicationAnswerData)

      const navigationHistory = _.pick(getState()?.brokerReducerNew, [
        "brokerFormPathIndex",
        "currentFormPathIndex",
        "brokerFormPaths",
      ])
      const res = await BrokerApiServices.saveQuoteData({
        quoteAnswersData,
        applicationAnswerData: {
          ...applicationAnswerData,
          navigationHistory,
        },
        glassGuideData: glassGuideDataBrokerNew,
        glassGuideLabel: glassGuideLabelBrokerNew,
        userId: id,
      })
      if (res?.data?.status === "SUCCESS" && res?.data?.applicationId && res?.data?.dealId) {
        await dispatch(setBrokerQoteNowzohoData({ zohobrokerid: res?.data?.dealId }))

        /* get path */
        const indexToGetPathFrom = brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
        const indexToGetPathFromAnother = brokerExtraPathData?.navigationHistory?.brokerFormPaths?.findIndex(
          e => e?.[0] === "quoteDetails",
        )

        if (indexToGetPathFrom >= 0) {
          let applicationPaths = brokerFormPaths.slice(indexToGetPathFrom)
          applicationPaths = applicationPaths?.map(e => ({ formKey: e?.[0], subFormKey: e?.[1] || "" }))
          const data = { paths: applicationPaths }

          await storeNextDataRecursion(
            { id: res?.data?.applicationId, data, nextApiCall: res?.data?.nextApiCall, dispatch },
            cb,
          )
        } else {
          if (indexToGetPathFromAnother >= 0) {
            let applicationPaths = brokerExtraPathData?.navigationHistory?.brokerFormPaths.slice(
              indexToGetPathFromAnother,
            )
            applicationPaths = applicationPaths?.map(e => ({ formKey: e?.[0], subFormKey: e?.[1] || "" }))
            const data = { paths: applicationPaths }

            await storeNextDataRecursion(
              { id: res?.data?.applicationId, data, nextApiCall: res?.data?.nextApiCall, dispatch },
              cb,
            )
          }
        }
        /* get path */
      }
    } catch (error) {
      cb(false)
      errorNotification(error)
      stopLoader("brokerLoader")
    }
  }
}

export const storeNextDataRecursion = async ({ id, data, nextApiCall, dispatch }, cb) => {
  const response = await BrokerApiServices.saveQuoteDataRecursion(id, data, nextApiCall)
  if (response?.data?.status === "SUCCESS") {
    if (response?.data?.nextApiCall) {
      await storeNextDataRecursion({ id, data, nextApiCall: response?.data?.nextApiCall, dispatch }, cb)
    } else {
      /* const response = await BrokerApiServices.getQuoteData(id)
      if (response?.data?.status === "SUCCESS") {
        dispatch(setQuoteAnswerData({ data: response?.data?.data }))

        const responseApplication = await BrokerApiServices.getApplicationData(id)
        if (responseApplication?.data?.status === "SUCCESS") {
          dispatch(setApplicationAnswerData(responseApplication?.data?.data))
        }
      }
      successNotification("Application submitted successfully")
      */
      dispatch(setBrokerQoteNowData({ isBrokerquotecomplete: true }))
      stopLoader("brokerLoader")
      cb && cb(true)
    }
  }
}

export const saveBrokerDataNew = (id, dealId, cb) => {
  return async (dispatch, getState) => {
    try {
      const { formData, brokerFormPaths, glassGuideDataBrokerNew, glassGuideLabelBrokerNew } =
        getState()?.brokerReducerNew || {}
      if (!formData || !id) throw Error(ERROR_MESSAGES.somethingWentWrong)

      const { applicationFormPaths, quoteFormPaths } = getQuoteAndApplicationFormPaths(brokerFormPaths)

      let quoteAnswersData = _.pick(formData, quoteFormPaths)
      let applicationAnswerData = _.pick(formData, applicationFormPaths)

      quoteAnswersData = prepareBrokerDataForSave(quoteAnswersData)
      new BeneficiaryChange().changeFormName(quoteAnswersData, quoteAnswersData)

      applicationAnswerData = prepareBrokerDataForSave(applicationAnswerData)
      new BeneficiaryChange().changeFormName(applicationAnswerData, applicationAnswerData)

      // const glassGuideLabel = filterGlassGuideLabelDataForSave(glassGuideLabelBroker)
      const navigationHistory = _.pick(getState()?.brokerReducerNew, [
        "brokerFormPathIndex",
        "currentFormPathIndex",
        "brokerFormPaths",
      ])

      startLoader("brokerLoader")
      const payload = {
        quoteAnswersData,
        applicationAnswerData: {
          ...applicationAnswerData,
          navigationHistory,
        },
        glassGuideData: glassGuideDataBrokerNew,
        glassGuideLabel: glassGuideLabelBrokerNew,
        userId: id,
      }
      if (dealId) payload.dealId = dealId
      const res = await BrokerApiServices.saveData(payload)
      if (res?.data?.status === "SUCCESS" && res?.data?.data) {
        if (cb && typeof cb === "function") {
          cb(res?.data?.data)
        }
      } else throw Error(ERROR_MESSAGES.somethingWentWrong)
    } catch (error) {
      errorNotification(error)
      stopLoader("brokerLoader")
    }
  }
}

export const quickSaveBrokerDataNew = cb => {
  return async (dispatch, getState) => {
    try {
      const { formData, brokerFormPaths, glassGuideDataBrokerNew, glassGuideLabelBrokerNew } =
        getState()?.brokerReducerNew || {}
      if (!formData) throw Error(ERROR_MESSAGES.somethingWentWrong)

      const { applicationFormPaths, quoteFormPaths } = getQuoteAndApplicationFormPaths(brokerFormPaths)

      let quoteAnswersData = _.pick(formData, quoteFormPaths)
      let applicationAnswerData = _.pick(formData, applicationFormPaths)

      quoteAnswersData = prepareBrokerDataForSave(quoteAnswersData)
      new BeneficiaryChange().changeFormName(quoteAnswersData, quoteAnswersData)

      applicationAnswerData = prepareBrokerDataForSave(applicationAnswerData)
      new BeneficiaryChange().changeFormName(applicationAnswerData, applicationAnswerData)

      // const glassGuideLabel = filterGlassGuideLabelDataForSave(glassGuideLabelBroker)
      const navigationHistory = _.pick(getState()?.brokerReducerNew, [
        "brokerFormPathIndex",
        "currentFormPathIndex",
        "brokerFormPaths",
      ])

      startLoader("brokerLoader")

      const payload = {
        quoteAnswersData,
        applicationAnswerData: {
          ...applicationAnswerData,
          navigationHistory,
        },
        glassGuideData: glassGuideDataBrokerNew,
        glassGuideLabel: glassGuideLabelBrokerNew,
      }

      if (getQueryParams(DEAL_ID_QUERY_PARAM)) payload.dealId = getQueryParams(DEAL_ID_QUERY_PARAM)
      if (getQueryParams(USER_ID_QUERY_PARAM)) payload.userId = getQueryParams(USER_ID_QUERY_PARAM)

      payload.userId = getQueryParams(USER_ID_QUERY_PARAM)

      const res = await BrokerApiServices.quickSaveData(payload)

      if (res?.data?.status === "SUCCESS" && res?.data?.applicationId) {
        if (res?.data?.dealId) addQueryParameter(DEAL_ID_QUERY_PARAM, res.data.dealId)

        let applicationPaths = brokerFormPaths.slice(0)
        applicationPaths = applicationPaths?.map(e => ({ formKey: e?.[0], subFormKey: e?.[1] || "" }))
        const data = { paths: applicationPaths }

        await storeNextDataRecursion(
          {
            id: res.data.applicationId,
            data,
            nextApiCall: res.data.nextApiCall,
            dispatch,
          },
          cb,
        )
      } else throw Error(ERROR_MESSAGES.somethingWentWrong)
    } catch (error) {
      errorNotification(error)
      stopLoader("brokerLoader")
    }
  }
}

export const verifyOTPAction = (data, cb) => {
  return async () => {
    try {
      startLoader("brokerLoader")
      const res = await BrokerApiServices.onBrokerSubmitOtp(data)
      if (res?.data?.status === "SUCCESS" && cb && typeof cb === "function") {
        successNotification(res?.data?.message || "OTP verified successfully")
        cb(res?.data?.data)
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const getVanishFormDataNew = data => {
  return async (dispatch, getState) => {
    try {
      const { formToShow, fieldName, value, index, answer } = data

      const { brokerApplicationJsonData, brokerFormPaths, formData, brokerFormPathIndex } =
        getState()?.brokerReducerNew || {}

      let fieldsToKeep = _.cloneDeep(formData)

      let brokerFormPath = _.cloneDeep(brokerFormPaths)
      let brokerFormIndex = _.cloneDeep(brokerFormPathIndex)
      const isQuoteField = _.get(brokerApplicationJsonData, `${getCurrentForm(brokerFormPaths, index)}.isQuoteForm`)
      if (isQuoteField) {
        // call quote API
        const dataToPass = {
          key: fieldName,
          value,
          formKey: formToShow?.[0],
          oldAnswer: answer,
        }
        const res = await BrokerApiServices.quoteVanishNextForms(dataToPass)

        // console.log('res', res);

        if (res?.data?.status === "SUCCESS") {
          if (res?.data?.data?.length > 0) {
            const formFieldList = res?.data?.data

            formFieldList?.forEach((form, index) => {
              if (form?.fields?.length > 0) {
                // remove only selected fields
                form?.fields?.forEach(field => {
                  const findFieldIndex = fieldsToKeep?.[form?.formName]?.findIndex(
                    fieldRemove => fieldRemove.fieldName === field.key,
                  )
                  if (findFieldIndex > 0) {
                    if (field?.vanishValueOnly) {
                      const fieldsToKeepUpdated = fieldsToKeep?.[form?.formName]?.map(fieldRemove => {
                        if (fieldRemove.fieldName === field.key) {
                          fieldRemove = { ...fieldRemove, answer: "" }
                        }
                        return fieldRemove
                      })
                      //  const fieldsToKeepUpdated = fieldsToKeep?.[ form?.formName ]?.filter( fieldRemove => fieldRemove.fieldName !== field.key )
                      fieldsToKeep = { ...fieldsToKeep, [form?.formName]: fieldsToKeepUpdated }
                    } else {
                      const fieldsToKeepUpdated = fieldsToKeep?.[form?.formName]?.filter(
                        fieldRemove => fieldRemove.fieldName !== field.key,
                      )
                      fieldsToKeep = { ...fieldsToKeep, [form?.formName]: fieldsToKeepUpdated }
                    }
                  }
                })
              } else {
                /* if (fieldsToKeep?.[form?.formName]) {
                  if (brokerFormIndex > 0) {
                    brokerFormIndex--
                  }

                  fieldsToKeep = Object.keys(fieldsToKeep)
                    .filter(key => !key.includes(form?.formName))
                    .reduce((obj, key) => {
                      return Object.assign(obj, {
                        [key]: fieldsToKeep[key],
                      })
                    }, {})
                } 
                // remove data from broker form path
                brokerFormPath = brokerFormPath?.filter(formRemove => formRemove["0"] !== form?.formName) */
              }
            })
          }

          // console.log('fieldsToKeep', fieldsToKeep);
          // console.log('brokerFormPath', brokerFormPath);
          // console.log('brokerFormIndex', brokerFormIndex);

          dispatch(changeFormData({ fieldsToKeep, brokerFormPath, brokerFormIndex }))
        }
      } else {
        // call application API
        const dataToPass = {
          key: fieldName,
          value,
          formKey: formToShow?.[0],
          subFormKey: formToShow?.[1],
          oldAnswer: answer,
        }
        if (formToShow?.[1] === "addressInformation" || formToShow?.[1] === "previousAddressDetails") {
          let cPropertyYear = 0
          let cPropertyMonth = 0
          let pPropertyYear = 0
          let pPropertyMonth = 0

          const AddrYearFieldIndex = fieldsToKeep?.[formToShow?.[0]]?.addressInformation?.findIndex(
            AddrYearField => AddrYearField.fieldName === "currentPropertyYears",
          )
          if (AddrYearFieldIndex >= 0) {
            cPropertyYear = fieldsToKeep?.[formToShow?.[0]]?.addressInformation?.[AddrYearFieldIndex]?.answer || 0
          }

          const AddrMonthFieldIndex = fieldsToKeep?.[formToShow?.[0]]?.addressInformation?.findIndex(
            AddrMonthField => AddrMonthField.fieldName === "currentPropertyMonths",
          )
          if (AddrMonthFieldIndex >= 0) {
            cPropertyMonth = fieldsToKeep?.[formToShow?.[0]]?.addressInformation?.[AddrMonthFieldIndex]?.answer || 0
          }

          const PAddrYearFieldIndex = fieldsToKeep?.[formToShow?.[0]]?.previousAddressDetails?.findIndex(
            pAddrYearField => pAddrYearField.fieldName === "previousPropertyYears",
          )
          if (PAddrYearFieldIndex >= 0) {
            pPropertyYear = fieldsToKeep?.[formToShow?.[0]]?.previousAddressDetails?.[PAddrYearFieldIndex]?.answer || 0
          }

          const PAddrMonthFieldIndex = fieldsToKeep?.[formToShow?.[0]]?.previousAddressDetails?.findIndex(
            PAddrYearField => PAddrYearField.fieldName === "previousPropertyMonths",
          )
          if (PAddrMonthFieldIndex >= 0) {
            pPropertyMonth =
              fieldsToKeep?.[formToShow?.[0]]?.previousAddressDetails?.[PAddrMonthFieldIndex]?.answer || 0
          }
          const dataToPassAddr = {
            currentPropertyYear: cPropertyYear,
            currentPropertyMonth: cPropertyMonth,
            PreviousPropertyYear: pPropertyYear,
            PreviousPropertyMonth: pPropertyMonth,
          }
          dataToPass.addressData = dataToPassAddr
        }

        const res = await BrokerApiServices.applicaitonVanishNextForms(dataToPass)
        if (res?.data?.status === "SUCCESS") {
          if (res?.data?.data?.length > 0) {
            const formFieldList = res?.data?.data
            const formToRemove = []
            formFieldList?.forEach((form, index) => {
              if (form?.fields?.length > 0) {
                if (form?.formName && !form?.subFormName) {
                  form?.fields?.forEach(field => {
                    const findFieldIndex = fieldsToKeep?.[form?.formName]?.findIndex(
                      fieldRemove => fieldRemove.fieldName === field.key,
                    )
                    if (findFieldIndex > 0) {
                      if (field?.vanishValueOnly) {
                        const fieldsToKeepUpdated = fieldsToKeep?.[form?.formName]?.map(fieldRemove => {
                          if (fieldRemove.fieldName === field.key) {
                            fieldRemove = { ...fieldRemove, answer: "" }
                          }
                          return fieldRemove
                        })
                        fieldsToKeep = { ...fieldsToKeep, [form?.formName]: fieldsToKeepUpdated }
                      } else {
                        const fieldsToKeepUpdated = fieldsToKeep?.[form?.formName]?.filter(
                          fieldRemove => fieldRemove.fieldName !== field.key,
                        )
                        fieldsToKeep = { ...fieldsToKeep, [form?.formName]: fieldsToKeepUpdated }
                      }
                    }
                  })
                } else {
                  let fieldArray = fieldsToKeep?.[form?.formName]?.[form?.subFormName]
                  const formPath = [form?.formName, form?.subFormName].join(".")
                  form?.fields?.forEach(field => {
                    const findFieldIndex = fieldsToKeep?.[form?.formName]?.[form?.subFormName]?.findIndex(
                      fieldRemove => fieldRemove.fieldName === field.key,
                    )

                    if (findFieldIndex > 0) {
                      if (field?.vanishValueOnly) {
                        fieldArray?.map(fieldRemove => {
                          if (fieldRemove.fieldName === field.key) {
                            fieldRemove = { ...fieldRemove, answer: "" }
                          }
                          return fieldRemove
                        })
                      } else {
                        fieldArray = fieldArray.filter(fieldRemove => fieldRemove.fieldName !== field.key)
                      }
                    }
                  })

                  _.set(fieldsToKeep, formPath, fieldArray)
                }
              } else {
                if (form?.formName && !form?.subFormName) {
                  fieldsToKeep = Object.keys(fieldsToKeep)
                    .filter(key => !key.includes(form?.formName))
                    .reduce((obj, key) => {
                      return Object.assign(obj, {
                        [key]: fieldsToKeep[key],
                      })
                    }, {})
                } else {
                  const formPath = [form?.formName, form?.subFormName].join(".")
                  formToRemove.push(formPath)
                }

                if (form?.formName && !form?.subFormName && !form?.fields) {
                  formToRemove.push(form?.formName)
                } else {
                  if (form?.formName && form?.subFormName && !form?.fields) {
                    const formPath = [form?.formName, form?.subFormName].join(".")
                    formToRemove.push(formPath)
                  }
                }
              }
            })
            formToRemove.forEach(element => {
              brokerFormPath = brokerFormPath?.filter(formRemove => formRemove.join(".") !== element)
              /* remove form data */
              _.set(fieldsToKeep, element, null)
              /* remove form data */
            })

            Object.keys(fieldsToKeep).forEach(key => {
              if (!Array.isArray(fieldsToKeep[key]) && fieldsToKeep[key] != null) {
                Object.keys(fieldsToKeep[key]).forEach(subKey => {
                  if (fieldsToKeep[key][subKey] == null) {
                    delete fieldsToKeep[key][subKey]
                  }
                })
              }
            })
            for (const item in fieldsToKeep) {
              const pairKey = JSON.stringify(fieldsToKeep[item])
              if (pairKey === "{}" || pairKey === "null") {
                delete fieldsToKeep[item]
              }
            }
            brokerFormIndex = brokerFormPath.length - 1
            dispatch(changeFormData({ fieldsToKeep, brokerFormPath, brokerFormIndex }))

            // dispatch(changeFormData({ fieldsToKeep, brokerFormPath, brokerFormIndex })).then(data => {
            //   if (data) {
            //    // dispatch(changeSecondFlag(true))
            //   }
            // })
          }
        }
      }

      //  dispatch( setLastQuestionOnly( false ) )
    } catch (error) {
      errorNotification(error)
    }
  }
}

export const getBrokerDataNew = id => {
  return async dispatch => {
    try {
      if (!id) throw Error(ERROR_MESSAGES.somethingWentWrong)

      startLoader("brokerLoader")

      const res = await BrokerApiServices.getData(id)

      if (res?.data?.status === "SUCCESS" && res?.data?.data) {
        const financeWhatAnswer = Object.keys(res.data.data?.loanType || {}).reduce((acc, x) => {
          if (x === "financeWhat") acc = res.data.data?.loanType[x]

          return acc
        }, "")

        const form = getUpdatedFinanceFormBroker(financeWhatAnswer)

        const employmentForm = getUpdatedEmploymentFormBroker(financeWhatAnswer)

        await dispatch(
          restoreGlassGuideDataAndLabel({
            glassGuideDataBrokerNew: res.data.data?.glassGuideData,
            glassGuideLabelBrokerNew: res.data.data?.glassGuideLabel,
          }),
        )
        await dispatch(changeJsonData([form, employmentForm]))

        await dispatch(restoreFormData(res.data.data))

        setTimeout(() => {
          dispatch(resetExtraFields())
          stopLoader("brokerLoader")
        }, 2000)
      } else {
        stopLoader("brokerLoader")
        throw Error(ERROR_MESSAGES.somethingWentWrong)
      }
    } catch (error) {
      stopLoader("brokerLoader")
      errorNotification(error)
    }
  }
}

export const checkEmailStatusAction = (id, cb) => {
  return async () => {
    try {
      startLoader("brokerLoader")
      const res = await BrokerApiServices.getEmailVerificationStatus(id)
      if (res?.data?.status === "SUCCESS" && cb && typeof cb === "function" && res?.data?.data?.isEmailVerified) {
        // successNotification(res?.data?.message || "Email verified successfully")
        cb(res?.data?.data)
      }
    } catch (e) {
      stopLoader("brokerLoader")
      errorNotification(e)
    }
  }
}

export const getBrokerQuoteNew = id => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await BrokerApiServices.getQuoteData(id)

      console.log("response new", response)

      if (response?.data?.status === "SUCCESS") {
        dispatch(setQuoteAnswerData({ data: response?.data?.data }))
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("brokerLoader")
    }
  }
}
export const getBrokerApplication = id => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await BrokerApiServices.getApplicationData(id)
      if (response?.data?.status === "SUCCESS") {
        dispatch(setApplicationAnswerData(response?.data?.data))
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("brokerLoader")
    }
  }
}
export const getBrokerRateCardNew = id => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await BrokerApiServices.getRateCardData(id)

      console.log("reponse card new", response.data)

      if (response?.data?.status === "SUCCESS") {
        dispatch(setRateDataNew({ data: response?.data }))
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("brokerLoader")
    }
  }
}
export const getBrokerPathDataNew = id => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await BrokerApiServices.getBrokerPathData(id)
      if (response?.data?.status === "SUCCESS") {
        dispatch(setBrokerExtraPathData(response?.data?.data))
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const equifaxSaveDatForBrokerNew = (data, cb) => {
  return async dispatch => {
    try {
      startLoader("brokerLoader")
      const response = await EquifaxServices.equifaxSaveData(data)
      if (response?.data?.status === "SUCCESS") {
        const dataOfPDf = data
        await EquifaxServices.submitPdfData(dataOfPDf)
        successNotification(response?.data?.message)
        if (cb && typeof cb === "function") {
          cb(response?.data?.data?.creditScore)
        }
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("brokerLoader")
    }
  }
}

export const storeRateCardDataNew = ({ id, rateCardId, repayments }, cb) => {
  return async (dispatch, getState) => {
    try {
      const { brokerFormPaths, brokerExtraPathData } = getState()?.brokerReducerNew || {}

      startLoader("brokerLoader")
      /* get path */
      const indexToGetPathFrom = brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
      const indexToGetPathFromAnother = brokerExtraPathData?.navigationHistory?.brokerFormPaths?.findIndex(
        e => e?.[0] === "quoteDetails",
      )

      if (indexToGetPathFrom >= 0) {
        let applicationPaths = brokerFormPaths.slice(indexToGetPathFrom)
        applicationPaths = applicationPaths?.map(e => ({ formKey: e?.[0], subFormKey: e?.[1] || "" }))
        const data = { paths: applicationPaths, rateId: rateCardId, repayments }
        const response = await BrokerApiServices.saveZOHOData(id, data)

        if (response?.data?.status === "SUCCESS") {
          await storeRateCardDataRecursion({ id, data, nextApiCall: response?.data?.nextApiCall, dispatch }, cb)
        }
      } else {
        if (indexToGetPathFromAnother >= 0) {
          let applicationPaths = brokerExtraPathData?.navigationHistory?.brokerFormPaths.slice(
            indexToGetPathFromAnother,
          )
          applicationPaths = applicationPaths?.map(e => ({ formKey: e?.[0], subFormKey: e?.[1] || "" }))
          const data = { paths: applicationPaths, rateId: rateCardId, repayments }
          const response = await BrokerApiServices.saveZOHOData(id, data)

          if (response?.data?.status === "SUCCESS") {
            // successNotification("Application submitted successfully")
            await storeRateCardDataRecursion({ id, data, nextApiCall: response?.data?.nextApiCall, dispatch }, cb)
          }
        }
      }
      /* get path */
    } catch (e) {
      cb(false)
      /**/
    }
  }
}

export const storeRateCardDataRecursion = async ({ id, data, nextApiCall, dispatch }, cb) => {
  const response = await BrokerApiServices.saveZOHODataRecursion(id, data, nextApiCall)
  if (response?.data?.status === "SUCCESS") {
    if (response?.data?.dealId && !getQueryParams(DEAL_ID_QUERY_PARAM))
      addQueryParameter(DEAL_ID_QUERY_PARAM, response?.data?.dealId)
    if (response?.data?.nextApiCall) {
      await storeRateCardDataRecursion({ id, data, nextApiCall: response?.data?.nextApiCall, dispatch }, cb)
    } else {
      const responseQuote = await BrokerApiServices.getQuoteData(id)
      if (responseQuote?.data?.status === "SUCCESS") {
        dispatch(setQuoteAnswerData({ data: responseQuote?.data?.data }))

        const responseApplication = await BrokerApiServices.getApplicationData(id)
        if (responseApplication?.data?.status === "SUCCESS") {
          dispatch(setApplicationAnswerData(responseApplication?.data?.data))
        }
      }
      successNotification("Application submitted successfully")
      stopLoader("brokerLoader")
      cb(true)
    }
  }
}
