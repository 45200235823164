import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { handleOtherQuestionOnSamePage } from "../redux/QuoteActions"
import GetQuoteComponent from "./GetQuoteComponent"
import Question from "./Question/Question"

const MultiFieldForm = props => {
  const { error } = props ?? {}
  const { currentQuoteData, quoteAnswersData, currentForm } = useSelector(({ quoteReducer }) => quoteReducer ?? {})
  const dispatch = useDispatch()

  const answer = quoteAnswersData?.[currentForm]

  useEffect(() => {
    const questionToCheckFlow = currentQuoteData?.fields?.find(e => e?.questionToCheckFlow)
    const selectedAns = quoteAnswersData?.[currentForm]?.[questionToCheckFlow?.fieldName]
    if (
      ["Other", "Visa"]?.includes(selectedAns) &&
      questionToCheckFlow?.otherOnSamePage &&
      !currentQuoteData?.otherField
    ) {
      dispatch(handleOtherQuestionOnSamePage())
    }
  }, [answer, currentQuoteData?.otherField])
  return (
    <div className="quote-multi-fields">
      {currentQuoteData?.fields?.map(e => (
        <React.Fragment key={e?.fieldName}>
          {e?.question && e?.type !== "checkbox" && (
            <Question question={e?.question} description={e?.description} isFromMulti={true} />
          )}
          <GetQuoteComponent data={e} isOtherField={false} error={error} />
        </React.Fragment>
      ))}
      {currentQuoteData?.otherField && (
        <>
          {currentQuoteData?.question && (
            <Question
              question={currentQuoteData?.question}
              toolTipText={currentQuoteData?.toolTipText}
              description={currentQuoteData?.description}
              isFromMulti={false}
            />
          )}
          <GetQuoteComponent data={currentQuoteData?.otherField} isOtherField={true} error={error} />
        </>
      )}
    </div>
  )
}

export default React.memo(MultiFieldForm)
