import PropTypes from "prop-types"
import Header from "../Header/Header"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"
import { useEffect, useMemo } from "react"
import { getApplicationDetail } from "../../screens/auth/redux/AuthActions"
import Loader from "../loader/Loader"

const Layout = props => {
  const { children, location } = props

  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const { isLoginLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const dispatch = useDispatch()
  const history = useHistory()

  const isAppOrQuote = useMemo(() => {
    return (
      location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION) ||
      location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.RATE_CARD) ||
      location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)
    )
  }, [location])

  const isBrokerPage = useMemo(() => {
    return (
      location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.BROKER_ONEPAGE_FORM) ||
      location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM)
    )
  }, [location])

  useEffect(() => {
    if (authStatus && location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.LOGIN)) {
      let myResponse = ROUTE_CONSTANTS_VARIABLE.DASHBOARD
      const zohoId = new URLSearchParams(location?.search).get("zohoid")
      if (zohoId) {
        ;(async () => {
          myResponse = await dispatch(getApplicationDetail(zohoId))
          if (myResponse) {
            history.push(myResponse)
          }
        })()
      }
    }
  }, [location, authStatus])

  if (authStatus && isLoginLoader) {
    return <Loader isLoading={isLoginLoader} />
  }

  if (!authStatus) {
    return children
  } else if (isBrokerPage) {
    return children
  } else {
    return (
      <div className="layout-wrapper">
        <Header />
        <div className={`page-container ${!isAppOrQuote ? "page-container-padding" : ""}`}>{children}</div>
      </div>
    )
  }
}

export default withRouter(Layout)

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.any,
}

Layout.defaultProps = {
  children: null,
}
