import ApiService from "../../../services/ApiServices"
import { AUTH_URLS } from "../../../constants/UrlConstants"

const AuthApiService = {
  loginUser: data => ApiService.postData(AUTH_URLS.LOGIN_URL, data),
  docLoginUser: (data, zohoId) => ApiService.postData(AUTH_URLS.DOC_LOGIN_URL + "?zohoId=" + zohoId, data),
  docWithLoginUser: (data, zohoId) => ApiService.postData(AUTH_URLS.DOC_WITH_LOGIN_URL + "?zohoId=" + zohoId, data),

  registerUser: data => ApiService.postData(AUTH_URLS.REGISTRATION_URL, data),
  getEmailOTP: data => ApiService.postData(AUTH_URLS.EMAIL_OTP_VERIFY, data),
  getOTP: data => ApiService.postData(AUTH_URLS.GET_OTP, data),
}

export default AuthApiService
