import { QUOTE_REDUX_CONSTANTS } from "./QuoteReduxConstants"

const initialState = {
  currentForm: "loanType",
  currentQuestion: "Q1",
  quoteJsonData: {},
  currentQuoteData: { nextQuestions: "" },
  quoteAnswersData: {},
  AbnOrAcnSearchResults: [],
  previousQuestions: [],
  storePreviousQuestions: [],
  fieldsToKeep: [],
  dropDownOptions: {},
  glassGuideData: {},
  glassGuideLabel: {},
  quoteListPage: {
    quoteList: {},
  },
  viewQuoteData: {},
  isAbnDataCalled: true,
  isRegoDataCalled: false,
  isQuoteFirstPage: false,
  isOnlyOneData: false,
}

const getDefaultQuestion = (jsonData, currentForm) => {
  let currentQuestion = "Q1"
  let defaultQuestion = jsonData?.[currentForm]?.[currentQuestion]
  for (const [key, value] in Object.entries(jsonData?.[currentForm])) {
    if (value?.defaultQuestion) {
      defaultQuestion = value
      currentQuestion = key
      break
    }
  }
  return { defaultQuestion, currentQuestion }
}
const checkSkippedQuestion = previousQuestions => {
  let previousQuestionsArr = []
  const previousQuestion = previousQuestions?.pop()
  previousQuestionsArr = previousQuestions
  if (previousQuestion?.canSkip === true) {
    return checkSkippedQuestion(previousQuestionsArr)
  } else {
    return { previousQuestion, previousQuestionsArr }
  }
}
const getCurrentFormData = data => {
  const previousQuestion = data?.pop()
  const currentForm = previousQuestion?.form ?? "loanType"
  const currentQuestion = previousQuestion?.question ?? "Q1"
  return { currentForm, currentQuestion }
}

const getUpdatedFinanceForm = fieldName => {
  switch (fieldName) {
    case "Personal Loan":
      return "financeNumbersPersonalLoan"
    case "Unsecured Business Loan":
      return "financeNumbers"
    case "Cashflow Finance":
    case "Business Loan":
    case "Debtor Finance":
      return "financeBusinessCashFlowDebtor"
    case "Debt Consolidation":
      return "financeNumbersDebtConsolidation"
    case "Insurance":
    case "Warranty":
    case "Other":
      return "financeNumbersInsuranceWarrantyOther"
    default:
      return "financeNumbersDefault"
  }
}
const getUpdatedEmploymentForm = fieldName => {
  switch (fieldName) {
    case "Personal Loan":
    case "Debt Consolidation":
      return "employmentPersonalLoan"
    case "Business Loan":
    case "Cashflow Finance":
    case "Unsecured Business Loan":
    case "Debtor Finance":
      return "employmentBusinessLoan"
    default:
      return "employmentDefault"
  }
}

const getEnableDisableFieldsData = (isDisabled, currentQuoteData, affectedFields = "") => {
  const fieldsToChange = currentQuoteData?.fields?.filter(e => e?.fieldName !== affectedFields)?.map(e => e?.fieldName)

  return currentQuoteData?.fields?.map(e => {
    if (fieldsToChange?.includes(e?.fieldName)) {
      return { ...e, isDisabled: isDisabled || false }
    }
    return e
  })
}

export const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_REDUX_CONSTANTS.SET_QUOTE_JSON_DATA: {
      let currentFormToUpdate
      let currentQuestionToUpdate
      let currentQuestionDataToUpdate

      const { defaultQuestion, currentQuestion } = getDefaultQuestion(action?.data, state?.currentForm)

      if ([...state?.previousQuestions]?.length) {
        const { currentForm, currentQuestion } = getCurrentFormData([...state?.previousQuestions])
        currentFormToUpdate = currentForm
        currentQuestionToUpdate = currentQuestion
        currentQuestionDataToUpdate = {
          ...state?.quoteJsonData?.[currentForm]?.[currentQuestion],
        }
        state?.previousQuestions?.pop()
        state?.storePreviousQuestions?.pop()
      } else {
        currentFormToUpdate = state?.currentForm
        currentQuestionToUpdate = currentQuestion
        currentQuestionDataToUpdate = defaultQuestion
      }
      return {
        ...state,
        quoteJsonData: {
          ...action?.data,
          financeNumbersDefault: action?.data?.financeNumbers,
        },
        currentForm: currentFormToUpdate,
        currentQuestion: currentQuestionToUpdate,
        currentQuoteData: currentQuestionDataToUpdate,
      }
    }

    case QUOTE_REDUX_CONSTANTS.UPDATE_FINANCE_NUMBER_FORM_ON_ASSET_CHANGE: {
      const form = getUpdatedFinanceForm(action?.fieldName)
      const employmentform = getUpdatedEmploymentForm(action?.fieldName)
      return {
        ...state,
        quoteJsonData: {
          ...state?.quoteJsonData,
          financeNumbers: state?.quoteJsonData?.[form],
          employment: state?.quoteJsonData?.[employmentform],
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.UPDATE_ASSET_CONDITION_CHANGE: {
      const affectedForms = ["assetInfo", "vehicleSelect"]
      let updatedForms = {}

      const getFinalForm = formName => {
        const form = state?.quoteJsonData?.[formName]
        let finalObj = { ...form }
        let previousQuestion
        let currentQuestion

        for (const key in form) {
          if (form?.[key]?.fieldName === "kilometers" || form?.[key]?.fieldName === "Kilometres") {
            currentQuestion = form?.[key]
            previousQuestion = currentQuestion?.previousQuestion
          }

          finalObj = {
            ...finalObj,
            [key]: form?.[key]?.fields
              ? {
                  ...form?.[key],
                  fields: form?.[key]?.fields?.filter(
                    e => e?.fieldName !== "kilometers" && e?.fieldName !== "Kilometres",
                  ),
                }
              : form?.[key]?.fieldName === "kilometers" && form?.[key]?.fieldName === "Kilometres"
              ? undefined
              : form?.[key],
          }
        }

        if (previousQuestion) {
          finalObj = {
            ...finalObj,
            [previousQuestion]: {
              ...finalObj?.[previousQuestion],
              nextQuestions: currentQuestion?.nextQuestions,
              defaultQuestions: currentQuestion?.defaultQuestions,
            },
          }
        }

        return finalObj
      }

      affectedForms?.forEach(e => {
        updatedForms = {
          ...updatedForms,
          [e]: getFinalForm(e),
        }
      })

      return {
        ...state,
        quoteJsonData: {
          ...state?.quoteJsonData,
          ...updatedForms,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.HANDLE_OTHER_QUESTION_ON_SAME_PAGE: {
      let otherField
      let nextQuestion
      let questionToCheckFlow
      let questionAnswer

      if (state?.currentQuoteData?.type === "multipleQuestions") {
        questionToCheckFlow = state?.currentQuoteData?.fields?.find(e => e?.questionToCheckFlow)
        questionAnswer = state?.quoteAnswersData?.[state?.currentForm]?.[questionToCheckFlow?.fieldName]
        nextQuestion =
          state?.currentQuoteData?.nextQuestions?.[questionToCheckFlow?.fieldName]?.Other ||
          state?.currentQuoteData?.nextQuestions?.[questionToCheckFlow?.fieldName]?.Visa ||
          state?.currentQuoteData?.nextQuestions?.[questionToCheckFlow?.fieldName]?.Yes
      } else {
        questionAnswer = state?.quoteAnswersData?.[state?.currentForm]?.[state?.currentQuoteData?.fieldName]

        nextQuestion =
          state?.currentQuoteData?.nextQuestions?.Other ||
          state?.currentQuoteData?.nextQuestions?.Visa ||
          state?.currentQuoteData?.nextQuestions?.Yes
      }
      if (["Other", "Visa", "Yes"]?.includes(questionAnswer)) {
        for (const [key, value] of Object.entries(state?.quoteJsonData?.[state?.currentForm])) {
          if (key === nextQuestion?.[0]) {
            otherField = value
            break
          }
        }
      }
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          otherField,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_ANSWER_DATA: {
      let otherField
      let fieldToRemove
      let updatedQuoteAnswer

      if (action?.isOtherField) {
        otherField = {
          ...state?.currentQuoteData?.otherField,
        }
      }

      if (
        !["Other", "Visa", "Yes"]?.includes(action?.fieldValue) &&
        !action?.isOtherField &&
        state?.currentQuoteData?.otherField
      ) {
        fieldToRemove = state?.currentQuoteData?.otherField?.fieldName
      }

      if (fieldToRemove) {
        updatedQuoteAnswer = {
          ...state?.quoteAnswersData,
          [state?.currentForm]: {
            ...state?.quoteAnswersData?.[state?.currentForm],
            [action?.fieldName]: action?.fieldValue,
            [fieldToRemove]: undefined,
          },
        }
      } else {
        updatedQuoteAnswer = {
          ...state?.quoteAnswersData,
          [state?.currentForm]: {
            ...state?.quoteAnswersData?.[state?.currentForm],
            [action?.fieldName]: action?.fieldValue,
          },
        }
      }

      return {
        ...state,
        quoteAnswersData: updatedQuoteAnswer,
        currentQuoteData: {
          ...state?.currentQuoteData,
          otherField,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.SET_QUOTE_ID:
      return {
        ...state,
        quoteId: action?.id,
      }

    case QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_DATA_ON_NEXT_CLICK: {
      const { defaultQuestion, currentQuestion } = getDefaultQuestion(state?.quoteJsonData, action?.form)
      const storePreviousQuestions = [
        ...state?.storePreviousQuestions,
        ...(!action.canSkip
          ? [
              {
                form: state?.currentForm,
                question: state?.currentQuestion,
              },
            ]
          : []),
      ]

      const isAlreadyVisited = state?.storePreviousQuestions?.findIndex(
        e => e?.form === action?.form && e?.question === currentQuestion,
      )

      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          [state?.currentForm]: {
            ...state?.quoteAnswersData?.[state?.currentForm],
            isBlocked: false,
            isCompleted: true,
          },
        },
        previousQuestions: [
          ...state?.previousQuestions,
          {
            form: state?.currentForm,
            question: state?.currentQuestion,
            canSkip: action.canSkip,
          },
        ],
        storePreviousQuestions: storePreviousQuestions.filter(
          ({ form, question }, index, array) =>
            array.findIndex(e => e.form === form && e.question === question) === index,
        ),
        currentForm: action?.form,
        currentQuestion,
        currentQuoteData: { ...defaultQuestion, isAlreadyVisited: isAlreadyVisited >= 0 },
      }
    }

    case QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_QUESTION_ERROR: {
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          error: action?.errorMessages,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_NEXT_CLICK: {
      const storePreviousQuestions = [
        ...state?.storePreviousQuestions,
        {
          form: state?.currentForm,
          question: state?.currentQuestion,
          canSkip: action.canSkip,
        },
      ]

      const nextQuestion = action?.data

      const isAlreadyVisited = state?.storePreviousQuestions?.findIndex(
        e => e?.form === state?.currentForm && e?.question === nextQuestion?.[0],
      )

      for (const [key, value] of Object.entries(state?.quoteJsonData?.[state?.currentForm])) {
        if (nextQuestion && key === nextQuestion?.[0]) {
          return {
            ...state,
            previousQuestions: [
              ...state?.previousQuestions,
              {
                form: state?.currentForm,
                question: state?.currentQuestion,
                canSkip: action.canSkip,
              },
            ],
            storePreviousQuestions: storePreviousQuestions.filter(
              ({ form, question }, index, array) =>
                array.findIndex(e => e.form === form && e.question === question) === index,
            ),
            currentQuoteData: {
              ...value,
              isAlreadyVisited: isAlreadyVisited >= 0,
            },
            currentQuestion: key,
          }
        }
      }
      return state
    }

    case QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_BACK_CLICK: {
      const { previousQuestion, previousQuestionsArr } = checkSkippedQuestion([...state.previousQuestions]) ?? {}
      let prevQuestion = previousQuestion
      const previousQuestions = previousQuestionsArr
      let removeEarlyPrevious = false

      let prevQuestionData = state?.quoteJsonData?.[prevQuestion?.form]?.[prevQuestion?.question]

      let isAlreadyVisited = state?.storePreviousQuestions?.findIndex(
        e => e?.form === prevQuestion?.form && e?.question === prevQuestion?.question,
      )
      if (prevQuestionData?.option !== undefined && prevQuestionData?.option.length === 1) {
        removeEarlyPrevious = true
      }

      if (prevQuestion) {
        if (!removeEarlyPrevious) {
          return {
            ...state,
            currentForm: prevQuestion?.form,
            currentQuestion: prevQuestion?.question,
            currentQuoteData: { ...prevQuestionData, isAlreadyVisited: isAlreadyVisited >= 0 },
            previousQuestions: previousQuestions,
          }
        } else {
          prevQuestion = previousQuestions?.pop()
          prevQuestionData = state?.quoteJsonData?.[prevQuestion?.form]?.[prevQuestion?.question]
          isAlreadyVisited = state?.storePreviousQuestions?.findIndex(
            e => e?.form === prevQuestion?.form && e?.question === prevQuestion?.question,
          )
          return {
            ...state,
            currentForm: prevQuestion?.form,
            currentQuestion: prevQuestion?.question,
            currentQuoteData: { ...prevQuestionData, isAlreadyVisited: isAlreadyVisited >= 0 },
            previousQuestions: previousQuestions,
          }
        }
      }
      return state
    }

    case QUOTE_REDUX_CONSTANTS.UPDATE_ANSWERS_ON_PREV_CHANGES: {
      let answersOnUpdate = {}
      let formsToKeep = state?.previousQuestions?.map(e => e?.form)

      if (state?.currentForm) formsToKeep = [...formsToKeep, state?.currentForm]
      let ansToKeep = state?.previousQuestions
        ?.map(({ form, question }) => {
          if (state?.quoteJsonData?.[form]?.[question]?.fields?.length > 0) {
            return state?.quoteJsonData?.[form]?.[question]?.fields?.map(e => e?.fieldName)
          }

          return state?.quoteJsonData?.[form]?.[question]?.fieldName
        })
        .flat()

      const ansToKeepOther = state?.previousQuestions
        ?.map(({ form, question }) => {
          if (state?.quoteAnswersData?.[form]?.[state?.quoteJsonData?.[form]?.[question]?.fieldName] === "Other") {
            const keyOfQuestion = state?.quoteJsonData?.[form]?.[question]?.nextQuestions?.Other?.[0]
            const fieldNameofOther = state?.quoteJsonData?.[form]?.[keyOfQuestion]?.fieldName
            return fieldNameofOther
          }
          return ""
        })
        .flat()
      const filtered = ansToKeepOther.filter(el => {
        return el !== ""
      })
      if (ansToKeepOther !== "") {
        ansToKeep = filtered.concat(ansToKeep)
      }
      ansToKeep.filter(n => n)

      const anstoKeepDefaultSelected = []
      const anstoKeepMerge = []

      for (const compForms in state?.quoteAnswersData) {
        for (const compFormField in state?.quoteAnswersData?.[compForms]) {
          if (compFormField !== "isBlocked" && compFormField !== "isCompleted") {
            anstoKeepDefaultSelected.push(compFormField)
          }
        }
      }
      for (const jsonForms in state?.quoteJsonData) {
        for (const jsonFormField in state?.quoteJsonData?.[jsonForms]) {
          for (const jsonFormFieldData in state?.quoteJsonData?.[jsonForms]?.[jsonFormField]) {
            if (
              jsonFormFieldData === "option" &&
              state?.quoteJsonData?.[jsonForms]?.[jsonFormField]?.[jsonFormFieldData].length === 1 &&
              anstoKeepDefaultSelected.includes(state?.quoteJsonData?.[jsonForms]?.[jsonFormField]?.fieldName)
            ) {
              anstoKeepMerge.push(state?.quoteJsonData?.[jsonForms]?.[jsonFormField]?.fieldName)
            }
          }
        }
      }
      ansToKeep = anstoKeepMerge.concat(ansToKeep)
      ansToKeep.filter(n => n)

      if (action?.fieldsToKeep?.length) {
        ansToKeep = [...ansToKeep, ...action?.fieldsToKeep]
      }
      if (ansToKeep.includes("nvic")) {
        ansToKeep = [...ansToKeep, ...["model"]]
      }
      if (ansToKeep.includes("assetDescription")) {
        ansToKeep = [...ansToKeep, ...["LVR", "assetAge", "assetBadge", "assetSeries"]]
      }
      if (ansToKeep.includes("placingDeposit")) {
        ansToKeep = [...ansToKeep, ...["deposit"]]
      }
      if (ansToKeep.includes("tradeIn")) {
        ansToKeep = [...ansToKeep, ...["tradeInAmount"]]
      }
      if (ansToKeep.includes("payout")) {
        ansToKeep = [...ansToKeep, ...["payoutAmount"]]
      }
      if (ansToKeep.includes("isBalloon")) {
        ansToKeep = [...ansToKeep, ...["balloon"]]
      }
      if (ansToKeep.includes("vin")) {
        ansToKeep = [
          ...ansToKeep,
          ...[
            "modelName",
            "yearCreate",
            "CCName",
            "ManufacturerName",
            "FamilyName",
            "VariantName",
            "SeriesName",
            "Engine_ConfigName",
            "EngineName",
            "TransmissionName",
            "Retail",
            "engineNumber",
            "nvic",
            "yearOfManufacture",
            "compliancePlate",
            "modelTypeCode",
            "manufacturerCode",
          ],
        ]
      }

      Object.keys(state?.quoteAnswersData)?.forEach(form => {
        Object?.keys(state?.quoteAnswersData?.[form])?.forEach(field => {
          if (["isCompleted", "isBlocked"]?.includes(field)) {
            answersOnUpdate = {
              ...answersOnUpdate,
              [form]: {
                ...answersOnUpdate?.[form],
                [field]: state?.quoteAnswersData?.[form]?.[field],
              },
            }
          } else {
            answersOnUpdate = {
              ...answersOnUpdate,
              [form]: {
                ...answersOnUpdate?.[form],
                [field]: ansToKeep?.includes(field) ? state?.quoteAnswersData?.[form]?.[field] : undefined,
              },
            }
          }
        })
      })
      let finalAnsData = {}
      Object.keys(answersOnUpdate)?.forEach(form => {
        if (formsToKeep?.includes(form)) {
          finalAnsData = {
            ...finalAnsData,
            [form]: {
              ...answersOnUpdate?.[form],
            },
          }
        }
      })
      return {
        ...state,
        quoteAnswersData: finalAnsData,
        currentQuoteData: {
          ...state?.currentQuoteData,
          isAlreadyVisited: false,
        },
        storePreviousQuestions: state?.previousQuestions,
      }
    }

    case QUOTE_REDUX_CONSTANTS.WIPE_OUT_DATA_ON_FIELD_CHANGES: {
      const { quoteAnswersData, currentForm } = state ?? {}
      const answerToUpdate = { ...quoteAnswersData?.[currentForm] } ?? {}
      Object.keys(answerToUpdate)?.forEach(fieldName => {
        if (action?.fieldsToWipeOut?.includes(fieldName)) {
          answerToUpdate[fieldName] = undefined
        }
      })
      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          [currentForm]: answerToUpdate,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.SET_OPTIONS_FROM_API: {
      const { table, ...restData } = action?.data
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          error: [],
        },
        dropDownOptions: {
          ...state?.dropDownOptions,
          glassGuideOptions: table ?? [],
        },
        glassGuideData: {
          ...state?.glassGuideData,
          ...restData,
        },
        glassGuideLabel: {
          ...state?.glassGuideLabel,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.RESET_OPTIONS_FROM_API:
      return {
        ...state,
        dropDownOptions: {
          ...state?.dropDownOptions,
          glassGuideOptions: [],
        },
      }

    case QUOTE_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_ANS_DATA:
      return {
        ...state,
        glassGuideData: {
          ...state?.glassGuideData,
          [action?.fieldName]: action?.fieldValue,
        },
      }
    case QUOTE_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_LABEL_DATA:
      return {
        ...state,
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          [action?.fieldName]: action?.fieldLabel,
        },
      }

    case QUOTE_REDUX_CONSTANTS.ABN_ACN.ON_ABN_OR_ACN_CHANGE:
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          error: [],
        },
        AbnOrAcnSearchResults: action?.data,
      }

    case QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_ANSWER_DATA_FIELDS:
      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          [state?.currentForm]: {
            ...state?.quoteAnswersData?.[state?.currentForm],
            ...action?.data,
          },
        },
        glassGuideData: {
          ...state?.glassGuideData,
          ...action?.data,
        },
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          ...action?.data,
        },
      }

    case QUOTE_REDUX_CONSTANTS.ABN_ACN.RESET_ABN_DATA: {
      const dataToUpdate = {
        abn: undefined,
        registeredDate: undefined,
        isActive: undefined,
        acn: undefined,
        stateCode: undefined,
        postCode: undefined,
        entityName: undefined,
        gstStatus: undefined,
        businessName: undefined,
      }
      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          [state?.currentForm]: {
            ...state?.quoteAnswersData?.[state?.currentForm],
            ...dataToUpdate,
          },
        },
        glassGuideData: {
          ...state?.glassGuideData,
          ...dataToUpdate,
        },
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          ...dataToUpdate,
        },
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_EXISTING_QUOTE_DATA: {
      const { _id, previousQuestions, quoteAnswersData, glassGuideData, glassGuideLabel } = action?.data ?? {}
      let { currentForm, currentQuestion } = getCurrentFormData([...previousQuestions])
      let previousQuestionNew = previousQuestions
      if (action.isQuoteFirstPage) {
        currentForm = "loanType"
        currentQuestion = "Q1"
        previousQuestionNew = []
      }

      previousQuestionNew?.pop()
      return {
        ...state,
        quoteId: _id,
        currentForm,
        currentQuestion,
        quoteAnswersData,
        previousQuestions: previousQuestionNew,
        storePreviousQuestions: previousQuestions,
        glassGuideData,
        glassGuideLabel,
        currentQuoteData: { ...state.quoteJsonData?.[currentForm]?.[currentQuestion], isAlreadyVisited: true },
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_VIEW_QUOTE_DATA: {
      return {
        ...state,
        viewQuoteData: action?.data,
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_ABN_CALLED: {
      return {
        ...state,
        isAbnDataCalled: action?.data,
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_REGO_CALLED: {
      return {
        ...state,
        isRegoDataCalled: action?.rego_called,
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_EDIT_CALLED: {
      return {
        ...state,
        isQuoteFirstPage: action?.data,
      }
    }
    case QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED: {
      return {
        ...state,
        isOnlyOneData: action?.data,
      }
    }

    case QUOTE_REDUX_CONSTANTS.QUOTE_LIST.GET_QUOTE_LIST:
      return {
        ...state,
        quoteListPage: {
          quoteList: {
            ...action?.data,
          },
        },
      }

    case QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.RESET_REGO_DATA: {
      const dataToUpdate = {
        rego: undefined,
        state: undefined,
        yearCreate: undefined,
        modelName: undefined,
        Retail: undefined,
        engineNumber: undefined,
        vin: undefined,
        nvic: undefined,
        yearOfManufacture: undefined,
        compliancePlate: undefined,
      }
      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          carSearch: {
            ...state?.currentQuoteData?.carSearch,
            ...dataToUpdate,
          },
        },
        glassGuideData: {
          ...state?.glassGuideData,
          ...dataToUpdate,
        },
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          ...dataToUpdate,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.RESET_REGO_DATA_PARTIAL: {
      const dataToUpdatePartial = {
        state: undefined,
        yearCreate: undefined,
        modelName: undefined,
        Retail: undefined,
        engineNumber: undefined,
        vin: undefined,
        nvic: undefined,
        yearOfManufacture: undefined,
        compliancePlate: undefined,
      }
      return {
        ...state,
        quoteAnswersData: {
          ...state?.quoteAnswersData,
          carSearch: {
            ...state?.quoteAnswersData?.carSearch,
            ...dataToUpdatePartial,
          },
        },
        glassGuideData: {
          ...state?.glassGuideData,
          ...dataToUpdatePartial,
        },
        glassGuideLabel: {
          ...state?.glassGuideLabel,
          ...dataToUpdatePartial,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.DISABLED_QUOTE_FIELDS: {
      const updatedFields = getEnableDisableFieldsData(true, { ...state?.currentQuoteData }, action?.fieldToNotDisable)
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          fields: updatedFields,
        },
      }
    }

    case QUOTE_REDUX_CONSTANTS.CHANGE_QUOTE_FIELDS_VALUE: {
      const updatedFieldsValue = action?.FinanceWhatAns
      let optiondata = action?.optionVal

      if (updatedFieldsValue === "Personal Loan") {
        optiondata = optiondata.filter(function(item) {
          return item !== "Business Use"
        })
        //   optiondata.pop("Business Use")
      }
      if (updatedFieldsValue === "Business Loan") {
        optiondata = optiondata.filter(function(item) {
          return item !== "Personal Use"
        })
        //  optiondata.pop("Personal Use")
      }
      return {
        ...state,
        quoteJsonData: {
          ...state?.quoteJsonData,
          employment: {
            ...state?.quoteJsonData?.employment,
            Q1: {
              ...state?.quoteJsonData?.employment?.Q1,
              option: optiondata,
            },
          },
        },
      }
    }
    case QUOTE_REDUX_CONSTANTS.ENABLE_QUOTE_FIELDS: {
      const updatedFields = getEnableDisableFieldsData(false, { ...state?.currentQuoteData })
      return {
        ...state,
        currentQuoteData: {
          ...state?.currentQuoteData,
          fields: updatedFields,
        },
      }
    }
    case QUOTE_REDUX_CONSTANTS.UPDATE_PREVIOUS_QUESTION_DETAILS_MANUAL: {
      const currentForm = action?.currentForm
      const currentQuestion = action?.currentQuestion
      const prevQuAvailable = state?.previousQuestions?.map(e => {
        if (e?.question === currentQuestion) {
          return true
        }
        return false
      })
      const storePrevQuAvailable = state?.storePreviousQuestions?.map(e => {
        if (e?.question === currentQuestion) {
          return true
        }
        return false
      })
      if (!prevQuAvailable && !storePrevQuAvailable) {
        return {
          ...state,
          previousQuestions: [
            ...state?.previousQuestions,
            {
              form: currentForm,
              question: currentQuestion,
            },
          ],
          storePreviousQuestions: [
            ...state?.storePreviousQuestions,
            {
              form: currentForm,
              question: currentQuestion,
            },
          ],
        }
      } else if (!prevQuAvailable && storePrevQuAvailable) {
        return {
          ...state,
          previousQuestions: [
            ...state?.previousQuestions,
            {
              form: currentForm,
              question: currentQuestion,
            },
          ],
          storePreviousQuestions: [...state?.storePreviousQuestions],
        }
      } else if (prevQuAvailable && !storePrevQuAvailable) {
        return {
          ...state,
          previousQuestions: [...state?.previousQuestions],
          storePreviousQuestions: [
            ...state?.storePreviousQuestions,
            {
              form: currentForm,
              question: currentQuestion,
            },
          ],
        }
      } else {
        return {
          ...state,
          previousQuestions: [...state?.previousQuestions],
          storePreviousQuestions: [...state?.storePreviousQuestions],
        }
      }
    }

    case QUOTE_REDUX_CONSTANTS.SET_QUOTE_LAST_QUESTION:
      return {
        ...state,
        currentQuoteData: {
          ...state.currentQuoteData,
          isLastQuestion: action?.value || false,
        },
      }

    case QUOTE_REDUX_CONSTANTS.SKIP_QUOTE_SCREEN:
      return {
        ...state,
        currentQuoteData: {
          ...state.currentQuoteData,
          canSkip: action?.value,
        },
      }

    case QUOTE_REDUX_CONSTANTS.UPDATE_PREVIOUS_QUESTION_DETAILS:
      return {
        ...state,
        previousQuestions: [
          ...state?.previousQuestions,
          {
            form: state?.currentForm,
            question: state?.currentQuestion,
            canSkip: action.canSkip,
          },
        ],
        storePreviousQuestions: [
          ...state?.storePreviousQuestions,
          {
            form: state?.currentForm,
            question: state?.currentQuestion,
            canSkip: action.canSkip,
          },
        ],
      }

    case QUOTE_REDUX_CONSTANTS.RESET_QUOTE_REDUCER:
      return initialState

    default:
      return state
  }
}
