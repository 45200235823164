import { cloneDeep, get, last, uniq, update } from "lodash"
import { useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  checkFieldVisibilityCondition,
  checkNextQuestionCondition,
  formatAnswerForKey,
  getCloneDeep,
  getNextQuestionsFromAnswer,
  isObject,
  isValidAnswer,
} from "../../../helpers/applicationHelper"
import { formVisibilitySubForm } from "../../../helpers/formVisibilitySubForm"
import { ASSET, COMMON_QUESTION_SUB_FORM } from "../ApplicationPage/ApplicationPageConstants"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { applicationSelector } from "../redux/ApplicationSelector"

export const useUpdateFields = () => {
  const { applicationJsonData, formsKey, subFormsKey, savedFormData, fieldsToShow, dataForActiveSave } = useSelector(
    applicationSelector || {},
  )
  const dispatch = useDispatch()
  const isFieldUpdated = useRef(false)

  const haveSubForms = useMemo(() => subFormsKey?.length > 0, [subFormsKey])
  const lastFormKey = useMemo(() => last(formsKey), [formsKey])

  // checks that field should be allowed
  const isFieldAllowed = (field, fields = []) => {
    if (Array.isArray(field?.fieldVisibility) && field?.fieldVisibility?.length > 0) {
      const savedFormDataNew = cloneDeep(savedFormData)
      if (["asset1"].includes(formsKey[0])) {
        update(savedFormDataNew, `${formsKey[0]}.assetDetails[1].answer`, () => {
          if (fieldsToShow?.[0]?.[1]?.fieldName === "assetType") {
            return fieldsToShow?.[0]?.[1]?.answer
          } else {
            return get(savedFormData, `${formsKey[0]}.assetDetails[1].answer`)
          }
        })
      }
      if (["asset2", "asset3", "asset4"].includes(formsKey[0])) {
        update(savedFormDataNew, `${formsKey[0]}.assetDetails[1]`, () => {
          if (fieldsToShow?.[1]?.[1]?.fieldName === "assetType") {
            return fieldsToShow?.[1]?.[1]
          } else {
            return get(savedFormData, `${formsKey[0]}.assetDetails[1]`)
          }
        })
      }
      return checkFieldVisibilityCondition(field, savedFormDataNew, fields)
    }
    return isValidAnswer(field?.answer) || field?.defaultQuestion
  }
  // get fields from JSON data using form and subform keys
  const getJSONFormFields = (formKey, subFormKey) => {
    return getCloneDeep(
      applicationJsonData,
      haveSubForms && formKey !== subFormKey ? `${formKey}.${subFormKey}` : `${formKey}`,
    )
  }
  /*
  Get fields by combining data of flow JSON and saved data
  criteria to add field
  - looks for answer key
  - if answer key has nextQuestions key
  how it works
  - gets all fields from JSON and saved data
  - creates array of keys. it'll add answered fields and nextQuestions fields
  - finds fields from unique keys. if there are multiple fields it'll select best one (have answer or default question)
*/
  const keysOfFieldsToKeep = allFields => {
    if (Array.isArray(allFields) && allFields?.length > 0) {
      return uniq(
        allFields.reduce((acc, x, i, self) => {
          // added default question because it was taking field which had answer after changing option
          if (x?.defaultQuestion && isFieldAllowed(x, self)) {
            acc.push(x?.key)
          }
          const nextQuestions = getNextQuestionsFromAnswer(x?.nextQuestions, x?.answer)
          if (nextQuestions && checkNextQuestionCondition(x)) {
            acc.push(...nextQuestions)
          }
          return acc
        }, []),
      )
    } else {
      return []
    }
  }
  const getNextQuestions = (nextQuestions, answer, needAllNextQuestions = true) => {
    if (Array.isArray(nextQuestions)) {
      return nextQuestions
    }
    if (isObject(nextQuestions)) {
      if ("default" in nextQuestions && answer && answer?.toLowerCase?.() !== "other") {
        return nextQuestions.default
      }
      if (answer && nextQuestions?.[answer]?.length > 0) {
        return nextQuestions[answer]
      }
      if (!answer && needAllNextQuestions) return Object.values(nextQuestions).flatMap(x => x)
    }
    return []
  }
  // eslint-disable-next-line camelcase
  const RECURSION_removeKeys = (fieldKeys, fields, answer) => {
    return fieldKeys.reduce((acc, key) => {
      acc.push(key)
      const findField = fields.find(x => x?.key === key)
      const nextQuestions = getNextQuestions(findField?.nextQuestions, answer)
      if (findField && nextQuestions?.length > 0) {
        acc.push(RECURSION_removeKeys(nextQuestions, fields, answer))
      }
      return acc
    }, [])
  }

  const keysOfFieldsToRemove = (allFields, deletedField) => {
    const keysToKeep = keysOfFieldsToKeep(allFields)
    return uniq(
      allFields
        .reduce((acc, x, i, self) => {
          if (x?.key === deletedField?.key) {
            let nextQuestions = getNextQuestions(x?.nextQuestions, formatAnswerForKey(deletedField?.answer))
            nextQuestions = nextQuestions.filter(x => !keysToKeep.includes(x))
            if (!x?.answer && nextQuestions?.length > 0) {
              acc.push(RECURSION_removeKeys(nextQuestions, self, formatAnswerForKey(deletedField?.answer)))
            }
          }
          return acc
        }, [])
        .flat(Infinity),
    )
  }
  const getCorrectFields = (allFields, fieldToKeepKeys) => {
    const fields = []
    fieldToKeepKeys.forEach(key => {
      const fieldsWithSameKey = allFields.filter(x => x?.key === key)
      if (fieldsWithSameKey?.length === 1) {
        const savedFormDataNew = cloneDeep(savedFormData)
        if (["asset1"].includes(formsKey[0])) {
          update(savedFormDataNew, `${formsKey[0]}.assetDetails[1].answer`, () => {
            if (fieldsToShow?.[0]?.[1]?.fieldName === "assetType") {
              return fieldsToShow?.[0]?.[1]?.answer
            } else {
              return get(savedFormData, `${formsKey[0]}.assetDetails[1].answer`)
            }
          })
        }
        if (["asset2", "asset3", "asset4"].includes(formsKey[0])) {
          update(savedFormDataNew, `${formsKey[0]}.assetDetails[1]`, () => {
            if (fieldsToShow?.[1]?.[1]?.fieldName === "assetType") {
              return fieldsToShow?.[1]?.[1]
            } else {
              return get(savedFormData, `${formsKey[0]}.assetDetails[1]`)
            }
          })
        }
        if (checkFieldVisibilityCondition(fieldsWithSameKey[0], savedFormDataNew, allFields)) {
          fields.push(fieldsWithSameKey[0])
        }
      } else {
        const fieldsWithAnswer = fieldsWithSameKey.filter(x => isValidAnswer(x))
        const fieldsWithDefault = fieldsWithSameKey.filter(x => isFieldAllowed(x))
        if (fieldsWithAnswer?.length > 0) fields.push(fieldsWithAnswer[0])
        else if (fieldsWithDefault?.length > 0) fields.push(fieldsWithDefault[0])
      }
    })
    return fields
  }
  const getFields = (formKey, subFormKey, removeSavedFormFields = false) => {
    const savedFormFields = getCloneDeep(
      savedFormData,
      haveSubForms && formKey !== subFormKey ? `${formKey}.${subFormKey}` : `${formKey}`,
      [],
    )
    const JSONFormFields = getJSONFormFields(formKey, subFormKey) || []
    const allFields = [
      removeSavedFormFields ? [] : Array.isArray(savedFormFields) ? savedFormFields : savedFormFields?.fields || [],
      JSONFormFields?.fields || [],
    ].flat()
    const fieldToKeepKeys = keysOfFieldsToKeep(allFields)
    return getCorrectFields(allFields, fieldToKeepKeys)
  }

  // get fields from subformKey and add it to fieldsToShow
  const updateFieldsToShowAccordingToSubformsAndFirstQuestion = (customSubFormsKey = formsKey) => {
    if (subFormsKey?.length > 0) {
      customSubFormsKey = subFormsKey
    }

    if (customSubFormsKey.length > 0) {
      const fieldsToUpdate = []
      customSubFormsKey.forEach((form, i) => {
        const path = subFormsKey?.length > 0 ? [lastFormKey, form, "fields", 0] : [form, "fields", 0]
        const firstQuestionJSON = getCloneDeep(applicationJsonData, path)
        const haveInFieldsToShowIndex = fieldsToShow.findIndex(
          fields => fields?.[0]?.fieldName === firstQuestionJSON?.fieldName,
        )
        if (haveInFieldsToShowIndex > -1) {
          fieldsToUpdate[i] = [...fieldsToShow[haveInFieldsToShowIndex]]
        } else {
          fieldsToUpdate[i] = getFields(lastFormKey, form, form === ASSET.ASSET_GLASS_GUIDE && isFieldUpdated.current)
        }
      })

      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
        payload: fieldsToUpdate,
      })
    }
  }

  // get fields from all form keys(formkey & subformkey) and add it to fieldsToShow
  const updateFieldsToShowOnRefresh = customSubForms => {
    const forms = customSubForms || haveSubForms ? subFormsKey : formsKey

    const updatedFields = forms.reduce((acc, subFormKey) => {
      acc.push(getFields(lastFormKey, subFormKey))

      return acc
    }, [])
    dispatch({ type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW, payload: updatedFields })
  }

  const subformfilterByFormVisibility = subForms => {
    return subForms.filter((subForm, i) => {
      const subformData = getCloneDeep(applicationJsonData, [last(formsKey), subForm])
      return formVisibilitySubForm(subformData, fieldsToShow, i)
    })
  }

  const copyAsset = (updatedField, updatedForm, index) => {
    const subFormsToCopy = updatedField.copyForms?.fieldsToCopy
    const copyFromFormName = updatedField.copyForms.formName
    const isAdd = !!updatedField?.answer
    const modifiedFieldsToShow = [...fieldsToShow]
    modifiedFieldsToShow[index] = updatedForm
    let updatedFieldsToShow = cloneDeep(modifiedFieldsToShow)
    // START   -------------------------------------------------------------- Update subforms keys based on subFormsToCopy values
    let newSubFormsKey = cloneDeep(subFormsKey.filter(x => !subFormsToCopy.includes(x)))
    const commonQuestionIndex = newSubFormsKey.findIndex(x => x === COMMON_QUESTION_SUB_FORM) + 1
    const mergeFormOfCommonQuestionForm = getCloneDeep(applicationJsonData, [
      last(formsKey),
      newSubFormsKey[commonQuestionIndex - 1],
      "mergeFormName",
    ])
    // Only copy subforms of asset1 where it has data because subforms are condtional based so may not be available
    const subFormsWithData = subFormsToCopy.filter(subf => {
      const copyFromFields = get(savedFormData, [copyFromFormName, subf])
      return Array.isArray(copyFromFields) && copyFromFields?.length > 0
    })

    // Add new subforms
    newSubFormsKey.splice(commonQuestionIndex, 0, ...(isAdd ? subFormsWithData : [mergeFormOfCommonQuestionForm]))
    // END     -------------------------------------------------------------- Update subforms keys based on subFormsToCopy values

    // START   -------------------------------------------------------------- Update fieldsToShow based on subFormsToCopy
    const currentFormKey = last(formsKey)
    if (isAdd) {
      const newFieldsBasedOnSubFormsToCopy = subFormsToCopy.reduce((acc, subForm) => {
        const fields = getCloneDeep(applicationJsonData, [currentFormKey, subForm, "fields"])
        const copyFromFields = getCloneDeep(savedFormData, [copyFromFormName, subForm])
        if (Array.isArray(copyFromFields) && copyFromFields?.length > 0) {
          const keys = keysOfFieldsToKeep([...copyFromFields, ...fields])
          const fieldsWithAnswer = fields
            .map(field => {
              const found = copyFromFields.find(x => x?.key === field?.key && x?.fieldName === field?.fieldName)
              if (found && "answer" in found) {
                field.answer = found.answer
                return field
              } else if (keys.includes(field?.key)) {
                return found || field
              } else {
                return undefined
              }
            })
            .filter(x => x)
          if (fieldsWithAnswer) {
            acc.push({ subForm, fields: fieldsWithAnswer })
          }
        }
        return acc
      }, [])

      newSubFormsKey.forEach((subform, i) => {
        const firstQuestionJSON = getCloneDeep(applicationJsonData, [currentFormKey, subform, "fields", 0])
        const haveInFieldsToShowIndex = modifiedFieldsToShow.findIndex(
          fields => fields?.[0]?.fieldName === firstQuestionJSON?.fieldName,
        )

        const haveInNewFields = newFieldsBasedOnSubFormsToCopy.find(x => x?.subForm === subform)
        if (haveInNewFields) {
          updatedFieldsToShow[i] = haveInNewFields?.fields
        } else if (haveInFieldsToShowIndex > -1) {
          updatedFieldsToShow[i] = modifiedFieldsToShow[haveInFieldsToShowIndex]
        } else {
          updatedFieldsToShow[i] = getCloneDeep(applicationJsonData, [currentFormKey, subform, "fields"])?.filter?.(
            x => x?.defaultQuestion,
          )
        }
      })
    } else {
      const isVendorForm = !!updatedFieldsToShow
        .flat()
        .find(e => ["sameAssetDetailsAs", "assetYouAfter"].includes(e?.fieldName))?.answer
      const formsKeyToCheck = ["commonQuestion", "assetDetails"]
      if (isVendorForm) formsKeyToCheck.push("assetVendorDetails")
      newSubFormsKey = newSubFormsKey.filter(e => formsKeyToCheck.includes(e))
      const indexesToRemove = subFormsKey.reduce((acc, subForm, i) => {
        if (!newSubFormsKey.includes(subForm)) {
          acc.push(i)
        }
        return acc
      }, [])
      updatedFieldsToShow = updatedFieldsToShow.filter((_, i) => !indexesToRemove.includes(i))
      newSubFormsKey.forEach((subform, i) => {
        if (subFormsToCopy.includes(subform)) {
          const fields = getCloneDeep(applicationJsonData, [currentFormKey, subform, "fields"])?.filter?.(
            x => x?.defaultQuestion,
          )
          updatedFieldsToShow[i] = fields
        }
      })
    }
    // END     -------------------------------------------------------------- Update fieldsToShow based on subFormsToCopy

    /* remove pricing */
    // const pricingSubformIndex = newSubFormsKey.findIndex(x => x === "assetPricingDetails")
    // if (pricingSubformIndex > -1) {
    //   newSubFormsKey.splice(pricingSubformIndex, 1)
    //   updatedFieldsToShow.splice(pricingSubformIndex, 1)
    // }
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.SET_SUB_FORM_KEY_AND_FIELDS_TO_SHOW,
      payload: { subFormsKey: newSubFormsKey, fieldsToShow: updatedFieldsToShow },
    })
  }
  const copyVendor = (updatedField, updatedForm, index) => {
    const isNew = updatedField?.answer?.toLowerCase?.() === "new"
    const subFormsToCopy = updatedField.copyForms?.fieldsToCopy
    const copyFromFormName = updatedField.copyForms.formName[updatedField?.answer] || ""
    const isAdd = !!updatedField?.answer && !isNew
    // START   -------------------------------------------------------------- Copy state
    const currentFormKey = last(formsKey)
    const modifiedFieldsToShow = [...fieldsToShow]
    modifiedFieldsToShow[index] = updatedForm
    let newSubFormsKey = cloneDeep(subFormsKey)
    let newFieldsToShow = cloneDeep(modifiedFieldsToShow)
    // END     -------------------------------------------------------------- Copy state

    if (isAdd) {
      subFormsToCopy.forEach(subform => {
        const jsonFields = getCloneDeep(applicationJsonData, [currentFormKey, subform, "fields"])
        const savedFields = getCloneDeep(savedFormData, [copyFromFormName, subform])

        const keys = keysOfFieldsToKeep([...savedFields, ...jsonFields])
        const finalFields = jsonFields
          .map(field => {
            const found = savedFields.find(x => x?.key === field?.key && x?.fieldName === field?.fieldName)
            if (found && "answer" in found) {
              field.answer = found.answer
              return field
            } else if (keys.includes(field?.key)) {
              return found || field
            } else {
              return undefined
            }
          })
          .filter(x => x)
        if (newSubFormsKey.includes(subform)) {
          // add fresh json fields
          const subformIndex = newSubFormsKey.findIndex(x => x === subform)
          newFieldsToShow[subformIndex] = finalFields

          const assetOtherDetailsIndex = newSubFormsKey.findIndex(x => x === "assetOtherDetails")
          if (assetOtherDetailsIndex > -1) {
            newFieldsToShow.splice(assetOtherDetailsIndex, 1)
            newSubFormsKey.splice(assetOtherDetailsIndex, 1)
          }
        } else {
          newSubFormsKey.push(subform)
          newFieldsToShow.push(finalFields)
        }
      })
    } else if (isNew) {
      subFormsToCopy.forEach(subform => {
        const jsonFields = getCloneDeep(applicationJsonData, [currentFormKey, subform, "fields"])?.filter?.(
          x => x?.defaultQuestion,
        )
        if (newSubFormsKey.includes(subform)) {
          // add fresh json fields
          const subformIndex = newSubFormsKey.findIndex(x => x === subform)
          newFieldsToShow[subformIndex] = jsonFields

          const assetOtherDetailsIndex = newSubFormsKey.findIndex(x => x === "assetOtherDetails")
          if (assetOtherDetailsIndex > -1) {
            newFieldsToShow.splice(assetOtherDetailsIndex, 1)
            newSubFormsKey.splice(assetOtherDetailsIndex, 1)
          }
        } else {
          newSubFormsKey.push(subform)
        }
      })
    } else {
      const indexesToRemove = newSubFormsKey.reduce((acc, subform, i) => {
        if (subFormsToCopy.includes(subform)) {
          acc.push(i)
        }
        if (subform === "assetOtherDetails") {
          acc.push(i)
        }
        return acc
      }, [])
      newSubFormsKey = newSubFormsKey.filter((_, i) => !indexesToRemove.includes(i))
      newFieldsToShow = newFieldsToShow.filter((_, i) => !indexesToRemove.includes(i))
    }
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.SET_SUB_FORM_KEY_AND_FIELDS_TO_SHOW,
      payload: { subFormsKey: newSubFormsKey, fieldsToShow: newFieldsToShow },
    })
  }

  const removeAssetData = (index, updatedField) => {
    // cloning current fields to show
    const updatedFieldsToShow = cloneDeep(fieldsToShow)
    const dataForActiveSaveNew = cloneDeep(dataForActiveSave)
    const savedFormDataUpdated = cloneDeep(savedFormData)

    // current updated index is asset details or glass guide
    const isAssetDetailsOrGlassGuide = [ASSET.ASSET_DETAILS, ASSET.ASSET_GLASS_GUIDE].includes(subFormsKey?.[index])

    // Get fieldtoshow index of updated field
    let fieldIndex = updatedFieldsToShow?.[index]?.findIndex?.(
      x => x?.answer && updatedField?.answer && updatedField?.key === x?.key && updatedField?.answer !== x?.answer,
    )
    if (updatedField?.fieldName === "marineType" && fieldIndex < 0) {
      fieldIndex = updatedFieldsToShow?.[index]?.findIndex?.(
        x => !x?.answer && updatedField?.answer && updatedField?.key === x?.key && updatedField?.answer !== x?.answer,
      )
    }

    // If not found field index then search it by when field dont have answer
    if (fieldIndex < 0) {
      fieldIndex = updatedFieldsToShow?.[index]?.findIndex?.(
        x => x?.answer && !updatedField?.answer && updatedField?.key === x?.key && updatedField?.answer !== x?.answer,
      )
    }

    // field index is found
    // updated field is inside details or glass guide form
    if (isAssetDetailsOrGlassGuide && fieldIndex > -1) {
      // remove all fields after current updated field
      updatedFieldsToShow?.[index]?.splice?.(fieldIndex)

      // add updated field to field index because it was spliced away
      updatedFieldsToShow[index][fieldIndex] = updatedField

      // Get keys to keep based on answers of updated fields
      const keys = keysOfFieldsToKeep(updatedFieldsToShow?.[index])

      // If there are keys then loop throught it
      if (keys?.length > 0) {
        keys.forEach(key => {
          // Check if key is already there in updated fields to show or not
          const alreadyHave = updatedFieldsToShow?.[index]?.find(x => x?.key === key)

          // If key isn't there then get it from application json data
          if (!alreadyHave) {
            const field = getCloneDeep(applicationJsonData, [lastFormKey, subFormsKey[index], "fields"])?.find(
              x => x?.key === key,
            )
            // If found field in application json data then push it to updated fields to show
            if (field) {
              updatedFieldsToShow[index].push(field)
            }
          }
        })
      }

      /* remove  assetManufacturerOptions from every where to pre selection */

      updatedFieldsToShow.map(updatedField => {
        return updatedField.map(field => {
          if (field?.fieldName === "assetManufacturerOptions") {
            delete field?.answer
          }
          if (field?.fieldName === "assetAge" && field?.answer) {
            const index = subFormsKey.findIndex(x => x === ASSET.ASSET_GLASS_GUIDE)
            const field = updatedFieldsToShow[index][0]
            delete field?.answer
            delete field?.options

            updatedFieldsToShow[index] = [field]
            dispatch({
              type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
              payload: updatedFieldsToShow,
            })
          }
          return field
        })
      })
      /* remove  assetManufacturerOptions from every where to pre selection */

      dispatch({ type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW, payload: updatedFieldsToShow })

      /* remove  assetManufacturerOptions from every where to preselection */

      const UpdatedDataForActiveSaveNew = delete dataForActiveSaveNew?.asset1?.assetOptions?.assetManufacturerOptions
      if (UpdatedDataForActiveSaveNew) {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_ACTIVE_SAVE_DATA,
          payload: dataForActiveSaveNew,
        })
      }
      const UpdatedDataForIdenSaveNew = delete dataForActiveSaveNew?.asset1?.assetIdentifiersDetails?.HIN
      if (UpdatedDataForIdenSaveNew) {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_ACTIVE_SAVE_DATA,
          payload: dataForActiveSaveNew,
        })
      }
      if (["asset1", "asset2", "asset3", "asset4"].includes(formsKey[0])) {
        const copyFromIndex = savedFormDataUpdated?.[formsKey[0]]?.assetOptions?.findIndex(
          x => x?.fieldName === "assetManufacturerOptions",
        )
        if (copyFromIndex > -1) {
          const UpdatedSavedFormDataNew = delete savedFormDataUpdated?.[formsKey[0]]?.assetOptions[copyFromIndex]
            ?.answer
          if (UpdatedSavedFormDataNew) {
            dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_SAVED_FORM_DATA, payload: savedFormDataUpdated })
          }
        }
        const copyFromIndexHIN = savedFormDataUpdated?.[formsKey[0]]?.assetIdentifiersDetails?.findIndex(
          x => x?.fieldName === "HIN",
        )
        if (copyFromIndexHIN > -1) {
          const UpdatedSavedFormDataNew = delete savedFormDataUpdated?.[formsKey[0]]?.assetIdentifiersDetails[
            copyFromIndexHIN
          ]?.answer
          if (UpdatedSavedFormDataNew) {
            dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_SAVED_FORM_DATA, payload: savedFormDataUpdated })
          }
        }
      }
      /* remove  assetManufacturerOptions from every where to preselection */

      // Update redux state with updated fields to show

      // Returning true so that code doesn't continue in application page and end there because fields to show is already updated here
      return true
    }
    return false
  }

  return {
    keysOfFieldsToKeep,
    keysOfFieldsToRemove,
    getCorrectFields,
    updateFieldsToShowOnRefresh,
    updateFieldsToShowAccordingToSubformsAndFirstQuestion,
    subformfilterByFormVisibility,
    copyAsset,
    copyVendor,
    removeAssetData,
    isFieldUpdated,
  }
}
