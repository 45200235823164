import { updateQuoteAnswers } from "../screens/quote/redux/QuoteActions"

export const calculateToBeFinanced = (values, dispatch, isReturnValue = false) => {
  let { payoutAmount, tradeInAmount, deposit, purchasePrice } = values ?? {}

  payoutAmount = payoutAmount ? Number(payoutAmount) : 0
  tradeInAmount = tradeInAmount ? Number(tradeInAmount) : 0
  deposit = deposit ? Number(deposit) : 0
  purchasePrice = purchasePrice ? Number(purchasePrice) : 0

  const calculations = purchasePrice - deposit - tradeInAmount + payoutAmount
  if (calculations?.toString()) {
    if (isReturnValue)
      return parseFloat(calculations)
        .toFixed(2)
        ?.toString()
    else
      dispatch(
        updateQuoteAnswers(
          "toBeFinanced",
          parseFloat(calculations)
            .toFixed(2)
            ?.toString(),
          false,
        ),
      )
  }
}

export const calculateBalloon = (values, dispatch, isReturnValue = false) => {
  let { balloon, toBeFinanced } = values ?? {}

  balloon = balloon ? Number(balloon) : 0
  toBeFinanced = toBeFinanced ? Number(toBeFinanced) : 0

  let calculations
  if (balloon < 41 && balloon > 0) {
    calculations = toBeFinanced * (balloon / 100)
  } else {
    calculations = balloon
  }
  if (calculations?.toString()) {
    if (isReturnValue)
      return parseFloat(calculations)
        .toFixed(2)
        ?.toString()
    else
      dispatch(
        updateQuoteAnswers(
          "balloonCalculation",
          parseFloat(calculations)
            .toFixed(2)
            ?.toString(),
          false,
        ),
      )
  }
}
