import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import Input from "../../../common/Input/Input"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import AuthLayout from "../AuthLayout"
import { loginUser } from "../redux/AuthActions"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { validateEmail, validatePhone } from "../../../helpers/ValidationHelper"
import { defaultCountry } from "../../../constants/quoteDefaultConstants"
import useKeyPress from "../../../hooks/useKeyPress"

const LoginScreen = () => {
  const history = useHistory()

  const zohoId = new URLSearchParams(history?.location?.search).get("zohoid")

  const dispatch = useDispatch()
  const isEnterPressed = useKeyPress("Enter")
  const { isRedirectedFromQuote, isQuoteSubmit } = history?.location?.state ?? {}
  const [isEmail, setIsEmail] = useState(false)

  const { isLoginLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const [email, setEmail] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry)
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState({})

  const { quoteAnswersData, currentForm, previousQuestions, glassGuideData, glassGuideLabel } = useSelector(
    ({ quoteReducer }) => quoteReducer ?? {},
  )
  const { equifaxData } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})

  const handleErrorMessage = error => {
    setErrors({ ...errors, mobileNumber: error })
  }

  const handleLoginButtonClick = async () => {
    const errors = {}
    if (isEmail) {
      if (!email) errors.email = "Please enter email address"
      if (email && !validateEmail(email)) errors.email = "Please enter valid email address"
    } else {
      if (!selectedCountry || !selectedCountry?.label?.toString()?.trim()?.length)
        errors.mobileNumber = "Please select country code"
      if (!mobileNumber || !mobileNumber?.length) errors.mobileNumber = "Please enter mobile number"
      if (mobileNumber && validatePhone(mobileNumber).errorMessage)
        errors.mobileNumber = validatePhone(mobileNumber).errorMessage
    }
    if (!password || !password?.toString()?.trim()?.length) errors.password = "Please enter password"

    if (_.isEmpty(errors)) {
      setErrors({})
      let finalQuoteAnswers = { ...quoteAnswersData }
      if (isRedirectedFromQuote) {
        finalQuoteAnswers = {
          ...finalQuoteAnswers,
          [currentForm]: {
            ...finalQuoteAnswers?.[currentForm],
            isCompleted: !!isQuoteSubmit,
            isBlocked: false,
          },
        }
      }
      const quoteFormData = {
        quoteAnswersData: finalQuoteAnswers,
        glassGuideData: glassGuideData,
        glassGuideLabel: glassGuideLabel,
        previousQuestions,
      }
      const equifaxDataFinal = { data: equifaxData?.data }

      const loginDetails = {
        email: isEmail ? email.toLowerCase().trim() : undefined,
        countryCode: !isEmail ? selectedCountry?.value?.code : undefined,
        mobileNumber: !isEmail ? mobileNumber : undefined,
        password: password?.toString().trim(),
      }
      dispatch(
        loginUser(loginDetails, history, {
          isRedirectedFromQuote,
          isQuoteSubmit,
          quoteFormData,
          equifaxDataFinal,
          zohoId,
        }),
      )
    } else {
      setErrors(errors)
    }
  }

  useEffect(async () => {
    if (isEnterPressed) {
      await handleLoginButtonClick()
    }
  }, [isEnterPressed])

  useEffect(() => {
    return () => {
      setIsEmail(false)
      setEmail("")
      setMobileNumber("")
      setSelectedCountry(defaultCountry)
      setPassword("")
      setErrors({})
    }
  }, [])

  return (
    <AuthLayout
      title="Login"
      toggleText={!isEmail ? "Log in with email" : "Log in with mobile"}
      onToggleText={() => {
        setIsEmail(e => !e)
        setPassword("")
        setErrors({})
      }}
    >
      {isEmail ? (
        <Input
          type="userName"
          placeholder="Email address"
          name="userName"
          className="w-100"
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={errors?.email}
        />
      ) : (
        <CountrySelect
          countryCode=""
          countryName={selectedCountry}
          phoneNumber={mobileNumber}
          placeholder="Mobile number"
          className="w-100"
          updatePhoneNumber={e => {
            setMobileNumber(e?.toString()?.trim())
          }}
          updateErrorMessage={handleErrorMessage}
          updatePhoneCountry={e => {
            setSelectedCountry(e)
          }}
          error={errors?.mobileNumber}
        />
      )}
      <Input
        type="password"
        placeholder="Password"
        name="password"
        className="w-100 mt-15"
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={errors?.password}
      />
      <div className="remember-me-forgot-password-container">
        {/* <CheckBox title="Remember Me" /> */}
        <a
          href="javascript:void(0);"
          onClick={() =>
            history.push(ROUTE_CONSTANTS_VARIABLE.FORGOT_PASSWORD, { isRedirectedFromQuote, isQuoteSubmit })
          }
          className="f-14 font-primary"
        >
          Forgot Password?
        </a>
      </div>
      <div className="text-center w-100 mt-20">
        <Button
          buttonType="secondary"
          title="Login"
          className="form-button"
          isLoading={isLoginLoader}
          onClick={() => handleLoginButtonClick()}
        />
        <div className="form-already-account-text">
          {`Don't have an account? `}
          <span
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.REGISTER, { isRedirectedFromQuote, isQuoteSubmit })}
          >
            Register
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}
export default LoginScreen
