import pepper from "../../../assets/images/pepper.png"
import Button from "../../../common/Button/Button"
import PropTypes from "prop-types"
import { NumberCommaSeparator } from "../../../helpers/NumberCommaSeparator"

const getValue = (value, isPercent = false, isAmount = false) => {
  if (isAmount) return "$ " + NumberCommaSeparator(value)
  if (isPercent) return value + " %"
  if (value === true) return "Yes"
  if (value === false) return "No"
  return value
}

const RateCardInfo = ({ title, value, subTitle, isAmount, isPercent }) => {
  return (
    <div className="rate-card-info">
      <p className="info-title">{title}</p>
      <p className="info-description font-primary">{getValue(value, isPercent, isAmount)}</p>
      {subTitle && <p className="info-title">{subTitle}</p>}
    </div>
  )
}

RateCardInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  subTitle: PropTypes.string,
  isAmount: PropTypes.bool,
  isPercent: PropTypes.bool,
}

const RateCardDetails = ({ data, onCardSelect, isOpenView, isMobileView, applicationId }) => {
  const {
    lenderImage,
    rateId,
    interest,
    interestType,
    comparisonRate,
    comparisonType,
    repayments,
    repaymentType,
    totalPayments,
    makeExtraPayments,
    fixedInterest,
    onGoingFees,
    applicationFee,
    frequency,
    extraPayments,
    productType,
    productInterest,
    maxLoanTerm,
    maxLoanAmount,
    setUpFees,
    ongoingFees,
    earlyExitFee,
    breakCost,
    commercial,
  } = data ?? {}

  // console.log("data", data)

  return (
    <div className="rate-card">
      <div className="rate-card-main text-center pt-20">
        {!isMobileView && (
          <>
            <Button buttonType="secondary" onClick={() => onCardSelect(rateId, repayments)}>
              {applicationId ? "Select Rate" : "Apply Now"}
            </Button>
            <div className="rate-card-main-img">
              <img src={lenderImage || pepper} alt="pepper" className="rate-card-image" />
            </div>
          </>
        )}
        <RateCardInfo
          title={commercial ? "Effective rate" : "Interest rate"}
          value={interest}
          subTitle={interestType}
          isPercent
        />
        <RateCardInfo title="Comparison rate" value={comparisonRate} subTitle={comparisonType} isPercent />
        <RateCardInfo title="Repayments" value={repayments} subTitle={repaymentType} isAmount />
        <RateCardInfo title="Total payments" value={totalPayments} isAmount />
      </div>
      {isOpenView && (
        <>
          <div className="rate-card-sub text-center">
            <div className="properties mt-20">
              <div className="properties-title mb-20">
                <p>Features</p>
              </div>
              <RateCardInfo title="Make extra payments" value={makeExtraPayments} />{" "}
              {/* subTitle={makeExtraPayments}* */}
              <RateCardInfo title="Fixed interest" value={fixedInterest} />
              <RateCardInfo title="No ongoing fees" value={onGoingFees} />
              <RateCardInfo title="No application fee" value={applicationFee} />
            </div>

            <div className="properties mt-20">
              <div className="properties-title mb-20">
                <p>Repayment Options</p>
              </div>
              <RateCardInfo
                title="Frequency"
                value={frequency}
                subTitle={productInterest === "Fixed" ? "Fixed" : "Variable"}
              />
              <RateCardInfo title="Can make extra repayments?" value={extraPayments} subTitle="Fixed" />
            </div>

            <div className="properties mt-20">
              <div className="properties-title mb-20">
                <p>Product Information</p>
              </div>
              <RateCardInfo title="Product type" value={productType} />
              <RateCardInfo title="Interest" value={productInterest} />
              <RateCardInfo title="Max loan terms" value={maxLoanTerm} />
              <RateCardInfo title="Max loan amount" value={maxLoanAmount} isAmount />
            </div>

            <div className="properties mt-20">
              <div className="properties-title mb-20">
                <p>Fees</p>
              </div>
              <RateCardInfo title="Set up fees" value={setUpFees} isAmount />
              <RateCardInfo title="Ongoing fee" value={ongoingFees} />
              <RateCardInfo title="Early exit fee" value={earlyExitFee} />
              <RateCardInfo title="Break costs" value={breakCost} />
              <div className={isMobileView ? "rate-card-apply-container" : ""}>
                <Button
                  buttonType="secondary"
                  className={isMobileView ? "rate-card-apply-btn" : "mt-20"}
                  onClick={() => onCardSelect(rateId, repayments)}
                >
                  {applicationId ? "Select Rate" : "Apply Now"}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default RateCardDetails

RateCardDetails.propTypes = {
  data: PropTypes.object,
  onCardSelect: PropTypes.func,
  isOpenView: PropTypes.bool,
  isMobileView: PropTypes.bool,
  applicationId: PropTypes.object,
}
