import { APPLICATION_URLS, ZOHO_URLS } from "../../../constants/UrlConstants"
import ApiService from "../../../services/ApiServices"

const ApplicationApiServices = {
  getApplicationToolJSON: () => ApiService.getData(APPLICATION_URLS.GET_APPLICATION_JSON),
  getApplicationList: params => ApiService.getData(APPLICATION_URLS.GET_APPLICATION_LIST, { params }),
  getInitialApplicationData: (id, data) =>
    ApiService.postData(`${APPLICATION_URLS.GET_INITIAL_APPLICATION}${id}`, data),
  formPathDetails: data => ApiService.putData(APPLICATION_URLS.FORM_PATH_DETAILS, data),
  activeSaveApplicationData: data => ApiService.postData(APPLICATION_URLS.ACTIVE_SAVE, data),
  getDocumentOfApplicationData: (id, data) => ApiService.postData(`${APPLICATION_URLS.DOCUMENT_GET}${id}`, data),
  sendDocumentOfApplicationData: (id, data) => ApiService.postData(`${APPLICATION_URLS.DOCUMENT_SEND}${id}`, data),
  sendZohoEquifaxData: data => ApiService.postData(`${APPLICATION_URLS.PDF_SEND}`, data),
  getApplicationFilledData: id => ApiService.getData(`${APPLICATION_URLS.GET_APPLICATION_FILLED_DATA}${id}`),
  uploadFileInApplicaiton: (data, config, id) =>
    ApiService.postData(`${APPLICATION_URLS.UPLOAD_FILE}${id}`, data, config),

  removeFileInApplicaiton: data => ApiService.postData(APPLICATION_URLS.REMOVE_FILE, data),
  getAllData: applicationId => ApiService.getData(`${APPLICATION_URLS.GET_ALL_DATA}${applicationId}`),
  getDataFromExternalSources: (url, method, params) =>
    ApiService.request({
      url,
      method,
      params,
      timeout: 68000,
    }),
  getQuickAccessMenuList: data => ApiService.postData(APPLICATION_URLS.QUICK_ACCESS_MENU_LIST, data),
  getStoreRateLenderId: data => {
    const url = APPLICATION_URLS.GET_STORE_RATE_LENDER_ID
    let queryParams = new URLSearchParams(data)?.toString?.() || ""
    if (queryParams) {
      queryParams = `?${queryParams}`
    }
    return ApiService.getData(`${url}${queryParams}`)
  },
  postZohoData: data => ApiService.postData(ZOHO_URLS.ZOHO_PASS_DATA, data, { timeout: 0 }),
  postZohoDataUpload: data => ApiService.postData(ZOHO_URLS.ZOHO_UPLOAD, data, { timeout: 0 }),
}
export default ApplicationApiServices
