import OtpInput from "../../../common/OtpInput/OtpInput"
import Button from "../../../common/Button/Button"
import AuthLayout from "../AuthLayout"
import { useDispatch, useSelector } from "react-redux"
import { onResendOtp, onSubmitOtpNumber } from "./redux/otpAction"
import { errorNotification } from "../../../common/NotifyToaster"
import { useEffect, useState } from "react"
import { useMediaQuery } from "../../../hooks/useMediaQuery"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { useHistory } from "react-router-dom"

const OTPScreen = () => {
  dayjs.extend(duration)

  const history = useHistory()
  const isMobileScreen = useMediaQuery("(max-width: 480px)")

  const [counter, setCounter] = useState(localStorage.getItem("otpCount") || 300)

  const { isOtpLoading } = useSelector(({ loaderReducer }) => loaderReducer ?? false)

  const { isRedirectedFromQuote, isQuoteSubmit, mobileNumber, countryCode, email } = history?.location?.state ?? {}

  const dispatch = useDispatch()
  const otpLength = 6
  const onSubmitOtp = otpData => {
    if (otpData && otpData.length === otpLength) {
      const data = {
        otp: Number(otpData.join("")),
        mobileNumber,
        countryCode,
        email,
      }
      dispatch(onSubmitOtpNumber(data, isRedirectedFromQuote, isQuoteSubmit, history, false))
    } else {
      errorNotification("Please enter valid OTP")
    }
  }

  const onResetOtp = async () => {
    const data = {
      mobileNumber,
      countryCode,
      email,
    }
    const isOtpResend = await dispatch(onResendOtp(data))
    if (isOtpResend) {
      setCounter(180)
    }
  }

  const getOtpInfoText = () => {
    return email || (countryCode && mobileNumber && mobileNumber) || "mobile or email"
  }

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    localStorage.setItem("otpCount", counter)
  }, [counter])

  return (
    <AuthLayout title="Verification Code">
      <div className="otp-info-text">{`Code will be sent to ${getOtpInfoText()}`}</div>
      <OtpInput
        otpInputs={otpLength}
        otpBoxHeight={isMobileScreen ? 45 : 55}
        otpBoxWidth={isMobileScreen ? 45 : 55}
        onOtpFill={onSubmitOtp}
        otpBoxWithValueClass="border-primary"
        onFocusOtpBoxClass="border-secondary"
      />
      <div className="otp-resend-button">
        <Button
          title="Resend"
          className={counter > 0 ? "otp-resend-button-disabled" : ""}
          buttonType="secondary"
          onClick={onResetOtp}
          isDisabled={counter > 0 || isOtpLoading}
        />
      </div>
      {counter > 0 && (
        <div className="otp-reset-text">
          Resend code in <span>{dayjs.duration(counter, "seconds").format("mm:ss")}</span>
        </div>
      )}
    </AuthLayout>
  )
}

export default OTPScreen
