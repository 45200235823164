export const APPLICATION_REDUX_CONSTANTS = {
  SET_APPLICATION_JSON_DATA: "SET_APPLICATION_JSON_DATA",
  SET_INITIAL_APPLICATION_DATA: "SET_INITIAL_APPLICATION_DATA",
  UPDATE_FIELDS_TO_SHOW: "UPDATE_FIELDS_TO_SHOW",
  ADD_SAVED_FORM_DATA: "ADD_SAVED_FORM_DATA",
  GET_APPLICATION_LIST: "GET_APPLICATION_LIST",
  NAVIGATE_PREVIOUS: "NAVIGATE_PREVIOUS",
  NAVIGATE_NEXT: "NAVIGATE_NEXT",
  SET_VIEW_APPLICATION_DATA: "SET_VIEW_APPLICATION_DATA",
  UPDATE_APPLICATION_ACTIVE_SAVE_DATA: "UPDATE_APPLICATION_ACTIVE_SAVE_DATA",
  SET_EDIT_APPLICATION_DATA: "SET_EDIT_APPLICATION_DATA",
  SET_DATA_AFTER_REFRESH: "SET_DATA_AFTER_REFRESH",
  RESET_APPLICATION_DATA: "RESET_APPLICATION_DATA",
  UPDATE_IS_LAST_FORM: "UPDATE_IS_LAST_FORM",
  UPDATE_SUB_FORMS_KEY: "UPDATE_SUB_FORMS_KEY",
  UPDATE_APPLICATION_GLASS_GUIDE_DATA: "UPDATE_APPLICATION_GLASS_GUIDE_DATA",
  UPDATE_LVR_DATA: "UPDATE_LVR_DATA",
  ADD_EDIT_WARNINGS: "ADD_EDIT_WARNINGS",
  SET_SUB_FORM_KEY_AND_FIELDS_TO_SHOW: "SET_SUB_FORM_KEY_AND_FIELDS_TO_SHOW",
  SET_SAVED_FORM_DATA: "SET_SAVED_FORM_DATA",
  QUICK_ACCESS_MENU_NAVIGATION: "QUICK_ACCESS_MENU_NAVIGATION",
  SET_QUICK_ACCESS_MENU_LIST: "SET_QUICK_ACCESS_MENU_LIST",
  SET_FORMS_PATH: "SET_FORMS_PATH",
  SET_RECALCULATION_FIELD_UPDATE: "SET_RECALCULATION_FIELD_UPDATE",
  SET_POPUP_FIELD_UPDATE: "SET_POPUP_FIELD_UPDATE",
  SET_FILTER_IN_RATE_CARD: "SET_FILTER_IN_RATE_CARD",
  SET_NEXT_PREV_UPDATE: "SET_NEXT_PREV_UPDATE",
}
