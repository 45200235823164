import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure()
const breakpoint = 640

export const successNotification = message => {
  toast(message, {
    type: "success",
    position: window.innerWidth > breakpoint ? "bottom-left" : "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    toastId: `success${message}`,
  })
  return false
}

export const errorNotification = message => {
  toast(message, {
    type: "error",
    position: window.innerWidth > breakpoint ? "bottom-left" : "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
    toastId: `error${message}`,
  })
  return false
}

export const warningNotification = message => {
  toast(message, {
    type: "warning",
    position: window.innerWidth > breakpoint ? "bottom-left" : "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
    toastId: `Warning${message}`,
  })
  return false
}
