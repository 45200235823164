import { LOADER_REDUX_CONSTANTS } from "./LoaderReduxConstants"

export const loaderReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADER_REDUX_CONSTANTS.START_LOADER:
      return {
        ...state,
        [action?.loaderName]: true,
      }
    case LOADER_REDUX_CONSTANTS.STOP_LOADER:
      return {
        ...state,
        [action?.loaderName]: undefined,
      }

    default:
      return {
        ...state,
      }
  }
}
