import PropTypes from "prop-types"
import logo from "../../assets/images/logo.svg"
import { useRef, useState } from "react"

const AuthLayout = props => {
  const { title, toggleText, onToggleText, children } = props
  const [maxRequest, setMaxRequest] = useState(5)
  const logoRef = useRef(null)

  const reloadLogo = e => {
    if (maxRequest > 0) {
      logoRef.current.src = e?.target?.src
      setMaxRequest(count => count - 1)
    }
  }

  return (
    <div className="auth-background ">
      <div className={`form-white-container ${title === "Register" && "register-form-height"}`}>
        <div>
          <img
            ref={logoRef}
            src={logo}
            alt="Simply Finance"
            title="Simply Finance"
            className="form-logo"
            onError={reloadLogo}
          />
          <div className="form-title-container">
            <span className="auth-title">{title}</span>
            <span className="form-toggle-text" onClick={onToggleText}>
              {toggleText}
            </span>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
  toggleText: PropTypes.string,
  onToggleText: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default AuthLayout
