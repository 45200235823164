import { cloneDeep, isEmpty, isObject } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCloneDeep, stringCompare } from "../../../helpers/applicationHelper"
import { getModifiedVehicleSelectForDefaultOption } from "../../../helpers/QuoteActiveSavingHelper"
import { getGlassGuideDataFromBroker } from "../../broker/redux/BrokerAction"
import { getGlassGuideData, getGlassGuideDataFromAsset } from "../redux/ApplicaitonActions"
import { applicationSelector, currentFormKey } from "../redux/ApplicationSelector"
import { getGlassGuideDataFromBrokerNew } from "../../brokernew/redux/BrokerAction"

export const useGlassGuide = (isInitial = false) => {
  const dispatch = useDispatch()
  const { applicationJsonData, glassGuideData, subFormsKey, savedFormData, fieldsToShow } = useSelector(
    applicationSelector,
  )
  const { glassGuideDataAsset } = useSelector(({ assetReducer }) => assetReducer ?? {})
  const { glassGuideDataBroker } = useSelector(state => state?.brokerReducer)
  const { glassGuideDataBrokerNew } = useSelector(state => state?.brokerReducerNew)
  const { lastFormKey } = useSelector(currentFormKey)

  const recursivelyFindFieldForInitialCall = (keyToFind, data, path = "") => {
    if (data?.request?.response?.includes?.(keyToFind)) {
      const paths = path.split(".")
      const formPath = [paths[0], paths[1]].filter(x => x)
      return { field: data, formPath }
    } else {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length - 1; i++) {
          const found = recursivelyFindFieldForInitialCall(keyToFind, data[i], path.concat(path ? `.${i}` : `${i}`))
          if (found) return found
        }
      } else if (isObject(data)) {
        for (const [key, value] of Object.entries(data)) {
          const found = recursivelyFindFieldForInitialCall(keyToFind, value, path.concat(path ? `.${key}` : key))
          if (found) return found
        }
      }
    }
  }

  useEffect(() => {
    if (isInitial && applicationJsonData && savedFormData && isEmpty(glassGuideData)) {
      const result = recursivelyFindFieldForInitialCall("modelTypeCode", cloneDeep(applicationJsonData))
      if (result?.field && result?.formPath?.length === 2 && result?.field?.request) {
        getInitialData(result?.field?.request, result.field, result.formPath)
      }
    }
  }, [applicationJsonData, savedFormData])

  const getInitialData = (request, field, formPath) => {
    const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideData)

    const { url, method, params, response } = request
    const dataFromSavedForm = {}
    const savedFields = getCloneDeep(savedFormData, formPath)
    if (savedFields) {
      savedFields?.forEach(field => {
        if (Object.keys(params)?.includes(field?.fieldName)) {
          dataFromSavedForm[field?.fieldName] = field?.answer
        }
      })
    }
    const getValue = (key, value) => {
      return value || updatedGlassGuideData?.[key] || dataFromSavedForm?.[key]
    }
    let paramData = {}
    for (const [key, value] of Object.entries(params)) {
      const valueToSend = getValue(key, value)
      const isAssetAgeNotNumber = key === "assetAge" && isNaN(valueToSend)
      const otherAssetYear = fieldsToShow?.flat?.()?.find?.(x => x?.fieldName === "otherAssetYear")?.answer
      const myKey = key === "assetAge" ? "assetYear" : key
      if (valueToSend && !isAssetAgeNotNumber) {
        paramData = {
          ...paramData,
          [myKey]: valueToSend,
        }
      } else if (isAssetAgeNotNumber && otherAssetYear) {
        paramData[myKey] = otherAssetYear
      } else {
        return
      }
    }
    if (Object.keys(params).length === Object.values(paramData)?.filter?.(x => x)?.length)
      dispatch(getGlassGuideData({ url, method, params: paramData, response }, field, formPath))
  }

  const getGlassGuideOptionsFromAsset = (request, field, AssetFields) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideDataAsset)

        const { url, method, params, response, optionalParams } = request
        const getValue = (key, value, AssetFields) => {
          const inField = AssetFields.find(x => x?.fieldName === key)
          return value || inField?.answer || updatedGlassGuideData?.[key]
        }
        let paramData = {}

        for (const [key, value] of Object.entries(params)) {
          const valueToSend = getValue(key, value, AssetFields)
          const isAssetAgeNotNumber = key === "assetAge" && isNaN(valueToSend)
          const otherAssetYear = AssetFields?.flat?.()?.find?.(x => x?.fieldName === "otherAssetYear")?.answer
          const myKey = key === "assetAge" ? "assetYear" : key
          if (valueToSend && !isAssetAgeNotNumber) {
            paramData = {
              ...paramData,
              [myKey]: valueToSend,
            }
          } else if (isAssetAgeNotNumber && otherAssetYear) {
            paramData[myKey] = otherAssetYear
          }
        }

        // Remove params which are optional so final condition can comparate paramData and params
        if (Array.isArray(optionalParams) && optionalParams?.length > 0) {
          optionalParams.forEach(optionalParam => {
            if (!paramData[optionalParam] && optionalParam in params) {
              delete params[optionalParam]
            }
          })
        }
        if (Object.keys(params).length === Object.values(paramData)?.filter?.(x => x)?.length) {
          // START   -------------------------------------------------------------- Send '' instead of none value in api call data
          for (const key in paramData) if (stringCompare(paramData[key], "none")) paramData[key] = ""
          // END     -------------------------------------------------------------- Send '' instead of none value in api call data
          dispatch(
            getGlassGuideDataFromAsset({ url, method, params: paramData, response }, field, () => {
              resolve()
            }),
          )
        } else resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const getGlassGuideOptions = (request, field, formPath) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideData)
        const { url, method, params, response, optionalParams } = request
        const dataFromCurrentForm = {}
        const dataFromSavedForm = {}
        const savedFields = subFormsKey.reduce((acc, subform) => {
          const fields = getCloneDeep(savedFormData, [lastFormKey, subform])
          if (fields?.length > 0) {
            acc.push(fields)
          }
          return acc
        }, [])
        for (const form of fieldsToShow) {
          form?.forEach(field => {
            if (Object.keys(params)?.includes(field?.fieldName)) {
              dataFromCurrentForm[field?.fieldName] = field?.answer
            }
          })
        }
        for (const form of savedFields) {
          form?.forEach(field => {
            if (Object.keys(params)?.includes(field?.fieldName)) {
              dataFromSavedForm[field?.fieldName] = field?.answer
            }
          })
        }
        const getValue = (key, value) => {
          return value || updatedGlassGuideData?.[key] || dataFromCurrentForm?.[key] || dataFromSavedForm?.[key]
        }
        let paramData = {}
        for (const [key, value] of Object.entries(params)) {
          const valueToSend = getValue(key, value)
          const isAssetAgeNotNumber = key === "assetAge" && isNaN(valueToSend)
          const otherAssetYear = fieldsToShow?.flat?.()?.find?.(x => x?.fieldName === "otherAssetYear")?.answer
          const myKey = key === "assetAge" ? "assetYear" : key
          if (valueToSend && !isAssetAgeNotNumber) {
            paramData = {
              ...paramData,
              [myKey]: valueToSend,
            }
          } else if (isAssetAgeNotNumber && otherAssetYear) {
            paramData[myKey] = otherAssetYear
          }
        }

        // Remove params which are optional so final condition can comparate paramData and params
        if (Array.isArray(optionalParams) && optionalParams?.length > 0) {
          optionalParams.forEach(optionalParam => {
            if (!paramData[optionalParam] && optionalParam in params) {
              delete params[optionalParam]
            }
          })
        }

        if (Object.keys(params).length === Object.values(paramData)?.filter?.(x => x)?.length) {
          // START   -------------------------------------------------------------- Send '' instead of none value in api call data
          for (const key in paramData) if (stringCompare(paramData[key], "none")) paramData[key] = ""
          // END     -------------------------------------------------------------- Send '' instead of none value in api call data
          dispatch(
            getGlassGuideData({ url, method, params: paramData, response }, field, formPath, () => {
              resolve()
            }),
          )
        } else resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
  const getGlassGuideOptionsBroker = (request, field, formData, pathOfField) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideDataBroker)

        const { url, method, params, response, optionalParams } = request
        const newParams = { ...params }
        const getValue = (key, value, formData) => {
          const inField = Object.keys(formData)
            ?.map(keyChild => {
              return (
                Array.isArray(formData?.[keyChild]) && formData?.[keyChild]?.find(x => x?.fieldName === key)?.answer
              )
            })
            ?.filter(n => n)

          return value || inField?.[0] || updatedGlassGuideData?.[key]
        }

        let paramData = {}
        for (const [key, value] of Object.entries(newParams)) {
          const valueToSend = getValue(key, value, formData)
          const isAssetAgeNotNumber = key === "assetAge" && isNaN(valueToSend)
          const otherAssetYear = getValue("otherAssetYear", "", formData)?.[0]
          const myKey = key === "assetAge" ? "assetYear" : key
          if (valueToSend && !isAssetAgeNotNumber) {
            paramData = {
              ...paramData,
              [myKey]: valueToSend,
            }
          } else if (isAssetAgeNotNumber && otherAssetYear) {
            paramData[myKey] = otherAssetYear
          }
        }

        if (field?.fieldName === "manufacturerOptions" && pathOfField.includes("carSearch")) {
          const alldata = formData?.carSearch?.find(e => e.fieldName === "carSearch")?.answer
          const obj = JSON.parse(alldata)
          paramData = {
            identifier: "getManufacturerOptions",
            optionType: "O",
            modelTypeCode: obj?.modelTypeCode,
            nvic: obj?.nvic,
          }
        }

        // if ("nvic" in newParams) paramData.nvic = "KSZ11A"

        // Remove params which are optional so final condition can comparate paramData and params
        if (Array.isArray(optionalParams) && optionalParams?.length > 0) {
          optionalParams.forEach(optionalParam => {
            if (!paramData[optionalParam] && optionalParam in newParams) {
              delete newParams[optionalParam]
            }
          })
        }
        if ("modelTypeCode" in newParams && !paramData.modelTypeCode && glassGuideDataBroker?.modelTypeCode) {
          paramData.modelTypeCode = glassGuideDataBroker.modelTypeCode
        }
        if (Object.keys(newParams).length === Object.values(paramData)?.filter?.(x => x)?.length) {
          // START   -------------------------------------------------------------- Send '' instead of none value in api call data
          for (const key in paramData) if (stringCompare(paramData[key], "none")) paramData[key] = ""
          // END     -------------------------------------------------------------- Send '' instead of none value in api call data
          dispatch(
            getGlassGuideDataFromBroker({ url, method, params: paramData, response }, field, pathOfField, () => {
              resolve()
            }),
          )
        } else resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
  const getGlassGuideOptionsBrokerNew = (request, field, formData, pathOfField) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideDataBrokerNew)

        const { url, method, params, response, optionalParams } = request
        const newParams = { ...params }
        const getValue = (key, value, formData) => {
          const inField = Object.keys(formData)
            ?.map(keyChild => {
              return (
                Array.isArray(formData?.[keyChild]) && formData?.[keyChild]?.find(x => x?.fieldName === key)?.answer
              )
            })
            ?.filter(n => n)

          return value || inField?.[0] || updatedGlassGuideData?.[key]
        }

        let paramData = {}
        for (const [key, value] of Object.entries(newParams)) {
          const valueToSend = getValue(key, value, formData)
          const isAssetAgeNotNumber = key === "assetAge" && isNaN(valueToSend)
          const otherAssetYear = getValue("otherAssetYear", "", formData)?.[0]
          const myKey = key === "assetAge" ? "assetYear" : key
          if (valueToSend && !isAssetAgeNotNumber) {
            paramData = {
              ...paramData,
              [myKey]: valueToSend,
            }
          } else if (isAssetAgeNotNumber && otherAssetYear) {
            paramData[myKey] = otherAssetYear
          }
        }

        if (field?.fieldName === "manufacturerOptions" && pathOfField.includes("carSearch")) {
          const alldata = formData?.carSearch?.find(e => e.fieldName === "carSearch")?.answer
          const obj = JSON.parse(alldata)
          paramData = {
            identifier: "getManufacturerOptions",
            optionType: "O",
            modelTypeCode: obj?.modelTypeCode,
            nvic: obj?.nvic,
          }
        }

        // if ("nvic" in newParams) paramData.nvic = "KSZ11A"

        // Remove params which are optional so final condition can comparate paramData and params
        if (Array.isArray(optionalParams) && optionalParams?.length > 0) {
          optionalParams.forEach(optionalParam => {
            if (!paramData[optionalParam] && optionalParam in newParams) {
              delete newParams[optionalParam]
            }
          })
        }
        if ("modelTypeCode" in newParams && !paramData.modelTypeCode && glassGuideDataBrokerNew?.modelTypeCode) {
          paramData.modelTypeCode = glassGuideDataBrokerNew.modelTypeCode
        }
        if (Object.keys(newParams).length === Object.values(paramData)?.filter?.(x => x)?.length) {
          // START   -------------------------------------------------------------- Send '' instead of none value in api call data
          for (const key in paramData) if (stringCompare(paramData[key], "none")) paramData[key] = ""
          // END     -------------------------------------------------------------- Send '' instead of none value in api call data
          dispatch(
            getGlassGuideDataFromBrokerNew({ url, method, params: paramData, response }, field, pathOfField, () => {
              resolve()
            }),
          )
        } else resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
  return {
    getGlassGuideOptions,
    getGlassGuideOptionsFromAsset,
    getGlassGuideOptionsBroker,
    getGlassGuideOptionsBrokerNew,
  }
}
export const dateCheck = (from, to, check) => {
  const fDate = Date.parse(from)
  const lDate = Date.parse(to)
  const cDate = Date.parse(check)

  if (cDate <= lDate && cDate >= fDate) {
    return true
  }
  return false
}
