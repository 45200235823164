import { RATE_CARD_REDUX_CONSTANTS } from "./rateCardReduxConstants"

export const rateCardReducer = (state = { rateCardData: {} }, action) => {
  switch (action.type) {
    case RATE_CARD_REDUX_CONSTANTS.SET_RATE_CARD_DATA: {
      return {
        ...state,
        rateCardData: action.data,
      }
    }
    default:
      return state
  }
}
