/* eslint-disable no-useless-escape */
export const VALIDATION_REGEX = {
  TEXT: /[`!@#$%^&*()_+\-={};':"|,.<>?~]/,
  PHONE: /^\d{2}\s\d{4}-\d{4}$/,
  ONLY_ALPHABETIC: /^[a-zA-Z]+$/,
  ALPHABETIC_WITH_SPACE: /^[a-zA-Z\s]+$/,
  NUMBER_REGEX: /^\d+$/,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  WEBSITE: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
}

export const NAVIGATION = {
  next: "NEXT",
  prev: "PREV",
}

export const APPLICATION_ID_QUERY_PARAM = "applicationId"
export const DEAL_ID_QUERY_PARAM = "dealId"
export const USER_ID_QUERY_PARAM = "userId"
export const COMMON_QUESTION_SUB_FORM = "commonQuestion"
export const DEFAULT_MERGE_FORMS = ["financierReference", "assetDetails", "assetVendorDetails", "bankDetails"]
export const ASSET = {
  ASSET_DETAILS: "assetDetails",
  ASSET_GLASS_GUIDE: "assetGlassGuide",
  ASSET_VENDOR_DETAILS: "assetVendorDetails",
  ASSET_MANUFACTURER_OPTIONS: "assetManufacturerOptions",
}

export const GLASS_GUIDE = {
  MANUFACTURER_OPTIONS: "manufacturerOptions",
}

export const LOCAL_STORAGE_KEYS = {
  NAVIGATION_HISTORY: "navigationHistory",
}
