import { REGISTER_REDUX_CONSTANTS } from "./RegisterReduxConstants"
import AuthApiService from "../../services/AuthApiService"
import { displayErrors } from "../../../../helpers/ErrorNotifyHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../../routes/RouteConstants"
import { successNotification } from "../../../../common/NotifyToaster"
import { startLoader, stopLoader } from "../../../../common/loader/redux/LoaderActions"

export const onRegistrationCredentialValueChange = (name, value) => ({
  type: REGISTER_REDUX_CONSTANTS.CREDENTIAL_CHANGE,
  name,
  value,
})

export const registerUser = (data, history, isRedirectedFromQuote, isQuoteSubmit, fromEquifaxPage) => {
  return async dispatch => {
    try {
      startLoader("isRegisterUserLoading")
      const response = await AuthApiService.registerUser({ ...data, role: "USER" })
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        dispatch({
          type: REGISTER_REDUX_CONSTANTS.CREDENTIAL_RESET,
        })
        if (!fromEquifaxPage) {
          history.push(ROUTE_CONSTANTS_VARIABLE.OTP_VALIDATE, {
            mobileNumber: data?.mobileNumber,
            countryCode: data?.countryCode,
            userId: response?.data?.data?.userId,
            isRedirectedFromQuote,
            isQuoteSubmit,
          })
        } else {
          return true
        }
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isRegisterUserLoading")
    }
  }
}
