import PropTypes from "prop-types"

const RadioButton = props => {
  const { id, key, label, value, name, className, isChecked, ...restProps } = props
  return (
    <div key={key} className={`${isChecked ? "checked" : "notchecked"} ${className}`}>
      <input type="radio" id={id} name={name} value={value} {...restProps} />
      <label htmlFor={id} className={`radio-button mr-15`}>
        {label}
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
}

RadioButton.defaultProps = {
  label: "",
  className: "",
}

export default RadioButton
