import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getQuoteFilledData } from "../redux/QuoteActions"
import Accordion from "../../../common/Accordion/Accordion"
import ViewQuoteSingle from "../ViewQuotePage/ViewQuoteSingle"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"

const ViewQuotePage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)

  useEffect(() => {
    ;(async () => {
      try {
        if (authStatus && id) {
          await dispatch(getQuoteFilledData(id))
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])
  const { viewQuoteData } = useSelector(({ quoteReducer }) => quoteReducer ?? {})

  return (
    <>
      <Button
        buttonType="secondary"
        onClick={() => history.push(`${ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST}/`)}
        className="mb-15"
      >
        Back
      </Button>

      <Accordion className="view-application-accordion">
        {viewQuoteData.length > 0
          ? viewQuoteData.map((task, index) => <ViewQuoteSingle data={task} key={index} index={index} />)
          : ""}
      </Accordion>
    </>
  )
}
export default ViewQuotePage
