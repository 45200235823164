import moment from "moment"
import { DATE_FORMAT } from "../../../constants/regexConstants"
import {
  checkEmailExistInBroker,
  generateApplicationErrorMessage,
  isValidAnswer,
  urlPatternValidation,
} from "../../../helpers/applicationHelper"
import { VALIDATION_REGEX } from "../../application/ApplicationPage/ApplicationPageConstants"

export const brokerFieldValidation = (field, path, NformData, formpath) => {
  const { required, answer, errorField, fieldName, type, minLimit, maxLimit, editable } = field
  const errorFieldName = errorField !== undefined ? errorField : fieldName

  const pathFirst = path.split(".")
  if (required && !isValidAnswer(answer) && editable) {
    return generateApplicationErrorMessage(type, errorFieldName)
  } else if (answer && minLimit && answer?.toString()?.trim()?.length < minLimit) {
    return `The minimum limit for ${errorFieldName} is ${minLimit} characters`
  } else if (answer && maxLimit && answer?.toString()?.replace(/ +/g, "")?.length > maxLimit) {
    return `The maximum limit for ${errorFieldName} is ${maxLimit} characters`
  } else if (["amount", "number"].includes(type) && editable && required && isNaN(answer)) {
    return `Please enter valid ${errorFieldName} to continue`
  } else if (field?.fieldName === "abn") {
    const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
    if (isNaN(answerVal)) {
      return `${errorFieldName} field must contain valid numeric value`
    }
  } else if (fieldName === "balloonCalculation" && answer) {
    if (isNaN(answer) && Number(answer) <= 0) {
      return `${errorFieldName} is invalid`
    }
    const toBeFinance = NformData?.financeNumbers?.find(e => e.fieldName === "toBeFinanced")?.answer
    if (Number(answer) >= Number(toBeFinance)) {
      return `Balloon must be less than to be financed amount`
    }
  } else if (field?.fieldName === "state" && answer) {
    const format = VALIDATION_REGEX.ALPHABETIC_WITH_SPACE
    const isValid = format.test(answer)
    if (!isValid) {
      return `Please enter valid answer for ${errorFieldName}`
    }
  } else if (field?.fieldName === "city" && answer) {
    const format = VALIDATION_REGEX.ALPHABETIC_WITH_SPACE
    const isValid = format.test(answer)
    if (!isValid) {
      return `Please enter valid answer for ${errorFieldName}`
    }
  } else if (field?.fieldName === "postcode" && answer) {
    const format = VALIDATION_REGEX.NUMBER_REGEX
    const isValid = format.test(answer)
    if (!isValid) {
      return `Please enter valid answer for ${errorFieldName}`
    }
  } else if (field?.fieldName === "country" && answer) {
    const format = VALIDATION_REGEX.ONLY_ALPHABETIC
    const isValid = format.test(answer)
    if (!isValid) {
      return `Please enter valid answer for ${errorFieldName}`
    }
  } else if (answer && editable && ["phone", "email"].includes(type)) {
    let format = VALIDATION_REGEX.TEXT
    if (type === "phone") format = VALIDATION_REGEX.PHONE
    if (type === "email") format = VALIDATION_REGEX.EMAIL

    const isValid = format.test(answer)

    if (!isValid) {
      return `Please enter valid ${errorFieldName} to continue`
    }
    // TODO: check if email exists anywhere
    if (type === "email" && !["vendorEmail"].includes(fieldName)) {
      const checkExistInOld = checkEmailExistInBroker(answer, NformData, formpath)
      if (checkExistInOld > 1) {
        return `Please enter new ${fieldName} to continue`
      }
    }
    if (type === "phone") {
      const secondDigit = String(answer)?.substring(1, 2)
      const fistDigit = String(answer)?.substring(0, 1)

      if (fistDigit === "0" && secondDigit === "0") {
        return `Please enter valid ${errorFieldName} to continue`
      }
    }
  } else if (answer && editable && ["website"].includes(type)) {
    const isValid = urlPatternValidation(answer)

    if (!isValid) {
      return `Please enter valid ${errorFieldName} to continue`
    }
  }
  if (answer && editable && ["date"].includes(type)) {
    const isValid = moment(answer, DATE_FORMAT.DDMMYYYY, true).isValid()

    if (!isValid) {
      return `Please enter valid ${errorFieldName} to continue`
    }
  }
  if (required && (field?.fieldName === "firstName" || field?.fieldName === "lastName")) {
    const format = VALIDATION_REGEX.ONLY_ALPHABETIC
    const isValid = format.test(answer)
    if (!isValid) {
      return `Please enter valid ${errorFieldName} to continue`
    }
  }
  if (field?.fieldName === "firstName" || field?.fieldName === "lastName") {
    const format = VALIDATION_REGEX.ONLY_ALPHABETIC
    const isVaid = format.test(answer)
    if (!isVaid) {
      return `Please enter valid ${errorField} to continue`
    }
  }

  if (field?.type === "checkbox" && required && path.includes("disclosure")) {
    if (!answer) {
      return `Please select/enter valid answer for ${errorFieldName}`
    }
  }
  if (answer && field?.type === "amount" && field?.fieldName !== "toBeFinanced") {
    let answerVal = answer ? Number(answer) : 0
    answerVal = Math.round(answerVal)
    if (answerVal <= 0) {
      return `Amount must be greater than 0`
    }
  }
  if (fieldName === "toBeFinanced" && !["asset1", "asset2", "asset3", "asset4"].includes(pathFirst?.[0])) {
    let answerVal = answer ? Number(answer) : 0

    answerVal = Math.round(answerVal)
    if (answerVal <= 0) {
      return `Amount must be greater than 0`
    }
  }
}
