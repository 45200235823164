import { BROKER_URLS } from "../../../constants/UrlConstants"
import ApiService from "../../../services/ApiServices"
export const BASE_URL = process.env.REACT_APP_BASE_URL

const BrokerApiServices = {
  getBrokerJson: () => ApiService.getData(BROKER_URLS.GET_BROKER_JSON),
  getQuoteFormPath: data => ApiService.putData(BROKER_URLS.GET_QUOTE_FORMPATH, data),
  getApplicationFormPath: data => ApiService.putData(BROKER_URLS.GET_APPLICATION_FORMPATH, data),
  createUser: data => ApiService.postData(BROKER_URLS.CREATE_USER, data),
  saveData: data => ApiService.postData(BROKER_URLS.SAVE_DATA, data),
  getData: id => ApiService.getData(BROKER_URLS.GET_DATA(id)),
  getEmailVerificationStatus: id => ApiService.getData(`${BROKER_URLS.GET_EMAIL_VERI_STATUS}/${id}`),
  onBrokerSubmitOtp: data => ApiService.postData(BROKER_URLS.OTP_VERIFY_STATUS, data),
  getQuoteData: id => ApiService.getData(BROKER_URLS.GET_QUOTE_DATA(id)),
  getRateCardData: id => ApiService.getData(BROKER_URLS.GET_RATE_DATA(id)),
  getBrokerPathData: id => ApiService.getData(BROKER_URLS.getBrokerPathData(id)),
  getApplicationData: id => ApiService.getData(BROKER_URLS.GET_APPLICATION_DATA(id)),
  saveZOHOData: (id, data) =>
    ApiService.postData(`${BROKER_URLS.SEND_APPLICATION_ZOHO_DATA}/${id}`, data, { timeout: 0 }),

  saveZOHODataRecursion: (id, data, Path) => ApiService.postData(`${BASE_URL}${Path}/${id}`, data, { timeout: 0 }),

  quoteVanishNextForms: data => ApiService.postData(BROKER_URLS.VANISH_QUOTE_FORM, data),
  applicaitonVanishNextForms: data => ApiService.postData(BROKER_URLS.VANISH_APPLICATION_FORM, data),
}
export default BrokerApiServices
