import _, { startCase } from "lodash"
import { successNotification } from "../../../common/NotifyToaster"
import { BASE_URL } from "../../../constants/UrlConstants"
import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import QuoteApiServices from "../services/QuoteServices"
import { QUOTE_REDUX_CONSTANTS } from "./QuoteReduxConstants"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
// import { quoteJSON } from "../../../constants/QuoteJSON"

export const getQuoteToolJSON = () => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.getQuoteToolJSON()
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.SET_QUOTE_JSON_DATA,
          data: response?.data?.data,
          // data: quoteJSON,s
        })
      }
    } catch (e) {
      /**/
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const handleQuestionErrorMessage = errorMessages => ({
  type: QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_QUESTION_ERROR,
  errorMessages,
})
export const handleFinanceNumberFormChangeOnAssetChange = fieldName => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_FINANCE_NUMBER_FORM_ON_ASSET_CHANGE,
  fieldName,
})

export const handleAssetConditionFieldChange = () => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_ASSET_CONDITION_CHANGE,
})

export const updateQuoteAnswers = (fieldName, fieldValue, isOtherField) => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_ANSWER_DATA,
  fieldName,
  fieldValue,
  isOtherField,
})

export const updateAnswersOnPrevChanges = fieldsToKeep => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_ANSWERS_ON_PREV_CHANGES,
  fieldsToKeep,
})

export const wipeOutDataOnFieldsChange = fieldsToWipeOut => ({
  type: QUOTE_REDUX_CONSTANTS.WIPE_OUT_DATA_ON_FIELD_CHANGES,
  fieldsToWipeOut,
})

export const handleOtherQuestionOnSamePage = () => ({
  type: QUOTE_REDUX_CONSTANTS.HANDLE_OTHER_QUESTION_ON_SAME_PAGE,
})

export const disableFields = fieldToNotDisable => ({
  type: QUOTE_REDUX_CONSTANTS.DISABLED_QUOTE_FIELDS,
  fieldToNotDisable,
})
export const changeFieldValue = (FinanceWhatAns, optionVal, fieldName) => {
  return async dispatch => {
    dispatch({
      type: QUOTE_REDUX_CONSTANTS.CHANGE_QUOTE_FIELDS_VALUE,
      FinanceWhatAns,
      optionVal,
      fieldName,
    })
  }
}

export const enableFields = () => ({
  type: QUOTE_REDUX_CONSTANTS.ENABLE_QUOTE_FIELDS,
})

export const getNextQuoteForm = (data, quoteJsonData, canSkip) => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.getNextQuoteForm(data)
      let nextQuestion
      if (response?.data?.status === "SUCCESS") {
        if (response?.data?.data) {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_DATA_ON_NEXT_CLICK,
            form: response?.data?.data,
            canSkip: canSkip,
          })

          if (
            quoteJsonData?.[response?.data?.data]?.Q1?.nextQuestions !== undefined &&
            quoteJsonData?.[response?.data?.data]?.Q1?.option !== undefined &&
            quoteJsonData?.[response?.data?.data]?.Q1?.option.length === 1
          ) {
            for (const [key, value] of Object.entries(quoteJsonData?.[response?.data?.data]?.Q1?.nextQuestions)) {
              if (
                _.toLower(_.camelCase(key)) ===
                _.toLower(_.camelCase(quoteJsonData?.[response?.data?.data]?.Q1?.option))
              ) {
                nextQuestion = value
                break
              }
            }
            dispatch(
              updateQuoteAnswers(
                quoteJsonData?.[response?.data?.data]?.Q1?.fieldName,
                quoteJsonData?.[response?.data?.data]?.Q1?.option[0],
                quoteJsonData?.[response?.data?.data]?.Q1?.isOtherField || false,
              ),
            )

            dispatch(handleNextCLick(nextQuestion))
          }
        }
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isQuoteCommonLoader")
      stopLoader("isGlassGuideSkipActive")
    }
  }
}

export const handleNextCLick = (nextQuestion, canSkip) => ({
  type: QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_NEXT_CLICK,
  data: nextQuestion,
  canSkip: canSkip,
})

export const updatePreviousQuestionDetails = canSkip => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_PREVIOUS_QUESTION_DETAILS,
  canSkip,
})

export const updatePreviousQuestionDetailsManual = (currentForm, currentQuestion) => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_PREVIOUS_QUESTION_DETAILS_MANUAL,
  currentForm: currentForm,
  currentQuestion: currentQuestion,
})

export const handleBackCLick = () => ({
  type: QUOTE_REDUX_CONSTANTS.HANDLE_QUOTE_BACK_CLICK,
})

export const getDataFromExternalSources = (
  url,
  method,
  params,
  fieldName,
  isOtherField,
  quoteAnswersData,
  currentQuoteData,
  isOnlyOneData,
) => {
  let OnlyOneData = isOnlyOneData
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      startLoader("isGlassGuideSkipActive")
      const response = await QuoteApiServices.getDataFromExternalSources(`${BASE_URL}${url}`, _.toUpper(method), params)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.SET_OPTIONS_FROM_API,
          data: response?.data?.data,
        })
        const disableValue = currentQuoteData?.fieldToCheckDisable
        if (response?.data?.data?.table?.length === 1 && quoteAnswersData?.vehicleSelect?.[disableValue] !== true) {
          if (fieldName === "nvic") {
            dispatch(
              updateQuoteAnswers(
                "assetDescription",
                quoteAnswersData?.vehicleSelect?.assetYear + " " + response?.data?.data?.table[0].label,
                false,
              ),
            )
            dispatch(updateQuoteAnswers("model", response?.data?.data?.table[0].label, false))
          }
          OnlyOneData = true
          await dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED,
            data: OnlyOneData,
          })

          dispatch(updateQuoteAnswers(fieldName, response?.data?.data?.table[0].value, isOtherField))
          dispatch(updateGlassGuideAnsData(fieldName, response?.data?.data?.table[0].value))
          dispatch(updateGlassGuideLabelData(fieldName, response?.data?.data?.table[0].label))
          dispatch(skipQuoteScreen(true))

          return "1"
        }
        if (!response?.data?.data?.table?.length && fieldName === "manufacturerOptions") {
          dispatch(updateQuoteAnswers(fieldName, ["None"], isOtherField))
          dispatch(updateGlassGuideAnsData(fieldName, "None"))
          dispatch(updateGlassGuideLabelData(fieldName, "None"))

          OnlyOneData = false
          await dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED,
            data: OnlyOneData,
          })

          return "2"
        }
        if (
          !response?.data?.data?.table?.length &&
          ["assetMake", "assetModel", "assetBadge", "assetSeries", "model"].includes(fieldName)
        ) {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.SET_OPTIONS_FROM_API,
            data: {
              ...response?.data?.data,
              table: [{ label: `No ${startCase(fieldName)}`, value: "optionToSkipOnVehicleSelect" }],
            },
          })
          dispatch(updateQuoteAnswers(fieldName, "optionToSkipOnVehicleSelect", isOtherField))
          dispatch(updateGlassGuideAnsData(fieldName, "optionToSkipOnVehicleSelect"))
          dispatch(updateGlassGuideLabelData(fieldName, "optionToSkipOnVehicleSelect"))
          OnlyOneData = false
          await dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED,
            data: OnlyOneData,
          })

          return "2"
        }

        if (response?.data?.data?.table?.length > 1) {
          OnlyOneData = false
          await dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED,
            data: OnlyOneData,
          })
        }
      }
    } catch (e) {
      displayErrors(e)
      return "2"
    } finally {
      !OnlyOneData && stopLoader("isQuoteCommonLoader")
      !OnlyOneData && stopLoader("isGlassGuideSkipActive")
    }
  }
}

export const updateGlassGuideAnsData = (fieldName, fieldValue) => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_ANS_DATA,
  fieldName,
  fieldValue,
})
export const updateGlassGuideLabelData = (fieldName, fieldLabel) => ({
  type: QUOTE_REDUX_CONSTANTS.UPDATE_GLASS_GUIDE_LABEL_DATA,
  fieldName,
  fieldLabel,
})
export const skipQuoteScreen = canSkip => ({
  type: QUOTE_REDUX_CONSTANTS.SKIP_QUOTE_SCREEN,
  value: canSkip,
})

export const handleABNSearch = (url, method, params) => {
  return async dispatch => {
    try {
      const response = await QuoteApiServices.getDataFromExternalSources(`${BASE_URL}${url}`, _.toUpper(method), params)
      if (response?.data?.status === "SUCCESS") {
        if (params?.identifier === "ABRNameLookUp") {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.ABN_ACN.ON_ABN_OR_ACN_CHANGE,
            data: response?.data?.data,
          })
        } else {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_ANSWER_DATA_FIELDS,
            data: response?.data?.data,
          })
        }
      }
    } catch (e) {
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.ABN_ACN.RESET_ABN_DATA,
      })
      displayErrors(e)
    }
  }
}

export const handleRegoSearch = (url, method, params) => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.getDataFromExternalSources(`${BASE_URL}${url}`, _.toUpper(method), params)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.UPDATE_QUOTE_ANSWER_DATA_FIELDS,
          data: response?.data?.data,
        })
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.SET_REGO_CALLED,
          rego_called: true,
        })
        dispatch(
          updateQuoteAnswers(
            "assetDescription",
            response?.data?.data?.yearCreate + " " + response?.data?.data?.modelName,
            false,
          ),
        )
        return true
      }
    } catch (e) {
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.RESET_REGO_DATA,
      })

      displayErrors(e)
      return false
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}

export const getQuoteList = params => {
  return async dispatch => {
    try {
      startLoader("isDashboardTableLoader")
      const response = await QuoteApiServices.getQuoteList(params)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.QUOTE_LIST.GET_QUOTE_LIST,
          data: response.data.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isDashboardTableLoader")
    }
  }
}

export const handleSaveAsDraftQuote = (data, history) => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.submitAndSaveAsDraftQuoteData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        const { _id } = response?.data?.data ?? {}
        dispatch(resetQuoteReducer())
        history.replace(`${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/${_id}`)
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}

export const handleSubmitQuote = data => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.submitAndSaveAsDraftQuoteData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        dispatch(resetQuoteReducer())
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const getQuoteData = (id, isQuoteFirstPage) => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.getQuoteData(id)
      if (response?.data?.status === "SUCCESS") {
        const financeWhat = response?.data?.data?.quoteAnswersData?.loanType?.financeWhat
        if (financeWhat) {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.UPDATE_FINANCE_NUMBER_FORM_ON_ASSET_CHANGE,
            fieldName: financeWhat,
          })
        }
        const assetCondition = response?.data?.data?.quoteAnswersData?.loanType?.assetCondition
        if (assetCondition && assetCondition === "New") {
          dispatch(handleAssetConditionFieldChange())
        }

        dispatch({
          type: QUOTE_REDUX_CONSTANTS.SET_EXISTING_QUOTE_DATA,
          data: response?.data?.data,
          isQuoteFirstPage,
        })
        if (isQuoteFirstPage) {
          await dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_EDIT_CALLED,
            data: false,
          })
        }
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const getQuoteFilledData = id => {
  return async dispatch => {
    try {
      const response = await QuoteApiServices.getQuoteFilledData(id)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: QUOTE_REDUX_CONSTANTS.SET_VIEW_QUOTE_DATA,
          data: response?.data?.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}
export const getQuoteValueUpdate = data => {
  return async dispatch => {
    try {
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.SET_ABN_CALLED,
        data: data,
      })
    } catch (e) {
      displayErrors(e)
    }
  }
}

export const checkToShowEquifax = () => {
  return async dispatch => {
    try {
      const response = await QuoteApiServices.getEquifaxStatus({})
      if (response?.data?.status === "SUCCESS") {
        return response?.data?.data?.creditReportScreen
      }
    } catch (e) {
      displayErrors(e)
      throw e
    }
  }
}
export const checkCarSearch = data => {
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.validateQuoteData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      //
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}

export const checkCarSearchPartial = () => {
  return async dispatch => {
    try {
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.GLASS_GUIDE.RESET_REGO_DATA_PARTIAL,
      })
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.SET_REGO_CALLED,
        rego_called: false,
      })
    } catch (e) {
      //
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const checkBusinessSearch = (quoteAnswersData, currentForm) => {
  const data = {
    entityName: quoteAnswersData?.[currentForm]?.entityName,
    abn:
      typeof quoteAnswersData?.[currentForm]?.abn !== "number"
        ? quoteAnswersData?.[currentForm]?.abn.replaceAll(" ", "")
        : quoteAnswersData?.[currentForm]?.abn,
  }
  return async dispatch => {
    try {
      startLoader("isQuoteCommonLoader")
      const response = await QuoteApiServices.validateQuoteData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isQuoteCommonLoader")
    }
  }
}
export const activeSaveQuote = (data, quoteId) => {
  return async dispatch => {
    try {
      startLoader("isQuoteActiveSaveLoader")
      const response = await QuoteApiServices.activeSaveQuote(data)
      if (response?.data?.status === "SUCCESS") {
        if (!quoteId) {
          dispatch({
            type: QUOTE_REDUX_CONSTANTS.SET_QUOTE_ID,
            id: response?.data?.data?._id,
          })
          window.history.replaceState({}, "", `${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/${response?.data?.data?._id}`)
        }
        return { isSaved: true, quoteIdForRateCard: response?.data?.data?._id }
      }
    } catch (e) {
      displayErrors(e)
      throw e
    } finally {
      stopLoader("isQuoteActiveSaveLoader")
    }
  }
}

export const checkQuoteLimitAction = () => {
  return async dispatch => {
    try {
      startLoader("isAddQuoteLoader")
      const response = await QuoteApiServices.checkQuoteLimit()
      if (response?.data?.status === "SUCCESS") {
        return true
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isAddQuoteLoader")
    }
    return false
  }
}

export const handleQuoteLastQuestion = value => ({
  type: QUOTE_REDUX_CONSTANTS.SET_QUOTE_LAST_QUESTION,
  value,
})

export const resetQuoteReducer = () => ({
  type: QUOTE_REDUX_CONSTANTS.RESET_QUOTE_REDUCER,
})
