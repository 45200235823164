export const countries = [
  {
    name: "Afghanistan",
    cca2: "AF",
    flag: "🇦🇫",
    code: "+93",
  },
  {
    name: "Angola",
    cca2: "AO",
    flag: "🇦🇴",
    code: "+244",
  },
  {
    name: "Albania",
    cca2: "AL",
    flag: "🇦🇱",
    code: "+355",
  },
  {
    name: "Andorra",
    cca2: "AD",
    flag: "🇦🇩",
    code: "+376",
  },
  {
    name: "United Arab Emirates",
    cca2: "AE",
    flag: "🇦🇪",
    code: "+971",
  },
  {
    name: "Argentina",
    cca2: "AR",
    flag: "🇦🇷",
    code: "+54",
  },
  {
    name: "Armenia",
    cca2: "AM",
    flag: "🇦🇲",
    code: "+374",
  },
  {
    name: "Antigua and Barbuda",
    cca2: "AG",
    flag: "🇦🇬",
    code: "+1268",
  },
  {
    name: "Australia",
    cca2: "AU",
    flag: "🇦🇺",
    code: "+61",
  },
  {
    name: "Austria",
    cca2: "AT",
    flag: "🇦🇹",
    code: "+43",
  },
  {
    name: "Azerbaijan",
    cca2: "AZ",
    flag: "🇦🇿",
    code: "+994",
  },
  {
    name: "Burundi",
    cca2: "BI",
    flag: "🇧🇮",
    code: "+257",
  },
  {
    name: "Belgium",
    cca2: "BE",
    flag: "🇧🇪",
    code: "+32",
  },
  {
    name: "Benin",
    cca2: "BJ",
    flag: "🇧🇯",
    code: "+229",
  },
  {
    name: "Burkina Faso",
    cca2: "BF",
    flag: "🇧🇫",
    code: "+226",
  },
  {
    name: "Bangladesh",
    cca2: "BD",
    flag: "🇧🇩",
    code: "+880",
  },
  {
    name: "Bulgaria",
    cca2: "BG",
    flag: "🇧🇬",
    code: "+359",
  },
  {
    name: "Bahrain",
    cca2: "BH",
    flag: "🇧🇭",
    code: "+973",
  },
  {
    name: "Bahamas",
    cca2: "BS",
    flag: "🇧🇸",
    code: "+1242",
  },
  {
    name: "Bosnia and Herzegovina",
    cca2: "BA",
    flag: "🇧🇦",
    code: "+387",
  },
  {
    name: "Belarus",
    cca2: "BY",
    flag: "🇧🇾",
    code: "+375",
  },
  {
    name: "Belize",
    cca2: "BZ",
    flag: "🇧🇿",
    code: "+501",
  },
  {
    name: "Bolivia",
    cca2: "BO",
    flag: "🇧🇴",
    code: "+591",
  },
  {
    name: "Brazil",
    cca2: "BR",
    flag: "🇧🇷",
    code: "+55",
  },
  {
    name: "Barbados",
    cca2: "BB",
    flag: "🇧🇧",
    code: "+1246",
  },
  {
    name: "Brunei",
    cca2: "BN",
    flag: "🇧🇳",
    code: "+673",
  },
  {
    name: "Bhutan",
    cca2: "BT",
    flag: "🇧🇹",
    code: "+975",
  },
  {
    name: "Botswana",
    cca2: "BW",
    flag: "🇧🇼",
    code: "+267",
  },
  {
    name: "Central African Republic",
    cca2: "CF",
    flag: "🇨🇫",
    code: "+236",
  },
  {
    name: "Canada",
    cca2: "CA",
    flag: "🇨🇦",
    code: "+1",
  },
  {
    name: "Switzerland",
    cca2: "CH",
    flag: "🇨🇭",
    code: "+41",
  },
  {
    name: "Chile",
    cca2: "CL",
    flag: "🇨🇱",
    code: "+56",
  },
  {
    name: "China",
    cca2: "CN",
    flag: "🇨🇳",
    code: "+86",
  },
  {
    name: "Ivory Coast",
    cca2: "CI",
    flag: "🇨🇮",
    code: "+225",
  },
  {
    name: "Cameroon",
    cca2: "CM",
    flag: "🇨🇲",
    code: "+237",
  },
  {
    name: "DR Congo",
    cca2: "CD",
    flag: "🇨🇩",
    code: "+243",
  },
  {
    name: "Republic of the Congo",
    cca2: "CG",
    flag: "🇨🇬",
    code: "+242",
  },
  {
    name: "Colombia",
    cca2: "CO",
    flag: "🇨🇴",
    code: "+57",
  },
  {
    name: "Comoros",
    cca2: "KM",
    flag: "🇰🇲",
    code: "+269",
  },
  {
    name: "Cape Verde",
    cca2: "CV",
    flag: "🇨🇻",
    code: "+238",
  },
  {
    name: "Costa Rica",
    cca2: "CR",
    flag: "🇨🇷",
    code: "+506",
  },
  {
    name: "Cuba",
    cca2: "CU",
    flag: "🇨🇺",
    code: "+53",
  },
  {
    name: "Cyprus",
    cca2: "CY",
    flag: "🇨🇾",
    code: "+357",
  },
  {
    name: "Czechia",
    cca2: "CZ",
    flag: "🇨🇿",
    code: "+420",
  },
  {
    name: "Germany",
    cca2: "DE",
    flag: "🇩🇪",
    code: "+49",
  },
  {
    name: "Djibouti",
    cca2: "DJ",
    flag: "🇩🇯",
    code: "+253",
  },
  {
    name: "Dominica",
    cca2: "DM",
    flag: "🇩🇲",
    code: "+1767",
  },
  {
    name: "Denmark",
    cca2: "DK",
    flag: "🇩🇰",
    code: "+45",
  },
  {
    name: "Dominican Republic",
    cca2: "DO",
    flag: "🇩🇴",
    code: "+1809",
  },
  {
    name: "Algeria",
    cca2: "DZ",
    flag: "🇩🇿",
    code: "+213",
  },
  {
    name: "Ecuador",
    cca2: "EC",
    flag: "🇪🇨",
    code: "+593",
  },
  {
    name: "Egypt",
    cca2: "EG",
    flag: "🇪🇬",
    code: "+20",
  },
  {
    name: "Eritrea",
    cca2: "ER",
    flag: "🇪🇷",
    code: "+291",
  },
  {
    name: "Spain",
    cca2: "ES",
    flag: "🇪🇸",
    code: "+34",
  },
  {
    name: "Estonia",
    cca2: "EE",
    flag: "🇪🇪",
    code: "+372",
  },
  {
    name: "Ethiopia",
    cca2: "ET",
    flag: "🇪🇹",
    code: "+251",
  },
  {
    name: "Finland",
    cca2: "FI",
    flag: "🇫🇮",
    code: "+358",
  },
  {
    name: "Fiji",
    cca2: "FJ",
    flag: "🇫🇯",
    code: "+679",
  },
  {
    name: "France",
    cca2: "FR",
    flag: "🇫🇷",
    code: "+33",
  },
  {
    name: "Micronesia",
    cca2: "FM",
    flag: "🇫🇲",
    code: "+691",
  },
  {
    name: "Gabon",
    cca2: "GA",
    flag: "🇬🇦",
    code: "+241",
  },
  {
    name: "United Kingdom",
    cca2: "GB",
    flag: "🇬🇧",
    code: "+44",
  },
  {
    name: "Georgia",
    cca2: "GE",
    flag: "🇬🇪",
    code: "+995",
  },
  {
    name: "Ghana",
    cca2: "GH",
    flag: "🇬🇭",
    code: "+233",
  },
  {
    name: "Guinea",
    cca2: "GN",
    flag: "🇬🇳",
    code: "+224",
  },
  {
    name: "Gambia",
    cca2: "GM",
    flag: "🇬🇲",
    code: "+220",
  },
  {
    name: "Guinea-Bissau",
    cca2: "GW",
    flag: "🇬🇼",
    code: "+245",
  },
  {
    name: "Equatorial Guinea",
    cca2: "GQ",
    flag: "🇬🇶",
    code: "+240",
  },
  {
    name: "Greece",
    cca2: "GR",
    flag: "🇬🇷",
    code: "+30",
  },
  {
    name: "Grenada",
    cca2: "GD",
    flag: "🇬🇩",
    code: "+1473",
  },
  {
    name: "Guatemala",
    cca2: "GT",
    flag: "🇬🇹",
    code: "+502",
  },
  {
    name: "Guyana",
    cca2: "GY",
    flag: "🇬🇾",
    code: "+592",
  },
  {
    name: "Honduras",
    cca2: "HN",
    flag: "🇭🇳",
    code: "+504",
  },
  {
    name: "Croatia",
    cca2: "HR",
    flag: "🇭🇷",
    code: "+385",
  },
  {
    name: "Haiti",
    cca2: "HT",
    flag: "🇭🇹",
    code: "+509",
  },
  {
    name: "Hungary",
    cca2: "HU",
    flag: "🇭🇺",
    code: "+36",
  },
  {
    name: "Indonesia",
    cca2: "ID",
    flag: "🇮🇩",
    code: "+62",
  },
  {
    name: "India",
    cca2: "IN",
    flag: "🇮🇳",
    code: "+91",
  },
  {
    name: "Ireland",
    cca2: "IE",
    flag: "🇮🇪",
    code: "+353",
  },
  {
    name: "Iran",
    cca2: "IR",
    flag: "🇮🇷",
    code: "+98",
  },
  {
    name: "Iraq",
    cca2: "IQ",
    flag: "🇮🇶",
    code: "+964",
  },
  {
    name: "Iceland",
    cca2: "IS",
    flag: "🇮🇸",
    code: "+354",
  },
  {
    name: "Israel",
    cca2: "IL",
    flag: "🇮🇱",
    code: "+972",
  },
  {
    name: "Italy",
    cca2: "IT",
    flag: "🇮🇹",
    code: "+39",
  },
  {
    name: "Jamaica",
    cca2: "JM",
    flag: "🇯🇲",
    code: "+1876",
  },
  {
    name: "Jordan",
    cca2: "JO",
    flag: "🇯🇴",
    code: "+962",
  },
  {
    name: "Japan",
    cca2: "JP",
    flag: "🇯🇵",
    code: "+81",
  },
  {
    name: "Kazakhstan",
    cca2: "KZ",
    flag: "🇰🇿",
    code: "+76",
  },
  {
    name: "Kenya",
    cca2: "KE",
    flag: "🇰🇪",
    code: "+254",
  },
  {
    name: "Kyrgyzstan",
    cca2: "KG",
    flag: "🇰🇬",
    code: "+996",
  },
  {
    name: "Cambodia",
    cca2: "KH",
    flag: "🇰🇭",
    code: "+855",
  },
  {
    name: "Kiribati",
    cca2: "KI",
    flag: "🇰🇮",
    code: "+686",
  },
  {
    name: "Saint Kitts and Nevis",
    cca2: "KN",
    flag: "🇰🇳",
    code: "+1869",
  },
  {
    name: "South Korea",
    cca2: "KR",
    flag: "🇰🇷",
    code: "+82",
  },
  {
    name: "Kuwait",
    cca2: "KW",
    flag: "🇰🇼",
    code: "+965",
  },
  {
    name: "Laos",
    cca2: "LA",
    flag: "🇱🇦",
    code: "+856",
  },
  {
    name: "Lebanon",
    cca2: "LB",
    flag: "🇱🇧",
    code: "+961",
  },
  {
    name: "Liberia",
    cca2: "LR",
    flag: "🇱🇷",
    code: "+231",
  },
  {
    name: "Libya",
    cca2: "LY",
    flag: "🇱🇾",
    code: "+218",
  },
  {
    name: "Saint Lucia",
    cca2: "LC",
    flag: "🇱🇨",
    code: "+1758",
  },
  {
    name: "Liechtenstein",
    cca2: "LI",
    flag: "🇱🇮",
    code: "+423",
  },
  {
    name: "Sri Lanka",
    cca2: "LK",
    flag: "🇱🇰",
    code: "+94",
  },
  {
    name: "Lesotho",
    cca2: "LS",
    flag: "🇱🇸",
    code: "+266",
  },
  {
    name: "Lithuania",
    cca2: "LT",
    flag: "🇱🇹",
    code: "+370",
  },
  {
    name: "Luxembourg",
    cca2: "LU",
    flag: "🇱🇺",
    code: "+352",
  },
  {
    name: "Latvia",
    cca2: "LV",
    flag: "🇱🇻",
    code: "+371",
  },
  {
    name: "Morocco",
    cca2: "MA",
    flag: "🇲🇦",
    code: "+212",
  },
  {
    name: "Monaco",
    cca2: "MC",
    flag: "🇲🇨",
    code: "+377",
  },
  {
    name: "Moldova",
    cca2: "MD",
    flag: "🇲🇩",
    code: "+373",
  },
  {
    name: "Madagascar",
    cca2: "MG",
    flag: "🇲🇬",
    code: "+261",
  },
  {
    name: "Maldives",
    cca2: "MV",
    flag: "🇲🇻",
    code: "+960",
  },
  {
    name: "Mexico",
    cca2: "MX",
    flag: "🇲🇽",
    code: "+52",
  },
  {
    name: "Marshall Islands",
    cca2: "MH",
    flag: "🇲🇭",
    code: "+692",
  },
  {
    name: "Macedonia",
    cca2: "MK",
    flag: "🇲🇰",
    code: "+389",
  },
  {
    name: "Mali",
    cca2: "ML",
    flag: "🇲🇱",
    code: "+223",
  },
  {
    name: "Malta",
    cca2: "MT",
    flag: "🇲🇹",
    code: "+356",
  },
  {
    name: "Myanmar",
    cca2: "MM",
    flag: "🇲🇲",
    code: "+95",
  },
  {
    name: "Montenegro",
    cca2: "ME",
    flag: "🇲🇪",
    code: "+382",
  },
  {
    name: "Mongolia",
    cca2: "MN",
    flag: "🇲🇳",
    code: "+976",
  },
  {
    name: "Mozambique",
    cca2: "MZ",
    flag: "🇲🇿",
    code: "+258",
  },
  {
    name: "Mauritania",
    cca2: "MR",
    flag: "🇲🇷",
    code: "+222",
  },
  {
    name: "Mauritius",
    cca2: "MU",
    flag: "🇲🇺",
    code: "+230",
  },
  {
    name: "Malawi",
    cca2: "MW",
    flag: "🇲🇼",
    code: "+265",
  },
  {
    name: "Malaysia",
    cca2: "MY",
    flag: "🇲🇾",
    code: "+60",
  },
  {
    name: "Namibia",
    cca2: "NA",
    flag: "🇳🇦",
    code: "+264",
  },
  {
    name: "Niger",
    cca2: "NE",
    flag: "🇳🇪",
    code: "+227",
  },
  {
    name: "Nigeria",
    cca2: "NG",
    flag: "🇳🇬",
    code: "+234",
  },
  {
    name: "Nicaragua",
    cca2: "NI",
    flag: "🇳🇮",
    code: "+505",
  },
  {
    name: "Netherlands",
    cca2: "NL",
    flag: "🇳🇱",
    code: "+31",
  },
  {
    name: "Norway",
    cca2: "NO",
    flag: "🇳🇴",
    code: "+47",
  },
  {
    name: "Nepal",
    cca2: "NP",
    flag: "🇳🇵",
    code: "+977",
  },
  {
    name: "Nauru",
    cca2: "NR",
    flag: "🇳🇷",
    code: "+674",
  },
  {
    name: "New Zealand",
    cca2: "NZ",
    flag: "🇳🇿",
    code: "+64",
  },
  {
    name: "Oman",
    cca2: "OM",
    flag: "🇴🇲",
    code: "+968",
  },
  {
    name: "Pakistan",
    cca2: "PK",
    flag: "🇵🇰",
    code: "+92",
  },
  {
    name: "Panama",
    cca2: "PA",
    flag: "🇵🇦",
    code: "+507",
  },
  {
    name: "Peru",
    cca2: "PE",
    flag: "🇵🇪",
    code: "+51",
  },
  {
    name: "Philippines",
    cca2: "PH",
    flag: "🇵🇭",
    code: "+63",
  },
  {
    name: "Palau",
    cca2: "PW",
    flag: "🇵🇼",
    code: "+680",
  },
  {
    name: "Papua New Guinea",
    cca2: "PG",
    flag: "🇵🇬",
    code: "+675",
  },
  {
    name: "Poland",
    cca2: "PL",
    flag: "🇵🇱",
    code: "+48",
  },
  {
    name: "North Korea",
    cca2: "KP",
    flag: "🇰🇵",
    code: "+850",
  },
  {
    name: "Portugal",
    cca2: "PT",
    flag: "🇵🇹",
    code: "+351",
  },
  {
    name: "Paraguay",
    cca2: "PY",
    flag: "🇵🇾",
    code: "+595",
  },
  {
    name: "Qatar",
    cca2: "QA",
    flag: "🇶🇦",
    code: "+974",
  },
  {
    name: "Romania",
    cca2: "RO",
    flag: "🇷🇴",
    code: "+40",
  },
  {
    name: "Russia",
    cca2: "RU",
    flag: "🇷🇺",
    code: "+7",
  },
  {
    name: "Rwanda",
    cca2: "RW",
    flag: "🇷🇼",
    code: "+250",
  },
  {
    name: "Saudi Arabia",
    cca2: "SA",
    flag: "🇸🇦",
    code: "+966",
  },
  {
    name: "Sudan",
    cca2: "SD",
    flag: "🇸🇩",
    code: "+249",
  },
  {
    name: "Senegal",
    cca2: "SN",
    flag: "🇸🇳",
    code: "+221",
  },
  {
    name: "Singapore",
    cca2: "SG",
    flag: "🇸🇬",
    code: "+65",
  },
  {
    name: "Solomon Islands",
    cca2: "SB",
    flag: "🇸🇧",
    code: "+677",
  },
  {
    name: "Sierra Leone",
    cca2: "SL",
    flag: "🇸🇱",
    code: "+232",
  },
  {
    name: "El Salvador",
    cca2: "SV",
    flag: "🇸🇻",
    code: "+503",
  },
  {
    name: "San Marino",
    cca2: "SM",
    flag: "🇸🇲",
    code: "+378",
  },
  {
    name: "Somalia",
    cca2: "SO",
    flag: "🇸🇴",
    code: "+252",
  },
  {
    name: "Serbia",
    cca2: "RS",
    flag: "🇷🇸",
    code: "+381",
  },
  {
    name: "South Sudan",
    cca2: "SS",
    flag: "🇸🇸",
    code: "+211",
  },
  {
    name: "São Tomé and Príncipe",
    cca2: "ST",
    flag: "🇸🇹",
    code: "+239",
  },
  {
    name: "Suriname",
    cca2: "SR",
    flag: "🇸🇷",
    code: "+597",
  },
  {
    name: "Slovakia",
    cca2: "SK",
    flag: "🇸🇰",
    code: "+421",
  },
  {
    name: "Slovenia",
    cca2: "SI",
    flag: "🇸🇮",
    code: "+386",
  },
  {
    name: "Sweden",
    cca2: "SE",
    flag: "🇸🇪",
    code: "+46",
  },
  {
    name: "Swaziland",
    cca2: "SZ",
    flag: "🇸🇿",
    code: "+268",
  },
  {
    name: "Seychelles",
    cca2: "SC",
    flag: "🇸🇨",
    code: "+248",
  },
  {
    name: "Syria",
    cca2: "SY",
    flag: "🇸🇾",
    code: "+963",
  },
  {
    name: "Chad",
    cca2: "TD",
    flag: "🇹🇩",
    code: "+235",
  },
  {
    name: "Togo",
    cca2: "TG",
    flag: "🇹🇬",
    code: "+228",
  },
  {
    name: "Thailand",
    cca2: "TH",
    flag: "🇹🇭",
    code: "+66",
  },
  {
    name: "Tajikistan",
    cca2: "TJ",
    flag: "🇹🇯",
    code: "+992",
  },
  {
    name: "Turkmenistan",
    cca2: "TM",
    flag: "🇹🇲",
    code: "+993",
  },
  {
    name: "Timor-Leste",
    cca2: "TL",
    flag: "🇹🇱",
    code: "+670",
  },
  {
    name: "Tonga",
    cca2: "TO",
    flag: "🇹🇴",
    code: "+676",
  },
  {
    name: "Trinidad and Tobago",
    cca2: "TT",
    flag: "🇹🇹",
    code: "+1868",
  },
  {
    name: "Tunisia",
    cca2: "TN",
    flag: "🇹🇳",
    code: "+216",
  },
  {
    name: "Turkey",
    cca2: "TR",
    flag: "🇹🇷",
    code: "+90",
  },
  {
    name: "Tuvalu",
    cca2: "TV",
    flag: "🇹🇻",
    code: "+688",
  },
  {
    name: "Tanzania",
    cca2: "TZ",
    flag: "🇹🇿",
    code: "+255",
  },
  {
    name: "Uganda",
    cca2: "UG",
    flag: "🇺🇬",
    code: "+256",
  },
  {
    name: "Ukraine",
    cca2: "UA",
    flag: "🇺🇦",
    code: "+380",
  },
  {
    name: "Uruguay",
    cca2: "UY",
    flag: "🇺🇾",
    code: "+598",
  },
  {
    name: "United States",
    cca2: "US",
    flag: "🇺🇸",
    code: "+1",
  },
  {
    name: "Uzbekistan",
    cca2: "UZ",
    flag: "🇺🇿",
    code: "+998",
  },
  {
    name: "Vatican City",
    cca2: "VA",
    flag: "🇻🇦",
    code: "+3906698",
  },
  {
    name: "Saint Vincent and the Grenadines",
    cca2: "VC",
    flag: "🇻🇨",
    code: "+1784",
  },
  {
    name: "Venezuela",
    cca2: "VE",
    flag: "🇻🇪",
    code: "+58",
  },
  {
    name: "Vietnam",
    cca2: "VN",
    flag: "🇻🇳",
    code: "+84",
  },
  {
    name: "Vanuatu",
    cca2: "VU",
    flag: "🇻🇺",
    code: "+678",
  },
  {
    name: "Samoa",
    cca2: "WS",
    flag: "🇼🇸",
    code: "+685",
  },
  {
    name: "Yemen",
    cca2: "YE",
    flag: "🇾🇪",
    code: "+967",
  },
  {
    name: "South Africa",
    cca2: "ZA",
    flag: "🇿🇦",
    code: "+27",
  },
  {
    name: "Zambia",
    cca2: "ZM",
    flag: "🇿🇲",
    code: "+260",
  },
  {
    name: "Zimbabwe",
    cca2: "ZW",
    flag: "🇿🇼",
    code: "+263",
  },
]

export const zohoDocuments = [
  "lastYearsCompanyFinancial",
  "previousYearsCompanyFinancials",
  "profitLossStatement",
  "companyBalanceSheet",
  "ATOPortalCurrentBalance",
  "ATOPortal12MonthsHistory",
  "bankStatements",
  "equifaxCompanySearch",
  "proposedFutureWorkDocument",
  "cashflowProjectionsWithAssumptions",
  "lastYearsCompanyFinancialUploadUrl",
  "previousYearsCompanyFinancialsUploadUrl",
  "profitLossStatementUploadUrl",
  "companyBalanceSheetUploadUrl",
  "ATOPortalCurrentBalanceUploadUrl",
  "ATOPortal12MonthsHistoryUploadUrl",
  "bankStatementUploadUrl",
  "equifaxCompanySearchUploadUr",
  "proposedFutureWorkDocumentUploadUr",
  "cashflowProjectionsWithAssumptionsUploadUrl",
  "driversLicence",
  "medicareCard",
  "ratesNotice",
  "bankStatement",
  "evidenceOfIncome",
  "spousesEvidenceOfIncome",
  "rentalIncomeStatement",
  "centreLink",
  "childSupportStatements",
  "employmentContract",
  "passport",
  "visaCertificate",
  "equifaxReport",
  "employerAccountantABN",
  "driversLicenceUploadUrl",
  "medicareCardUploadUrl",
  "ratesNoticeUploadUr",
  "evidenceOfIncomeUploadUrls",
  "spousesEvidenceOfIncomeUploadUr",
  "rentalIncomeStatementUploadUrl",
  "bankStatementUploadUrls",
  "centreLinkUploadUrl",
  "childSupportStatementsUploadUrl",
  "employmentContractUploadUrl",
  "passportUploadUrl",
  "visaCertificateUploadUrl",
  "equifaxReportUploadUrl",
  "employerAccountantABNUploadUrl",
  "trustDeed",
  "trustDeedUrl",
]

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY"
export const ERROR_MESSAGES = {
  somethingWentWrong: "Oops something went wrong. Please try again later",
}

export const APPLICATION_FIRST_FORM = "quoteDetails"
