import _ from "lodash"
import Button from "../../../common/Button/Button"
import Input from "../../../common/Input/Input"
import AuthLayout from "../AuthLayout"
import { validateEmail, validatePhone } from "../../../helpers/ValidationHelper"
import { useEffect, useState } from "react"
import { onSubmitForgotPassword } from "./redux/ForgotPasswordAction"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { defaultCountry } from "../../../constants/quoteDefaultConstants"
import useKeyPress from "../../../hooks/useKeyPress"

const ForgotPasswordScreen = () => {
  const [isEmail, setIsEmail] = useState(false)

  const [email, setEmail] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry)
  const [errors, setErrors] = useState({})

  const isEnterPressed = useKeyPress("Enter")

  const { isForgotPasswordLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const dispatch = useDispatch()
  const history = useHistory()
  const handleErrorMessage = error => {
    setErrors({ ...errors, mobileNumber: error })
  }
  const handleForgotPasswordButtonClick = async () => {
    const error = {}
    if (isEmail) {
      if (!email) error.email = "Please enter email address"
      if (email && !validateEmail(email)) error.email = "Please enter valid email address"
    } else {
      if (!selectedCountry || !selectedCountry?.label?.toString()?.trim()?.length)
        error.mobileNumber = "Please select country code"
      if (!mobileNumber || !mobileNumber?.length) error.mobileNumber = "Please enter mobile number"
      if (mobileNumber && validatePhone(mobileNumber).errorMessage)
        error.mobileNumber = validatePhone(mobileNumber).errorMessage
    }

    if (_.isEmpty(error)) {
      setErrors({})
      const data = {
        email: isEmail ? email.toLowerCase().trim() : undefined,
        countryCode: !isEmail ? selectedCountry?.value?.code : undefined,
        mobileNumber: !isEmail ? mobileNumber : undefined,
      }
      dispatch(onSubmitForgotPassword(isEmail, data, history))
    } else {
      setErrors(error)
    }
  }

  useEffect(() => {
    localStorage.removeItem("otpCount")
    return () => {
      setErrors(false)
      setEmail("")
      setMobileNumber("")
      setSelectedCountry(defaultCountry)
      setErrors({})
    }
  }, [])

  useEffect(async () => {
    if (isEnterPressed) {
      await handleForgotPasswordButtonClick()
    }
  }, [isEnterPressed])

  return (
    <AuthLayout
      title="Forgot Password"
      toggleText={!isEmail ? "Reset with email" : "Reset with mobile"}
      onToggleText={() => {
        setIsEmail(e => !e)
        setErrors({})
      }}
    >
      {isEmail ? (
        <Input
          type="userName"
          placeholder="Email address"
          name="userName"
          className="w-100"
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={errors?.email}
        />
      ) : (
        <CountrySelect
          countryCode=""
          countryName={selectedCountry}
          phoneNumber={mobileNumber}
          placeholder="Mobile number"
          className="w-100"
          updatePhoneNumber={e => {
            setMobileNumber(e?.toString()?.trim())
          }}
          updateErrorMessage={handleErrorMessage}
          updatePhoneCountry={e => {
            setSelectedCountry(e)
          }}
          error={errors?.mobileNumber}
        />
      )}
      <div className="back-to-login-text-container">
        <div onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)} className="mt-20 f-14 font-primary">
          Back to Login
        </div>
      </div>
      <div className="text-center w-100 mt-20">
        <Button
          buttonType="secondary"
          title="Submit"
          className="form-button"
          isLoading={isForgotPasswordLoader}
          onClick={() => handleForgotPasswordButtonClick()}
        />
      </div>
    </AuthLayout>
  )
}
export default ForgotPasswordScreen
