import { EQUIFAX_DEFAULT_SCORE } from "../../../../constants/regexConstants"
import { EQUIFAX_CONSTANTS } from "./EquifaxReduxConstants"

const initialState = {
  firstName: "",
  lastName: "",
  middleName: "",
  emailAddress: "",
  mobileNumber: "",
  countryCode: "",
  countryName: {
    label: "🇦🇺 +61",
    value: {
      name: "Australia",
      cca2: "AU",
      flag: "🇦🇺",
      code: "+61",
    },
  },
  suburb: "",
  streetAddress: "",
  state: "",
  stateShort: "",
  streetName: "",
  streetNumber: "",
  postCode: "",
  bdate: "",
  country: "",
  location: "",
  errors: {},
  equifaxScore: EQUIFAX_DEFAULT_SCORE,
  equifaxData: {},
  isonEquifaxPage: false,
  isRegistered: false,
  isMobileVerified: false,
  isPasswordSet: false,
  isMobVerificationModal: false,
  isFormShow: true,
  isEquifaxFailed: false,
  hideButton: false,
  registrationButtonDisable: false,
  authTokenForEquifax: "",
}

export const equifaxReducer = (state = initialState, action) => {
  switch (action.type) {
    case EQUIFAX_CONSTANTS.EQUIFAX_ADDRESS_CHANGE:
      return {
        ...state,
        streetAddress: action?.data?.streetAddress,
        streetNumber: action?.data?.street_number,
        streetName: action?.data?.street_name,
        location: action?.data?.location,
        country: action?.data?.country,
        suburb: action?.data?.city,
        postCode: action?.data?.zip,
        state: action?.data?.state,
        stateShort: action?.data?.stateshort,
      }

    case EQUIFAX_CONSTANTS.EQUIFAX_FIELD_CHANGE:
      return {
        ...state,
        [action?.fieldName]: action?.fieldValue,
      }
    case EQUIFAX_CONSTANTS.EQUIFAX_DATE_CHANGE:
      return {
        ...state,
        [action?.fieldName]: action?.fieldValue,
      }
    case EQUIFAX_CONSTANTS.RESET_EQUIFAX_REDUCER:
      return initialState
    default:
      return state
  }
}
