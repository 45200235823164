import PropTypes from "prop-types"
import { RenderField } from "./RenderField"
import { useSelector } from "react-redux"
import { applicationSelector } from "../redux/ApplicationSelector"
import { checkFieldVisibilityCondition } from "../../../helpers/applicationHelper"

const RepeaterForm = ({ field, onFieldUpdate, errorMessage, onAdd, onRemove }) => {
  const { question, fields, fieldName, fieldsToRender } = field

  const { savedFormData } = useSelector(applicationSelector)

  const handleFieldUpdate = (key, value, path, index, keyoffield) => {
    onFieldUpdate(fieldName, value, path, true, index, key, keyoffield)
  }

  const handleAdd = () => {
    const updatedFields = [...fields]
    if (fieldsToRender?.length) {
      updatedFields.push(
        fieldsToRender?.map(field => ({
          ...field,
          answer: undefined,
        })),
      )
      onAdd(updatedFields)
    } else {
      updatedFields.push(
        fields?.[0]?.map(field => ({
          ...field,
          answer: undefined,
        })),
      )
      onAdd(updatedFields)
    }
  }

  const handleRemove = key => {
    const updatedFields = [...fields].filter((_, index) => key !== index)
    onRemove(updatedFields)
  }

  return (
    <div className="repeater-wrapper repeater-questions">
      <div className="repeater-head">
        {question}
        <span className="material-icons-outlined repeater-add-icon" onClick={handleAdd}>
          control_point
        </span>
      </div>
      <table className="repeater-table">
        {fields?.length ? (
          fields?.map((f, index) => (
            <tr key={index} className={`row${index} repeater-rows`}>
              {f
                ?.filter((rf, i, self) => {
                  return checkFieldVisibilityCondition(rf, savedFormData, self)
                })
                ?.map(e => {
                  return (
                    <td key={e.fieldName} style={{ minWidth: "200px" }}>
                      <RenderField
                        field={e}
                        isFromAssetPage={false}
                        indexForRepeater={index}
                        showSeparate={""}
                        onFieldUpdate={(key, value, path) => handleFieldUpdate(key, value, path, index, e?.key)}
                        errorMessage={errorMessage?.[index]?.[e?.fieldName] || ""}
                      />
                    </td>
                  )
                })}
              {(fieldsToRender?.length || fields?.length > 1) && (
                <td className="repeater-remove-cell">
                  <span className="material-icons-outlined repeater-remove-icon" onClick={() => handleRemove(index)}>
                    remove_circle_outline
                  </span>
                </td>
              )}
            </tr>
          ))
        ) : (
          <div className="no-entry">
            <span className="material-icons-outlined" onClick={handleAdd}>
              control_point
            </span>
            <span onClick={handleAdd}>Add Entry</span>
          </div>
        )}
      </table>
    </div>
  )
}

RepeaterForm.propTypes = {
  // field to show
  field: PropTypes.object,
  // call when field changes
  onFieldUpdate: PropTypes.func,
  errorMessage: PropTypes.string,
  handleSearch: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

export default RepeaterForm
