import { useSelector, useDispatch } from "react-redux"
import Button from "../../common/Button/Button"
import IconButton from "../../common/IconButton/IconButton"
import PropTypes from "prop-types"
import { APPLICATION_REDUX_CONSTANTS } from "./redux/ApplicaitonReduxConstants"

const ApplicationBottomNavigation = ({ handleNextClick, handlePreviousClick }) => {
  const { _id: userId } = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const [title] = useSelector(({ applicationReducer }) => applicationReducer?.formsKey ?? {})
  const dispatch = useDispatch()

  return (
    <>
      {!userId ? (
        <Button buttonType="outlined-primary" onClick={() => {}}>
          Cancel
        </Button>
      ) : (
        <div></div>
      )}
      <div className="prev-next-button-container">
        <IconButton buttonType="outlined-primary" title="arrow_back_ios" onClick={handlePreviousClick} />

        {title === "disclosure" ? (
          <Button
            className={`icon-button`}
            buttonType="outlined-primary"
            onClick={() => dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_POPUP_FIELD_UPDATE, payload: true })}
          >
            <span> Complete Application</span>
          </Button>
        ) : (
          <Button className={`icon-button`} buttonType="outlined-primary" onClick={handleNextClick}>
            <span className="material-icons-outlined false">arrow_forward_ios</span>
          </Button>
        )}
      </div>
    </>
  )
}

ApplicationBottomNavigation.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
}

export default ApplicationBottomNavigation
