import { ROUTE_CONSTANTS_VARIABLE } from "../routes/RouteConstants"

export const UPPER_MENU_CONSTANTS = [
  {
    name: "dashboard",
    label: "Dashboard",
    icon: "home",
    path: ROUTE_CONSTANTS_VARIABLE.DASHBOARD,
  },
  {
    name: "quote",
    label: "Quote",
    icon: "request_quote",
    path: ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST,
  },
  {
    name: "application",
    label: "Application",
    icon: "description",
    path: ROUTE_CONSTANTS_VARIABLE.APPLICATION,
  },
  {
    name: "userModule",
    label: "User Module",
    icon: "perm_identity",
    path: ROUTE_CONSTANTS_VARIABLE.USER_MODULE,
  },
]
