import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useOnClickOutside } from "../../hooks/UserClickOutsideHook"
import UpperMenu from "../UpperMenu/UpperMenu"
import { getUserProfile, logoutUser } from "./Redux/headerAction"
import ChangePassword from "./components/ChangePassword"
import ProgressBar from "../../../src/common/ProgressBar/ProgressBar"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"
import ApplicationProgressBar from "../ProgressBar/ApplicationProgressBar"
import { useHistory, useLocation } from "react-router-dom"

const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userSettingsRef = useRef()
  const [showUserSettings, setShowUserSettings] = useState(false)
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false)

  useOnClickOutside(userSettingsRef, () => setShowUserSettings(false))
  const userDetail = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})

  const onLogoutClick = () => {
    dispatch(logoutUser(history))
  }

  const [onQuotePage, setOnQuotePage] = useState(false)
  const [onApplicationPage, setonApplicationPage] = useState(false)

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  useEffect(() => {
    return history.listen(location => {
      if (location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)) {
        setOnQuotePage(true)
      }
      if (location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION)) {
        setonApplicationPage(true)
      }
      if (
        !location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION) &&
        !location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)
      ) {
        setonApplicationPage(false)
        setOnQuotePage(false)
      }
    })
  }, [history])

  useEffect(() => {
    if (location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)) {
      setOnQuotePage(true)
    }
    if (location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION)) {
      setonApplicationPage(true)
    }
    if (
      !location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION) &&
      !location.pathname.includes(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)
    ) {
      setonApplicationPage(false)
      setOnQuotePage(false)
    }
  }, [location])

  return (
    <div className="header-wrapper" id="sim-3-header">
      <div className="header-container just-bet align-end">
        <UpperMenu />
        <div className="user-dropdown">
          <div className="user-name" onClick={() => setShowUserSettings(e => !e)}>
            <span className="dot">
              {userDetail?.firstName ? `${userDetail?.firstName.charAt(0)}${userDetail?.lastName.charAt(0)}` : "-"}
            </span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
          {showUserSettings && (
            <div ref={userSettingsRef} className="user-settings">
              <div>
                <div
                  onClick={() => {
                    setShowUserSettings(e => !e)
                    history.push(`${ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE}`)
                  }}
                >
                  <span className="material-icons-outlined">person_outline</span> Profile
                </div>
                <div
                  onClick={() => {
                    setIsChangePasswordModal(true)
                    setShowUserSettings(e => !e)
                  }}
                >
                  <span className="material-icons-outlined">lock</span> Change Password
                </div>
                <div onClick={onLogoutClick}>
                  <span className="material-icons-outlined">logout</span> Logout
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {onQuotePage && <ProgressBar className="progress-bar" id="completeprocess" />}
      {onApplicationPage && <ApplicationProgressBar className="progress-bar" id="completeprocess" />}
      {isChangePasswordModal && (
        <ChangePassword isOpen={isChangePasswordModal} onClose={() => setIsChangePasswordModal(false)} />
      )}
    </div>
  )
}
export default Header
