import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useOnClickOutside } from "../../hooks/UserClickOutsideHook"
import { useMediaQuery } from "../../hooks/useMediaQuery"

const QuickAccessPopup = ({ children, className, title, iconActive, iconInActive }) => {
  const [isActive, setIsActive] = useState(false)
  const isMobileScreen = useMediaQuery("(max-width: 680px)")

  const manuRef = useRef(null)
  const disableHover = useRef(false)

  const handleMouseHover = isHover => {
    if (!disableHover.current) {
      if (isHover) {
        !isActive && setIsActive(true)
      } else {
        isActive && setIsActive(false)
      }
    }
  }

  useOnClickOutside(manuRef, () => {
    setIsActive(false)
  })

  useEffect(() => {
    if (isMobileScreen) {
      disableHover.current = true
    }
  }, [isMobileScreen])

  return (
    <div ref={manuRef} className={`quick-access-popup ${className} ${isActive ? "active" : "inactive"}`}>
      <div
        className="arrow"
        onMouseOver={() => handleMouseHover(true)}
        onMouseOut={() => handleMouseHover(false)}
        onClick={() => {
          disableHover.current = true
          setIsActive(e => !e)
        }}
      >
        <span className="material-icons">{isActive ? iconActive : iconInActive}</span>
      </div>
      <div className="container" onMouseOver={() => handleMouseHover(true)} onMouseOut={() => handleMouseHover(false)}>
        <div className="header-title">{title}</div>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default QuickAccessPopup

QuickAccessPopup.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  iconActive: PropTypes.string,
  iconInActive: PropTypes.string,
  className: PropTypes.string,
}
