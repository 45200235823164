/* eslint-disable no-param-reassign */
import axios from "axios"
import { clearAuthTokenFromLocalStorage, getAuthTokenFromLocalStorage } from "../helpers/LocalStorageHelper"
import { AUTH_REDUX_CONSTANTS } from "../screens/auth/redux/AuthReduxConstants"
import { store } from "../redux/store"

const instance = axios.create({
  timeout: 22000,
  params: {}, // do not remove this, its added to add params later in the config
})

// Add a request interceptor
instance.interceptors.request.use(
  async config => {
    const token = getAuthTokenFromLocalStorage()
    if (token) {
      config.headers.common.authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

const ApiService = {
  request(config = {}) {
    return instance.request(config)
  },
  getData(url, config = {}) {
    return instance.get(url, config)
  },
  postData(url, data, config) {
    return instance.post(url, data, config)
  },
  putData(url, data, config) {
    return instance.put(url, data, config)
  },
  patchData(url, data) {
    return instance.patch(url, data)
  },
  deleteData(url, config = {}) {
    return instance.delete(url, config)
  },
}

instance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const statusCode = error?.response?.status ?? 0
    switch (statusCode) {
      case 401:
        clearAuthTokenFromLocalStorage()
        store.dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: false,
        })
        return false
      case 502:
        return false
      case 403:
        /* handle 403 here */
        // window.location.href = '/forbidden-access';
        return false
      default:
        break
    }
    return Promise.reject(error)
  },
)

export default ApiService
