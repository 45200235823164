import ApplicationApiServices from "../services/ApplicationServices"
import { APPLICATION_REDUX_CONSTANTS } from "./ApplicaitonReduxConstants"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { filterForActiveSave, prepareApplicationDataForActiveSave } from "../../../helpers/applicationHelper"
import { convertAPIDataToSavedFormData, restructureAllGetAPIData } from "../../../helpers/dataParser"
import { BASE_URL } from "../../../constants/UrlConstants"
import { cloneDeep, isEmpty, toUpper } from "lodash"
import { errorNotification, successNotification } from "../../../common/NotifyToaster"
import { getFullNavigationHistory, saveNavigationHistoryInStorage } from "../helpers/navigationHistoryHelper"
import { zohoDocuments } from "../../../constants/Constant"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { ASSET_REDUX_CONSTANTS } from "../../asset/redux/AssetReduxConstants"
import BrokerApiServices from "../../broker/services/BrokerServices"
// import applicationJSON from "../../../dummy/applicationJSON.json"

export const getApplicationToolJSON = () => {
  return async dispatch => {
    try {
      startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.getApplicationToolJSON()
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_APPLICATION_JSON_DATA,
          data: response?.data?.data,
          // data: applicationJSON,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in get application json:", e)
      errorNotification("Failed to get flow data")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}
export const removeApplicationFiles = (data, exported, element) => {
  return async dispatch => {
    try {
      startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.removeFileInApplicaiton(data)
      if (response?.data?.status === "SUCCESS") {
        exported = exported.filter(function(item) {
          return item !== element
        })

        const fileList = exported?.length ? exported?.join(",") : undefined

        successNotification(response?.data?.message || "Document removed successfully")
        return fileList
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in remove application files:", e)
      errorNotification("Failed to remove files")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const uploadApplicationFiles = (data, config, id) => {
  return async dispatch => {
    try {
      startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.uploadFileInApplicaiton(data, config, id)
      if (response?.data?.status === "SUCCESS") {
        const uploadedFile = []
        for (let i = 0; i < response?.data?.data.length; i++) {
          uploadedFile[i] = response?.data?.data[i]?.path
        }
        const fileList = uploadedFile.join(",")
        return fileList
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in upload application files:", e)
      errorNotification("Failed to upload files")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const getApplicationList = params => {
  return async dispatch => {
    try {
      startLoader("isDashboardTableLoader")
      const response = await ApplicationApiServices.getApplicationList(params)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.GET_APPLICATION_LIST,
          data: response.data.data,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in Application list:", e)
      errorNotification("Failed to fetch application list")
    } finally {
      stopLoader("isDashboardTableLoader")
    }
  }
}

export const formPathDetailsAPI = data => {
  return async (dispatch, getState) => {
    const state = getState()
    const dataForActiveSave = state?.applicationReducer?.dataForActiveSave
    if (!isEmpty(dataForActiveSave) || data) {
      if (!data) {
        data = dataForActiveSave
      }
      try {
        // startLoader("isApplicationCommonLoader")
        let navigationHistory = state?.applicationReducer?.navigationHistory
        navigationHistory = [
          ...(navigationHistory || []),
          { formsKey: state.applicationReducer.formsKey, subFormsKey: state.applicationReducer.subFormsKey },
        ]
        const payload = filterForActiveSave(
          data,
          getFullNavigationHistory(state.applicationReducer.formPaths, navigationHistory),
        )
        if (!isEmpty(payload)) {
          const response = await ApplicationApiServices.formPathDetails(payload)
          if (response?.data?.status === "SUCCESS") {
            dispatch({
              type: APPLICATION_REDUX_CONSTANTS.SET_FORMS_PATH,
              data: response?.data?.data,
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error in form path api:", e)
        errorNotification("Failed to get path data")
      } finally {
        //  stopLoader("isApplicationCommonLoader")
      }
    } else {
      throw new Error("Data is empty")
    }
  }
}

export const getInitialApplicationData = (quoteId, rateCardID, repayments) => {
  return async dispatch => {
    let payload = {}
    if (rateCardID && repayments) {
      payload = { rateId: rateCardID, repayments }
    }
    try {
      startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.getInitialApplicationData(quoteId, payload)
      if (response?.data?.status === "SUCCESS") {
        const responseData = response?.data?.data
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_FILTER_IN_RATE_CARD,
          payload: responseData,
        })
        if (response?.data?.data?.companyDetails?.companyRegistrationDetails?.GSTRegisteredFrom) {
          responseData.companyDetails.companyRegistrationDetails.registeredForGST = "Yes"
        }
        if (
          !response?.data?.data?.companyDetails?.companyRegistrationDetails?.GSTRegisteredFrom &&
          response?.data?.data?.companyDetails?.companyRegistrationDetails?.name
        ) {
          responseData.companyDetails.companyRegistrationDetails.registeredForGST = "No"
        }
        // else
        // {
        //   responseData.companyDetails.companyRegistrationDetails.registeredForGST = "No"
        // }
        if (response?.data?.data?.companyDetails?.trustDetails?.gstStatus) {
          responseData.companyDetails.trustDetails.isGstStatus = "Yes"
        }
        if (
          !response?.data?.data?.companyDetails?.trustDetails?.gstStatus &&
          response?.data?.data?.companyDetails?.trustDetails?.name
        ) {
          responseData.companyDetails.trustDetails.isGstStatus = "No"
        }
        // else
        // {
        //   responseData.companyDetails.trustDetails.isGstStatus = "No"
        // }

        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_INITIAL_APPLICATION_DATA,
          payload: responseData,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in initial application data:", e)
      errorNotification("Failed to get initial application data")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const activeSaveApplicationData = (dataToSave, history, LVRData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      if (!dataToSave?.applicationAnswersData) {
        dataToSave.applicationAnswersData = state?.applicationReducer?.dataForActiveSave || {}
      }

      let navigationHistory = state?.applicationReducer?.navigationHistory
      navigationHistory = [
        ...(navigationHistory || []),
        { formsKey: state.applicationReducer.formsKey, subFormsKey: state.applicationReducer.subFormsKey },
      ]
      let payload = {}
      startLoader("isApplicationCommonLoader")
      const noOfAsset = dataToSave?.applicationAnswersData?.quoteDetails?.numberOfAsset
      const noOfBenificiary = dataToSave?.applicationAnswersData?.companyDetails?.trustDetails?.numberOfBeneficiary
      if (noOfBenificiary === 0) {
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary4
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary3
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary2
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary1
      } else if (noOfBenificiary === 1) {
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary4
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary3
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary2
      } else if (noOfBenificiary === 2) {
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary4
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary3
      } else if (noOfBenificiary === 3) {
        delete dataToSave?.applicationAnswersData?.companyDetails?.beneficiary4
      }
      if (noOfAsset === 0) {
        delete dataToSave?.applicationAnswersData?.asset4
        delete dataToSave?.applicationAnswersData?.asset3
        delete dataToSave?.applicationAnswersData?.asset2
        delete dataToSave?.applicationAnswersData?.asset1
      }
      if (noOfAsset === 1) {
        delete dataToSave?.applicationAnswersData?.asset4
        delete dataToSave?.applicationAnswersData?.asset3
        delete dataToSave?.applicationAnswersData?.asset2
      }
      if (noOfAsset === 2) {
        delete dataToSave?.applicationAnswersData?.asset4
        delete dataToSave?.applicationAnswersData?.asset3
      }
      if (noOfAsset === 3) {
        delete dataToSave?.applicationAnswersData?.asset4
      }
      if (
        ["beneficiary1", "beneficiary2", "beneficiary3", "beneficiary4"].includes(
          state.applicationReducer.formsKey?.[0],
        )
      ) {
        payload = {
          ...dataToSave,
        }
      }
      if (["asset1", "asset2", "asset3", "asset4"].includes(state.applicationReducer.formsKey?.[0])) {
        payload = {
          ...dataToSave,
        }
      } else {
        payload = {
          ...dataToSave,
          applicationAnswersData: filterForActiveSave(
            dataToSave?.applicationAnswersData,
            getFullNavigationHistory(state.applicationReducer.formPaths, navigationHistory),
          ),
        }
      }

      payload.previousQuestions = {
        navigationHistory,
        completeNavigationHistory: getFullNavigationHistory(state.applicationReducer.formPaths, navigationHistory),
        formPaths: state.applicationReducer.formPaths,
        formPathIndex: state.applicationReducer.formPathIndex,
      }
      if (!isEmpty(payload?.applicationAnswersData)) {
        const response = await ApplicationApiServices.activeSaveApplicationData(payload)
        if (response?.data?.data?._id) {
          const isZohoUpdated = await dispatch(postZohoData(response?.data?.data?._id))
          // code that call zoho upload with doc types
          if (
            ["supportingCompanyDocumentation", "trustDetails", "documentUpload"]?.includes(
              state.applicationReducer.subFormsKey?.[0],
            )
          ) {
            const state = getState()

            const dataToCheckForDocs =
              dataToSave.applicationAnswersData?.[state?.applicationReducer?.formsKey]?.[
                state?.applicationReducer?.subFormsKey
              ]

            if (dataToCheckForDocs) {
              const documentsToCheck = Object?.keys(dataToCheckForDocs)
              const documentsToUpload = zohoDocuments.filter(element => documentsToCheck?.includes(element))?.join(",")
              if (documentsToUpload) {
                await dispatch(
                  postZohoUpload(
                    response?.data?.data?._id,
                    documentsToUpload,
                    state?.applicationReducer?.formsKey?.[0],
                    state?.applicationReducer?.subFormsKey?.[0],
                    false,
                  ),
                )
              }
            }
          }

          return {
            isApplicationCompleted:
              response?.data?.data?.applicationAnswersData?.status?.toLowerCase?.() === "completed",
            applicationId: response?.data?.data?._id,
            shouldShowRateCard: !!state?.applicationReducer?.isRecalculationFieldUpdated,
            isZohoUpdated: isZohoUpdated,
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in Active save:", e)
      if (e?.response?.data?.message?.toLowerCase?.() === "application with this quote id already exists") {
        history && history.push(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)
      }
      errorNotification(e?.response?.data?.message || "Failed to save active save data")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const getAllApplicationData = applicationId => {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      startLoader("isApplicationCommonLoader")

      const response = await ApplicationApiServices.getAllData(applicationId)
      if (response?.data?.status === "SUCCESS" && response?.data?.data) {
        if (
          response?.data?.data?.previousQuestions?.[0]?.completeNavigationHistory?.length > 0 &&
          response?.data?.data?.previousQuestions?.[0]?.formPaths?.length > 0
        ) {
          const { formPaths, completeNavigationHistory } = response?.data?.data?.previousQuestions?.[0]
          saveNavigationHistoryInStorage(formPaths, completeNavigationHistory)
        }
        dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_DATA_AFTER_REFRESH, payload: response.data.data })
        const structuredData = restructureAllGetAPIData(response.data.data)
        const newSavedFormData = convertAPIDataToSavedFormData(
          structuredData,
          cloneDeep(state.applicationReducer.applicationJsonData),
        )
        dispatch(formPathDetailsAPI(prepareApplicationDataForActiveSave(newSavedFormData)))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in get all application data:", e)
      errorNotification("Failed to get application data")
    } finally {
      stopLoader("isApplicationCommonLoader")
    }
  }
}
export const getApplicationDocData = (id, data) => {
  return async dispatch => {
    try {
      const response = await ApplicationApiServices.getDocumentOfApplicationData(id, data)
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_EDIT_APPLICATION_DATA,
          data: response?.data?.data,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in application filled data:", e)
      errorNotification("Failed to get autofill data")
    }
  }
}
export const sendZohoUploadData = data => {
  return async dispatch => {
    try {
      await ApplicationApiServices.sendZohoEquifaxData(data)
    } catch (e) {
      // eslint-disable-next-line no-console
    }
  }
}
export const sendApplicationDocData = (id, data) => {
  return async dispatch => {
    try {
      const response = await ApplicationApiServices.sendDocumentOfApplicationData(id, data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message || "Requested Document submitted successfully")
        // upload docs to zoho work drive
        const documentsToUpload = data?.sendDocuments?.map(e => e?.Document_Type)?.join(",")
        await dispatch(postZohoUpload(id, documentsToUpload, "", "", true))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in application filled data:", e)
      errorNotification(e?.response?.data?.message || "Error in document submission")
    }
  }
}
export const getApplicationFilledData = (id, isFromBroker = false, isFromBrokerNew = false) => {
  return async dispatch => {
    try {
      // loader add
      startLoader("isApplicationCommonLoader")
      let response = {}
      if (isFromBroker) {
        response = await BrokerApiServices.getApplicationData(id)
      } else if (isFromBrokerNew) {
        response = await BrokerApiServices.getApplicationData(id)
      } else {
        response = await ApplicationApiServices.getApplicationFilledData(id)
      }

      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_VIEW_APPLICATION_DATA,
          data: response?.data?.data,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in application filled data:", e)
      errorNotification("Failed to get autofill data")
    } finally {
      // loader remove
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const getGlassGuideDataFromAsset = (data, field, callBack) => {
  return async dispatch => {
    const { url, method, params } = data

    try {
      startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.getDataFromExternalSources(
        `${BASE_URL}${url}`,
        toUpper(method),
        params,
      )
      callBack && callBack()
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: ASSET_REDUX_CONSTANTS.UPDATE_APPLICATION_GLASS_GUIDE_DATA,
          data: response?.data?.data,
          field,
        })
        if (field?.fieldName === "assetManufacturerOptions") {
          const lvrData = response?.data?.data
          // delete lvrData?.table
          dispatch({
            type: ASSET_REDUX_CONSTANTS.UPDATE_ASSET_LVR_DATA,
            data: JSON.stringify(lvrData),
          })
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in glass guide:", e)
      callBack && callBack()
      errorNotification("Failed to fetch glass guide data")
    } finally {
      // loader remove
      stopLoader("isApplicationCommonLoader")
    }
  }
}

export const getGlassGuideData = (data, field, formPath, callBack) => {
  return async dispatch => {
    const { url, method, params } = data

    try {
      //   startLoader("isApplicationCommonLoader")
      const response = await ApplicationApiServices.getDataFromExternalSources(
        `${BASE_URL}${url}`,
        toUpper(method),
        params,
      )
      callBack && callBack()
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_GLASS_GUIDE_DATA,
          data: response?.data?.data,
          field,
          formPath,
        })
        if (field?.fieldName === "assetManufacturerOptions") {
          const lvrData = response?.data?.data
          // delete lvrData?.table
          dispatch({
            type: APPLICATION_REDUX_CONSTANTS.UPDATE_LVR_DATA,
            data: JSON.stringify(lvrData),
          })
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in glass guide:", e)
      callBack && callBack()
      errorNotification("Failed to fetch glass guide data")
    } finally {
      // loader remove
      //   stopLoader("isApplicationCommonLoader")
    }
  }
}

export const getQuickAccessMenuList = ({ quoteId, applicationId }) => {
  return async dispatch => {
    try {
      const response = await ApplicationApiServices.getQuickAccessMenuList({ quoteId, applicationId })
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_QUICK_ACCESS_MENU_LIST,
          payload: response?.data?.data,
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error in get quick access menu list:", error)
      errorNotification("Failed to get quick access menu data")
    }
  }
}
export const getStoreRateCardLenderId = data => {
  return async () => {
    try {
      startLoader("rateCardLoader")
      const response = await ApplicationApiServices.getStoreRateLenderId(data)
      stopLoader("rateCardLoader")
      if (response?.data?.status !== "SUCCESS") throw Error("Api failed")
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error in get store rate card lenderId:", error)
      errorNotification("Failed to store id")
      stopLoader("rateCardLoader")
    }
  }
}

export const postZohoData = applicationId => {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      if (state?.applicationReducer?.formsKey?.[0] && state?.applicationReducer?.subFormsKey && applicationId) {
        const data = {
          applicationId,
          currentForm: {
            formKey: state?.applicationReducer?.formsKey?.[0] || "",
            subFormKey: state?.applicationReducer?.subFormsKey?.[0] || "",
          },
        }
        if (["supportingCompanyDocumentation", "documentUpload"]?.includes(state.applicationReducer.subFormsKey?.[0])) {
          return true
        } else {
          const response = await ApplicationApiServices.postZohoData(data)
          return response?.data?.status === "SUCCESS"
        }
      }
    } catch (e) {
      errorNotification(e?.response?.data?.message || "Failed to update zoho data")
      return false
    }
  }
}

export const postZohoUpload = (applicationId, documentsToUpload, FormName, SubFormName, isRequestedDocument) => {
  return async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = {
        applicationId,
        type: documentsToUpload,
        FormName: FormName,
        SubFormName: SubFormName,
        isRequestedDocument,
      }
      const response = await ApplicationApiServices.postZohoDataUpload(data)
      if (response?.data?.status !== "SUCCESS")
        throw Error(response?.data?.message || "Failed to upload zoho documents")
    } catch (e) {
      throw e
    }
  }
}
