import Modal from "../../Modal/Modal"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import OtpInput from "../../OtpInput/OtpInput"
import { useMediaQuery } from "../../../hooks/useMediaQuery"
import { onResendOtp, onSubmitOtpNumber } from "../../../screens/auth/OTPPage/redux/otpAction"
import Button from "../../Button/Button"
import dayjs from "dayjs"
import { handleEquifaxFieldChange } from "../../../screens/auth/EquifaxPage/redux/EquifaxActions"
import { errorNotification } from "../../NotifyToaster"
import { useEffect, useState } from "react"
import { onlyLoginUser } from "../../../screens/auth/redux/AuthActions"
import { resetPasswordApiService } from "../../../screens/auth/ResetPassword/services/ResetPasswordApiService"
import { SHA256 } from "crypto-js"

const VerificationCode = ({
  isOpen,
  onClose,
  countryName,
  mobileNumber,
  emailAddress,
  isRedirectedFromQuote,
  isQuoteSubmit,
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 480px)")
  const [counter, setCounter] = useState(localStorage.getItem("otpCount") || 300)
  const dispatch = useDispatch()
  const otpLength = 6

  const { isRegistered, isMobileVerified, isMobVerificationModal } = useSelector(
    ({ equifaxReducer }) => equifaxReducer ?? {},
  )

  useEffect(() => {
    if (isRegistered) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counter, isRegistered])
  const onSubmitOtp = async otpData => {
    const countryCode = countryName?.value?.code || +61
    if (otpData && otpData.length === otpLength) {
      const data = {
        otp: Number(otpData.join("")),
        mobileNumber,
        countryCode,
        emailAddress,
      }
      const loginDetails = data
      const isOtpVerified = await dispatch(onSubmitOtpNumber(data, isRedirectedFromQuote, isQuoteSubmit, history, true))
      const isOtpVerifiedString = isOtpVerified.split("_test_")

      if (isOtpVerifiedString[0] === "1") {
        dispatch(handleEquifaxFieldChange("authTokenForEquifax", isOtpVerifiedString[1]))
        const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const passwordLength = 8
        let password = ""
        for (let i = 0; i <= passwordLength; i++) {
          const randomNumber = Math.floor(Math.random() * chars.length)
          password += chars.substring(randomNumber, randomNumber + 1)
        }

        const data = {
          password: SHA256(password).toString(),
          token: isOtpVerifiedString[1],
        }
        await resetPasswordApiService.onSubmitResetPassword(data)

        delete loginDetails.otp
        loginDetails.password = password?.toString().trim()
        await dispatch(onlyLoginUser(loginDetails))

        dispatch(handleEquifaxFieldChange("isMobileVerified", true))
        dispatch(handleEquifaxFieldChange("isMobVerificationModal", !isMobVerificationModal))
        localStorage.removeItem("otpCount")
        setCounter(0)
      }
    } else {
      errorNotification("Please enter valid OTP")
    }
  }

  const onResetOtp = async () => {
    const countryCode = countryName?.value?.code || +61
    const data = {
      mobileNumber,
      countryCode,
      emailAddress,
    }
    const isOtpResend = await dispatch(onResendOtp(data))
    if (isOtpResend) {
      setCounter(180)
    }
  }

  return (
    <>
      {isOpen && (
        <Modal
          header="Verification Code"
          bodyClassName="change-password-modal"
          isOutSideClick={false}
          closeIcon="close"
          onClick={onClose}
        >
          <div className="change-password-grid otpinput">
            <div>
              <OtpInput
                otpInputs={otpLength}
                otpBoxHeight={isMobileScreen ? 40 : 40}
                otpBoxWidth={isMobileScreen ? 40 : 40}
                onOtpFill={onSubmitOtp}
                otpBoxWithValueClass="border-primary"
                onFocusOtpBoxClass="border-secondary"
              />
            </div>
            <div>
              <Button
                buttonType="secondary"
                title="Resend"
                className={`smallButton mar4left ${counter > 0 ? "otp-resend-button-disabled centerbtn" : "centerbtn"}`}
                onClick={onResetOtp}
                isDisabled={counter > 0 ? true : false || isMobileVerified}
              />
              {counter > 0 && (
                <div className="otp-reset-text below5px">
                  Resend code in <span>{dayjs.duration(counter, "seconds").format("mm:ss")}</span>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

VerificationCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  countryName: PropTypes.object,
  mobileNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  isRedirectedFromQuote: PropTypes.bool,
  isQuoteSubmit: PropTypes.bool,
  isMobileVerified: PropTypes.bool,
}

export default VerificationCode
