import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import { clearAuthTokenFromLocalStorage } from "../../../helpers/LocalStorageHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { AUTH_REDUX_CONSTANTS } from "../../../screens/auth/redux/AuthReduxConstants"
import { successNotification } from "../../NotifyToaster"
import HeaderApiService from "../apiService/HeaderApiService"
import { HEADER_REDUX_CONSTANTS } from "./headerConstants"
import { startLoader, stopLoader } from "../../loader/redux/LoaderActions"
import { handleEquifaxFieldChange } from "../../../screens/auth/EquifaxPage/redux/EquifaxActions"
import { EQUIFAX_CONSTANTS } from "../../../screens/auth/EquifaxPage/redux/EquifaxReduxConstants"

export const logoutUser = history => {
  return async dispatch => {
    try {
      const response = await HeaderApiService.logoutUser()

      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message || "Logged out successfully.")
        clearAuthTokenFromLocalStorage()
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: false,
        })
        dispatch({
          type: EQUIFAX_CONSTANTS.RESET_EQUIFAX_REDUCER,
        })

        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}
export const handleProfileFieldChange = (fieldName, fieldValue) => ({
  type: HEADER_REDUX_CONSTANTS.PROFILE_FIELD_CHANGE,
  fieldName,
  fieldValue,
})
export const ProfileSaveData = data => {
  return async dispatch => {
    try {
      startLoader("isProfileDataSave")
      const response = await HeaderApiService.profileSaveData(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isProfileDataSave")
    }
  }
}
export const getUserProfile = () => {
  return async dispatch => {
    try {
      const response = await HeaderApiService.getUserProfile()
      if (response?.data?.status === "SUCCESS") {
        dispatch({
          type: HEADER_REDUX_CONSTANTS.USER_PROFILE,
          data: response.data.data,
        })
        dispatch(handleEquifaxFieldChange("firstName", response.data.data.firstName))
        dispatch(handleEquifaxFieldChange("lastName", response.data.data.lastName))
        dispatch(handleEquifaxFieldChange("emailAddress", response.data.data.email))
        dispatch(handleEquifaxFieldChange("countryCode", response.data.data.countryCode))
        dispatch(handleEquifaxFieldChange("mobileNumber", response.data.data.mobileNumber))
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}

export const changePassword = data => {
  return async () => {
    startLoader("isChangePasswordLoader")
    try {
      const response = await HeaderApiService.changePassword(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
      }
    } catch (e) {
      displayErrors(e)
      throw e
    } finally {
      stopLoader("isChangePasswordLoader")
    }
  }
}
