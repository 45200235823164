import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { AccordionContext } from "./Accordion"

const AccordionItem = props => {
  const {
    index,
    className,
    headerClass,
    prefixInActive,
    prefixActive,
    prefixClass,
    suffixActive,
    suffixInActive,
    setIndex,
    isExpanded,
    suffixClass,
    suffixClick,
    header,
    count,
    accordionBodyClass,
    children,
    openCallBack,
  } = props
  const accordionClass = `accordion-item ${className}`
  const headerClassName = `accordion-item-header-container ${headerClass}`
  const prefixClassName = `material-icons-round ${prefixClass}`
  const suffixClassName = `material-icons-round ${suffixClass}`
  const accordionBodyClassName = `accordion-body-container ${accordionBodyClass}`

  const content = useRef(null)
  const { openIndex, setOpenIndex } = React.useContext(AccordionContext)
  const activeAccordion = React.useMemo(() => openIndex === index, [openIndex, index])
  const onClickAccordionItem = React.useCallback(() => {
    setOpenIndex(!activeAccordion ? index : -1)
    openCallBack(!activeAccordion ? index : -1)
  }, [activeAccordion, setOpenIndex])
  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    setOpenIndex(setIndex ? setIndex - 1 : isExpanded ? 0 : -1)
  }, [setIndex])

  return (
    <div className={accordionClass}>
      <div
        className={`${headerClassName} ${activeAccordion && "active-accordion-header"}`}
        onClick={onClickAccordionItem}
      >
        <div className="d-flex align-center">
          {prefixInActive && prefixActive && (
            <span className={`${prefixClassName} ${activeAccordion && prefixInActive === "add" && "rotate-icon"}`}>
              {activeAccordion ? prefixActive : prefixInActive}
            </span>
          )}
          {header && header}
          {count && <span className="accordion-item-count">{count}</span>}
        </div>
        {suffixInActive && suffixActive && (
          <span
            className={`${suffixClassName} ${activeAccordion && suffixInActive === "add" && "rotate-icon"}`}
            onClick={suffixClick}
          >
            {activeAccordion ? suffixActive : suffixInActive}
          </span>
        )}
      </div>
      <div ref={content} className={`${accordionBodyClassName} ${activeAccordion && "active-accordion"}`}>
        {children !== null ? children : <div className="no-record-found">No record found</div>}
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.number,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  header: PropTypes.element,
  prefixInActive: PropTypes.string,
  prefixActive: PropTypes.string,
  suffixInActive: PropTypes.string,
  suffixActive: PropTypes.string,
  isExpanded: PropTypes.bool,
  count: PropTypes.number,
  prefixClass: PropTypes.string,
  suffixClass: PropTypes.string,
  suffixClick: PropTypes.func,
  accordionBodyClass: PropTypes.string,
  children: PropTypes.element,
  openCallBack: PropTypes.func,
}

AccordionItem.defaultProps = {
  className: "",
  setIndex: undefined,
  headerClass: "",
  prefixInActive: "",
  prefixActive: "",
  suffixInActive: "",
  suffixActive: "",
  count: "",
  prefixClass: "",
  suffixClass: "",
  isExpanded: false,
  accordionBodyClass: "",
  children: null,
  suffixClick: () => {},
  openCallBack: () => {},
}

export default AccordionItem
