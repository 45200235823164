export const BASE_URL = process.env.REACT_APP_BASE_URL

export const AUTH_URLS = {
  LOGIN_URL: `${BASE_URL}auth/login/`,
  DOC_LOGIN_URL: `${BASE_URL}auth/requestedDocLogin`,
  DOC_WITH_LOGIN_URL: `${BASE_URL}auth/validateDoc`,
  REGISTRATION_URL: `${BASE_URL}auth/register/`,
  FORGOT_PASSWORD: `${BASE_URL}auth/forget-password`,
  OTP_URL: `${BASE_URL}auth/verifyOTP`,
  MOBILE_OTP_VERIFY: `${BASE_URL}auth/mobileOTP`,
  RESEND_OTP_URL: `${BASE_URL}auth/resendOTP`,
  RESET_PASSWORD: `${BASE_URL}auth/set-password`,
  EMAIL_OTP_VERIFY: `${BASE_URL}auth/sendEmailOTP`,
  GET_OTP: `${BASE_URL}auth/sendOTP`,
}

export const HEADER_URLS = {
  LOGOUT_URL: ` ${BASE_URL}auth/logout/`,
  CHANGE_PASSWORD: ` ${BASE_URL}auth/change-password`,
  GET_USER_PROFILE: `${BASE_URL}user/profile`,
  UPDATE_USER_PROFILE: `${BASE_URL}user/edit`,
}

export const DASHBOARD_URLS = {
  DASHBOARD_QUOTE_LIST: `${BASE_URL}dashboard/quote`,
  DASHBOARD_APPLICATION_LIST: `${BASE_URL}dashboard/application`,
  DASHBOARD_QUOTE_COUNT: `${BASE_URL}dashboard/count`,
}

export const QUOTE_URLS = {
  GET_EMAIL_VERI_STATUS: `${BASE_URL}auth/emailAuthApp`,
  UPDATE_EMAIL_VERI_STATUS: `${BASE_URL}auth/emailSave`,
  GET_QUOTE_LIST: `${BASE_URL}quote/list`,
  GET_QUOTE_JSON: `${BASE_URL}quote/flow`,
  GET_NEXT_QUOTE_FORM: `${BASE_URL}quote/form-path-details`,
  SUBMIT_SAVE_AS_DRAFT: `${BASE_URL}quote/store`,
  GET_QUOTE_DATA: `${BASE_URL}quote`,
  VALIDATE_QUOTE_DATA: `${BASE_URL}quote/validate`,
  GET_QUOTE_FILLED_DATA: `${BASE_URL}quote/viewQuote`,
  ACTIVE_SAVE_QUOTE: `${BASE_URL}quote/activeSave`,
  SHOW_EQUIFAX_SCREEN: `${BASE_URL}equifax/credit-score`,
  SEARCH_DATA_BY_ABN_OR_ACN: `${BASE_URL}`,
  CHECK_QUOTE_LIMIT: `${BASE_URL}quote/checkQuote`,
}

export const APPLICATION_URLS = {
  GET_APPLICATION_JSON: `${BASE_URL}application/flow`,
  GET_APPLICATION_LIST: `${BASE_URL}application/list`,
  GET_INITIAL_APPLICATION: `${BASE_URL}application/startData/`,
  FORM_PATH_DETAILS: `${BASE_URL}application/form-path-details`,
  UPLOAD_FILE: `${BASE_URL}application/uploadFile/`,
  REMOVE_FILE: `${BASE_URL}application/removeFiles`,
  GET_APPLICATION_FILLED_DATA: `${BASE_URL}application/viewApplication/`,
  ACTIVE_SAVE: `${BASE_URL}application/activeSave`,
  DOCUMENT_GET: `${BASE_URL}zoho/applicationDocuments/`,
  DOCUMENT_SEND: `${BASE_URL}zoho/sendApplicationDocuments/`,
  PDF_SEND: `${BASE_URL}zoho/upload/`,
  GET_ALL_DATA: `${BASE_URL}application/`,
  QUICK_ACCESS_MENU_LIST: `${BASE_URL}application/quickAccessMenu`,
  GET_STORE_RATE_LENDER_ID: `${BASE_URL}quote/storeRate`,
}

export const EQUIFAX_URLS = {
  EQUIFAX_GET_RECORD: `${BASE_URL}equifax/report`,
  SUBMIT_EQUIFAX: `${BASE_URL}equifax/equifax-report`,
  SUBMIT_PDF: `${BASE_URL}equifax/pdf`,
}

export const USER_MODULE_URLS = {
  GET_USER_MODULE_LIST: `${BASE_URL}user/list`,
}

export const RATE_CARD_URLS = {
  // GET_RATE_CARD: `${BASE_URL}rateCard/dummyRateCard/`,
  // GET_RATE_CARD_APPLICATION: `${BASE_URL}rateCard/dummyRateCard/`,
  GET_RATE_CARD: `${BASE_URL}rateCard/quote/`,
  GET_RATE_CARD_APPLICATION: `${BASE_URL}rateCard/rateRecalculated/`,
}

export const ZOHO_URLS = {
  ZOHO_PASS_DATA: `${BASE_URL}zoho`,
  ZOHO_UPLOAD: `${BASE_URL}zoho/upload`,
}

export const ASSET_URLS = {
  SET_ASSET_DATA: `${BASE_URL}zoho/addAsset`,
}

export const BROKER_URLS = {
  GET_BROKER_JSON: `${BASE_URL}broker/flow`,
  GET_QUOTE_FORMPATH: `${BASE_URL}quote/form-path-details`,
  GET_APPLICATION_FORMPATH: `${BASE_URL}application/`,
  CREATE_USER: `${BASE_URL}broker/createUser`,
  SAVE_DATA: `${BASE_URL}broker/save`,
  GET_DATA: id => `${BASE_URL}broker/loadData/${id}`,
  GET_EMAIL_VERI_STATUS: `${BASE_URL}broker/email-verified`,
  OTP_VERIFY_STATUS: `${BASE_URL}broker/verifyOTP`,
  GET_QUOTE_DATA: id => `${BASE_URL}broker/viewQuote/${id}`,
  GET_RATE_DATA: id => `${BASE_URL}broker/rateCard/${id}`,
  getBrokerPathData: id => `${BASE_URL}broker/startData/${id}`,
  GET_APPLICATION_DATA: id => `${BASE_URL}broker/viewApplication/${id}`,
  // SEND_APPLICATION_ZOHO_DATA: `${BASE_URL}broker/zoho`,
  SEND_APPLICATION_ZOHO_DATA: `${BASE_URL}broker/zohoQuoteDetails`,
  VANISH_QUOTE_FORM: `${BASE_URL}broker/quoteVanishNextForms`,
  VANISH_APPLICATION_FORM: `${BASE_URL}broker/applicationVanishNextForms`,
}

export const BROKER_URLS_NEW = {
  GET_BROKER_JSON: `${BASE_URL}broker/new-broker-flow`,
  GET_QUOTE_FORMPATH: `${BASE_URL}broker/form-path-details`,
  GET_APPLICATION_FORMPATH: `${BASE_URL}application/form-path-details`,
  CREATE_USER: `${BASE_URL}broker/create-user-broker`,
  SAVE_DATA: `${BASE_URL}broker/save`,
  QUICK_SAVE: `${BASE_URL}broker/quick-save`,
  SAVE_QUOTE_DATA: `${BASE_URL}broker/quote-now-save`,
  GET_DATA: id => `${BASE_URL}broker/loadData/${id}`,
  GET_EMAIL_VERI_STATUS: `${BASE_URL}broker/email-verified`,
  OTP_VERIFY_STATUS: `${BASE_URL}broker/verifyOTP`,
  GET_QUOTE_DATA: id => `${BASE_URL}broker/viewQuote/${id}`,
  GET_RATE_DATA: id => `${BASE_URL}broker/rateCard/${id}`,
  getBrokerPathData: id => `${BASE_URL}broker/startData/${id}`,
  GET_APPLICATION_DATA: id => `${BASE_URL}broker/viewApplication/${id}`,
  // SEND_APPLICATION_ZOHO_DATA: `${BASE_URL}broker/zoho`,
  SEND_APPLICATION_ZOHO_DATA: `${BASE_URL}broker/zohoQuoteDetails`,
  VANISH_QUOTE_FORM: `${BASE_URL}broker/quoteVanishNextForms`,
  VANISH_APPLICATION_FORM: `${BASE_URL}broker/applicationVanishNextForms`,
}
