export const checkFormCount = quoteAnswersData => {
  const formList = ["loanType", "carSearch", "vehicleSelect", "assetInfo", "financeNumbers", "employment", "residency"]
  let numOfForms = formList.length
  let size = Object.keys(quoteAnswersData).length
  size = size - 1
  if (quoteAnswersData?.loanType) {
    const loanTypeData = quoteAnswersData.loanType
    const financeTypes = [
      "Personal Loan",
      "Business Loan",
      "Unsecured Business Loan",
      "Debtor Finance",
      "Debt Consolidation",
      "Insurance",
      "Warranty",
      "Other",
    ]
    if (financeTypes.includes(loanTypeData?.financeWhat)) {
      numOfForms = 3
    }

    if (loanTypeData?.financeWhat === "Asset Finance" && loanTypeData?.assetType) {
      if (loanTypeData?.assetType === "Fit Out" || loanTypeData?.assetType === "Other") {
        numOfForms = 4
      }

      if (
        loanTypeData?.assetType === "Big Truck" ||
        loanTypeData?.assetType === "Caravan" ||
        loanTypeData?.assetType === "Motorbike, Quad etc" ||
        loanTypeData?.assetType === "Boat, Jetski etc"
      ) {
        numOfForms = 5
      }
      if (
        loanTypeData?.assetType === "Farm Equipment" ||
        loanTypeData?.assetType === "Earth Moving Equipment" ||
        loanTypeData?.assetType === "Industrial Equipment" ||
        loanTypeData?.assetType === "IT, Cameras etc" ||
        loanTypeData?.assetType === "Cafe/White Goods"
      ) {
        numOfForms = 4
      }
    }
    if (loanTypeData?.assetCondition) {
      if (loanTypeData?.assetType === "Car, Van, Ute etc") {
        if (loanTypeData?.assetCondition === "Demo" || loanTypeData?.assetCondition === "Used") {
          numOfForms = 6
        }
        if (loanTypeData?.assetCondition === "New") {
          numOfForms = 5
        }
        if (loanTypeData?.assetCondition === "Undecided") {
          numOfForms = 4
        }
      }
      if (
        loanTypeData?.assetType === "Big Truck" ||
        loanTypeData?.assetType === "Caravan, Campervan" ||
        loanTypeData?.assetType === "Motorbike, Quad etc" ||
        loanTypeData?.assetType === "Boat, Jetski etc"
      ) {
        numOfForms = 5
      }
      if (
        !loanTypeData?.assetType === "Car, Van, Ute etc" &&
        !loanTypeData?.assetType === "Big Truck" &&
        !loanTypeData?.assetType === "Caravan, Campervan" &&
        !loanTypeData?.assetType === "Motorbike, Quad etc" &&
        !loanTypeData?.assetType === "Boat, Jetski etc"
      ) {
        numOfForms = 4
      }
    }
  }
  if (quoteAnswersData?.carSearch) {
    numOfForms = 3
    if (quoteAnswersData?.carSearch?.enterManually) {
      numOfForms = 5
    }
  }
  if (quoteAnswersData?.vehicleSelect) {
    numOfForms = 3
    if (
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForAssetYear ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForAssetMake ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForAssetModel ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForAssetBadge ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForAssetSeries ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForModel ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForVehicleNote ||
      quoteAnswersData?.vehicleSelect?.canNotFindWhatYouAreLookingForManufacturerOptions
    ) {
      numOfForms = 4
    }
  }
  if (quoteAnswersData?.assetInfo) {
    numOfForms = 3
  }
  if (quoteAnswersData?.financeNumbers) {
    numOfForms = 2
  }
  if (quoteAnswersData?.employment) {
    numOfForms = 1
  }
  if (quoteAnswersData?.residency?.filter) {
    numOfForms = 0
  }
  // if (
  //   quoteAnswersData?.residency?.residencyDetails &&
  //   quoteAnswersData?.residency?.residencyDetails === "Visa" &&
  //   quoteAnswersData?.residency?.visaCode !== undefined
  // ) {
  //   numOfForms = 0
  // }

  const totalForm = Number(size) + Number(numOfForms)
  let getPercentage = Math.round((size * 100) / totalForm)
  if (getPercentage === 0) {
    getPercentage = 5
  }
  return getPercentage
}
