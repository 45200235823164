import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardCounts } from "./Redux/dashboardActions"
import QuoteTable from "./QuoteTable/QuoteTable"
import ApplicationTable from "./ApplicationTable/ApplicationTable"
import { NavLink } from "react-router-dom"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"

const Dashboard = () => {
  const dispatch = useDispatch()

  const dashboardCounts = useSelector(({ dashboardReducer }) => dashboardReducer?.dashboardCounts ?? {})

  useEffect(async () => {
    dispatch(getDashboardCounts())
  }, [])

  return (
    <>
      <div className="content-header">Dashboard</div>
      <div className="dashboard-grey-card-container ">
        <div className="dashboard-grey-card">
          <NavLink
            className="dashboard-card__total-icon"
            key={ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST}
            to={ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST}
            replace
          >
            <div className="dashboard-card__total">
              <div className="dashboard-card__total-text">Total</div>
            </div>
            <div className="dashboard-card__title">Quote</div>
            <div className="dashboard-card_count-icon">
              {dashboardCounts?.quotes}
              <span className="material-icons-outlined">request_quote</span>
            </div>
          </NavLink>
        </div>
        <div className="dashboard-grey-card">
          <NavLink
            className="dashboard-card__total-icon"
            key={ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}
            to={ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}
            replace
          >
            <div className="dashboard-card__total">
              <div className="dashboard-card__total-text">Total</div>
            </div>
            <div className="dashboard-card__title">Application</div>
            <div className="dashboard-card_count-icon">
              {dashboardCounts?.application}
              <span className="material-icons-outlined">description</span>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="content-header">Quote</div>
      <div className="dashboard-table">
        <QuoteTable />
      </div>

      <div className="content-header">Application</div>

      <div className="dashboard-table">
        <ApplicationTable />
      </div>
    </>
  )
}

export default Dashboard
