import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../../../common/Pagination/Pagination"
import Table from "../../../common/Table/Table"
import { useUrlParamsUpdate } from "../../../hooks/useUrlParamsUpdate"
import { getApplicationList } from "../redux/ApplicaitonActions"
import Loader from "../../../common/loader/Loader"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import { Input } from "../../../common"
import { DATE_FORMAT } from "../../../constants/regexConstants"

const ApplicationList = () => {
  const dispatch = useDispatch()

  const { isDashboardTableLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const { docs, headers, total, page, limit, pages, mobileHeaders } = useSelector(
    ({ applicationReducer }) => applicationReducer.applicationListPage.applicationList,
  )
  const [startDate, setStartDate] = useState()
  const [startDateDMY, setStartDateDMY] = useState()
  const [endDate, setEndDate] = useState()
  const [endDateDMY, setEndDateDMY] = useState()
  const onSelectLimit = newLimit => {
    dispatch(getApplicationList({ page: 1, limit: newLimit, start: startDateDMY, end: endDateDMY }))
  }

  const pageActionClick = newPage => {
    dispatch(getApplicationList({ page: newPage, limit, start: startDateDMY, end: endDateDMY }))
  }
  useEffect(() => {
    dispatch(getApplicationList({ page: 1, limit: 10, start: startDateDMY, end: endDateDMY }))
  }, [])

  useUrlParamsUpdate(
    {
      page: page ?? 1,
      limit: limit ?? 10,
      startDate: startDate && endDate ? startDate : undefined,
      endDate: startDate && endDate ? endDate : undefined,
    },
    [page, limit, startDate, endDate],
  )
  const d = new Date()
  const year = d.getFullYear()
  const pastyear = d.getFullYear() - 20
  const handleApply = (event, picker) => {
    setStartDate(picker.startDate.format(DATE_FORMAT.DDMMYYYY))
    setStartDateDMY(picker.startDate.format(DATE_FORMAT.YMD))
    setEndDateDMY(picker.endDate.format(DATE_FORMAT.YMD))
    setEndDate(picker.endDate.format(DATE_FORMAT.DDMMYYYY))
    dispatch(
      getApplicationList({
        page,
        limit,
        start: picker.startDate.format(DATE_FORMAT.YMD),
        end: picker.endDate.format(DATE_FORMAT.YMD),
      }),
    )
  }

  const handleCancel = (event, picker) => {
    picker.element.val("")
    setStartDate("")
    setStartDateDMY("")
    setEndDateDMY("")
    setEndDate("")
    picker.element.removeClass("input-has-value")
    dispatch(getApplicationList({ page, limit }))
    picker.setStartDate(new Date())
    picker.setEndDate(new Date())
  }
  return (
    <>
      <div className="page-header-row">
        <div className="content-header">Application List</div>
        <div className="button-row">
          <div className={`date-picker-container`}>
            <DateRangePicker
              initialSettings={{
                opens: "left",
                autoUpdateInput: false,
                locale: {
                  format: DATE_FORMAT.DDMMYYYY,
                  cancelLabel: "Clear",
                },
                showDropdowns: false,
                autoApply: false,
                maxYear: year,
                minYear: pastyear,
                maxDate: d,
                cancelButtonClasses: "button outlined-primary-button",
                applyButtonClasses: "button outlined-primary-button",
              }}
              onApply={handleApply}
              onCancel={handleCancel}
            >
              <Input placeholder="Search Dates" value={startDate ? startDate + "-" + endDate : ""} />
            </DateRangePicker>
          </div>
        </div>
      </div>

      <div className="quote-table">
        {!isDashboardTableLoader ? (
          <>
            <Table tableType="application" data={docs} headers={headers} mobileHeaders={mobileHeaders} />
            <Pagination
              total={total}
              page={page}
              pages={pages}
              limit={limit}
              pageActionClick={pageActionClick}
              onSelectLimit={onSelectLimit}
            />
          </>
        ) : (
          <Loader isLoading={isDashboardTableLoader} />
        )}
      </div>
    </>
  )
}

export default ApplicationList
