import { useMediaQuery } from "../../../hooks/useMediaQuery"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useState } from "react"
import Modal from "../../../common/Modal/Modal"
import OtpInput from "../../../common/OtpInput/OtpInput"
import { onResendOtp } from "../../auth/OTPPage/redux/otpAction"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { errorNotification } from "../../../common/NotifyToaster"
import { saveBrokerData, verifyOTPAction } from "../redux/BrokerAction"
import { updateBrokerFields } from "../redux/brokerSlice"
import { useQuery } from "../../../hooks/useQuery"
import { DEAL_ID_QUERY_PARAM } from "../../application/ApplicationPage/ApplicationPageConstants"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { useHistory } from "react-router-dom"

dayjs.extend(duration)

const BrokerOTPVerification = () => {
  const isMobileScreen = useMediaQuery("(max-width: 480px)")
  const [counter, setCounter] = useState(localStorage.getItem("otpCount") || 10)
  const dispatch = useDispatch()
  const history = useHistory()
  const otpLength = 6
  const query = useQuery()
  const { verificationData: data } = useSelector(state => state?.brokerReducer)

  // eslint-disable-next-line no-unused-vars
  const { email, id, isContactNumberVerified, isEmailVerified, mobile: mobileNumber, verifyOTP } = useMemo(
    () => data ?? {},
    [data],
  )

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const onSubmitOtp = async otpData => {
    const countryCode = "+61"
    if (otpData && otpData.length === otpLength) {
      const dataToSend = {
        otp: Number(otpData.join("")),
        mobileNumber,
        countryCode,
      }
      await dispatch(
        verifyOTPAction(dataToSend, () => {
          if (!isEmailVerified) {
            dispatch(
              updateBrokerFields({
                fieldName: "verificationData",
                value: { ...data, verifyOTP: false, verifyEmail: true },
              }),
            )
          } else {
            dispatch(
              updateBrokerFields({
                fieldName: "verificationData",
                value: { id },
              }),
            )
            dispatch(
              saveBrokerData(data.id, query.get(DEAL_ID_QUERY_PARAM), response => {
                history.push(`${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM}/${response[0].applicationId}`)
              }),
            )
          }
        }),
      )
    } else {
      errorNotification("Please enter valid OTP")
    }
  }

  const onResetOtp = async () => {
    const countryCode = "+61"
    const data = {
      mobileNumber,
      countryCode,
      email,
    }
    const isOtpResend = await dispatch(onResendOtp(data))
    if (isOtpResend) {
      setCounter(180)
    }
  }

  const buttons = [
    {
      title: "Resend",
      buttonType: "secondary",
      onClick: onResetOtp,
      className: `smallButton mar4left ${counter > 0 ? "otp-resend-button-disabled centerbtn" : "centerbtn"}`,
      isDisabled: counter > 0,
    },
  ]

  const handleClose = () => {
    dispatch(
      updateBrokerFields({
        fieldName: "verificationData",
        value: null,
      }),
    )
  }

  return (
    <>
      {verifyOTP && (
        <Modal
          header="Verification Code"
          bodyClassName="change-password-modal"
          isOutSideClick={false}
          closeIcon="close"
          onClick={handleClose}
          buttons={buttons}
        >
          <div className="change-password-grid otpinput">
            <div>
              <OtpInput
                otpInputs={otpLength}
                otpBoxHeight={isMobileScreen ? 40 : 40}
                otpBoxWidth={isMobileScreen ? 40 : 40}
                onOtpFill={onSubmitOtp}
                otpBoxWithValueClass="border-primary"
                onFocusOtpBoxClass="border-secondary"
              />
            </div>
            <div>
              {counter > 0 && (
                <div className="otp-reset-text below5px">
                  Resend code in <span>{dayjs.duration(counter, "seconds")?.format("mm:ss")}</span>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default BrokerOTPVerification
