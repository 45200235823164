import { displayErrors } from "../../../../helpers/ErrorNotifyHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../../routes/RouteConstants"
import { otpApiService } from "../services/otpApiService"
import { startLoader, stopLoader } from "../../../../common/loader/redux/LoaderActions"
import { successNotification } from "../../../../common/NotifyToaster"

export const onSubmitOtpNumber = (data, isRedirectedFromQuote, isQuoteSubmit, history, fromEquifaxPage) => {
  return async () => {
    try {
      const passData = data
      startLoader("isOtpLoading")
      const response = await otpApiService.onSubmitOtp(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message || "OTP verified successfully")
        if (!fromEquifaxPage) {
          history.push(ROUTE_CONSTANTS_VARIABLE.RESET_PASSWORD, {
            token: response.data.token,
            userId: history?.location?.state?.userId,
            isRedirectedFromQuote,
            isQuoteSubmit,
            passData,
          })
        } else {
          return 1 + "_test_" + response.data.token
        }
        localStorage.removeItem("otpCount")
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isOtpLoading")
    }
  }
}

export const onResendOtp = data => {
  return async () => {
    try {
      startLoader("isOtpLoading")
      const response = await otpApiService.onResendOtp(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message || "OTP sent successfully")
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isOtpLoading")
    }
  }
}
