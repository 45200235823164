import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import logo from "../../assets/images/logo.svg"
import Button from "../../common/Button/Button"
import Loader from "../../common/loader/Loader"
import { useUpdateFields } from "../application/hooks/useUpdateFields"
import { RenderField } from "../application/components/RenderField"
import {
  handleRegoSearch,
  setAssetData,
  showFieldOnAssetAnswer,
  updateAssetAnswersREGO,
  updateAssetFlags,
  updateAssetGlassGuideLabelData,
} from "./redux/AssetAction"
import _ from "lodash"

const AssetFormScreen = () => {
  const {
    brokerGlassGuide,
    regoGlassGuide,
    FieldsUpdates,
    regoDataAsset,
    glassGuideLabel,
    lvrData,
    assetzohoID,
    isRegoSearch,
    isDisabledSubmit,
  } = useSelector(({ assetReducer }) => assetReducer ?? {})
  const dispatch = useDispatch()
  const { keysOfFieldsToKeep, keysOfFieldsToRemove } = useUpdateFields()
  const { isQuoteCommonLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const [isDisabled, setIsDisabled] = useState(true)
  const [error, setError] = useState({})
  useEffect(() => {
    const fieldtoShow = keysOfFieldsToKeep(brokerGlassGuide.fields)
    const FieldList = fieldtoShow
      .map(key => {
        const field = brokerGlassGuide.fields.find(x => x?.key === key)
        if (field) return field
        return undefined
      })
      .filter(x => x)
    dispatch(showFieldOnAssetAnswer(FieldList))
  }, [brokerGlassGuide])

  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const getParamValue = paramName => {
    const url = window.location.search.substring(1)
    const qArray = url.split("&")
    for (let i = 0; i < qArray.length; i++) {
      const pArr = qArray[i].split("=")
      if (pArr[0] === paramName) return pArr[1]
    }
  }
  const submitApplication = async () => {
    if (isRegoSearch) {
      //
      const dealId = getParamValue("deal_id")

      const assetsData = {}
      assetsData.name = `${regoDataAsset?.yearCreate} ${regoDataAsset?.ManufacturerName} ${regoDataAsset?.FamilyName}`
      assetsData.assetDescription = `${regoDataAsset?.yearCreate} ${regoDataAsset?.modelName}`
      assetsData.assetMake = regoDataAsset?.ManufacturerName || ""
      assetsData.assetModel = regoDataAsset?.FamilyName || ""
      assetsData.assetType = "Car, Van, Ute etc" || ""
      assetsData.NVIC = regoDataAsset?.nvic || ""
      assetsData.assetAge = regoDataAsset?.yearCreate || ""
      assetsData.assetCondition = "Demo" || ""
      assetsData.assetGenericOptions = ""
      assetsData.assetManufacturerOptions = ""
      assetsData.assetBadge = regoDataAsset?.VariantName || ""
      assetsData.assetSeries = regoDataAsset?.SeriesName || ""

      let assetModelRRP = ""
      let assetRetailValue = ""
      let tradeValue = ""
      let tradeLowValue = ""
      let adjustmentAmount = ""
      if (lvrData) {
        const lvrDataObj = JSON.parse(lvrData)
        assetModelRRP = lvrDataObj?.RRP
        assetRetailValue = lvrDataObj?.Retail
        tradeValue = lvrDataObj?.Trade
        tradeLowValue = lvrDataObj?.TradeLow
        adjustmentAmount = lvrDataObj?.AdjustmentAmount
      }

      assetsData.RRP = parseFloat(assetModelRRP)
      assetsData.Retail = parseFloat(assetRetailValue)
      assetsData.Trade = parseFloat(tradeValue)
      assetsData.TradeLow = parseFloat(tradeLowValue)
      assetsData.Adjustment_Amount = parseFloat(adjustmentAmount)

      assetsData.Kilometres = 0.0 || ""
      assetsData.deal_id = dealId || ""

      try {
        await dispatch(setAssetData({ assets: assetsData }))
        setIsDisabled(true)
      } catch (e) {
        /**/
      }
    } else {
      const errors = {}
      const dealId = getParamValue("deal_id")
      const fieldData = FieldsUpdates.find(x => !x.answer && x.required)
      if (fieldData === undefined) {
        let assetModelRRP = ""
        let assetRetailValue = ""
        let tradeValue = ""
        let tradeLowValue = ""
        let adjustmentAmount = ""

        setError({})
        const assetsData = {}
        assetsData.assetAge =
          FieldsUpdates.find(x => x.fieldName === "assetAge")?.answer ||
          FieldsUpdates.find(x => x.fieldName === "nonGlassGuideAssetAge")?.answer ||
          FieldsUpdates.find(x => x.fieldName === "otherAssetYear")?.answer ||
          ""
        assetsData.name = `${assetsData.assetAge} ${glassGuideLabel?.assetMake} ${glassGuideLabel?.assetModel}`
        assetsData.assetDescription = FieldsUpdates.find(x => x.fieldName === "assetDescription")?.answer
        assetsData.assetMake = glassGuideLabel?.assetMake || ""
        assetsData.assetModel = glassGuideLabel?.assetModel || ""
        assetsData.assetType = glassGuideLabel?.assetType || ""
        assetsData.NVIC = FieldsUpdates.find(x => x.fieldName === "nvic")?.answer || ""
        assetsData.assetAge =
          FieldsUpdates.find(x => x.fieldName === "assetAge")?.answer ||
          FieldsUpdates.find(x => x.fieldName === "nonGlassGuideAssetAge")?.answer ||
          FieldsUpdates.find(x => x.fieldName === "otherAssetYear")?.answer ||
          ""
        assetsData.assetCondition = FieldsUpdates.find(x => x.fieldName === "assetCondition")?.answer || ""
        assetsData.assetGenericOptions = FieldsUpdates.find(x => x.fieldName === "assetGenericOptions")?.answer?.join(
          ",",
        )
        assetsData.assetManufacturerOptions = FieldsUpdates.find(
          x => x.fieldName === "assetManufacturerOptions",
        )?.answer?.join(",")
        assetsData.assetBadge = FieldsUpdates.find(x => x.fieldName === "assetBadge")?.answer
        assetsData.assetSeries = FieldsUpdates.find(x => x.fieldName === "assetSeries")?.answer

        if (lvrData) {
          const lvrDataObj = JSON.parse(lvrData)
          assetModelRRP = lvrDataObj?.RRP
          assetRetailValue = lvrDataObj?.Retail
          tradeValue = lvrDataObj?.Trade
          tradeLowValue = lvrDataObj?.TradeLow
          adjustmentAmount = lvrDataObj?.AdjustmentAmount
        }

        assetsData.RRP = parseFloat(assetModelRRP)
        assetsData.Retail = parseFloat(assetRetailValue)
        assetsData.Trade = parseFloat(tradeValue)
        assetsData.TradeLow = parseFloat(tradeLowValue)
        assetsData.Adjustment_Amount = parseFloat(adjustmentAmount)
        assetsData.Kilometres = FieldsUpdates.find(x => x.fieldName === "assetKilometres")?.answer || ""
        assetsData.deal_id = dealId || ""

        try {
          await dispatch(setAssetData({ assets: assetsData }))
          setIsDisabled(true)
        } catch (e) {
          /**/
        }
      } else {
        errors.requiredField = "Please enter / select detail in required field"
        setError(errors)
      }
    }
    setIsDisabled(false)
  }
  const onFieldUpdate = async (fieldName, fieldValue, fieldLabel) => {
    if (fieldLabel !== "") {
      await dispatch(updateAssetGlassGuideLabelData(fieldName, fieldLabel))
      dispatch(updateAssetFlags("isDisabledSubmit", false))
    }

    let FieldList = FieldsUpdates
    const fieldNameFinal = fieldName.split("@test@")
    let fieldValueFinal = fieldValue
    if (!_.isArray(fieldValue)) {
      fieldValueFinal = fieldValue.split("@test@")
    }
    fieldNameFinal.forEach((element, i) => {
      const FiledChanged = FieldList.find(
        x => x.fieldName === element && !["assetGenericOptions", "assetManufacturerOptions"].includes(element),
      )

      if (FiledChanged?.answer !== fieldValueFinal[i] && FiledChanged?.answer) {
        const fieldIndex = FieldList?.findIndex?.(x => x?.fieldName === element)

        FieldList?.splice?.(fieldIndex + 1)
      }

      FieldList = FieldList.map(x => {
        if (x.fieldName === element) {
          if (!["assetGenericOptions", "assetManufacturerOptions"].includes(element)) {
            return { ...x, answer: fieldValueFinal[i] }
          } else {
            return { ...x, answer: fieldValueFinal }
          }
        }
        return x
      })

      if (fieldValueFinal[i]) {
        const fieldtoShow = keysOfFieldsToKeep(FieldList)
        FieldList = fieldtoShow
          .map(key => {
            const inField = FieldList.find(x => x?.key === key)
            if (inField) {
              return inField
            } else {
              const field = brokerGlassGuide.fields.find(x => x?.key === key)
              if (field) return field
            }
            return undefined
          })
          .filter(x => x)
      } else {
        const keysToRemove = keysOfFieldsToRemove(
          FieldList,
          FieldList.find(x => x.fieldName === element),
        )
        if (keysToRemove.length > 0) {
          FieldList = FieldList.filter(x => !keysToRemove.includes(x.key))
        }
      }
    })
    await dispatch(showFieldOnAssetAnswer(FieldList))
  }
  const onFieldUpdateREGO = async (fieldName, fieldValue, fieldLabel = "", request) => {
    await dispatch(updateAssetAnswersREGO(fieldName, fieldValue))
  }
  const handleSearch = async (fieldName, value, request) => {
    if (request?.url) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        vin: params?.identifier === "getByVIN" ? value : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearch(url, method, paramData)).then(() => {
        dispatch(updateAssetFlags("isDisabledSubmit", false))
        dispatch(updateAssetFlags("isRegoSearch", true))
      })
    }
  }

  return (
    <>
      {!authStatus && (
        <div className="header-container logo-login-button ">
          <img src={logo} alt="Simply Finance" title="Simply Finance" />
        </div>
      )}
      <div className="application-tool-form">
        <div className="application-tool-form-content">
          <div className="application-tool-form-inner">
            {FieldsUpdates?.length > 0 &&
              FieldsUpdates.map(field => {
                return (
                  <Fragment key={field?.key}>
                    {
                      <RenderField
                        field={field}
                        onFieldUpdate={onFieldUpdate}
                        isFromAssetPage={true}
                        AssetFields={FieldsUpdates}
                      />
                    }
                  </Fragment>
                )
              })}
          </div>
        </div>

        <div className="application-form-heading centerAlign">OR</div>

        <div className="application-tool-form-content">
          <div className="application-form-heading centerAlign">
            To help us locate the exact car quickly for you, please enter in the rego number and state, or just the VIN
          </div>

          <div className="width-50">
            {regoGlassGuide?.fields?.length > 0 &&
              regoGlassGuide?.fields.map(field => {
                return (
                  <Fragment key={field?.key}>
                    {
                      <RenderField
                        field={field}
                        onFieldUpdate={onFieldUpdateREGO}
                        handleSearch={handleSearch}
                        isFromAssetPage={true}
                        AssetFields={regoGlassGuide?.fields}
                      />
                    }
                  </Fragment>
                )
              })}
          </div>
        </div>

        <div className="error-text">{error?.requiredField}</div>
      </div>

      <div className="footer-container">
        <div className="showbtns">
          <Button isDisabled={isDisabledSubmit} buttonType="outlined-primary" onClick={submitApplication}>
            Submit
          </Button>
          <a
            className={`a-tag-button ${isDisabled ? "disable" : ""}`}
            target="_parent"
            href={!isDisabled ? `https://crm.zoho.com/crm/simply/tab/CustomModule8/${assetzohoID}` : "#"}
          >
            See Asset
          </a>
        </div>
      </div>
      <Loader isLoading={isQuoteCommonLoader} />
    </>
  )
}
export default AssetFormScreen
