import { LOADER_REDUX_CONSTANTS } from "./LoaderReduxConstants"
import { store } from "../../../redux/store"

export const startLoader = loaderName => {
  store.dispatch({
    type: LOADER_REDUX_CONSTANTS.START_LOADER,
    loaderName,
  })
}
export const stopLoader = loaderName => {
  store.dispatch({
    type: LOADER_REDUX_CONSTANTS.STOP_LOADER,
    loaderName,
  })
}
