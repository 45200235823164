export const QUOTE_REDUX_CONSTANTS = {
  SET_QUOTE_JSON_DATA: "SET_QUOTE_JSON_DATA",
  HANDLE_QUOTE_NEXT_CLICK: "HANDLE_QUOTE_NEXT_CLICK",
  HANDLE_QUOTE_BACK_CLICK: "HANDLE_QUOTE_BACK_CLICK",
  HANDLE_QUOTE_QUESTION_ERROR: "HANDLE_QUOTE_QUESTION_ERROR",
  HANDLE_OTHER_QUESTION_ON_SAME_PAGE: "HANDLE_OTHER_QUESTION_ON_SAME_PAGE",
  UPDATE_QUOTE_ANSWER_DATA: "UPDATE_QUOTE_ANSWER_DATA",
  UPDATE_QUOTE_ANSWER_DATA_FIELDS: "UPDATE_QUOTE_ANSWER_DATA_FIELDS",
  UPDATE_GLASS_GUIDE_ANS_DATA: "UPDATE_GLASS_GUIDE_ANS_DATA",
  UPDATE_GLASS_GUIDE_LABEL_DATA: "UPDATE_GLASS_GUIDE_LABEL_DATA",
  UPDATE_ANSWERS_ON_PREV_CHANGES: "UPDATE_ANSWERS_ON_PREV_CHANGES",
  WIPE_OUT_DATA_ON_FIELD_CHANGES: "WIPE_OUT_DATA_ON_FIELD_CHANGES",
  DISABLED_QUOTE_FIELDS: "DISABLED_QUOTE_FIELDS",
  CHANGE_QUOTE_FIELDS_VALUE: "CHANGE_QUOTE_FIELDS_VALUE",
  ENABLE_QUOTE_FIELDS: "ENABLE_QUOTE_FIELDS",
  UPDATE_QUOTE_DATA_ON_NEXT_CLICK: "UPDATE_QUOTE_DATA_ON_NEXT_CLICK",
  SET_EXISTING_QUOTE_DATA: "SET_EXISTING_QUOTE_DATA",
  SET_QUOTE_ID: "SET_QUOTE_ID",
  RESET_QUOTE_REDUCER: "RESET_QUOTE_REDUCER",
  SET_VIEW_QUOTE_DATA: "SET_VIEW_QUOTE_DATA",
  SET_ABN_CALLED: "SET_ABN_CALLED",
  SET_REGO_CALLED: "SET_REGO_CALLED",
  SET_EDIT_CALLED: "SET_EDIT_CALLED",
  SET_API_RESPONSE_CALLED: "SET_API_RESPONSE_CALLED",
  UPDATE_PREVIOUS_QUESTION_DETAILS: "UPDATE_PREVIOUS_QUESTION_DETAILS",
  UPDATE_PREVIOUS_QUESTION_DETAILS_MANUAL: "UPDATE_PREVIOUS_QUESTION_DETAILS_MANUAL",
  SET_QUOTE_LAST_QUESTION: "SET_QUOTE_LAST_QUESTION",
  SKIP_QUOTE_SCREEN: "SKIP_QUOTE_SCREEN",

  UPDATE_FINANCE_NUMBER_FORM_ON_ASSET_CHANGE: "UPDATE_FINANCE_NUMBER_FORM_ON_ASSET_CHANGE",
  UPDATE_ASSET_CONDITION_CHANGE: "UPDATE_ASSET_CONDITION_CHANGE",

  ABN_ACN: {
    ON_ABN_OR_ACN_CHANGE: "ON_ABN_OR_ACN_CHANGE",
    RESET_ABN_DATA: "RESET_ABN_DATA",
  },

  GLASS_GUIDE: {
    SET_OPTIONS_FROM_API: "SET_OPTIONS_FROM_API",
    RESET_OPTIONS_FROM_API: "RESET_OPTIONS_FROM_API",
    RESET_REGO_DATA: "RESET_REGO_DATA",
    RESET_REGO_DATA_PARTIAL: "RESET_REGO_DATA_PARTIAL",
  },

  QUOTE_LIST: {
    GET_QUOTE_LIST: "GET_QUOTE_LIST",
  },
}
