import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import Input from "../../../common/Input/Input"
import AuthLayout from "../AuthLayout"
import { onResetPassword } from "./redux/ResetPasswordAction"
import { REGEX } from "../../../constants/regexConstants"
// import { saveAuthTokenToLocalStorage } from "../../../helpers/LocalStorageHelper"
import { loginUser } from "../redux/AuthActions"
import useKeyPress from "../../../hooks/useKeyPress"

const ResetPasswordScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isRedirectedFromQuote, isQuoteSubmit } = history?.location?.state ?? {}
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errors, setErrors] = useState({})
  const isEnterPressed = useKeyPress("Enter")

  const { quoteAnswersData, currentForm, previousQuestions, glassGuideData, glassGuideLabel } = useSelector(
    ({ quoteReducer }) => quoteReducer ?? {},
  )
  const { equifaxData } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})
  const { isResetPasswordLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const handleChangePasswordButtonClick = async () => {
    const error = {}
    if (!password || !password?.toString()?.trim()?.length) error.passWord = "Please enter password"

    if (password && !REGEX.PASSWORD_REGEX.test(password?.toString().trim()))
      error.passWord =
        "Please enter a password with at least 8 characters, one uppercase letter, one lowercase letter, one special character and one number"

    if (!confirmPassword || !confirmPassword?.toString()?.trim()?.length)
      error.confirmPassword = "Please enter confirm password"

    if (password && confirmPassword && password?.toString().trim() !== confirmPassword?.toString().trim())
      error.confirmPassword = "Please enter same password"
    let responseOfApi = false
    if (_.isEmpty(error)) {
      setErrors({})
      responseOfApi = await dispatch(
        onResetPassword(password?.toString().trim(), isRedirectedFromQuote, isQuoteSubmit, history, "", false),
      )
      if (responseOfApi) {
        const loginDetails = history.location.state.passData
        delete loginDetails.otp
        loginDetails.password = password?.toString().trim()

        let finalQuoteAnswers = { ...quoteAnswersData }
        if (isRedirectedFromQuote) {
          finalQuoteAnswers = {
            ...finalQuoteAnswers,
            [currentForm]: {
              ...finalQuoteAnswers?.[currentForm],
              isCompleted: !!isQuoteSubmit,
              isBlocked: false,
            },
          }
        }
        const quoteFormData = {
          quoteAnswersData: finalQuoteAnswers,
          glassGuideData: glassGuideData,
          glassGuideLabel: glassGuideLabel,
          previousQuestions,
        }
        const equifaxDataFinal = { data: equifaxData?.data }

        dispatch(
          loginUser(loginDetails, history, {
            isRedirectedFromQuote,
            isQuoteSubmit,
            quoteFormData,
            equifaxDataFinal,
          }),
        )
      }
    } else {
      setErrors(error)
    }
  }

  useEffect(() => {
    return () => {
      setPassword("")
      setConfirmPassword("")
      setErrors({})
    }
  }, [])

  useEffect(async () => {
    if (isEnterPressed) {
      await handleChangePasswordButtonClick()
    }
  }, [isEnterPressed])

  return (
    <>
      <AuthLayout title="Create new password">
        <Input
          type="password"
          placeholder="Password"
          name="password"
          className="w-100"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={errors?.passWord}
        />
        <Input
          type="password"
          placeholder="Confirm password"
          name="confirmPassword"
          className="w-100 mt-15"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          error={errors?.confirmPassword}
        />

        <div className="text-center w-100 mt-20">
          <Button
            buttonType="secondary"
            title="Submit"
            className="form-button"
            isLoading={isResetPasswordLoader}
            onClick={() => handleChangePasswordButtonClick()}
          />
        </div>
        <span className="otp-info-text-password">
          *Password must contain at least one number, one uppercase, lowercase letter and at least 8 or more characters
        </span>
      </AuthLayout>
    </>
  )
}
export default ResetPasswordScreen
