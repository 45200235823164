import Modal from "../../Modal/Modal"
import PropTypes from "prop-types"
import Button from "../../Button/Button"
import Input from "../../Input/Input"
import { useState } from "react"
import _ from "lodash"
import { REGEX } from "../../../constants/regexConstants"
import sha256 from "crypto-js/sha256"
import { validateEmail } from "../../../helpers/ValidationHelper"
import QuoteApiServices from "../../../screens/quote/services/QuoteServices"
import { useSelector } from "react-redux"
import { errorNotification, successNotification } from "../../NotifyToaster"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { useHistory } from "react-router-dom"

const VerificationEmail = ({ isOpen, isPwd, id, rateCardId, repayments }) => {
  const userDetail = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const [newPassword, setNewPassword] = useState("")
  const [email, setEmail] = useState(userDetail?.email || "")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showFormSubmitMessage, setShowFormSubmitMessage] = useState(false)
  const [errors, setError] = useState({})
  const [IsOpen, setIsOpen] = useState(isOpen)
  const [IsPwd, setIsPwd] = useState(isPwd)

  const history = useHistory()
  const onVerificationChangeRecheck = async () => {
    try {
      const response1 = await QuoteApiServices.getEmailVerificationStatus(id)

      if (!response1?.data?.data?.passwordScreen && !response1?.data?.data?.emailAuthScreen) {
        history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${id}`, {
          rateCardID: rateCardId,
          repayments: repayments,
        })
      } else {
        setShowFormSubmitMessage(!showFormSubmitMessage)
        setIsPwd(response1?.data?.data?.passwordScreen)
        setIsOpen(response1?.data?.data?.emailAuthScreen)
      }
    } catch (e) {
      /**/
    }
  }

  const onVerificationChange = async () => {
    const errors = {}
    if (isPwd) {
      if (_.isEmpty(newPassword)) errors.newPassword = "Please enter new password"
      if (_.isEmpty(confirmPassword)) errors.confirmPassword = "Please confirm password"
      if (newPassword && !REGEX.PASSWORD_REGEX.test(newPassword?.toString().trim()))
        errors.newPassword =
          "Please enter a password with at least 8 characters, one uppercase letter, one lowercase letter, one special character and one number"
      if (newPassword && confirmPassword && newPassword?.toString().trim() !== confirmPassword?.toString().trim())
        errors.confirmPassword = "New password and confirm password must be same"
    }
    if (isOpen) {
      if (_.isEmpty(email)) errors.email = "Please enter email address"
      if (email && !validateEmail(email)) errors.email = "Please enter valid email address"
    }

    if (_.isEmpty(errors)) {
      setError({})
      try {
        const response1 = await QuoteApiServices.getEmailVerificationStatus(id)
        if (response1?.data?.data?.passwordScreen || response1?.data?.data?.emailAuthScreen) {
          let data = {}
          if (isOpen) {
            data = { ...data, email: email }
          }
          if (isPwd) {
            data = {
              ...data,
              newPassword: sha256(newPassword?.toString().trim()).toString(),
              setDefaultPassword: false,
            }
          }

          const response = await QuoteApiServices.validateEmailData(data)
          if (response?.data?.status === "SUCCESS") {
            successNotification(response?.data?.message)
          }
          if (isOpen) {
            setShowFormSubmitMessage(!showFormSubmitMessage)
          }
          if (!isOpen && isPwd) {
            history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${id}`, {
              rateCardID: rateCardId,
              repayments: repayments,
            })
          }
        } else {
          history.push(`${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/${id}`, {
            rateCardID: rateCardId,
            repayments: repayments,
          })
        }
      } catch (e) {
        errorNotification(e?.response?.data?.message)
      }
    } else setError(errors)
  }
  return (
    <>
      {(IsOpen || IsPwd) && (
        <Modal
          header={IsOpen && IsPwd ? `Add Details` : IsOpen && !IsPwd ? "Verify Email" : "Create Password"}
          bodyClassName="change-password-modal"
          isOutSideClick={false}
        >
          <div className={`${!showFormSubmitMessage ? "" : "hidediv"} change-password-grid otpinput align-center`}>
            <div className="align-center">
              {IsOpen && IsPwd
                ? `Before proceeding to your application, Please verify your email address and create password`
                : IsOpen && !IsPwd
                ? "Before proceeding to your application, Please verify your email address"
                : "Before proceeding to your application, Please create password"}
            </div>
            <div className={`${IsOpen ? "" : "hidediv"}`}>
              <Input
                type="email"
                className="w-100 mt-15"
                name="email"
                value={email}
                placeholder="Email address"
                onChange={e => {
                  setEmail(e.target.value?.toString()?.trim())
                }}
                error={errors?.email}
              />
            </div>
            <div className={`${IsPwd ? "" : "hidediv"}`}>
              <Input
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                name="newPassword"
                className="w-100"
                error={errors?.newPassword}
              />
            </div>
            <div className={`${IsPwd ? "" : "hidediv"}`}>
              <Input
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                name="confirmPassword"
                className="w-100"
                error={errors?.confirmPassword}
              />
            </div>
            <div>
              <Button
                buttonType="secondary"
                title={IsOpen && IsPwd ? `Continue` : IsOpen && !IsPwd ? "Verify Email" : "Create"}
                className={`smallButton`}
                onClick={onVerificationChange}
              />
            </div>
          </div>
          {showFormSubmitMessage && (
            <div className="align-center mb-10">We have sent to you an email please verify to continue.</div>
          )}
          {showFormSubmitMessage && (
            <div className="align-center">
              <Button
                buttonType="secondary"
                title="Click To Continue"
                className={`smallButton`}
                onClick={onVerificationChangeRecheck}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

VerificationEmail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPwd: PropTypes.bool.isRequired,
  id: PropTypes.string,
  rateCardId: PropTypes.string,
  repayments: PropTypes.string,
}

export default VerificationEmail
