import PropTypes from "prop-types"

const Loader = Props => {
  const { isLoading, fromPathLoader = false } = Props
  return (
    <>
      {isLoading && (
        <div className={`loading-overlay ${fromPathLoader ? "loader-footer" : ""}`}>
          <div className="loader" />
        </div>
      )}
    </>
  )
}

Loader.propTypess = {
  isLoading: PropTypes.bool.isRequired,
  fromPathLoader: PropTypes.bool,
}

Loader.defaultProps = {
  isLoading: false,
  fromPathLoader: false,
}

export default Loader
