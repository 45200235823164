import PageNotFound from "../common/PageNotFound"
import EquiFaxScreen from "../screens/auth/EquifaxPage/EquifaxScreen"
import ForgotPasswordScreen from "../screens/auth/ForgotPassword/ForgotPasswordScreen"
import LoginScreen from "../screens/auth/Login/LoginScreen"
import OTPScreen from "../screens/auth/OTPPage/OTPScreen"
import RegistrationPage from "../screens/auth/RegistrationPage/RegistrationPage"
import ResetPasswordScreen from "../screens/auth/ResetPassword/ResetPasswordScreen"
import Dashboard from "../screens/dashboard/Dashboard"
import QuoteList from "../screens/quote/QuoteList/QuoteList"
import ApplicationList from "../screens/application/applicationList/ApplicationList"
import QuotePage from "../screens/quote/QuotePage/QuotePage"
import ViewQuotePage from "../screens/quote/ViewQuotePage/ViewQuotePage"
import ViewAplicationPage from "../screens/application/ViewAplicationPage/ViewAplicationPage"
import ApplicationPage from "../screens/application/ApplicationPage/ApplicationPage"
import RateCard from "../screens/RateCard/RateCard"
import EditProfile from "../screens/auth/EditProfile/EditProfile"
import ViewProfile from "../screens/auth/ViewProfile/ViewProfile"
import EditAplicationPage from "../screens/application/EditAplicationPage/EditAplicationPage"
import AssetFormScreen from "../screens/asset/AssetFormScreen"

import BrokerFormScreen from "../screens/broker/BrokerFormScreen"

import BrokerFormScreenNew from "../screens/brokernew/BrokerFormScreenNew"

export const ROUTE_CONSTANTS_VARIABLE = {
  DASHBOARD: "/dashboard",
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  OTP_VALIDATE: "/otp-validate",
  EDIT_PROFILE: "/edit-profile",
  VIEW_PROFILE: "/view-profile",
  EQUIFAX: "/equifax",
  CHANGE_PASSWORD: "/change-password",
  QUOTE_LIST: "/quote",
  VIEW_QUOTE: "/view-quote",
  VIEW_APPLICATION: "/view-application",
  EDIT_APPLICATION: "/edit-application",
  UN_AUTH_QUOTE: "/quote-tool",
  AUTH_QUOTE: "/quote/quote-tool",
  APPLICATION_LIST: "/application",
  AUTH_APPLICATION: "/application/application-tool",
  RESET_PASSWORD: "/reset-password",
  APPLICATION: "/application",
  USER_MODULE: "/user-module",
  RATE_CARD: "/rate-card",
  BROKER_ASSET_FORM: "/asset-form",
  BROKER_ONEPAGE_FORM: "/broker-form",
  BROKER_VIEW_FORM: "/broker-view",
  BROKER_ONEPAGE_FORM_NEW: "/broker-form-new",
  BROKER_VIEW_FORM_NEW: "/broker-view-new",
}

export const ROUTE_CONSTANTS = [
  {
    path: "/",
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.REGISTER,
    component: RegistrationPage,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.LOGIN,
    component: LoginScreen,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.FORGOT_PASSWORD,
    component: ForgotPasswordScreen,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.OTP_VALIDATE,
    component: OTPScreen,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EQUIFAX,
    component: EquiFaxScreen,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RESET_PASSWORD,
    component: ResetPasswordScreen,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.BROKER_ASSET_FORM,
    component: AssetFormScreen,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.BROKER_ONEPAGE_FORM,
    component: BrokerFormScreen,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.BROKER_ONEPAGE_FORM_NEW,
    component: BrokerFormScreenNew,
    isEscapeRedirect: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM}/:id`,
    component: () => <ViewAplicationPage isFromBroker={true} isFromBrokerNew={false} />,
    isEscapeRedirect: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM_NEW}/:id`,
    component: () => <ViewAplicationPage isFromBroker={false} isFromBrokerNew={true} />,
    isEscapeRedirect: true,
  },

  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_PROFILE,
    component: EditProfile,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE,
    component: ViewProfile,
    authenticated: true,
  },

  {
    path: ROUTE_CONSTANTS_VARIABLE.DASHBOARD,
    component: Dashboard,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST,
    component: QuoteList,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RATE_CARD,
    component: RateCard,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.UN_AUTH_QUOTE,
    component: QuotePage,
    isEscapeRedirect: false,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}`,
    component: QuotePage,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE}/:id`,
    component: QuotePage,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.VIEW_QUOTE}/:id`,
    component: ViewQuotePage,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.VIEW_APPLICATION}/:id`,
    component: () => <ViewAplicationPage isFromBroker={false} />,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.EDIT_APPLICATION}/:id`,
    component: EditAplicationPage,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.AUTH_APPLICATION}/:id`,
    component: ApplicationPage,
    authenticated: true,
  },
  {
    path: `${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}`,
    component: ApplicationList,
    authenticated: true,
  },
  // {
  //   path: ROUTE_CONSTANTS_VARIABLE.USER_MODULE,
  //   component: UserModule,
  //   authenticated: true,
  // },
  {
    path: "*",
    component: PageNotFound,
    isEscapeRedirect: true,
  },
]
