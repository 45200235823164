import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import { dashboardApiService } from "../dashboardApiSevice"
import { DASHBOARD } from "./dashboardReduxConstants"

export const getDashboardQuoteList = params => {
  return async dispatch => {
    try {
      const response = await dashboardApiService.getDashboardQuoteList(params)
      if (response?.data?.status) {
        dispatch({
          type: DASHBOARD.DASHBOARD_GET_QUOTE_LIST,
          data: response.data.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}
export const getDashboardApplicationList = params => {
  return async dispatch => {
    try {
      const response = await dashboardApiService.getDashboardApplicationList(params)
      if (response?.data?.status) {
        dispatch({
          type: DASHBOARD.DASHBOARD_GET_APPLICATION_LIST,
          data: response.data.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}

export const getDashboardCounts = () => {
  return async dispatch => {
    try {
      const response = await dashboardApiService.getDashboardCounts()
      if (response?.data?.status) {
        dispatch({
          type: DASHBOARD.DASHBOARD_GET_COUNTS,
          data: response.data.data,
        })
      }
    } catch (e) {
      displayErrors(e)
    }
  }
}
