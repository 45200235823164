export const saveAuthTokenToLocalStorage = authToken => {
  localStorage.setItem("authToken", authToken)
}

export const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem("authToken")
}

export const clearAuthTokenFromLocalStorage = () => {
  localStorage.removeItem("authToken")
}

// Broker Token
export const saveBrokerTokenToLocalStorage = authToken => {
  localStorage.setItem("brokerToken", authToken)
}

export const getBrokerTokenFromLocalStorage = () => {
  return localStorage.getItem("brokerToken")
}

export const clearBrokerTokenFromLocalStorage = () => {
  localStorage.removeItem("brokerToken")
}
