import { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Select from "../CustomSelect/CustomSelect"

const recordsPerPage = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
]
const Pagination = props => {
  const { total, limit, pages, page, className, pageActionClick, onSelectLimit, isAllowedChooseLimit } = props

  const paginationClass = `pagination-container ${className} ${!isAllowedChooseLimit ? "just-end" : ""}`

  const [recordLimit, setRecordLimit] = useState([{ label: "15", value: 15 }])

  const fromRecordCount = useMemo(() => (page - 1) * limit + 1, [page, limit])
  const toRecordCount = useMemo(() => (total < page * limit ? total : page * limit), [page, limit, total])

  const onNextClick = () => (page < pages ? pageActionClick(page + 1) : null)
  const onPrevClick = () => (page > 1 ? pageActionClick(page - 1) : null)
  const onFirstClick = () => (page > 1 ? pageActionClick(1) : null)
  const onLastClick = () => (page < pages ? pageActionClick(pages) : null)
  const onChangeLimit = e => {
    setRecordLimit(e)
    onSelectLimit(e.value)
  }

  useEffect(() => {
    const found = recordsPerPage.find(e => e.value === limit)
    let value = { label: "15", value: 15 }

    if (found) {
      value = found
    }
    setRecordLimit(value)
  }, [limit])

  if (total === 0) {
    return null
  }

  return (
    <div className={paginationClass}>
      {isAllowedChooseLimit && (
        <div className="records-per-page-container">
          <span className="mr-10 pagination-info-text">Show</span>
          <Select
            className="pagination-custom-select"
            options={recordsPerPage}
            onChangeCustomSelect={onChangeLimit}
            value={recordLimit}
            isLabelRequired={false}
            isCountryDropDown={false}
            placeholder="Select"
            isSearchable={false}
            isNoClearIcon
          />
          <span className="ml-10 pagination-records-count">
            Records {fromRecordCount} to {toRecordCount} of {total}
          </span>
        </div>
      )}
      <div className="pagination">
        <span className="mr-10 pagination-info-text">
          {pages} Page{pages !== 1 && "s"}
        </span>
        <div className="page-handler">
          <div className={`first-page ${page === 1 && "pagination-button-disabled"}`} onClick={onFirstClick}>
            <span className="material-icons-outlined">first_page</span>
          </div>
          <div className={`prev-page ${page === 1 && "pagination-button-disabled"}`} onClick={onPrevClick}>
            <span className="material-icons-outlined">arrow_back_ios</span>
          </div>
          <div className="page-number">{page}</div>
          <div className={`next-page ${page === pages && "pagination-button-disabled"}`} onClick={onNextClick}>
            <span className="material-icons-outlined">arrow_forward_ios</span>
          </div>
          <div className={`last-page ${page === pages && "pagination-button-disabled"}`} onClick={onLastClick}>
            <span className="material-icons-outlined">last_page</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  limit: PropTypes.number,
  pages: PropTypes.number,
  page: PropTypes.number,
  pageActionClick: PropTypes.func,
  onSelectLimit: PropTypes.func,
  isAllowedChooseLimit: PropTypes.bool,
}

Pagination.defaultProps = {
  className: "",
  total: 0,
  limit: 0,
  pages: 0,
  page: 0,
  pageActionClick: null,
  onSelectLimit: null,
  isAllowedChooseLimit: true,
}

export default Pagination
