import { successNotification } from "../../../../common/NotifyToaster"
import { displayErrors } from "../../../../helpers/ErrorNotifyHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../../routes/RouteConstants"
import { ForgotPasswordApiService } from "../services/ForgorPasswordApiService"
import { startLoader, stopLoader } from "../../../../common/loader/redux/LoaderActions"

export const onSubmitForgotPassword = (isEmail, data, history) => {
  const { isRedirectedFromQuote, isQuoteSubmit } = history?.location?.state ?? {}

  return async () => {
    startLoader("isForgotPasswordLoader")
    try {
      const response = await ForgotPasswordApiService.onSubmitForgotPasswordApi(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        const authInfo = {}
        if (isEmail) {
          authInfo.email = data?.email
        } else {
          authInfo.mobileNumber = data?.mobileNumber
          authInfo.countryCode = data?.countryCode
        }
        history.push(ROUTE_CONSTANTS_VARIABLE.OTP_VALIDATE, { ...authInfo, isRedirectedFromQuote, isQuoteSubmit })
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isForgotPasswordLoader")
    }
  }
}
