import { LOCAL_STORAGE_KEYS } from "../ApplicationPage/ApplicationPageConstants"

/* 
    it'll save navigation history according to formPath correctly
    it'll get navigation history according to formPath correctly
*/
export const getNavigationHistory = (formPaths, navigationHistory) => {
  const navigationHistoryStorage =
    navigationHistory || JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY) || "[]")
  if (Array.isArray(navigationHistoryStorage) && navigationHistoryStorage?.length > 0) {
    return formPaths.reduce((acc, { nextForm, innerForm }) => {
      const found = navigationHistoryStorage.find(({ formsKey, subFormsKey }) => {
        if (innerForm) return subFormsKey.includes(innerForm) && formsKey.includes(nextForm)
        return formsKey.includes(nextForm)
      })
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
  }
  return []
}

export const getFullNavigationHistory = (formPaths, navigationHistory) => {
  const history1 = getNavigationHistory(formPaths, navigationHistory)
  const history2 = getNavigationHistory(formPaths)
  if (history1?.length > history2?.length) return history1
  else return history2
}

export const saveNavigationHistoryInStorage = (formPaths, navigationHistory) => {
  const history1 = getNavigationHistory(formPaths, navigationHistory)
  const history2 = getNavigationHistory(formPaths)

  if (history1?.length > history2?.length) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY, JSON.stringify(history1))
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY, JSON.stringify(history2))
  }
}

export const removeNavigationHistory = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY)
}
