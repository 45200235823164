import { USER_MODULE_REDUX_CONSTANTS } from "./userModuleReduxConstants"

const initialUserModuleReducer = {
  userModuleList: {},
}

export const userModuleReducer = (state = initialUserModuleReducer, action) => {
  switch (action?.type) {
    case USER_MODULE_REDUX_CONSTANTS.GET_USER_MODULE_LIST:
      return {
        ...state,
        userModuleList: {
          ...action?.data,
        },
      }

    default: {
      return state
    }
  }
}
