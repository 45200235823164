import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import Button from "../../../common/Button/Button"
import Footer from "../../../common/Footer/Footer"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import Answer from "../components/Answer/Answer"
import Question from "../components/Question/Question"
import { getQuoteData, getQuoteToolJSON } from "../redux/QuoteActions"
import Loader from "../../../common/loader/Loader"

import ProgressBar from "../../../../src/common/ProgressBar/ProgressBar"
import { handleEquifaxFieldChange } from "../../auth/EquifaxPage/redux/EquifaxActions"
import { useHistory } from "react-router-dom"
import logo from "../../../assets/images/logo.svg"

const QuotePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)

  const { isQuoteCommonLoader, isQuoteActiveSaveLoader, isGlassGuideSkipActive } = useSelector(
    ({ loaderReducer }) => loaderReducer ?? {},
  )

  const { currentQuoteData, quoteId, isQuoteFirstPage, quoteJsonData } = useSelector(
    ({ quoteReducer }) => quoteReducer ?? {},
  )
  const { question, description, toolTipText, suggestion, error } = currentQuoteData ?? {}

  const handleLogInButtonClick = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const size = Object.keys(quoteJsonData).length
        if (!quoteId && size === 0) {
          await dispatch(getQuoteToolJSON())
        }
        await dispatch(handleEquifaxFieldChange("isonEquifaxPage", false))
        await dispatch(handleEquifaxFieldChange("isFormShow", true))
        await dispatch(handleEquifaxFieldChange("isEquifaxFailed", false))
        // dispatch(resetEquifaxReducer())

        if (authStatus && !quoteId && id) {
          await dispatch(getQuoteData(id, isQuoteFirstPage))
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])

  return (
    <>
      {!authStatus && (
        <div className="header-container logo-login-button ">
          <img src={logo} alt="Simply Finance" title="Simply Finance" />
          <Button buttonType="primary" onClick={handleLogInButtonClick}>
            Login
          </Button>
        </div>
      )}
      {!authStatus && <ProgressBar className="progress-bar" id="completeprocess" />}
      <section className="quote-tool-container">
        {authStatus && (
          <div className="quote-tool-top-bar">
            <span className="breadcrumb">
              <span className="breadcrumbClick" onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.QUOTE_LIST)}>
                Quote List
              </span>
              <span className="material-icons-round">navigate_next</span>
              <span>Quote</span>
            </span>
            {quoteId && (
              <span className="quote-active-saving">
                <span className="material-icons-round">
                  {isQuoteActiveSaveLoader ? "sync" : "published_with_changes"}
                </span>
                {isQuoteActiveSaveLoader ? "Saving..." : "Saved"}
              </span>
            )}
          </div>
        )}
        <div className={`quote-tool-form ${isGlassGuideSkipActive ? "d-none" : ""}`}>
          <div className="quote-tool-form-content">
            <Question question={question} toolTipText={toolTipText} description={description} isFromMulti={false} />
            <Answer data={currentQuoteData} suggestion={suggestion} error={error} />
            {/* <div className="quote-error-message">
            {error?.map((data, index) => {
              return <span key={index}>{data}</span>
            })}
          </div> */}
          </div>
        </div>
      </section>
      <Footer />
      <Loader isLoading={isQuoteCommonLoader} />
    </>
  )
}
export default React.memo(QuotePage)
