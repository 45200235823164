/* eslint-disable no-console */
import { useEffect } from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStoreData, store } from "./redux/store"
import Routes from "./routes/Routes"

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY
  const loadScript = (url, callback) => {
    const script = document.createElement("script")
    script.type = "text/javascript"

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null
          callback && callback()
        }
      }
    } else {
      script.onload = () => callback && callback()
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
  }
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty("--app-height", `${window.innerHeight}px`)
  }
  window.addEventListener("resize", appHeight)
  appHeight()

  console.logLodash = data => {
    console.log(JSON.parse(JSON.stringify(data)))
  }

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`)
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistStoreData}>
        <Routes />
      </PersistGate>
    </Provider>
  )
}

export default App
