import React from "react"
import Modal from "../Modal/Modal"
import PropTypes from "prop-types"
import { LoaderSmall } from "../LoaderSmall/LoaderSmall"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import ABNModalMobileTable from "./ABNModalMobileTable"

const ABNModalTable = Props => {
  const { tableData, isLoading, isModalOpen, onClose, onRecordClick } = Props

  const isMobileScreen = useMediaQuery("(max-width: 680px)")

  const onRecordClickMain = record => {
    onRecordClick(record)
    onClose(false)
  }

  return (
    <React.Fragment>
      {isModalOpen && (
        <Modal
          header="ABN Search"
          className="abn-lookup-modal"
          bodyClassName="d-flex just-center"
          closeIcon="close"
          onClick={onClose}
          isOutSideClick={false}
        >
          {!isLoading ? (
            <div style={{ height: "50px", width: "100%" }}>
              <LoaderSmall isRelativePosition={true} />
            </div>
          ) : isMobileScreen ? (
            <ABNModalMobileTable {...Props} />
          ) : (
            <table className="table-class abn-look-up-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ABN</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr>
              </thead>
              {tableData?.length > 0 ? (
                tableData.map(record => (
                  <tr key={record.abn} onClick={() => onRecordClickMain(record)}>
                    <td className="cursor-pointer">{record.label}</td>
                    <td className="cursor-pointer">{record.abn}</td>
                    <td className="cursor-pointer">{record.Type}</td>
                    <td className="cursor-pointer">{record.Location}</td>
                    <td className="cursor-pointer">{record.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No record found</td>
                </tr>
              )}
            </table>
          )}
        </Modal>
      )}
    </React.Fragment>
  )
}

ABNModalTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(ABNModalTable)
