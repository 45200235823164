/* eslint-disable no-console */
import { createSlice, current } from "@reduxjs/toolkit"
import _, { isEmpty } from "lodash"
import {
  checkFieldVisibilityCondition,
  copyFieldAnswer,
  getNextQuestionsFromAnswer,
  isValidAnswer,
  prepareApplicationDataWithSubTypes,
} from "../../../helpers/applicationHelper"
import {
  convertAPIDataToSavedFormData,
  fieldVisibilityWithoutFormname,
  restructureAllGetAPIData,
} from "../../../helpers/dataParser"

// import { convertAPIDataToSavedFormDataNew } from "../../../helpers/dataParserNew"

import FieldsToKeep from "../helpers/keysToKeep"
import { brokerFieldValidation } from "../helpers/brokerValidations"
import { getCorrectMergeForm, getCorrectMergeFormNew, mergeFormFilterByFormVisibility } from "../helpers"
// import applicationJSON from "../applicationJSON.json"

const getFieldsFromJSONNew = (data, path) => {
  // console.log("path", path.join("."))
  return _.get(data, [...path, "fields"].join("."), [])
}
const getFieldsFromFormDataNew = (data, path) => {
  return _.get(data, path?.join("."), [])
}
export const getCurrentForm = (formPaths, formPathIndex) => {
  // console.log('currentForm', formPaths?.[formPathIndex].join('.'))
  return formPaths?.[formPathIndex]
}

const initialFormPath = [
  ["loanType"],
  ["assetInfo"],
  ["employmentDefault"],
  ["financeNumbers"],
  ["residency"],
  ["quoteDetails"],
  ["applicantDetails1", "customerDetails"],
] // quoteDetails
// const initialFormPath = [["asset1", "assetDetails"]]

const initialState = {
  brokerFormPathIndex: 6,
  brokerFormPaths: initialFormPath,
  formData: {},
  currentFormPathIndex: 0,
  brokerApplicationJsonData: {},
  // updatedFieldsLength: { prev: 0, current: 0 },
  glassGuideDataBrokerNew: {},
  glassGuideLabelBrokerNew: {},
  glassGuideRegoBrokerNew: {},
  brokerQuoteDataNew: {},
  brokerApplicationData: {},
  rateDataNew: {},
  errorCounter: 0,
  isBrokerquotecomplete: false,
  zohobrokerid: "",
}
const brokerSliceNew = createSlice({
  name: "brokernew",
  initialState,
  reducers: {
    setJsonData(state, { payload }) {
      const preparedApplicationData = prepareApplicationDataWithSubTypes(payload)

      // START   -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname
      fieldVisibilityWithoutFormname(preparedApplicationData)
      // END     -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname

      state.brokerApplicationJsonData = preparedApplicationData
    },

    changeJsonData(state, { payload }) {
      state.brokerApplicationJsonData.financeNumbers = state?.brokerApplicationJsonData?.[payload[0]]
      state.brokerApplicationJsonData.employment = state?.brokerApplicationJsonData?.[payload[1]]
    },

    setFormPath(state, { payload }) {
      const { newForms, isForApplication } = payload ?? {}

      let formPaths = newForms.map(x => {
        const returnValue = []
        if (x.nextForm) returnValue.push(x.nextForm)
        if (x.innerForm) returnValue.push(x.innerForm)
        if (Array.isArray(x)) return x
        return returnValue
      })
      // Add assetDetails path if path is commonQuestion
      formPaths.forEach((formPath, i) => {
        if (
          formPath?.[0]?.includes("asset") &&
          formPath?.[1] === "commonQuestion" &&
          formPaths?.[i + 1]?.[1] !== "assetDetails"
        ) {
          formPaths.splice(i + 1, 0, [formPath[0], "assetDetails"])
        }
      })

      const shuffledPaths = state.brokerFormPaths

      /* console.log(
        JSON.parse(
          JSON.stringify({
            shuffledPaths,
          }),
        ),
      ) */

      // check form name and create array of paths
      const arrayOfMerge = []
      shuffledPaths.forEach(formsforarray => {
        if (formsforarray?.["1"] === "dependant" || formsforarray?.["1"] === "driverLicenceDetails") {
          arrayOfMerge.push(formsforarray)
        }
        if (
          formsforarray?.["1"] === "assetGlassGuide" ||
          formsforarray?.["1"] === "assetOptions" ||
          formsforarray?.["1"] === "assetIdentifiersDetails" ||
          formsforarray?.["1"] === "assetPricingDetails" ||
          formsforarray?.["1"] === "assetVendorDetails"
        ) {
          arrayOfMerge.push(formsforarray)
        }
      })

      if (isForApplication) {
        // const updatedFormPaths = []
        let temp = []
        temp = temp.concat(state.brokerFormPaths)
        temp = temp.concat(formPaths)
        const updatedFormPaths = [...new Set(temp.map(JSON.stringify))].map(JSON.parse)
        // shuffledPaths.forEach((e, index) => {
        //   if (index <= indexToUpdateFrom) {
        //     updatedFormPaths?.push(e)
        //   }
        // })

        // if (indexToUpdateAfterFromReceivedForm > -1) {
        //   formPaths.forEach((e, index) => {
        //     if (index > indexToUpdateAfterFromReceivedForm) {
        //       updatedFormPaths?.push(e)
        //     }
        //   })
        // } else {
        //   shuffledPaths.forEach((e, index) => {
        //     if (index > indexToUpdateFrom) {
        //       updatedFormPaths?.push(e)
        //     }
        //   })
        // }

        // Set brokerFormPathIndex to last item of asset
        const lastIndexOfAsset = updatedFormPaths.findLastIndex(x => x[0].includes("asset") && x[0] !== "assetInfo")
        if (
          lastIndexOfAsset > -1 &&
          [
            "adverseCreditHistory",
            "assetDetails",
            "commonQuestion",
            "assetGlassGuide",
            "assetOptions",
            "assetPricingDetails",
            "assetIdentifiersDetails",
            "assetVendorDetails",
          ].includes(shuffledPaths[state.brokerFormPathIndex][1])
        ) {
          state.brokerFormPathIndex = lastIndexOfAsset
        }
        formPaths = updatedFormPaths
      }

      // console.log('arrayofmerge', arrayOfMerge);

      if (arrayOfMerge.length > 0) {
        arrayOfMerge.forEach(element => {
          // console.log('element', element[0], element[1]);

          const findIndexOffForm = formPaths.findIndex(fromOfPath => fromOfPath.join(".") === element.join("."))
          if (element?.["1"] === "dependant" && findIndexOffForm <= 0) {
            const findIndexOfPersonalDetails = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "personalDetails",
            )
            if (findIndexOfPersonalDetails > 0) {
              formPaths.splice(findIndexOfPersonalDetails + 1, 0, element)
            }
          }
          if (element?.["1"] === "driverLicenceDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "dependant",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetGlassGuide" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetOptions" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetGlassGuide",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetPricingDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetOptions",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetIdentifiersDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetPricingDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetVendorDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetIdentifiersDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
        })
      }

      let inc = 0
      if (shuffledPaths.length > 0 && formPaths.length > 0 && state.brokerFormPathIndex + 1 < formPaths.length) {
        inc = inc + 1
        state.brokerFormPathIndex++
      }
      if (!isForApplication && formPaths.length > 0) {
        const fLength = formPaths.length - 1 - inc
        for (let i = 0; i < fLength; i++) {
          state.brokerFormPathIndex++
        }
      }

      // console.log('formPaths', formPaths);

      const addressInformation = formPaths.findIndex(
        x => x?.["1"] === "addressInformation" && x?.["0"] === "applicantDetails1",
      )
      const previousAddress = formPaths.findIndex(
        x => x?.["1"] === "previousAddressDetails" && x?.["0"] === "applicantDetails1",
      )

      // console.log('address', addressInformation, previousAddress);

      if (addressInformation > 0 && previousAddress > 0) {
        const newArr = []

        formPaths.splice(previousAddress, 1)

        formPaths.forEach(element => {
          newArr.push(element)

          if (element?.["1"] === "addressInformation") {
            newArr.push(["applicantDetails1", "previousAddressDetails"])

            const previousAddress2 = formPaths.findIndex(x => x?.["1"] === "previousAddressDetails2")

            if (previousAddress2 > 0) {
              formPaths.splice(previousAddress2, 1)
              newArr.push(["applicantDetails1", "previousAddressDetails2"])
            }
          }
        })

        formPaths = newArr
      }

      /* const applicantType = state.formData?.quoteDetails?.[3].answer;

      console.log('applicantType', applicantType)

      if (applicantType === 'Individual') {

        const newPaths = [];

        formPaths.forEach( element => {

          if (element?.["0"] !== 'gurantor1') {

            newPaths.push( element )

          }

        })

        formPaths = newPaths;

      } */

      // console.log('formPaths', formPaths);

      state.brokerFormPaths = formPaths
      if (isForApplication) {
        state.brokerFormPathIndex = formPaths?.length - 1
      } else {
        if (state.brokerFormPathIndex > formPaths?.length - 1) {
          state.brokerFormPathIndex = formPaths?.length - 1
        }
      }
    },

    setInitialFormDataNew(state) {
      for (let index = 0; index <= state.brokerFormPathIndex; index++) {
        const alreadyHaveData =
          Object.values(getFieldsFromFormDataNew(state.formData, getCurrentForm(state.brokerFormPaths, index))).length >
          0
        const jsonFieldList = getFieldsFromJSONNew(
          state.brokerApplicationJsonData,
          getCurrentForm(state.brokerFormPaths, index),
        )

        const { fieldsToKeep } = new FieldsToKeep(
          jsonFieldList,
          jsonFieldList,
          state.formData,
          getCurrentForm(state.brokerFormPaths, index),
        )

        if (!alreadyHaveData) {
          _.set(state.formData, getCurrentForm(state.brokerFormPaths, index).join("."), fieldsToKeep)
        }
      }
    },
    changeFormData(state, { payload }) {
      // state.waitForSecond = false
      state.formData = payload.fieldsToKeep
      state.brokerFormPaths = payload.brokerFormPath
      state.brokerFormPathIndex = payload.brokerFormIndex
    },
    changeRegoData(state, { payload }) {
      state.glassGuideRegoBrokerNew = payload
    },
    changeSecondFlag(state, { payload }) {
      state.waitForSecond = payload
    },

    restoreFormData(state, { payload }) {
      if (payload?.isFromBroker) {
        if (payload?.navigationHistory && Object.keys(payload?.navigationHistory).length > 0) {
          const newFormData = _.omit(payload, ["navigationHistory"])
          // START   -------------------------------------------------------------- Restore saved redux state
          const navigationHistory = payload?.navigationHistory

          Object.keys(initialState).forEach(key => {
            if (navigationHistory?.[key]) {
              state[key] = navigationHistory[key]
            }
          })

          // END     -------------------------------------------------------------- Restore saved redux state

          // START   -------------------------------------------------------------- Generate formData from saved data

          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // const newSavedFormData = convertAPIDataToSavedFormDataNew(structuredData, state.brokerApplicationJsonData)

          // To change structure of income and outgoing savedFormData
          const restoreRepeaterData = data => {
            Object.keys(data).forEach(key => {
              if (data?.[key]?.fields && !data?.[key]?.key && !data?.[key]?.fieldName) {
                data[key] = data[key].fields
              } else if (typeof data?.[key] === "object") {
                // console.log(data[key]);

                restoreRepeaterData(data[key])
              }
            })
          }

          console.log("newSaveFormData", newSavedFormData)
          // console.log('history', navigationHistory.brokerFormPaths.length);

          restoreRepeaterData(newSavedFormData)

          for (let formPathIndex = 0; formPathIndex < navigationHistory.brokerFormPaths.length; formPathIndex++) {
            const currentForm = getCurrentForm(state.brokerFormPaths, formPathIndex)

            // console.log('currentForm', currentForm);

            // console.log('navigationHistory', navigationHistory);

            const formDataFieldList = getFieldsFromFormDataNew(
              newSavedFormData,
              getCurrentForm(navigationHistory.brokerFormPaths, formPathIndex),
            )

            // console.log('state', state.brokerApplicationJsonData);
            // console.log('currentForm', currentForm.join("."))

            const jsonFieldList = getFieldsFromJSONNew(state.brokerApplicationJsonData, currentForm)

            // console.log('state form data', state.formData);

            // console.log('json', jsonFieldList);

            // console.log('currentForm', currentForm.join("."));

            const { fieldsToKeep } = new FieldsToKeep(
              formDataFieldList,
              jsonFieldList,
              state.formData,
              currentForm,
              true,
            )

            _.set(state.formData, currentForm.join("."), fieldsToKeep)
          }
        } else {
          const newFormData = _.omit(payload, [
            "navigationHistory",
            "quotePreviousQuestions",
            "applicationPreviousQuestions",
          ])

          // START   -------------------------------------------------------------- Set brokerFormPaths
          const quoteFormPath = payload?.quotePreviousQuestions?.reduce((acc, { form }) => {
            const isAlreadyExists = acc.findIndex(x => x[0] === form) > -1
            if (!isAlreadyExists && form) acc.push([form])
            return acc
          }, [])
          const applicationFormPath = payload?.applicationPreviousQuestions?.[0]?.completeNavigationHistory?.reduce(
            (acc, { formsKey, subFormsKey }) => {
              if (formsKey?.[0] && subFormsKey?.length > 0) {
                subFormsKey.forEach(subForm => {
                  acc.push([formsKey[0], subForm])
                })
              } else if (formsKey?.[0]) {
                acc.push([formsKey[0]])
              }
              return acc
            },
            [],
          )
          const formPath = [...(quoteFormPath || []), ...(applicationFormPath || [])]
          if (formPath?.length > 0) {
            state.brokerFormPaths = formPath
            state.brokerFormPathIndex = formPath.length - 1
          }
          // END     -------------------------------------------------------------- Set brokerFormPaths

          // START   -------------------------------------------------------------- Generate formData from saved data
          const structuredData = restructureAllGetAPIData(newFormData)

          // console.e.log('structredData', structuredData)

          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // console.log('newSaveFormData', newSavedFormData);

          state.formData = newSavedFormData
          // END     -------------------------------------------------------------- Generate formData from saved data
        }

        // END     -------------------------------------------------------------- Generate formData from saved data
      } else {
        if (
          !payload?.isFromBroker &&
          payload?.navigationHistory &&
          Object.keys(payload?.navigationHistory).length > 0
        ) {
          const newFormData = _.omit(payload, ["navigationHistory"])
          // START   -------------------------------------------------------------- Restore saved redux state
          const navigationHistory = payload?.navigationHistory
          Object.keys(initialState).forEach(key => {
            if (navigationHistory?.[key]) {
              state[key] = navigationHistory[key]
            }
          })
          // END     -------------------------------------------------------------- Restore saved redux state

          // START   -------------------------------------------------------------- Generate formData from saved data

          console.log("convert 495")

          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // const newSavedFormData = convertAPIDataToSavedFormDataNew(structuredData, state.brokerApplicationJsonData)

          // To change structure of income and outgoing savedFormData
          const restoreRepeaterData = data => {
            Object.keys(data).forEach(key => {
              if (data?.[key]?.fields && !data?.[key]?.key && !data?.[key]?.fieldName) {
                data[key] = data[key].fields
              } else if (typeof data?.[key] === "object") {
                restoreRepeaterData(data[key])
              }
            })
          }
          restoreRepeaterData(newSavedFormData)

          for (let formPathIndex = 0; formPathIndex < navigationHistory.brokerFormPaths.length - 1; formPathIndex++) {
            const formDataFieldList = getFieldsFromFormDataNew(
              newSavedFormData,
              getCurrentForm(navigationHistory.brokerFormPaths, formPathIndex),
            )
            const jsonFieldList = getFieldsFromJSONNew(
              state.brokerApplicationJsonData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
            )
            const { fieldsToKeep } = new FieldsToKeep(
              formDataFieldList,
              jsonFieldList,
              state.formData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
              // checked is constructor called from sync or not
              true,
            )
            _.set(state.formData, getCurrentForm(state.brokerFormPaths, formPathIndex).join("."), fieldsToKeep)
          }
        } else {
          const newFormData = _.omit(payload, [
            "navigationHistory",
            "quotePreviousQuestions",
            "applicationPreviousQuestions",
          ])
          // START   -------------------------------------------------------------- Set brokerFormPaths
          const quoteFormPath = payload?.quotePreviousQuestions?.reduce((acc, { form }) => {
            const isAlreadyExists = acc.findIndex(x => x[0] === form) > -1
            if (!isAlreadyExists && form) acc.push([form])
            return acc
          }, [])
          const applicationFormPath = payload?.applicationPreviousQuestions?.[0]?.completeNavigationHistory?.reduce(
            (acc, { formsKey, subFormsKey }) => {
              if (formsKey?.[0] && subFormsKey?.length > 0) {
                subFormsKey.forEach(subForm => {
                  acc.push([formsKey[0], subForm])
                })
              } else if (formsKey?.[0]) {
                acc.push([formsKey[0]])
              }
              return acc
            },
            [],
          )
          const formPath = [...(quoteFormPath || []), ...(applicationFormPath || [])]
          if (formPath?.length > 0) {
            state.brokerFormPaths = formPath
            state.brokerFormPathIndex = formPath.length - 1
          }
          // END     -------------------------------------------------------------- Set brokerFormPaths

          // START   -------------------------------------------------------------- Generate formData from saved data

          console.log("convert 571")

          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // const newSavedFormData = convertAPIDataToSavedFormDataNew(structuredData, state.brokerApplicationJsonData)

          state.formData = newSavedFormData
          // END     -------------------------------------------------------------- Generate formData from saved data
        }
      }
    },
    restoreGlassGuideDataAndLabel(state, { payload }) {
      if (payload?.glassGuideDataBrokerNew) {
        state.glassGuideDataBrokerNew = payload.glassGuideDataBrokerNew
      }
      if (payload?.glassGuideLabelBrokerNew) {
        state.glassGuideLabelBrokerNew = payload.glassGuideLabelBrokerNew
      }
    },
    setFormDataNew(state, { payload }) {
      // console.log('formData', state.formData);
      // console.log('payload', payload.path);
      // console.log('value', payload.value);

      _.set(state.formData, payload.path, payload.value)
    },
    setGlassGuideLabelDataNew(state, { payload }) {
      _.set(state.glassGuideLabelBrokerNew, `${payload?.formToShow}.${payload.fieldName}`, payload.label)
    },
    setFieldData(state, { payload }) {
      const { table, ...restData } = payload?.data

      // START   -------------------------------------------------------------- Push "None" if table don't have options NOTE: just for manufacturer options
      if (
        !table.length ||
        payload?.field?.fieldName === "manufacturerOptions" ||
        payload?.field?.fieldName === "assetManufacturerOptions"
      ) {
        table.push({ label: "None", value: "None" })
      }
      // END     -------------------------------------------------------------- Push "None" if table don't have options NOTE: just for manufacturer options

      if (payload?.field?.fieldName === "assetManufacturerOptions") {
        const fieldIndex = _.get(state.formData, `${payload?.pathOfField?.split("[")[0]}`)?.findIndex(
          x => x.fieldName === "assetGlassGuideData",
        )

        if (fieldIndex >= 0) {
          const restOfTheTable = payload?.data
          _.set(
            state.formData,
            `${payload?.pathOfField?.split("[")[0]}[${fieldIndex}].answer`,
            JSON.stringify(restOfTheTable),
          )
        }
      }
      state.glassGuideDataBrokerNew = { ...state.glassGuideDataBrokerNew, ...restData }
      if (_.get(state.formData, `${payload.pathOfField}`))
        _.set(state.formData, `${payload.pathOfField}.options`, table)
    },

    syncFormDataWithFieldsNew(state, action) {
      // state.errorCounter = 0
      const { index: formPathIndex, path, glassGuideFieldAnswerChangedKey } = action.payload ?? {}

      // console.log('index', formPathIndex);

      const formDataFieldList = getFieldsFromFormDataNew(
        state.formData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
      )
      const jsonFieldList = getFieldsFromJSONNew(
        state.brokerApplicationJsonData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
      )

      // To remove glassguide next fields when glassguide field option changed
      if (glassGuideFieldAnswerChangedKey) {
        const keys = formDataFieldList.reduce((acc, field) => {
          if (field?.key === glassGuideFieldAnswerChangedKey) {
            acc = getNextQuestionsFromAnswer(field?.nextQuestions, field?.answer)
          }
          return acc
        }, [])
        if (keys?.length)
          keys.forEach(key => {
            const fieldIndex = formDataFieldList.findIndex(x => x?.key === key)
            if (fieldIndex >= 0 && formDataFieldList[fieldIndex]?.options?.length > 0) {
              delete formDataFieldList[fieldIndex].options
              delete formDataFieldList[fieldIndex].answer
            }
          })
      }

      // START   -------------------------------------------------------------- To remove assetOptions if assetGlassguide fields changed
      if (
        path.includes("assetGlassGuide") &&
        (glassGuideFieldAnswerChangedKey === "" || glassGuideFieldAnswerChangedKey)
      ) {
        const assetOptionsIndex = state.brokerFormPaths.findIndex(
          (formPath, i) => i > formPathIndex && formPath[1] === "assetOptions",
        )
        if (assetOptionsIndex >= 0) {
          _.unset(state.formData, state.brokerFormPaths[assetOptionsIndex])
          state.brokerFormPaths.splice(assetOptionsIndex, 1)
          state.brokerFormPathIndex--
        }
      }
      // END     -------------------------------------------------------------- To remove assetOptions if assetGlassguide fields changed

      const { fieldsToKeep } = new FieldsToKeep(
        formDataFieldList,
        jsonFieldList,
        state.formData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
        // checked is constructor called from sync or not
        true,
      )

      _.set(state.formData, getCurrentForm(state.brokerFormPaths, formPathIndex).join("."), fieldsToKeep)

      /** copied from remove */
      // const updatedFormData = {}
      // state.brokerFormPaths.forEach( e =>
      // {
      //   _.set( updatedFormData, e?.join( "." ), _.get( state.formData, e?.join( "." ) ) )
      // } )
      // state.formData = updatedFormData
      /** copied from remove */

      const isQuoteField = _.get(
        state.brokerApplicationJsonData,
        `${getCurrentForm(state.brokerFormPaths, formPathIndex)}.isQuoteForm`,
      )

      const currentQuestion = _.get(current(state.formData), path)

      if (isQuoteField) {
        const isAPIField = currentQuestion?.quoteApiCallLastQuestion ?? false

        // eslint-disable-next-line no-unused-vars
        const nextQuestions = getNextQuestionsFromAnswer(currentQuestion?.nextQuestions, currentQuestion?.answer)

        const haveApiCall = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.[0]}.apiCall`,
        )

        if (
          getCurrentForm(state?.brokerFormPaths, formPathIndex)?.[0] === "residency" &&
          ["Q3", "Q4"].includes(currentQuestion?.key)
        ) {
          if (
            currentQuestion?.key === "Q3" &&
            currentQuestion?.answer
            // && currentQuestion?.answer !== "Visa"
            // &&
            // state.brokerFormPathIndex < state.brokerFormPaths.length
          ) {
            const indexToGetPathFrom = state.brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
            if (indexToGetPathFrom <= 0) {
              state.brokerFormPaths.push(["quoteDetails"])
              state.brokerFormPathIndex++
            }
          }
          // if (
          //   currentQuestion?.key === "Q4" &&
          //   currentQuestion?.answer
          //   // &&
          //   // state.brokerFormPathIndex < state.brokerFormPaths.length
          // ) {
          //   const indexToGetPathFrom = state.brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
          //   if (indexToGetPathFrom <= 0) {
          //     state.brokerFormPaths.push(["quoteDetails"])
          //     state.brokerFormPathIndex++
          //   }
          // }
        } else if (haveApiCall && isAPIField) {
          //! nextQuestions

          state.isLastQuestion = true
        }
      } else {
        // for application
        const isAPIField = currentQuestion?.applicationApiCallLastQuestion ?? false
        const canMerge = currentQuestion?.canMerge ?? false
        const haveApiCall =
          _.get(
            state.brokerApplicationJsonData,
            `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.apiCall`,
          ) ?? false

        const mergeFormName = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.mergeFormName`,
        )

        if (haveApiCall && isAPIField) {
          if (!currentQuestion?.isSkip || (currentQuestion?.isSkip && currentQuestion?.answer)) {
            if (
              [
                "currentPropertyYears",
                "currentPropertyMonths",
                "previousPropertyYears",
                "previousPropertyMonths",
              ].includes(currentQuestion?.fieldName)
            ) {
              state.waitForSecond = true
            }
            state.isApplicationLastQuestion = true
          }
        }
        if (mergeFormName && state.brokerFormPaths?.[formPathIndex]?.[0] && canMerge) {
          if (!currentQuestion?.isSkip || (currentQuestion?.isSkip && isValidAnswer(currentQuestion?.answer))) {
            state.mergeFormsData = {
              formPathIndex,
              path,
            }
          }
        }
      }

      // setting index of current changing form to check validations
      state.currentFormPathIndex = formPathIndex

      return state
    },
    performMergeFormNew: (state, action) => {
      // if ((!state.errorCounter || state.errorCounter <= 0) && !isEmpty(state.mergeFormsData)) {
      if (!isEmpty(state.mergeFormsData)) {
        const { formPathIndex } = action.payload ?? {}
        let mergeFormName = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.mergeFormName`,
        )
        const mergeFormDirect = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.mergeFormDirect`,
        )

        if (Array.isArray(mergeFormName) && mergeFormName?.length > 0 && !mergeFormDirect) {
          mergeFormName = getCorrectMergeForm(
            mergeFormName,
            state.formData,
            getCurrentForm(state.brokerFormPaths, formPathIndex)[0],
          )
        } else if (mergeFormDirect && Array.isArray(mergeFormName)) {
          mergeFormName = getCorrectMergeFormNew(mergeFormName)
        } else {
          mergeFormName = [mergeFormName]
        }

        mergeFormName = mergeFormFilterByFormVisibility(
          mergeFormName,
          getCurrentForm(state.brokerFormPaths, formPathIndex)[0],
          state.brokerApplicationJsonData,
          state.formData,
        )

        if (mergeFormName?.length > 0) {
          const formPaths = [...state.brokerFormPaths]
          let indexToIncrement = state.brokerFormPathIndex

          mergeFormName?.forEach((form, index) => {
            if (form) {
              const availableIndex = formPaths.findIndex(
                e => e?.[0] === formPaths?.[formPathIndex]?.[0] && e?.[1] === form,
              )
              if (availableIndex === -1) {
                formPaths.splice(formPathIndex + index + 1, 0, [formPaths[formPathIndex][0], form])
                if (indexToIncrement < formPaths.length) indexToIncrement++
              } else {
                if (indexToIncrement !== availableIndex && indexToIncrement < availableIndex) {
                  indexToIncrement = availableIndex
                }
              }
            }
          })

          state.brokerFormPaths = formPaths
          state.brokerFormPathIndex = indexToIncrement < formPaths.length - 1 ? indexToIncrement : formPaths?.length - 1

          // To increase formpathindex if brokerPathIndex is at commonQuestion subform
          if (state.brokerFormPaths[state.brokerFormPathIndex]?.[1] === "commonQuestion") state.brokerFormPathIndex++
        }
        state.mergeFormsData = null
      }
    },
    setLastQuestionOnly: (state, action) => {
      state.isLastQuestion = action.payload
    },
    setAppLastQuestionOnly: (state, action) => {
      state.isApplicationLastQuestion = action.payload
      state.waitForSecond = action.payload
    },
    setLastQuestion: (state, action) => {
      state.isLastQuestion = action.payload
      state.isApplicationLastQuestion = action.payload
      state.waitForSecond = false
    },

    resetExtraFields: state => {
      state.isLastQuestion = false
      state.isApplicationLastQuestion = false
      state.errorCounter = 0
      state.mergeFormsData = {}
      state.waitForSecond = false
    },
    removeFormDataNew(state, action) {
      const { index, mainIndex } = action?.payload ?? {}
      const isQuoteField = _.get(
        state.brokerApplicationJsonData,
        `${getCurrentForm(state.brokerFormPaths, index)}.isQuoteForm`,
      )
      if (isQuoteField) {
        let formToUpdate = state?.formData?.[state?.brokerFormPaths?.[index]]
        if (formToUpdate?.length > 0 && index >= 0 && mainIndex >= 0) {
          formToUpdate = formToUpdate?.filter((_, i) => i <= mainIndex)
          state.formData = {
            ...state.formData,
            [`${state.brokerFormPaths[index]}`]: formToUpdate,
          }
        }
      }
      const updatedFormData = {}

      state.brokerFormPaths.forEach(e => {
        _.set(updatedFormData, e?.join("."), _.get(state.formData, e?.join(".")))
      })

      // state.formData = updatedFormData
    },
    removeFormData(state, action) {
      const { index, mainIndex } = action?.payload ?? {}
      const isQuoteField = _.get(
        state.brokerApplicationJsonData,
        `${getCurrentForm(state.brokerFormPaths, index)}.isQuoteForm`,
      )
      if (state.brokerFormPathIndex > index) {
        state.brokerFormPaths = state?.brokerFormPaths?.filter((_, i) => i <= index)
        state.brokerFormPathIndex = index
      }
      if (isQuoteField) {
        let formToUpdate = state?.formData?.[state?.brokerFormPaths?.[index]]
        if (formToUpdate?.length > 0 && index >= 0 && mainIndex >= 0) {
          formToUpdate = formToUpdate?.filter((_, i) => i <= mainIndex)
          state.formData = {
            ...state.formData,
            [`${state.brokerFormPaths[index]}`]: formToUpdate,
          }
        }
      }
      const updatedFormData = {}
      state.brokerFormPaths.forEach(e => {
        _.set(updatedFormData, e?.join("."), _.get(state.formData, e?.join(".")))
      })
      state.formData = updatedFormData
    },

    autoPopulateOutgoing: (state, { payload: { index, mainIndex, outGoingType } }) => {
      // const path = state.brokerFormPaths[ payload.index ];

      _.update(state.formData, `${state.brokerFormPaths[index].join(".")}[${mainIndex + 1}]`, data => {
        if (Array.isArray(data?.fields)) {
          const checkRepeaterFieldVisibility = inputData =>
            inputData.filter(x => checkFieldVisibilityCondition(x, state.formData, data.fields))

          const fieldsToRender = data.fieldsToRender

          fieldsToRender[0].answer = outGoingType
          fieldsToRender[1].answer = "Monthly"
          fieldsToRender[0].editable = false

          data.fields.push(checkRepeaterFieldVisibility(fieldsToRender))

          return data
        }
      })

      // data.fields.push(checkRepeaterFieldVisibility(data.fieldsToRender))
      // if field dont have fieldToRender then use fields first element and remove answers from it
      // else if (data?.fields?.[0]?.length > 0)
      // data.fields.push(checkRepeaterFieldVisibility(data.fields[0].map(x => ({ ...x, answer: undefined }))))
    },

    // TO ADD AND REMOVE REPEATER SET
    repeaterFormAddAndRemoveNew: (state, { payload: { index, mainIndex, removeIndex, field } }) => {
      _.update(state.formData, `${state.brokerFormPaths[index].join(".")}[${mainIndex}]`, data => {
        // Need to remove repeater set

        if (removeIndex >= 0) {
          if (data?.fields?.[removeIndex]) data.fields.splice(removeIndex, 1)
          // Need to add repeater set
        } else {
          if (Array.isArray(data?.fields)) {
            // Check field visibility condition for repeater fields

            if (field?.fieldName === "outgoings") {
              data.fieldsToRender[0].answer = ""
              data.fieldsToRender[0].editable = true
            }

            const checkRepeaterFieldVisibility = inputData =>
              inputData.filter(x => checkFieldVisibilityCondition(x, state.formData, data.fields))

            // if field has fieldsToRender then push it to fields
            if (data?.fieldsToRender?.length > 0) data.fields.push(checkRepeaterFieldVisibility(data.fieldsToRender))
            // if field dont have fieldToRender then use fields first element and remove answers from it
            else if (data?.fields?.[0]?.length > 0)
              data.fields.push(checkRepeaterFieldVisibility(data.fields[0].map(x => ({ ...x, answer: undefined }))))
          }
        }
        return data
      })
    },

    // validate fields
    validateFieldsNew: (state, { payload }) => {
      const index = Number(payload)
      let counter = 0
      const formPathsToValidate = state.brokerFormPaths.slice(
        0,
        (index || index === 0 ? index : state.brokerFormPathIndex) + 1,
      )

      // To only validate fields which needs to be validated
      // uses index of formpath to validate particular fields
      const doesPathIncludeFormPath = path =>
        formPathsToValidate.findIndex(formPath => path.includes(formPath.join("."))) >= 0

      const findFieldAndSetError = (data, path = "", NformData, formpath) => {
        for (const key in data) {
          // console.log('data', data[key]?.fieldName);

          if (data[key]?.key && data[key]?.question && data[key]?.fieldName) {
            const error = brokerFieldValidation(data[key], `${path}.${key}`, NformData, formpath)

            if (error && doesPathIncludeFormPath(path)) {
              data[key].errorMessage = error

              counter++

              // console.log('error', error, data[key]);
            } else if (data?.[key]?.errorMessage) data[key].errorMessage = ""

            if (data[key]?.fields)
              findFieldAndSetError(data[key]?.fields, `${path ? `${path}.` : ""}${key}.fields`, NformData, formpath)
          } else if (data[key] instanceof Object) {
            findFieldAndSetError(data[key], `${path ? `${path}.` : ""}${key}`, NformData, formpath)
          }
        }
      }
      const formpath = state.brokerFormPaths?.[state.currentFormPathIndex]?.[0]

      findFieldAndSetError(state.formData, "", state.formData, formpath)

      state.errorCounter = counter
    },

    copyAssetNew: (state, { payload }) => {
      const { mainForm, copyForms, isAdd } = payload
      // Loop through forms to copy
      if (state?.glassGuideLabelBrokerNew?.[copyForms.formName] && state?.glassGuideLabelBrokerNew && mainForm) {
        _.set(state.glassGuideLabelBrokerNew, mainForm, state.glassGuideLabelBrokerNew[copyForms.formName])
      }
      copyForms.fieldsToCopy.forEach(formToCopy => {
        // Need to add form or remove it
        if (isAdd) {
          // get fields from formData if exists
          let fields = _.get(state.formData, `${copyForms.formName}.${formToCopy}`)

          if (!copyForms?.formName) {
            // if it doesnt have formName then it means its copy vendor with "New" value
            // Get fields to keep from json
            const { fieldsToKeep } = new FieldsToKeep(
              getFieldsFromJSONNew(state.brokerApplicationJsonData, [mainForm, formToCopy]),
              getFieldsFromJSONNew(state.brokerApplicationJsonData, [mainForm, formToCopy]),
              state.formData,
              [mainForm, formToCopy],
              // checked is constructor called from sync or not
              false,
            )
            fields = fieldsToKeep
          }
          // If cant find fields then dont do anything
          if (!(Array.isArray(fields) && fields?.length > 0)) return
          // Set fields to formData
          _.set(state.formData, `${mainForm}.${formToCopy}`, fields)
        } else {
          // Delete data from formData
          delete state.formData[mainForm][formToCopy]
        }
      })

      // Add Forms
      if (isAdd) {
        const allFormsOfAssetToCopy = state.brokerFormPaths.filter(
          formPath => formPath[0] === copyForms.formName && !["commonQuestion"].includes(formPath[1]),
        )
        allFormsOfAssetToCopy.forEach(([_, assetFormToCopy]) => {
          const isAlreadyExists =
            state.brokerFormPaths.findIndex(formPath => formPath[0] === mainForm && formPath[1] === assetFormToCopy) >
            -1
          if (!isAlreadyExists) {
            const lastCurrentFormIndex = state.brokerFormPaths.findLastIndex(formPath => formPath[0] === mainForm)
            state.brokerFormPaths.splice(lastCurrentFormIndex + 1, 0, [mainForm, assetFormToCopy])
            if (state.brokerFormPathIndex + 1 < state.brokerFormPaths.length) state.brokerFormPathIndex++
          }
        })
        // Remove forms
      } else {
        const allFormsOfAssetToRemove = state.brokerFormPaths.filter(
          formPath =>
            formPath[0] === mainForm && !["commonQuestion", "assetDetails", "assetVendorDetails"].includes(formPath[1]),
        )
        allFormsOfAssetToRemove.forEach(([_, assetFormToRemove]) => {
          const indexToRemove = state.brokerFormPaths.findIndex(
            formPath => formPath[0] === mainForm && formPath[1] === assetFormToRemove,
          )
          if (indexToRemove > -1) {
            state.brokerFormPaths.splice(indexToRemove, 1)
            state.brokerFormPathIndex--
          }
        })
      }
      const lastAssetFormIndex = state.brokerFormPaths.findIndex(formPath => formPath[0] === mainForm)
      if (lastAssetFormIndex > state.brokerFormPathIndex) state.brokerFormPathIndex = lastAssetFormIndex
    },
    copyFieldsNew: (state, { payload }) => {
      const { field, formPathIndex } = payload
      copyFieldAnswer(
        field?.answer,
        _.get(state.formData, `${state.brokerFormPaths[formPathIndex].join(".")}`),
        field?.copyFields?.from,
        field?.copyFields?.to,
      )
    },
    updateBrokerFieldsNew: (state, action) => {
      const { fieldName, value } = action.payload ?? {}
      state[fieldName] = value
    },
    setQuoteAnswerData: (state, { payload }) => {
      state.brokerQuoteDataNew = payload.data
    },
    setApplicationAnswerData: (state, { payload }) => {
      state.brokerApplicationData = payload
    },
    setRateDataNew: (state, { payload }) => {
      console.log("payload", payload)

      state.rateDataNew = payload.data
    },
    setBrokerExtraPathData: (state, action) => {
      state.brokerExtraPathData = action?.payload ?? {}
    },
    FORCE_PUSH_FORMDATA: (state, action) => {
      Object.assign(state.formData, action.payload)
    },
    setBrokerQoteNowData: (state, action) => {
      state.isBrokerquotecomplete = action?.payload?.isBrokerquotecomplete ?? {}
    },
    setBrokerQoteNowzohoData: (state, action) => {
      state.zohobrokerid = action?.payload?.zohobrokerid ?? {}
    },

    setQuoteData: (state, payload) => {
      // state.brokerApplicationJsonData.quoteDetails = state?.brokerApplicationJsonData?.[payload[0]]
    },

    setPayoutField: (state, payload) => {
      const updatedFormData = {}
      state.brokerFormPaths.forEach(e => {
        const fields = _.get(state.formData, e?.join("."))

        fields.map((field, index, self) => {
          if (field.fieldName === "payout") {
            field.answer = "No"
          }

          return field
        })

        _.set(updatedFormData, e?.join("."), fields)
      })

      // console.log('formData', updatedFormData);
      state.formData = updatedFormData
    },

    setQuoteDetails: (state, payload) => {
      const updatedFormData = {}

      state.brokerFormPaths.forEach(e => {
        const fields = _.get(state.formData, e?.join("."))

        fields.map((field, index, self) => {
          if (field.fieldName === "consumerOrCommercial") {
            field.answer = payload.payload.quote
            field.editable = false
          }

          if (field.fieldName === "applicantType") {
            if (payload.payload.quote === "Consumer") {
              field.answer = "Individual(s)"
              field.options = ["Individual(s)"]
              field.nextQuestions = { Individuals: ["Q6"] }
            } else if (payload.payload.quote === "Commercial") {
              field.answer = "Individual"
              field.options = ["Individual", "Partnership", "Trust", "Company"]
              field.nextQuestions = {
                Individual: ["Q7"],
                Partnership: ["Q8", "Q9"],
                Trust: ["Q8", "Q9"],
                Company: ["Q8", "Q9"],
              }
            }
          }

          return field
        })

        _.set(updatedFormData, e?.join("."), fields)
      })
      state.formData = updatedFormData
    },

    scrollOnError: () => {
      // const counter = state.errorCounter

      const errorMessage = document.getElementsByClassName("application-error-message")

      // console.log('message', errorMessage.length)

      if (errorMessage.length > 0) {
        const element = errorMessage[0]?.closest("div > div")

        element?.scrollIntoView({ behavior: "smooth" })
      }

      // console.log('counter', counter, errorMessage[0]);
    },

    setAssetDetails: (state, payload) => {
      let pathIndex = state.brokerFormPathIndex

      const paths = state.brokerFormPaths.map((path, index) => {
        if (payload.payload.value === "New" || payload.payload.value === "Demo") {
          if (path?.[0] === "assetInfo") {
            return ["vehicleSelect"]
          }
        } else if (path?.[0] === "vehicleSelect" && index === 1) {
          return ["assetInfo"]
        }

        return path
      })

      for (let i = 0; i <= paths.length - 1; i++) {
        if (paths[i]?.[0] === "vehicleSelect" && i > 1) {
          paths.splice(i, 1)
          pathIndex -= 1
        }
      }

      // console.log('paths', paths);

      state.brokerFormPathIndex = pathIndex
      state.brokerFormPaths = paths
    },

    setDirectors: (state, payload) => {
      // console.log('brokerPaths', state.brokerFormPaths);
      // console.log('payload', payload);

      const paths = []

      if (payload.payload === 1 || payload.payload === 2) {
        state.brokerFormPaths.map(path => {
          if (payload.payload === 1) {
            if (["gurantor2", "gurantor3", "gurantor4"].includes(path[0]) === false) {
              paths.push(path)
            }
          } else if (payload.payload === 2) {
            if (["gurantor3", "gurantor4"].includes(path[0]) === false) {
              paths.push(path)
            }
          }

          return path
        })

        // console.log('paths', paths);

        state.brokerFormPaths = paths
        state.brokerFormPathIndex = paths.length - 1
      }
    },
  },
})

export const {
  setFormPath,
  changeSecondFlag,
  changeRegoData,
  setJsonData,
  setFormDataNew,
  setInitialFormDataNew,
  syncFormDataWithFieldsNew,
  removeFormData,
  removeFormDataNew,
  dataUpdated,
  changeJsonData,
  setFieldData,
  setLastQuestion,
  setLastQuestionOnly,
  repeaterFormAddAndRemoveNew,
  autoPopulateOutgoing,
  validateFieldsNew,
  setGlassGuideLabelDataNew,
  performMergeFormNew,
  copyAssetNew,
  copyFieldsNew,
  updateBrokerFieldsNew,
  restoreFormData,
  changeFormData,
  setQuoteAnswerData,
  setApplicationAnswerData,
  setRateDataNew,
  setBrokerExtraPathData,
  setBrokerQoteNowData,
  setBrokerQoteNowzohoData,
  resetExtraFields,
  restoreGlassGuideDataAndLabel,
  setAppLastQuestionOnly,
  setQuoteDetails,
  setQuoteData,
  FORCE_PUSH_FORMDATA,
  scrollOnError,
  setPayoutField,
  setAssetDetails,
  setDirectors,
} = brokerSliceNew.actions
export default brokerSliceNew.reducer
