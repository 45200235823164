import { useDispatch, useSelector } from "react-redux"
import { useMemo } from "react"
import Modal from "../../../common/Modal/Modal"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { checkEmailStatusAction, saveBrokerDataNew } from "../redux/BrokerAction"
import { updateBrokerFieldsNew } from "../redux/brokerSliceNew"
import { useQuery } from "../../../hooks/useQuery"
import { DEAL_ID_QUERY_PARAM } from "../../application/ApplicationPage/ApplicationPageConstants"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { useHistory } from "react-router-dom"

dayjs.extend(duration)

const BrokerEmailVerification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()

  const { verificationData: data } = useSelector(state => state?.brokerReducerNew)

  const { id, verifyEmail } = useMemo(() => data ?? {}, [data])

  const checkEmailStatus = async () => {
    await dispatch(
      checkEmailStatusAction(id, cbData => {
        if (cbData?.isEmailVerified) {
          dispatch(
            updateBrokerFieldsNew({
              fieldName: "verificationData",
              value: { id },
            }),
          )
          dispatch(
            saveBrokerDataNew(id, query.get(DEAL_ID_QUERY_PARAM), response => {
              history.push(`${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM}/${response[0].applicationId}`)
            }),
          )
        }
      }),
    )
  }

  const buttons = [
    {
      title: "Click To Continue",
      buttonType: "secondary",
      onClick: checkEmailStatus,
      className: `smallButton`,
    },
  ]

  const handleClose = () => {
    dispatch(
      updateBrokerFieldsNew({
        fieldName: "verificationData",
        value: null,
      }),
    )
  }

  return (
    <>
      {verifyEmail && (
        <Modal
          header="Verification Code"
          bodyClassName="change-password-modal"
          isOutSideClick={false}
          closeIcon="close"
          onClick={handleClose}
          buttons={buttons}
        >
          <div className="align-center mb-10">We have sent to you an email please verify to continue.</div>
        </Modal>
      )}
    </>
  )
}

export default BrokerEmailVerification
