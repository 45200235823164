import Modal from "../../Modal/Modal"
import { useState } from "react"
import Input from "../../Input/Input"
import PropTypes from "prop-types"
import _ from "lodash"
import { SHA256 } from "crypto-js"
import { useDispatch, useSelector } from "react-redux"
import { changePassword } from "../Redux/headerAction"
import { REGEX } from "../../../constants/regexConstants"

const ChangePassword = ({ isOpen, onClose }) => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState({})

  const { isChangePasswordLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const dispatch = useDispatch()

  const resetFields = () => {
    setNewPassword("")
    setConfirmPassword("")
    setError({})
  }

  const onPasswordChange = async () => {
    const errors = {}
    if (_.isEmpty(newPassword)) errors.newPassword = "Please enter new password"
    if (_.isEmpty(confirmPassword)) errors.confirmPassword = "Please confirm password"
    if (newPassword && !REGEX.PASSWORD_REGEX.test(newPassword?.toString().trim()))
      errors.newPassword =
        "Please enter a password with at least 8 characters, one uppercase letter, one lowercase letter, one special character and one number"

    if (newPassword && confirmPassword && newPassword?.toString().trim() !== confirmPassword?.toString().trim())
      errors.confirmPassword = "New password and confirm password must be same"

    if (_.isEmpty(errors)) {
      setError({})
      try {
        const data = {
          newPassword: SHA256(newPassword?.toString().trim()).toString(),
          setDefaultPassword: false,
        }
        await dispatch(changePassword(data))
        resetFields()
        onClose()
      } catch (e) {
        /**/
      }
    } else setError(errors)
  }

  const changePasswordModalButtons = [
    {
      title: "Close",
      buttonType: "outlined-primary",
      onClick: () => {
        resetFields()
        onClose()
      },
    },
    {
      title: "Save",
      buttonType: "primary",
      isLoading: isChangePasswordLoader,
      onClick: onPasswordChange,
    },
  ]

  return (
    <>
      {isOpen && (
        <Modal
          header="Change Password"
          buttons={changePasswordModalButtons}
          bodyClassName="change-password-modal"
          hideModal={onClose}
        >
          <div className="change-password-grid">
            <div>
              <Input
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                name="newPassword"
                className="w-100"
                error={error?.newPassword}
              />
            </div>
            <div>
              <Input
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                name="confirmPassword"
                className="w-100"
                error={error?.confirmPassword}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

ChangePassword.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChangePassword
