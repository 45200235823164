import PropTypes from "prop-types"

const RateCardFilters = ({ label, icon }) => {
  return (
    <div className="summary-box">
      <span className="material-icons-outlined summary-icon">{icon}</span>
      <span className="font-primary">{label}</span>
    </div>
  )
}

RateCardFilters.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.icon,
}

export default RateCardFilters
