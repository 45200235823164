import PropTypes from "prop-types"
import { RenderField } from "../../application/components/RenderField"

const RepeaterForm = ({ field, onFieldUpdate, onAdd, onRemove, currentForm }) => (
  <div className="repeater-wrapper repeater-questions">
    <div className="repeater-head">
      {field?.question || ""}
      <span className="material-icons-outlined repeater-add-icon" onClick={onAdd}>
        control_point
      </span>
    </div>
    <table className="repeater-table">
      {field?.fields?.length ? (
        field.fields.map((f, index) => (
          <tr key={index} className={`row${index} repeater-rows`}>
            {f?.map((e, innnerIndex) => {
              return (
                <td key={`${e.key}-${e.fieldName}`} style={{ minWidth: "200px" }}>
                  <RenderField
                    field={e}
                    isFromAssetPage={false}
                    indexForRepeater={index}
                    isFromBrokerPage
                    isFromBrokerPageNew
                    currentForm={currentForm}
                    onFieldUpdate={(key, value) => onFieldUpdate(index, innnerIndex, e, value)}
                    errorMessage={e?.errorMessage || ""}
                  />
                </td>
              )
            })}
            {(field?.fieldsToRender?.length || field?.fields?.length > 1) && (
              <td className="repeater-remove-cell">
                <span className="material-icons-outlined repeater-remove-icon" onClick={() => onRemove(index)}>
                  remove_circle_outline
                </span>
              </td>
            )}
          </tr>
        ))
      ) : (
        <div className="no-entry">
          <span className="material-icons-outlined" onClick={onAdd}>
            control_point
          </span>
          <span onClick={onAdd}>Add Entry</span>
        </div>
      )}
    </table>
  </div>
)

RepeaterForm.propTypes = {
  // field to show
  field: PropTypes.object,
  // call when field changes
  onFieldUpdate: PropTypes.func,
  errorMessage: PropTypes.string,
  handleSearch: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  currentForm: PropTypes.array,
}

export default RepeaterForm
