import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { checkFormCount } from "../../helpers/QuoteProgressBarHelper"

const ProgressBar = () => {
  const { quoteAnswersData } = useSelector(({ quoteReducer }) => quoteReducer ?? {})
  const [style, setStyle] = useState({})
  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${checkFormCount(quoteAnswersData)}%`,
      }
      setStyle(newStyle)
    }, 200)
  }, [quoteAnswersData])
  return (
    <div className="progress">
      <div className="progress-done" style={style}></div>
    </div>
  )
}
export default ProgressBar
