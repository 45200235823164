import _ from "lodash"
import { successNotification, warningNotification } from "../../../common/NotifyToaster"
import { displayErrors } from "../../../helpers/ErrorNotifyHelper"
import { saveAuthTokenToLocalStorage } from "../../../helpers/LocalStorageHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { handleSaveAsDraftQuote, handleSubmitQuote } from "../../quote/redux/QuoteActions"
import { handleSubmitEquifax } from "../../../screens/auth/EquifaxPage/redux/EquifaxActions"
import AuthApiService from "../services/AuthApiService"
import { AUTH_REDUX_CONSTANTS } from "./AuthReduxConstants"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { SHA256 } from "crypto-js"
import { otpApiService } from "../OTPPage/services/otpApiService"

export const loginUser = ({ password, ...loginDetails }, history, quoteDraftDetails) => {
  return async dispatch => {
    startLoader("isLoginLoader")
    try {
      const data = { ...loginDetails, password: SHA256(password).toString() }
      const zohoId = quoteDraftDetails?.zohoId
      if (!zohoId) {
        const response = await AuthApiService.loginUser(data)

        if (response?.data?.status === "SUCCESS") {
          successNotification(response?.data?.message)
          dispatch({
            type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
            status: true,
          })
          const { token } = response?.data?.data
          saveAuthTokenToLocalStorage(token)

          if (quoteDraftDetails?.isRedirectedFromQuote && !quoteDraftDetails?.isQuoteSubmit) {
            dispatch(
              handleSaveAsDraftQuote(
                {
                  ...quoteDraftDetails?.quoteFormData,
                  userId: response?.data?.data?.userId,
                },
                history,
              ),
            )
          } else if (quoteDraftDetails?.isRedirectedFromQuote && quoteDraftDetails?.isQuoteSubmit) {
            const isSubmitted = await dispatch(
              handleSubmitQuote({
                ...quoteDraftDetails?.quoteFormData,
                userId: response?.data?.data?.userId,
                isSubmit: true,
              }),
            )
            if (_.isEmpty(quoteDraftDetails?.equifaxDataFinal) && isSubmitted) {
              history.replace(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)
            } else {
              if (quoteDraftDetails?.equifaxDataFinal?.creditScore > 0) {
                const isSubmittedEquifax = await dispatch(
                  handleSubmitEquifax({
                    ...quoteDraftDetails?.equifaxDataFinal,
                    userId: response?.data?.data?.userId,
                  }),
                )
                if (isSubmitted && isSubmittedEquifax) history.replace(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)
              } else {
                if (isSubmitted) history.replace(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)
              }
            }
          } else history.push(ROUTE_CONSTANTS_VARIABLE.DASHBOARD)
        }
        if (response?.data?.status === "WARN") {
          warningNotification(response?.data?.message)
        }
      } else {
        const response = await AuthApiService.docLoginUser(data, zohoId)
        if (response?.data?.status === "SUCCESS") {
          successNotification(response?.data?.message)
          dispatch({
            type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
            status: true,
          })
          const token = response?.data?.data?.authToken
          saveAuthTokenToLocalStorage(token)
          const applicationId = response?.data?.data?.applicationId
          history.push(`${ROUTE_CONSTANTS_VARIABLE.EDIT_APPLICATION}/${applicationId}`)
        }
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isLoginLoader")
    }
  }
}

export const onlyLoginUser = ({ password, ...loginDetails }) => {
  return async dispatch => {
    startLoader("isLoginLoader")
    try {
      const data = { ...loginDetails, password: SHA256(password).toString() }

      const response = await AuthApiService.loginUser(data)

      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: true,
        })
        const { token } = response?.data?.data
        saveAuthTokenToLocalStorage(token)
      }
      if (response?.data?.status === "WARN") {
        warningNotification(response?.data?.message)
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isLoginLoader")
    }
  }
}

export const getApplicationDetail = zohoId => {
  return async dispatch => {
    startLoader("isLoginLoader")
    try {
      const response = await AuthApiService.docWithLoginUser({}, zohoId)
      if (response?.data?.status === "SUCCESS") {
        const applicationId = response?.data?.data?.applicationId
        return `${ROUTE_CONSTANTS_VARIABLE.EDIT_APPLICATION}/${applicationId}`
      } else {
        return ROUTE_CONSTANTS_VARIABLE.DASHBOARD
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isLoginLoader")
    }
  }
}

export const verifyEmailAddressUsingOTP = data => {
  return async dispatch => {
    startLoader("isVerifyEmailLoader")
    try {
      const response = await AuthApiService.getEmailOTP(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
    } finally {
      stopLoader("isVerifyEmailLoader")
    }
    return false
  }
}
export const getOTPAction = data => {
  return async dispatch => {
    startLoader("isVerifyPhoneLoader")
    try {
      const response = await AuthApiService.getOTP(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isVerifyPhoneLoader")
    }
  }
}
export const verifyOTPAction = data => {
  return async dispatch => {
    startLoader("isVerifyPhoneLoader")
    try {
      const response = await otpApiService.verifyMobileUpdate(data)
      if (response?.data?.status === "SUCCESS") {
        successNotification(response?.data?.message)
        return true
      }
    } catch (e) {
      displayErrors(e)
      return false
    } finally {
      stopLoader("isVerifyPhoneLoader")
    }
  }
}
