import { forwardRef } from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import PropTypes from "prop-types"
import Input from "../Input/Input"
import dayjs from "dayjs"
import "bootstrap-daterangepicker/daterangepicker.css"
import { DATE_FORMAT } from "../../constants/regexConstants"

const getDate = date => {
  return date ? dayjs(date).format(DATE_FORMAT.DDMMYYYY) : ""
}

const CalendarInput = forwardRef(({ value, onClick, error, ...restProps }, ref) => (
  <>
    <Input
      placeholder={restProps?.placeholderText}
      {...(error !== undefined ? (error = { error }) : {})}
      onClick={onClick}
      ref={ref}
      value={
        value ||
        (restProps?.startDate &&
          restProps?.endDate &&
          `${getDate(restProps?.startDate)} - ${getDate(restProps?.endDate)}`)
      }
    />
  </>
))

// eslint-disable-next-line react/prop-types
const Calendar = ({ className, error, ...restProps }) => {
  CalendarInput.displayName = "CalendarInput"
  const d = new Date()
  const year = d.getFullYear()
  const pastyear = d.getFullYear() - 20
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format(DATE_FORMAT.MMDDYYYY) + " - " + picker.endDate.format(DATE_FORMAT.MMDDYYYY),
    )
  }

  const handleCancel = (event, picker) => {
    picker.element.val("")
  }
  return (
    <div className={`date-picker-container ${className}`}>
      <DateRangePicker
        initialSettings={{
          autoUpdateInput: true,
          locale: {
            format: DATE_FORMAT.DDMMYYYY,
            cancelLabel: "Clear",
          },
          showDropdowns: false,
          autoApply: false,
          maxYear: year,
          minYear: pastyear,
          maxDate: d,
        }}
        onApply={handleApply}
        onCancel={handleCancel}
      >
        <CalendarInput
          placeholderText={restProps?.placeholderText}
          startDate={restProps?.startDate}
          endDate={restProps?.endDate}
          {...(error !== undefined ? (error = { error }) : {})}
        />
      </DateRangePicker>
    </div>
  )
}

Calendar.propTypes = {
  className: PropTypes.string,
}

Calendar.defaultProps = {
  className: "",
}

CalendarInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
}

CalendarInput.defaultProps = {
  value: null,
  onClick: () => {},
  error: null,
}

export default Calendar
