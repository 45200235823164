import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getApplicationFilledData, getApplicationToolJSON } from "../redux/ApplicaitonActions"
import Accordion from "../../../common/Accordion/Accordion"
import ViewApplicationSingle from "../ViewAplicationPage/ViewApplicationSingle"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { restructureAllViewAPIData } from "../../../helpers/applicationHelper"
import { applicationSelector } from "../redux/ApplicationSelector"
import Loader from "../../../common/loader/Loader"
import PropTypes from "prop-types"
import {
  getBrokerPathData,
  getBrokerQuote,
  getBrokerRateCard,
  storeRateCardData,
} from "../../broker/redux/BrokerAction"
import ViewQuoteSingle from "../../quote/ViewQuotePage/ViewQuoteSingle"
import logo from "../../../assets/images/logo.svg"
import Carousel from "react-elastic-carousel"
import RateCardDetails from "../../RateCard/components/RateCardDetails"
import RateCardMobile from "../../RateCard/components/RateCardMobile"
import {
  getBrokerPathDataNew,
  getBrokerQuoteNew,
  getBrokerRateCardNew,
  storeRateCardDataNew,
} from "../../brokernew/redux/BrokerAction"
import { getQueryParams } from "../../brokernew/helpers/queryParams"
import { DEAL_ID_QUERY_PARAM } from "../ApplicationPage/ApplicationPageConstants"
// import { errorNotification } from "../../../common/NotifyToaster"

const ViewAplicationPage = ({ isFromBroker = false, isFromBrokerNew = false }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const { isApplicationCommonLoader, brokerLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})
  const [applicationDetail, setApplicationDetail] = useState({})
  const [apicall, setAPICall] = useState(false)
  useEffect(() => {
    ;(async () => {
      try {
        if ((authStatus && id) || isFromBroker || isFromBrokerNew) {
          await dispatch(getApplicationToolJSON())
          await dispatch(getApplicationFilledData(id, isFromBroker, isFromBrokerNew))
          setAPICall(false)
          if (isFromBroker) {
            await dispatch(getBrokerQuote(id))
            await dispatch(getBrokerRateCard(id))
            await dispatch(getBrokerPathData(id))
            setAPICall(true)
          }
          if (isFromBrokerNew) {
            await dispatch(getBrokerQuoteNew(id))
            await dispatch(getBrokerRateCardNew(id))
            await dispatch(getBrokerPathDataNew(id))
            setAPICall(true)
          }
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])

  const { brokerQuoteData = {}, rateData = {} } = useSelector(state => state?.brokerReducer)
  const { brokerQuoteDataNew = {}, rateDataNew = {}, zohobrokerid } = useSelector(state => state?.brokerReducerNew)
  const { viewApplicationData, applicationJsonData } = useSelector(applicationSelector)
  useEffect(() => {
    if (viewApplicationData?.application !== undefined) {
      const newData = {}
      for (const mainKey in viewApplicationData?.application) {
        if (Array.isArray(viewApplicationData?.application[mainKey])) {
          newData[mainKey] = restructureAllViewAPIData(
            viewApplicationData?.application[mainKey],
            mainKey,
            applicationJsonData,
          )
        } else {
          newData[mainKey] = { ...viewApplicationData?.application[mainKey] }
        }
      }
      setApplicationDetail(newData)
    }
  }, [viewApplicationData])

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 475, itemsToShow: 2 },
    { width: 775, itemsToShow: 3 },
    { width: 1075, itemsToShow: 4 },
    { width: 1375, itemsToShow: 5 },
    { width: 1675, itemsToShow: 6 },
  ]
  const [isViewMore, setIsViewMore] = useState(false)
  const [makeBtnDisable, setmakeBtnDisable] = useState(false)

  const handleRateSelectBroker = async (rid, repayments) => {
    if (rid) {
      await dispatch(
        storeRateCardData({ id, rateCardId: rid, repayments: repayments }, res => {
          if (res) {
            setmakeBtnDisable(res)
          } else {
            // errorNotification("Error in application submission.")
          }
        }),
      )
    }
  }
  const handleRateSelectBrokerNew = async (rid, repayments) => {
    if (rid) {
      await dispatch(
        storeRateCardDataNew({ id, rateCardId: rid, repayments: repayments }, res => {
          if (res) {
            setmakeBtnDisable(res)
          } else {
            // errorNotification("Error in application submission.")
          }
        }),
      )
    }
  }

  const getDataFromResponse = (key, response) => {
    switch (key) {
      case "companyDetails": {
        return (
          <ViewApplicationSingle
            isFromBroker
            isFromBrokerNew
            data={response}
            formkey={key}
            formName={"Company Details"}
            key={500}
            index={500}
          />
        )
      }
      case "applicantDetails": {
        return (
          <ViewApplicationSingle
            formName={"Applicant Details "}
            formkey={key}
            isFromBroker
            isFromBrokerNew
            data={response}
            key={1000}
            index={1000}
          />
        )
      }
      case "disclosure": {
        return (
          <ViewApplicationSingle
            formName={"Disclosure Details "}
            formkey={key}
            isFromBroker
            isFromBrokerNew
            data={response}
            key={2000}
            index={2000}
          />
        )
      }
      // case "signatureDetails": {
      //   return <ViewApplicationSingle formName={"Signature "} formkey={key} data={response} key={2500} index={2500} />
      // }
      case "serviceability": {
        return (
          <ViewApplicationSingle
            isFromBroker
            isFromBrokerNew
            formName={"Serviceability "}
            formkey={key}
            data={response}
            key={3500}
            index={3500}
          />
        )
      }

      case "assets": {
        return (
          <ViewApplicationSingle
            formName={"Asset Details "}
            isFromBroker
            isFromBrokerNew
            formkey={key}
            data={response?.assetData}
            key={1500}
            index={1500}
            applicationJsonData={applicationJsonData}
          />
        )
      }

      case "quoteDetails": {
        return (
          <ViewApplicationSingle
            isFromBroker
            isFromBrokerNew
            formName={"Quote Details"}
            formkey={key}
            data={response}
            key={3000}
            index={3000}
          />
        )
      }
      default:
        return <></>
    }
  }

  // console.log("rateData", rateDataNew, brokerQuoteDataNew)

  return (
    <React.Fragment>
      {!isFromBroker && !isFromBrokerNew && (
        <Button
          buttonType="secondary"
          onClick={() => history.push(`${ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST}/`)}
          className="mb-15"
        >
          Back
        </Button>
      )}
      {(isFromBroker || isFromBrokerNew) && (
        <div className="header-container logo-login-button">
          <div>
            <img src={logo} alt="Simply Finance" title="Simply Finance" />
          </div>
        </div>
      )}
      <div className="page-container page-container-padding">
        {isFromBroker && (
          <>
            <h2 className="application-form-heading">Quote</h2>
            <Accordion className="view-application-accordion">
              {isFromBroker && brokerQuoteData.length > 0 && brokerQuoteData !== undefined
                ? brokerQuoteData.map((task, index) => <ViewQuoteSingle data={task} key={index} index={index} />)
                : ""}
            </Accordion>
            <h2 className="application-form-heading">Application</h2>
          </>
        )}
        {isFromBrokerNew && (
          <>
            <h2 className="application-form-heading">Quote</h2>
            <Accordion className="view-application-accordion">
              {isFromBrokerNew && brokerQuoteDataNew?.length > 0 && brokerQuoteDataNew !== undefined
                ? brokerQuoteDataNew.map((task, index) => <ViewQuoteSingle data={task} key={index} index={index} />)
                : ""}
            </Accordion>
            <h2 className="application-form-heading">Application</h2>
          </>
        )}

        {!isApplicationCommonLoader && (
          <Accordion className="view-application-accordion applicationsingle">
            {Object.keys(applicationDetail).map(key => getDataFromResponse(key, applicationDetail[key]))}
          </Accordion>
        )}
        {!makeBtnDisable && (
          <>
            {isFromBroker && rateDataNew?.rateCards?.length ? (
              <>
                <h2 className="application-form-heading centerAlign">Select Rate</h2>
                <section className="rate-card-container">
                  <Carousel itemsToShow={4} pagination={false} itemPadding={[10, 10]} breakPoints={breakPoints}>
                    {rateData?.rateCards?.length &&
                      rateData?.rateCards?.map((e, index) => (
                        <React.Fragment key={index}>
                          <RateCardDetails
                            data={e}
                            onCardSelect={handleRateSelectBroker}
                            applicationId={id}
                            isOpenView={isViewMore}
                            isMobileView={false}
                          />
                        </React.Fragment>
                      ))}
                  </Carousel>
                  <div className="p-20 text-center">
                    <span
                      className="font-primary font-underline cursor-pointer"
                      onClick={() => setIsViewMore(prev => !prev)}
                    >
                      {!isViewMore ? "Click Here For More Info" : "View less"}
                    </span>
                  </div>
                </section>
                <RateCardMobile
                  rateCards={rateData?.rateCards?.map((e, index) => ({ ...e, index: index }))}
                  onCardSelect={handleRateSelectBroker}
                  isOpenView
                />
              </>
            ) : isFromBrokerNew && rateDataNew?.summary?.length ? (
              <>
                <h2 className="application-form-heading centerAlign">Select Rate</h2>
                <section className="rate-card-container">
                  <Carousel itemsToShow={4} pagination={false} itemPadding={[10, 10]} breakPoints={breakPoints}>
                    {rateDataNew?.rateCards?.length &&
                      rateDataNew?.rateCards?.map((e, index) => (
                        <React.Fragment key={index}>
                          <RateCardDetails
                            data={e}
                            onCardSelect={handleRateSelectBrokerNew}
                            applicationId={id}
                            isOpenView={isViewMore}
                            isMobileView={false}
                          />
                        </React.Fragment>
                      ))}
                  </Carousel>
                  <div className="p-20 text-center">
                    <span
                      className="font-primary font-underline cursor-pointer"
                      onClick={() => setIsViewMore(prev => !prev)}
                    >
                      {!isViewMore ? "Click Here For More Info" : "View less"}
                    </span>
                  </div>
                </section>
                <RateCardMobile
                  rateCards={rateDataNew?.rateCards?.map((e, index) => ({ ...e, index: index }))}
                  onCardSelect={handleRateSelectBroker}
                  isOpenView
                />
              </>
            ) : (
              apicall &&
              (!rateData?.rateCards || rateData?.rateCards?.length === 0) && (
                <div className={`${brokerLoader} font-primary mt-20 text-center`}>No Rate Cards available</div>
              )
            )}
          </>
        )}
        {makeBtnDisable && (
          <section className="quote-tool-container">
            <div className="quote-tool-form-content">
              <div className="question">
                <p className="question_text">
                  Application submitted successfully. To edit visit ZOHO CRM and search{" "}
                  {getQueryParams(DEAL_ID_QUERY_PARAM) || zohobrokerid} deal and click edit application button
                </p>
                <Button
                  isDisabled={false}
                  buttonType="outlined-primary"
                  onClick={() =>
                    window.location.replace(
                      `https://crm.zoho.com/crm/simply/tab/Potentials/${getQueryParams(DEAL_ID_QUERY_PARAM) ||
                        zohobrokerid}`,
                    )
                  }
                >
                  Edit Application
                </Button>
              </div>
            </div>
          </section>
        )}
        <Loader isLoading={isApplicationCommonLoader || brokerLoader} />
      </div>
    </React.Fragment>
  )
}
export default ViewAplicationPage

ViewAplicationPage.propTypes = {
  isFromBroker: PropTypes.bool,
  isFromBrokerNew: PropTypes.bool,
}
