import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import { useMemo } from "react"

const ViewProfile = () => {
  const userDetail = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const data = useMemo(
    () => [
      { label: "First Name", value: userDetail?.firstName },
      { label: "Last Name", value: userDetail?.lastName },
      { label: "Email", value: userDetail?.email || "-" },
      { label: "Mobile Number", value: userDetail?.countryCode + " " + userDetail?.mobileNumber },
    ],
    [userDetail],
  )
  const history = useHistory()
  return (
    <>
      <div className="page-header-row">
        <div className="content-header">View Profile</div>
      </div>
      <div className="profile-wrapper">
        {data?.map(e => (
          <div className="view-profile-row" key={e}>
            <div>{e?.label}</div>
            <div>{e?.value}</div>
          </div>
        ))}
        <Button
          buttonType="secondary"
          title="Edit Profile"
          className="form-button"
          onClick={() => history.push(`${ROUTE_CONSTANTS_VARIABLE.EDIT_PROFILE}`)}
        />
      </div>
    </>
  )
}
export default ViewProfile
