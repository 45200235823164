import { activeSaveQuote, checkToShowEquifax } from "../screens/quote/redux/QuoteActions"
import { isEmpty } from "lodash"

export const getModifiedVehicleSelectForDefaultOption = obj => {
  return Object.keys(obj).reduce((accumulator, key) => {
    if (
      ["assetMake", "assetModel", "assetBadge", "assetSeries"].includes(key) &&
      obj?.[key] === "optionToSkipOnVehicleSelect"
    ) {
      accumulator[key] = ""
    } else accumulator[key] = obj?.[key]
    return accumulator
  }, {})
}

export const checkEquifaxShow = async dispatch => {
  try {
    return await dispatch(checkToShowEquifax())
  } catch (e) {
    return false
  }
}
export const activeSave = async (
  userId,
  quoteId,
  glassGuideData,
  glassGuideLabel,
  quoteAnswersData,
  previousQuestions,
  currentForm,
  currentQuestion,
  isLastQuestion,
  isEquifaxFailed,
  creditScore,
  dispatch,
  canSkip,
) => {
  let data = {}
  const loanType = quoteAnswersData?.loanType?.financeWhat
  const residencyDetails = quoteAnswersData?.residency?.residencyDetails
  const employmentDetails = quoteAnswersData?.employment?.employmentStatus
  if (isLastQuestion) {
    if (
      (loanType !== "Asset Finance" && loanType !== "Personal Loan") ||
      residencyDetails === "Visa" ||
      employmentDetails === "Unemployed"
    ) {
      isEquifaxFailed = !isEquifaxFailed
    } else {
      if (creditScore < 450 && creditScore !== undefined) {
        isEquifaxFailed = !isEquifaxFailed
      }
    }
  }
  if (isLastQuestion !== undefined) {
    data = {
      userId,
      isActiveSubmit: isLastQuestion,
      isEquifaxFailed: isEquifaxFailed,
      _id: quoteId,
      quoteAnswersData: {
        ...quoteAnswersData,
        [currentForm]: {
          ...quoteAnswersData?.[currentForm],
          isCompleted: !!quoteAnswersData?.[currentForm]?.isCompleted,
          isBlocked: !!quoteAnswersData?.[currentForm]?.isBlocked,
        },
      },
      glassGuideData,
      glassGuideLabel,
      previousQuestions: [
        ...previousQuestions,
        ...(!canSkip
          ? [
              {
                form: currentForm,
                question: currentQuestion,
              },
            ]
          : []),
      ],
    }
  } else {
    data = {
      userId,
      isActiveSubmit: isLastQuestion,
      isEquifaxFailed: isEquifaxFailed,
      _id: quoteId,
      quoteAnswersData: {
        ...quoteAnswersData,
        [currentForm]: {
          ...quoteAnswersData?.[currentForm],
          isCompleted: !!quoteAnswersData?.[currentForm]?.isCompleted,
          isBlocked: !!quoteAnswersData?.[currentForm]?.isBlocked,
        },
      },
      glassGuideData,
      glassGuideLabel,
      previousQuestions: [
        ...previousQuestions,
        ...(!canSkip
          ? [
              {
                form: currentForm,
                question: currentQuestion,
              },
            ]
          : []),
      ],
    }
  }

  try {
    // when no data on glass guide call, need to show no asset option and remove on sending data back to BE
    if (
      !isEmpty(data?.quoteAnswersData?.vehicleSelect) &&
      !isEmpty(data?.glassGuideData) &&
      !isEmpty(data?.glassGuideLabel)
    ) {
      data = {
        ...data,
        quoteAnswersData: {
          ...data.quoteAnswersData,
          vehicleSelect: getModifiedVehicleSelectForDefaultOption(data?.quoteAnswersData?.vehicleSelect),
        },
        glassGuideData: getModifiedVehicleSelectForDefaultOption(data?.glassGuideData),
        glassGuideLabel: getModifiedVehicleSelectForDefaultOption(data?.glassGuideLabel),
      }
    }
    return await dispatch(activeSaveQuote(data, quoteId))
  } catch (e) {
    return false
  }
}
