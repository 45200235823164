export const formVisibilitySubForm = (targetSubFormData, fieldsToShow, index) => {
  if (!targetSubFormData?.formVisibility?.length) {
    return true
  }

  return targetSubFormData?.formVisibility?.reduce((acc, curr) => {
    const andResult = curr?.reduce((acc2, curr2) => {
      const { fieldName, value } = curr2
      const answer2 = fieldsToShow?.[index]?.find(e => e.fieldName === fieldName)?.answer
      return acc2 && answer2 === value
    }, true)

    return acc || andResult
  }, true)
}
