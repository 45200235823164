import Modal from "../../../common/Modal/Modal"
import Input from "../../../common/Input/Input"
import { useEffect, useState } from "react"
import _ from "lodash"
import { validateEmail } from "../../../helpers/ValidationHelper"
import { useDispatch, useSelector } from "react-redux"
import { verifyEmailAddressUsingOTP, verifyOTPAction } from "../redux/AuthActions"
import PropTypes from "prop-types"
import { useMediaQuery } from "../../../hooks/useMediaQuery"
import { onResendOtp } from "../OTPPage/redux/otpAction"
import OtpInput from "../../../common/OtpInput/OtpInput"
import Button from "../../../common/Button/Button"
import dayjs from "dayjs"

const otpLength = 6

const ChangeEmailModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  const [isVerificationSent, setIsVerificationSent] = useState(false)
  const [counter, setCounter] = useState(300)
  const isMobileScreen = useMediaQuery("(max-width: 480px)")

  const { isVerifyEmailLoader, isVerifyPhoneLoader } = useSelector(({ loaderReducer }) => loaderReducer ?? {})

  const dispatch = useDispatch()

  const resetFields = () => {
    setEmail(null)
    setError(null)
    setIsVerificationSent(false)
  }

  const handleClose = (isOTPVerify = false) => {
    setCounter(300)
    if (isOTPVerify === true) {
      onClose(email)
    } else onClose()
    resetFields()
  }

  const onEmailChange = async () => {
    let errors = null
    if (_.isEmpty(email)) errors = "Please enter email address"
    if (email && !validateEmail(email)) errors = "Please enter valid email address"
    if (!errors) {
      setError(null)
      const isOTPSent = await dispatch(verifyEmailAddressUsingOTP({ email: email }))
      setIsVerificationSent(isOTPSent)
    } else setError(errors)
  }

  const onSubmitOtp = async otpData => {
    if (otpData && otpData.length === otpLength) {
      const data = {
        otp: Number(otpData.join("")),
        email,
      }
      const isOTPVerify = await dispatch(verifyOTPAction(data))
      if (isOTPVerify) {
        handleClose(true)
      } else handleClose()
    }
  }

  const onResetOtp = async () => {
    const data = {
      email: email,
    }
    const isOtpResend = await dispatch(onResendOtp(data))
    if (isOtpResend) {
      setCounter(180)
    }
  }

  const changeEmailModalButtons = [
    {
      title: "Close",
      buttonType: "outlined-primary",
      onClick: handleClose,
    },
    {
      title: "Save",
      buttonType: "primary",
      isLoading: isVerifyEmailLoader || isVerifyPhoneLoader,
      onClick: onEmailChange,
    },
  ]

  useEffect(() => {
    let timer
    if (isVerificationSent) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    }
    return () => clearInterval(timer)
  }, [counter, isVerificationSent])

  return (
    <>
      {isOpen && (
        <Modal
          header="Update Email"
          buttons={!isVerificationSent ? changeEmailModalButtons : changeEmailModalButtons?.slice(0, 1)}
          hideModal={onClose}
          isOutSideClickAllowed={false}
        >
          {!isVerificationSent ? (
            <Input
              type="email"
              className="w-100 mt-15"
              name="email"
              value={email}
              placeholder="Email address"
              onChange={e => {
                setEmail(e.target.value?.toString()?.trim())
              }}
              error={error}
            />
          ) : (
            <>
              <div className="otp-info-text">{`Code will be sent to ${email}`}</div>
              <OtpInput
                otpInputs={otpLength}
                otpBoxHeight={isMobileScreen ? 45 : 55}
                otpBoxWidth={isMobileScreen ? 45 : 55}
                onOtpFill={onSubmitOtp}
                otpBoxWithValueClass="border-primary"
                onFocusOtpBoxClass="border-secondary"
              />
              <div className="otp-resend-button">
                <Button
                  title="Resend"
                  className={counter > 0 ? "otp-resend-button-disabled" : ""}
                  buttonType="secondary"
                  onClick={onResetOtp}
                  isDisabled={counter > 0 || isVerifyEmailLoader || isVerifyPhoneLoader}
                />
              </div>
              {counter > 0 && (
                <div className="otp-reset-text">
                  Resend code in <span>{dayjs.duration(counter, "seconds").format("mm:ss")}</span>
                </div>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  )
}

ChangeEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChangeEmailModal
