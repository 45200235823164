import { NavLink } from "react-router-dom"
import error from "../../src/assets/images/error-404.png"
import { getAuthTokenFromLocalStorage } from "../helpers/LocalStorageHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../routes/RouteConstants"

const PageNotFound = () => {
  const token = getAuthTokenFromLocalStorage()
  return (
    <center className="page-not-found">
      <img src={error} />
      <div>Page Not Found</div>
      <NavLink className="mt-15" to={token ? ROUTE_CONSTANTS_VARIABLE.DASHBOARD : ROUTE_CONSTANTS_VARIABLE.LOGIN}>
        Back to {token ? "Dashboard" : "Login"}
      </NavLink>
    </center>
  )
}

export default PageNotFound
